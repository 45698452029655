import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';

class DashboardItemMobileView extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    hideItemOnMobile = async () => {
        const {item} = this.props;
        try {
            await Api.Dash.changeMobileItemView({id: item.id, availableOnMobile: !item.availableOnMobile});
            await Api.updateJsf();
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    render() {
        return (
            <li onClick={this.hideItemOnMobile}>
                <DashboardItemMenuTitle icon="remove_red_eye"
                                        title={this.props.context.msg.t(this.props.item.availableOnMobile ? 'hide.item.on.mobile' : 'show.item.on.mobile')}
                                        more={false}
                />
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMobileView);