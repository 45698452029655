"use strict";

import {Axios} from "commonUtils";

const $ = jQuery;


class MapApi {

    static filterItems(content, filterName) {
        return $.getJSON('/spr/maps/filter-items', {content, filterName}).then(data => {
            return {
                members: data[filterName].map(m => {
                    for (let key in m) {
                        return {value: key, label: m[key]};
                    }
                })
            };
        });
    }

    static saveAs = async ({folder, name, description}) => {
        const {data} = await Axios.post(`/spr/maps/save-as`, {folder, name, description});
        return data;
    };

}

export default MapApi;
