import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import BngMarkdownEditor from "components/bng/form/markdown/BngMarkdownEditor";

const MarkdownViewer = ({ className = "", value, onRef = _.noop }) => {
  const tuiRef = useRef();
  useEffect(() => {
    tuiRef.current?.setMarkdown(value);
  }, [value]);

  return (
    <BngMarkdownEditor
      className={`MarkdownViewer ${className}`}
      value={value}
      onTuiRef={(ref) => {
        tuiRef.current = ref;
        onRef(ref);
      }}
      viewer
      height={'auto'}
    />
  );
};

export const processMarkdown = (markdown) => {
  return new Promise((resolve) => {
    const container = document.createElement("div");
    const onRef = (ref) => {
      const html = ref?.preview?.getHTML();
      ReactDOM.unmountComponentAtNode(container);
      resolve(html);
    };
    ReactDOM.render(
      <MarkdownViewer value={markdown} onRef={onRef} />,
      container
    );
  });
};

export default MarkdownViewer;
