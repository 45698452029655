import './PublisherMenuButton.css';

import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";
import MenuButton from "components/ui/navbar/MenuButton";
import OpConfirmation from "components/ui/OpConfirmation";
import Api from 'components/Api'
import {MODALS} from "components/ui/redux/Actions";
import PublisherListDialog from "components/ui/publisher/PublisherListDialog";
import PublisherIncorporateLink from "components/ui/publisher/PublisherIncorporateLink";
import {publishSelectItems} from "components/ui/publisher/PublisherListDialog";
import Icon from "components/ui/common/Icon";
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

class PublisherMenuButton extends Component {

    static propTypes = {
        publications: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                caption: PropTypes.string.isRequired,
                icon: PropTypes.string.isRequired,
                user: PropTypes.string,
                path: PropTypes.string,
                visibility: PropTypes.string,
                publicationDate: PropTypes.string,
                config: PropTypes.object,
            })
        ),
        badgeValue: PropTypes.any
    };

    static defaultProps = {
        publications: [],
        badgeValue: 0
    };

    openPublisherListDialog = () => {
        this.props.dispatch(MODALS.open(PublisherListDialog, this.props));
    };

    openIncorporateDialog = (props) => {
        this.props.dispatch(MODALS.open(PublisherIncorporateLink, {
            ...props,
            onUpdate: _.noop
        }));
    };

    openSelectObjectDialog = async () => {
        publishSelectItems({...this.props});
    };

    verifyUserPermission = () => {
        let classname = "published-objects-list-header-fragment publisher-header-button-color";
        if (this.props.context.cockpitEnabled) {
            classname = "published-objects-list-header-fragment-cockpit-enable publisher-header-button-color";
        }

        if (this.props.context.userRole === 'Expert') {
            classname = "published-objects-list-header-fragment-expert publisher-header-button-color"
        }

        return classname;
    };

    onOpen = async () => {
      const publisherEnabled = checkAddonEnabled(AddonType.PUBLISHER.key, true);
      if (publisherEnabled) {
        await this.props.findPublications()
      }
      return publisherEnabled;
    }

    render() {
        return (
            <MenuButton className={'PublisherMenuButton'}
                        icon="public"
                        badge={{value: this.props.badgeValue}}
                        onOpen={this.onOpen}
                        title={this.props.context.msg.t('publications')}
                        buttonActionEnable={true}
                        buttonActionLabel={this.props.context.msg.t('create')}
                        buttonActionClick={() => {
                            this.openSelectObjectDialog();
                            MenuButton.closeAllMenus();
                        }}>
                {this.props.publications.length > 0 && this.props.publications.map(_pub =>

                    <li key={_pub.id} className="colouredTabBorder">
                        <a href="#" className="publisher-item-link">
                            <div className="row-fluid publisher-item-container">

                                <div className="publisher-item publisher-item-title">
                                    <Icon className={'publisher-fragment-icon'} icon={_pub.icon}></Icon>
                                    <span className="publisher-fragment-text"
                                          title={_pub.caption}>{_pub.caption}</span>
                                </div>

                                <div className="publisher-buttons">
                                    <Button icon="file_copy"
                                            title={this.props.context.msg.t('copy.link.embed')}
                                            className="btn-only-icon"
                                            onClick={() => {
                                                this.openIncorporateDialog({
                                                    path: _pub.path,
                                                    caption: _pub.caption,
                                                });

                                                MenuButton.closeAllMenus();
                                            }}/>

                                    <Button icon="delete"
                                            title={this.props.context.msg.t('unpublish')}
                                            className="btn-only-icon delete-only-icon"
                                            onClick={() => {
                                                OpConfirmation({
                                                    title: _pub.caption,
                                                    message: this.props.context.msg.t('published.object.remove.confirmation'),
                                                    onConfirm: () => Api.Publisher.removePublisher(_pub.id),
                                                    msg: this.props.context.msg
                                                });
                                                MenuButton.closeAllMenus();
                                            }}/>
                                </div>

                            </div>
                            <div className="row-fluid publisher-item-container">
                                <div className="publisher-item publisher-item-author">
                                    <Icon icon="account_circle"/>
                                    <span className="publisher-fragment-text publisher-fragment-text-author"
                                          title={_pub.user}> {this.props.context.msg.t('publisher.by')}
                                        &nbsp;<span style={{fontWeight: 'bolder'}}>{_pub.user}</span>
                                    </span>
                                </div>
                            </div>
                        </a>
                    </li>
                )}
                <li className="no-hover">
                    <div className="menu-bottom-btn">
                        <Button className="btn-link btn-small"
                                onClick={() => {
                                    this.openPublisherListDialog();
                                    MenuButton.closeAllMenus();
                                }}>
                            {this.props.context.msg.t('see.all.publications')}
                        </Button>
                    </div>
                </li>

            </MenuButton>
        );
    }

}


export default ContextEnhancer(connect()(PublisherMenuButton));