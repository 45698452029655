import "./OrgmapPropertiesDialog.css";
import React from "react";
import {Field, Form, Formik} from "formik";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Dialog from "components/ui/Dialog";
import UiMsg from "components/ui/UiMsg";
import {DefaultDialogActions} from "components/ui/FormUtils";
import {bngYup} from "components/bng/form/yup/BngYup";
import BngSwitch from "components/bng/form/BngSwitch";
import {BngField} from "components/bng/form/BngField";
import BngInputColor from "components/bng/form/BngInputColor";
import {FormikListener} from "components/bng/form/formik/FormikListener";

const MAX_LINES_MIN_VALUE = 1;

const OrgmapPropertiesSchema = bngYup(yup => {
    return yup.object().shape({
        interactiveMap: yup.boolean().required().default(true),
        limitLines: yup.boolean().required().default(false),
        maxLines: yup.number().required().min(MAX_LINES_MIN_VALUE).default(MAX_LINES_MIN_VALUE),
        startClosed: yup.boolean().required().default(false),
        startLine: yup.number().required().min(MAX_LINES_MIN_VALUE).default(MAX_LINES_MIN_VALUE),
        backgroundColor: yup.string().required(),
    });
});

const OrgmapPropertiesDialog = ({
                                    context = {},
                                    initialFormValues = OrgmapPropertiesSchema.default(),
                                    closeModal = _.noop()
                                }) => {
    const saveProperties = async (values, actions) => {
        actions.setSubmitting(true);
        try {
            await Api.OrgMap.saveProperties(values);
            await Api.updateJsf();
            closeModal();
        } catch (e) {
            UiMsg.error(null, e);
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik initialValues={initialFormValues}
                validationSchema={OrgmapPropertiesSchema}
                onSubmit={saveProperties}>
            {({values, isSubmitting, setFieldValue}) => {
                return (
                    <Dialog className="OrgmapPropertiesDialog"
                            loading={isSubmitting}
                            onClose={closeModal}
                            title={context.msg.t('properties')}>
                        <Form>
                            <FormikListener onChange={({values, setValues}) => {
                                // Enforce min/max value on fields width/height
                                const update = {};
                                for (const key of ['maxLines', 'startLine']) {
                                    const value = values[key];
                                    if (!value || value < MAX_LINES_MIN_VALUE) {
                                        update[key] = MAX_LINES_MIN_VALUE;
                                    }
                                }
                                if (!_.isEmpty(update)) {
                                    setValues({
                                        ...values,
                                        ...update
                                    });
                                }
                            }}
                            />

                            <Dialog.Body>
                                <div className="row-fluid">
                                    <span>{context.msg.t('orgmap.interactive.active')}</span>
                                    <Field name="interactiveMap"
                                           component={BngSwitch}/>
                                </div>
                                <div className="row-fluid">
                                    <span>{context.msg.t('orgmap.limit.max.lines')}</span>
                                    <Field name="limitLines"
                                           component={BngSwitch}/>
                                </div>
                                {values.limitLines &&
                                <div className="row-fluid">
                                    <span>{context.msg.t('orgmap.max.lines')}</span>
                                    <Field name="maxLines"
                                           type="number"
                                    />
                                </div>
                                }
                                <div className="row-fluid">
                                    <span>{context.msg.t('orgmap.start.shrink')}</span>
                                    <Field name="startClosed"
                                           component={BngSwitch}/>
                                </div>
                                {values.startClosed &&
                                <div className="row-fluid">
                                    <span>{context.msg.t('orgmap.start.level')}</span>
                                    <Field name="startLine"
                                           type="number"
                                    />
                                </div>
                                }
                                <div className="row-fluid">
                                    <span>{context.msg.t('background.color')}</span>
                                    <Field label=""
                                           name="backgroundColor"
                                           component={BngField}
                                           inputComponent={BngInputColor}
                                    />
                                </div>
                            </Dialog.Body>
                            <Dialog.Footer>
                                <DefaultDialogActions context={context}
                                                      submitting={isSubmitting}
                                                      closeModal={closeModal}/>
                            </Dialog.Footer>
                        </Form>
                    </Dialog>
                )
            }}
        </Formik>
    )
};

export default ContextEnhancer(OrgmapPropertiesDialog)