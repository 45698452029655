import React from 'react';

import BigTableDataForm from "components/bng/pages/bigTable/menus/BigTableDataForm";

class DataTab extends React.Component {
    render() {
        const {datasourceConfig, datasourceName, onDataFormSubmit, setOpenAccordion, dataFormSubmitted} = this.props;
        return (
            <div className="BngBigTable__menuTab ObjectRightMenuAccordion"
                 style={{backgroundColor: '#efefef'}}>
                <BigTableDataForm
                    onSubmit={onDataFormSubmit}
                    datasourceConfig={datasourceConfig}
                    setOpenAccordion={setOpenAccordion}
                    datasourceName={datasourceName}
                    dataFormSubmitted={dataFormSubmitted}
                    filters={this.props.filters}
                    projectFilters={this.props.projectFilters}
                />
            </div>
        );
    }
}

export default DataTab;