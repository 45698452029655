import React from "react";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import ObjectRightMenu from "components/ui/right-menu/ObjectRightMenu";


const MapRightMenu = (props) => {

    const saveLegacyMap = async ({submitForm = _.noop}) => {
        try {
            await Api.executeExp(`#{MapsBean.saveCurrentMap()}`);
            await submitForm();
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    const removeLegacyMap = () => {
        try {
            Api.executeExp(`#{MapsBean.removeCurrentMap()}`);
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    return (
        <ObjectRightMenu {...props}
                         save={saveLegacyMap}
                         remove={removeLegacyMap}
                         saveAs={Api.Map.saveAs}/>
    )
}

export default ContextEnhancer(MapRightMenu);