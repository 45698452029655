import styles from './BngColorSolid.module.css';
import transparentBar from './BngColorPickerTransparentBackgroundBar.png';

import converter from 'color-convert';
import React, { useEffect, useRef } from 'react';

import ColorUtils from 'components/bng/colorPicker/ColorUtils';
import BngColorPickerPreview from "components/bng/colorPicker/BngColorPickerPreview";

export default function BngColorSolid({
  color = { color: '#005dff', opacity: 1, position: 0, id: 0 },
  selectColor = _.noop,
  width = 330,
  setMarkerPosition = _.noop,
  markerPosition = [],
  transparencyEnabled = true,
  forceColorUpdate = false,
  setForceColorUpdate = _.noop,
  updateGradientBarColor = _.noop,
}) {
  const setColorFromElementPosition = (elementCtx, x, y) => {
    const pixel = elementCtx.getImageData(x ?? 1, y ?? 1, 1, 1)['data'];
    //Dirty fix for chromium based browsers, see #7168
    const roundedPixels = pixel.map(color => {
      //6 = approximate colorBar corner margin of error
      const pixelInflation = 6;
      if (color >= 255 - pixelInflation) {
        return 255;
      } else if (color <= pixelInflation) {
        return 0;
      } else {
        return color;
      }
    })
    const rgbToHex = converter.rgb.hex(roundedPixels[0], roundedPixels[1], roundedPixels[2]);
    hexColor.current.color = '#' + rgbToHex;
  };

  const updateColorBarPreview = () => {
    barColorPreview.current.style.backgroundColor = hexColor.current.color;
    barColorPreview.current.style.opacity = hexColor.current.opacity;
  }

  const updateCanvasMarkerColor = () => {
    let x = Math.max(0, canvasPixelPos.current[0]);
    let y = Math.max(0, canvasPixelPos.current[1]);

    setColorFromElementPosition(colorCanvasRef.current.getContext('2d'), x, y);
    canvasMarkerRef.current.style.backgroundColor = hexColor.current.color;
  };

  //Used mainly to get marker position while forcing it to respect the element size
  const getEventCoords = (event, element, calcY = true) => {
    let elementOffset = element.getBoundingClientRect();

    let x = Math.max(0, Math.floor(event.pageX - window.scrollX - elementOffset.left));

    if (x > element.clientWidth - 1) {
      x = element.clientWidth - 1;
    }

    if (calcY) {
      let y = Math.max(0, Math.floor(event.pageY - window.scrollY - elementOffset.top));

      if (y > element.scrollHeight - 1) {
        y = element.scrollHeight - 1;
      }

      return [x, y];
    } else {
      return [x];
    }
  };

  //Used to set and unset document listeners.
  //Document as a whole was used to make possible the infinite mouse move effect
  const setDocumentListeners = (event, onMoveFn = _.noop, onUpFn = _.noop) => {
    const cleanUpFn = (event) => {
      window.removeEventListener('mousemove', onMoveFn);
      onUpFn(event);
    };

    window.addEventListener('mousemove', onMoveFn);
    window.addEventListener('mouseup', cleanUpFn, { once: true });
  };

  const setColorBar = () => {
    const barCtx = colorBarRef.current.getContext('2d');

    let gradient = barCtx.createLinearGradient(0, 0, barCtx.canvas.width, 0);
    gradient.addColorStop(0, 'hsl(0deg, 100%, 50%)');
    gradient.addColorStop(0.1, 'hsl(36deg, 100%, 50%)');
    gradient.addColorStop(0.2, 'hsl(72deg, 100%, 50%)');
    gradient.addColorStop(0.3, 'hsl(108deg, 100%, 50%)');
    gradient.addColorStop(0.4, 'hsl(144deg, 100%, 50%)');
    gradient.addColorStop(0.5, 'hsl(180deg, 100%, 50%)');
    gradient.addColorStop(0.6, 'hsl(216deg, 100%, 50%)');
    gradient.addColorStop(0.7, 'hsl(252deg, 100%, 50%)');
    gradient.addColorStop(0.8, 'hsl(288deg, 100%, 50%)');
    gradient.addColorStop(0.9, 'hsl(324deg, 100%, 50%)');
    gradient.addColorStop(1, 'hsl(360deg, 100%, 50%)');

    barCtx.fillStyle = gradient;
    barCtx.fillRect(0, 0, barCtx.canvas.width, barCtx.canvas.height);
  };

  const updateTransparencyBarColor = () => {
    if (transparencyBarRef.current) {
      transparencyBarRef.current.style.backgroundImage = `linear-gradient(270deg, ${hexColor.current.color} 0%, rgba(0,0,0,0) 100%), url('${transparentBar}')`;
    }
  };

  const setTransparencyBar = () => {
    if (transparencyBarRef.current) {
      updateTransparencyBarColor();

      let markerInitialPosition = transparencyBarRef.current.clientWidth * hexColor.current.opacity;
      markerInitialPosition = Math.max(-5, markerInitialPosition - HALF_MARKER_SIZE);
      transparencyBarMarkerRef.current.style.left = markerInitialPosition + 'px';

      transparencyTextRef.current.innerText = Math.round(hexColor.current.opacity * 100) + '%';
    }
  };

  const updateCanvasColor = () => {
    if (shouldColorUpdate.current) {
      const canvasCtx = colorCanvasRef.current.getContext('2d');

      const maximumSpectrumColor = ColorUtils.hslToString([converter.hex.hsl(hexColor.current.color)[0], 100, 50]);

      let gradientH = canvasCtx.createLinearGradient(0, 0, canvasCtx.canvas.width, 0);
      gradientH.addColorStop(0, '#fff');
      gradientH.addColorStop(1, maximumSpectrumColor);
      canvasCtx.fillStyle = gradientH;
      canvasCtx.fillRect(0, 0, canvasCtx.canvas.width, canvasCtx.canvas.height);

      let gradientV = canvasCtx.createLinearGradient(0, 0, 0, canvasCtx.canvas.height);
      gradientV.addColorStop(0, 'rgba(0,0,0,0)');
      gradientV.addColorStop(1, '#000');
      canvasCtx.fillStyle = gradientV;
      canvasCtx.fillRect(0, 0, canvasCtx.canvas.width, canvasCtx.canvas.height);
    }
  };

  const setMarkersPosition = (forceMarkerPositionUpdate = false) => {
    const canvasCtx = colorCanvasRef.current.getContext('2d');
    const barCtx = colorBarRef.current.getContext('2d');

    let hslArrayColor = converter.hex.hsl(hexColor.current.color);

    if (shouldColorUpdate.current) {
      const colorBarMarkerPosition = Math.max(
        -5,
        (hslArrayColor[0] * (barCtx.canvas.width - 1)) / 360 - HALF_MARKER_SIZE
      );
      barMarkerRef.current.style.left = colorBarMarkerPosition + 'px';
    }

    //If marker position is not empty, it means that the position was already calculated before
    //This was made to minimize the inconsistencies while changing tabs
    //forceMarkerPositionUpdate was added so the user input can recalculate the marker position
    if (_.isEmpty(markerPosition) || forceMarkerPositionUpdate) {
      //As HSV, the color position in the gradient square can be calculated
      let hsvColor = converter.hsl.hsv(hslArrayColor);
      canvasPixelPos.current = [
        Math.max(-5, (hsvColor[1] * canvasCtx.canvas.width) / 100 - HALF_MARKER_SIZE - 1),
        Math.max(-5, canvasCtx.canvas.height - (hsvColor[2] * canvasCtx.canvas.height) / 100 - HALF_MARKER_SIZE),
      ];

      canvasMarkerRef.current.style.left = canvasPixelPos.current[0] + 'px';
      canvasMarkerRef.current.style.top = canvasPixelPos.current[1] + 'px';
      canvasMarkerRef.current.style.backgroundColor = hexColor.current.color;

      setMarkerPosition(canvasPixelPos.current);
    } else {
      canvasMarkerRef.current.style.left = canvasPixelPos.current[0] + 'px';
      canvasMarkerRef.current.style.top = canvasPixelPos.current[1] + 'px';
      canvasMarkerRef.current.style.backgroundColor = hexColor.current.color;
    }
  };

  const handleHexSelectorInput = (event) => {
    event.target.value = event.target.value.replace(/[^\w\s]/gi, '').toUpperCase();
    hexSelectorInputRef.current.value = event.target.value;

    if (ColorUtils.isValidHex(event.target.value)) {
      shouldColorUpdate.current = true;
      hexColor.current.color = '#' + event.target.value;
      updateCanvasColor();
      setMarkersPosition(true);
      setTransparencyBar();
      updateGradientBarColor(hexColor.current);
      selectColor(hexColor.current);
      shouldColorUpdate.current = false;
    }
  };

  const handleHexSelectorPaste = (event) => {
    let clipboardValue = event.clipboardData.getData('text/plain');
    if (clipboardValue.charAt(0) === '#') {
      clipboardValue = clipboardValue.slice(1);
    }

    event.target.value = clipboardValue;
    handleHexSelectorInput(event);
  }

  const setHexText = () => {
    hexSelectorInputRef.current.value = hexColor.current.color.substring(1).toUpperCase();
  };

  const canvasMouseMoveFn = (event) => {
    const canvasCtx = colorCanvasRef.current.getContext('2d');
    let [x, y] = getEventCoords(event, colorCanvasRef.current);
    let markerPosition = [Math.max(-5, x - HALF_MARKER_SIZE), Math.max(-5, y - HALF_MARKER_SIZE)];

    canvasMarkerRef.current.style.left = markerPosition[0] + 'px';
    canvasMarkerRef.current.style.top = markerPosition[1] + 'px';
    setColorFromElementPosition(canvasCtx, x, y);

    canvasMarkerRef.current.style.backgroundColor = hexColor.current.color;
    canvasPixelPos.current = [...markerPosition];
    updateTransparencyBarColor();
    setHexText();
    updateColorBarPreview();
    updateGradientBarColor(hexColor.current);
  };

  const canvasMouseDownFn = (event) => {
    shouldColorUpdate.current = false;
    canvasMouseMoveFn(event);
    setDocumentListeners(event, canvasMouseMoveFn, () => {
      selectColor(hexColor.current);
      setMarkerPosition(canvasPixelPos.current);
    });
  };

  const transparencyBarMouseMoveFn = (event) => {
    let [x] = getEventCoords(event, transparencyBarRef.current, false);

    transparencyTextRef.current.innerText =
      Math.round(Number((x / (transparencyBarRef.current.clientWidth - 1)) * 100)) + '%';

    let markerPosition = Math.max(-5, x - HALF_MARKER_SIZE);

    transparencyBarMarkerRef.current.style.left = markerPosition + 'px';
    hexColor.current.opacity = Math.round((x / (transparencyBarRef.current.clientWidth - 1)) * 1e2) / 1e2;
    updateColorBarPreview();
    updateGradientBarColor(hexColor.current);
  };

  const transparencyBarMouseDownFn = (event) => {
    shouldColorUpdate.current = false;
    transparencyBarMouseMoveFn(event);
    setDocumentListeners(event, transparencyBarMouseMoveFn, (event) => {
      selectColor(hexColor.current);
    });
  };

  const colorBarMouseMoveFn = (event) => {
    let [x] = getEventCoords(event, colorBarRef.current, false);
    barMarkerRef.current.style.left = Math.max(-5, x - HALF_MARKER_SIZE) + 'px';

    setColorFromElementPosition(colorBarRef.current.getContext('2d'), x);
    updateCanvasColor();
    updateCanvasMarkerColor();
    updateTransparencyBarColor();
    setHexText();
    updateColorBarPreview();
    updateGradientBarColor(hexColor.current);
  };

  const colorBarMouseDownFn = (event) => {
    shouldColorUpdate.current = true;
    colorBarMouseMoveFn(event);
    setDocumentListeners(event, colorBarMouseMoveFn, () => {
      shouldColorUpdate.current = false;
      selectColor(hexColor.current);
    });
  };

  const initialColorSetup = () => {
    let initialColor = { ...color };

    initialColor.color = ColorUtils.defaultToHex(initialColor);

    hexColor.current = initialColor;

    setMarkersPosition();
    updateCanvasColor();
    setColorBar();
    setTransparencyBar();
    setHexText();
    updateColorBarPreview();
  };

  useEffect(() => {
    if (forceColorUpdate) {
      shouldColorUpdate.current = true;
      setForceColorUpdate(false);
    }

    initialColorSetup();

    colorBarRef.current?.addEventListener('mousedown', colorBarMouseDownFn);
    barMarkerRef.current?.addEventListener('mousedown', colorBarMouseDownFn);
    transparencyBarRef.current?.addEventListener('mousedown', transparencyBarMouseDownFn);
    transparencyBarMarkerRef.current?.addEventListener('mousedown', transparencyBarMouseDownFn);
    colorCanvasRef.current?.addEventListener('mousedown', canvasMouseDownFn);
    canvasMarkerRef.current?.addEventListener('mousedown', canvasMouseDownFn);

    return () => {
      colorBarRef.current?.removeEventListener('mousedown', colorBarMouseDownFn);
      barMarkerRef.current?.removeEventListener('mousedown', colorBarMouseDownFn);
      transparencyBarRef.current?.removeEventListener('mousedown', transparencyBarMouseDownFn);
      transparencyBarMarkerRef.current?.removeEventListener('mousedown', transparencyBarMouseDownFn);
      colorCanvasRef.current?.removeEventListener('mousedown', canvasMouseDownFn);
      canvasMarkerRef.current?.removeEventListener('mousedown', canvasMouseDownFn);
    };
  }, [color]);

  const colorBarRef = useRef(null);
  const barMarkerRef = useRef(null);
  const barColorPreview = useRef(null);
  const colorCanvasRef = useRef(null);
  const canvasMarkerRef = useRef(null);
  const transparencyBarRef = useRef(null);
  const transparencyBarMarkerRef = useRef(null);
  const transparencyTextRef = useRef(null);
  const hexSelectorInputRef = useRef(null);
  const hexColor = useRef({});
  const canvasPixelPos = useRef(markerPosition);

  //This Ref is used to control color update between renders when a color exists in multiple hues squares, like white.
  //It is also used to prevent colorBar moving by itself in renders
  const shouldColorUpdate = useRef(true);

  const BAR_WIDTH = width - 40;
  const BAR_HEIGHT = 16;
  const HALF_MARKER_SIZE = 7;

  return (
    <div className={`BngColorSolid ${styles.colorSolidWrapper}`}>
      <div className={`ColorPickerCanvas ${styles.colorPickerCanvasContainer}`} style={{ width: width, height: width }}>
        <canvas ref={colorCanvasRef} width={`${width}`} height={`${width}`} />
        <div ref={canvasMarkerRef} className={`ColorPickerCanvasMarker ${styles.canvasMarker}`} />
      </div>
      <div className={`ColorPickerBottomBar ${styles.colorPickerBottomBar}`}>
        <div className={`ColorPickerCanvasBarContainer ${styles.colorPickerCanvasBarContainer}`} style={{ width: BAR_WIDTH, height: BAR_HEIGHT }}>
          <canvas
            ref={colorBarRef}
            width={BAR_WIDTH}
            height={BAR_HEIGHT}
            style={{ borderRadius: BAR_HEIGHT, position: 'relative' }}
          />
          <div ref={barMarkerRef} className={`ColorPickerBarMarker ${styles.colorBarMarker}`} />
        </div>
        {transparencyEnabled && (
          <div
            className={`ColorPickerTransparencyBarContainer ${styles.colorPickerTransparencyBarContainer}`}
            style={{ width: BAR_WIDTH, height: BAR_HEIGHT }}
          >
            <div ref={transparencyBarRef} className={`ColorPickerTransparencyBar ${styles.transparencyBar}`} style={{ width: BAR_WIDTH }} />
            <div ref={transparencyBarMarkerRef} className={`ColorPickerTransparencyMarker ${styles.transparencyBarMarker}`} />
          </div>
        )}
        <div className={`ColorPickerHexSelector ${styles.colorPickerHexSelector}`} style={{ width: BAR_WIDTH }}>
          <div className={`ColorPickerBarColorPreview ${styles.barColorPreview}`}>
            <BngColorPickerPreview color={hexColor.current} buttonSize={26} ref={barColorPreview}/>
          </div>
          <div className={`ColorPickerHexSelectorHashContainer ${styles.hexSelectorHashContainer}`}>
            <span className={`ColorPickerHexSelectorHash ${styles.hexSelectorHash}`}>#</span>
          </div>
          <div className={`ColorPickerHexSelectorInputContainer ${styles.hexSelectorInputContainer}`}>
            <input
              className={`ColorPickerHexSelectorInput ${styles.hexSelectorInput}`}
              maxLength={6}
              ref={hexSelectorInputRef}
              onChange={handleHexSelectorInput}
              onPaste={handleHexSelectorPaste}
            />
          </div>
          {transparencyEnabled && (
            <>
              <div className={`ColorPickerHexSelectorSeparator ${styles.hexSelectorSeparator}`} />
              <div className={`ColorPickerHexSelectorTransparencyContainer${styles.hexSelectorTransparencyContainer}`}>
                <span ref={transparencyTextRef} className={`ColorPickerHexSelectorTransparencyText ${styles.hexSelectorTransparencyText}`}></span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
