import React from 'react';
import {RightMenuContainer} from 'components/ui/right-menu/index';


export const NewMapRightMenu = ({
                                    onAjax,
                                    openOnEditMode,
                                    formValues,
                                    isNotMapPersisted,
                                    context,
                                    analystMenu,
                                    invalid,
                                    toggleAnalystMenu = _.noop,
                                    closeAnalystMenu = _.noop,
                                    save = _.noop,
                                    executeSaveAs = _.noop,
                                    removeMap = _.noop,
                                    printer,
                                    ...props
                                }) => {

    const objectProps = {
        path: formValues.path,
        folder: formValues.parentFolder,
        name: formValues.caption,
        description: formValues.information.description,
        printer: printer,
    };

    const saveNewMap = async ({submitForm = _.noop}) => {
        await save();
        await submitForm();
    }

    return (
        <RightMenuContainer loading={onAjax}
                            isNewMap={true}
                            invalid={invalid}
                            open={openOnEditMode}
                            context={context}
                            isPersisted={!isNotMapPersisted}
                            toggleAnalystMenu={toggleAnalystMenu}
                            closeAnalystMenu={closeAnalystMenu}
                            analystMenuClose={!analystMenu}
                            saveAs={executeSaveAs}
                            save={saveNewMap}
                            remove={removeMap}
                            enableReinitialize={true}
                            {...objectProps}/>
    )
};