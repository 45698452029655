import React, {useState} from 'react';

import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import {RightMenuItem} from "components/ui/right-menu/RightMenu";
import ConfigureAccordion from "components/ui/common/ConfigureAccordion";
import {validateAndConfirmPath} from "components/ui/common/SaveAsDialog";


export const handleConfigSubmit = async (props, {description, printer, ...values}, actions) => {
    const {proceed} = await validateAndConfirmPath({
        values: {projectId: props.context.project.id, path: props.path, ...values},
        props,
        actions,
        isSimpleSave: true,
    });

    if (!proceed) {
        actions.setSubmitting(false);
        return;
    }
    let newPath = props.path;
    const renamed = props.name !== values.name || props.folder !== values.folder;
    if (renamed) {
        const result = await Api.Project.renameObject({
            projectId: props.context.project.id,
            path: props.path,
            description,
            ...values
        });
        newPath = result.newPath;
    }
    await Api.Bng.setObjectConfigs({
        path: newPath,
        description: description,
        renamed: renamed,
        name: values.name,
        printer: printer,
        ...values
    });
    if (renamed) {
        UiMsg.ok(props.context.msg.t('object.rename.success'));
        window.__LOAD_OBJECT_URL = {content: newPath};
    }
    await Api.updateJsf();
};

export const useFetchVersion = ({ path = '' }) => {
  const [versions, setVersions] = useState(null);

  const fetchVersions = async () => {
    if (!path) return;
    try {
      const _versions = await Api.Bng.getLatestVersions(path);
      setVersions(_versions);
    } catch (e) {
      console.error('Error on fetchVersions()', { path }, e);
      UiMsg.ajaxError('fetch.versions.fail', e);
      setVersions({ versions: [] });
    }
  };

  return [versions, fetchVersions];
};

const GeneralConfigMenuItem = ({
                                   toggleAccordion = _.noop,
                                   context,
                                   ...props
                               }) => {

    const toggleConfigAccordion = async (event) => {
        return toggleAccordion(ConfigureAccordion, 'GeneralConfigMenuItem');
    };

    return (
        <RightMenuItem title={context.msg.t('general.config')}
                       icon="menu_open"
                       className="GeneralConfigMenuItem"
                       onClick={toggleConfigAccordion}
                       {...props}/>
    );
};

export default ContextEnhancer(GeneralConfigMenuItem);