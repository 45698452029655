import styles from './BngObjectCockpitSelection.module.css';

import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import Dialog from 'components/ui/Dialog';
import Utils from 'components/Utils';
import SelectObjectsCheckbox from 'components/ui/common/SelectObjectsCheckbox';
import BngRadio from 'components/bng/form/BngRadio';
import BngField from 'components/bng/form/BngField';
import bngYup from 'components/bng/form/yup/BngYup';
import BngForm from 'components/bng/form/BngForm';
import TreeTable from 'components/ui/common/TreeTable';
import Api from 'components/Api';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';

function SelectObjectTree({ form, field, folders, defaultExportType }) {
  return (
    <div className={`${styles.selectObjectTreeWrapper} ExportSchedulingSelectObjectTree TransparentBg`}>
      <TreeTable
        paddingleft={true}
        folders={folders}
        component={(props) => {
          return (
            <div className="flex-center-items flex-grow-1">
              <SelectObjectsCheckbox {...props} />
            </div>
          );
        }}
        onChange={(value) => {
          form.setFieldValue(
            field.name,
            field.value.some((i) => i.path === value.href)
              ? [...field.value.filter((i) => i.path !== value.href)]
              : [
                  ...field.value,
                  {
                    name: value.text,
                    path: value.href,
                    exportType: defaultExportType,
                  },
                ]
          );
        }}
        objectSelect={field.value.map((v) => v.path)}
        allowClear={true}
        onClearSelection={() => {
          form.setFieldValue(field.name, []);
        }}
      />
    </div>
  );
}

function SelectCockpit({ cockpits, field, defaultExportType }) {
  return (
    <React.Fragment>
      <div className={`${styles.selectCockpitHeader}`}></div>
      <div className={`${styles.selectCockpitBody}`}>
        {cockpits.map((cockpit, idx) => {
          return (
            <Field
              name={'cockpit'}
              key={idx}
              component={BngField}
              withLabel={false}
              showErrors={false}
              showErrorClass={false}
              inputComponent={BngRadio}
              checked={cockpit.id === field.value?.id}
              asProps={{
                label: cockpit.name,
                value: {
                  id: cockpit.id,
                  name: cockpit.name,
                  exportType: defaultExportType,
                },
              }}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

const BngObjectCockpitSelectionSchema = bngYup((yup) => {
  return yup.object({
    type: yup.string().default(Utils.Scheduling.TYPE.OBJECT),
    contents: yup.array().when('type', {
      is: Utils.Scheduling.TYPE.OBJECT,
      then: yup.array().required('Share.objects.error.selectObjects').min(1, ''),
    }),
    cockpit: yup
      .object()
      .when('type', {
        is: Utils.Scheduling.TYPE.COCKPIT,
        then: yup
          .object()
          .required()
          .test('cockpit-required', 'Share.objects.error.selectCockpit', (val) => val && Object.keys(val).length > 1),
      })
      .nullable()
      .default(null),
  });
});

export default function BngObjectCockpitSelection({ form, field, open, onClose }) {
  const context = useBimContext();

  const [folders, setFolders] = useState([]);
  const [cockpits, setCockpits] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [folders, cockpits] = await Promise.all([
          Api.Menu.findRootsFor({ projectId: context.project.id, userId: context.user.id }),
          Api.Cockpit.findCockpits(context.project.name),
        ]);
        setFolders(folders);
        setCockpits(cockpits);
      } catch (e) {
        console.error('Error while trying to fetch objects and cockpits', e);
        UiMsg.ajaxError(null, e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const save = (values) => {
    form.setFieldValue(field.name, {
      ...values,
    });
    onClose();
  };

  return (
    <Dialog
      className={`BngObjectCockpitSelection ${styles.selectObjectDialog} large`}
      open={open}
      onClose={onClose}
      loading={loading}
      title={context.msg.t('select.objects')}
      newDialogLayout={true}
    >
      <Formik initialValues={{ ...field.value }} onSubmit={save} validationSchema={BngObjectCockpitSelectionSchema}>
        {({ values, errors, submitCount }) => {
          return (
            <BngForm>
              <div className={`${styles.selectObjectSwitch} gap-2`}>
                {[
                  {
                    label: context.msg.t('objects'),
                    value: Utils.Scheduling.TYPE.OBJECT,
                  },
                  {
                    label: context.msg.t('cockpits'),
                    value: Utils.Scheduling.TYPE.COCKPIT,
                  },
                ].map((opt, idx) => {
                  return (
                    <Field
                      name="type"
                      key={`type_${idx}`}
                      component={BngField}
                      withLabel={false}
                      inputComponent={BngRadio}
                      asProps={opt}
                    />
                  );
                })}
              </div>
              {values.type === Utils.Scheduling.TYPE.OBJECT ? (
                <Field
                  name={'contents'}
                  component={BngField}
                  withLabel={false}
                  showErrors={false}
                  showErrorClass={false}
                  inputComponent={SelectObjectTree}
                  defaultExportType={form.values.defaultExportType}
                  folders={folders}
                />
              ) : (
                <Field
                  name={'cockpit'}
                  component={BngField}
                  withLabel={false}
                  showErrors={false}
                  showErrorClass={false}
                  inputComponent={SelectCockpit}
                  cockpits={cockpits}
                  defaultExportType={form.values.defaultExportType}
                />
              )}
              <Dialog.Footer>
                <DefaultDialogActions
                  okLabel={'select'}
                  closeModal={onClose}
                  errors={errors}
                  submitCount={submitCount}
                />
              </Dialog.Footer>
            </BngForm>
          );
        }}
      </Formik>
    </Dialog>
  );
}
