import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BlockUi from 'react-block-ui';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ContextEnhancer from 'components/ContextEnhancer';
import FilterItem from 'components/filter/FilterItem';
import FilterBarWrapper from 'components/filter/FilterBarWrapper';
import FilterService from 'components/filter/FilterService';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import { BngDropdown, BngDropdownSeparator } from 'components/bng/ui/BngDropdown';
import BimEventBus from 'BimEventBus';
import { CLEAR_FILTER_EVENT } from 'components/bng/analysis/BngAnalysisDrillDownBar';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { PUBLISHER_FULL_INTERACTION_EVENT } from 'components/service/bng/PublisherApi';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class FilterBar extends Component {
  static propTypes = {
    filters: PropTypes.array,
    type: PropTypes.oneOf(['DASHBOARD', 'MAP', 'DASHBOARD_ICON', 'REACT_COCKPIT_DASHBOARD']),
    isPublisher: PropTypes.bool,
    canSave: PropTypes.bool,
    onEditorMode: PropTypes.bool,
    filterPosition: PropTypes.object,
    dashboardPath: PropTypes.string,
    runtimeDashboard: PropTypes.bool,
    ignoreWrapper: PropTypes.bool,
    isGlobalFilter: PropTypes.bool,
    onLoadingListener: PropTypes.func,
    onChangeListener: PropTypes.func,
    noDropdown: PropTypes.bool,
    beforeItemsSlot: PropTypes.any,
    customFilterSwitch: PropTypes.any,
    renderCustomMember: PropTypes.func,
    alwaysShowFilterBar: PropTypes.bool,
    scrollElId: PropTypes.string,
  };

  static defaultProps = {
    isPublisher: true,
    type: 'DASHBOARD',
    onLoadingListener: _.noop,
    filterPosition: { vertical: 'top', horizontal: 'fixed', retracted: 'expand' },
    ignoreWrapper: false,
    isGlobalFilter: false,
    noDropdown: false,
    runtimeDashboard: false,
    onEditorMode: false,
    renderCustomMember: _.noop,
    alwaysShowFilterBar: false,
    scrollElId: 'page-content',
  };

  state = {
    loading: true,
    blockFilters: false,
    filters: [],
    filterSelected: { id: 0 },
    showFilterBar: true,
    filterItemsHolder: {},
    currentOverflow: 0,
    contracted: 'expand',
  };

  filterItemRefs = {};

  constructor(props) {
    super(props);
    this.defaultFilters = _.cloneDeep(props.filters);
  }

  componentDidMount() {
    this._scrollEl = document.getElementById(this.props.scrollElId);

    this.setState(
      {
        filters: this.props.filters,
        loading: false,
        currentOverflow: this.props.filters.length,
      },
      () => {
        this.updateFilterBarState();
      }
    );
  }

  removeDashScroll = () => {
    this._scrollEl?.classList.add('no-scroll');
  };

  addDashScroll = () => {
    this._scrollEl?.classList.remove('no-scroll');
  };

  defaultValuesForFilter(filter) {
    return _.find(this.defaultFilters, { id: filter.id }).selectedMembers;
  }

  updateFilters = (fn) => {
    fn(this.props.filters);
  };

  notifyFilterChange = (result, members = []) => {
    const validateFilterMembers = (matchedFilter, onlyMatchedFilter) => {
      return onlyMatchedFilter ? matchedFilter : matchedFilter && !_.isEmpty(matchedFilter.members);
    };
    const onlyMatchedFilter =
      (this.isReactCockpitDash() || this.props.isPublisher || application.page.isMobile()) && !this.isDashIcon();

    this.props.filters.forEach((propFilter) => {
      const matchedFilter = result.find(({ id }) => id === propFilter.id);
      if (validateFilterMembers(matchedFilter, onlyMatchedFilter)) {
        propFilter.restrictionType = matchedFilter.restrictionType || 'SHOW_SELECTED';
        propFilter.selectedMembers =
          matchedFilter.members?.map((m) => {
            let member = members.find(({ value }) => value === m);
            if (member) {
              return member;
            } else {
              return { label: m, value: m };
            }
          }) || [];
      }
    });
    if (onlyMatchedFilter) {
      this.setState({
        filters: _.cloneDeep(this.props.filters),
      });
    }
  };

  clearSelectedFilter = async () => {
    this.setState({ filterSelected: { id: 0 } });
    const idFilterList = [];
    this.state.filters.map((i) => {
      idFilterList.push(i.id);
    });
    await Api.Dash.applyFilterBarOrder({ idFilterList: idFilterList });
    if (_.isFunction(window.__OBJECT_RELOAD)) {
      window.__OBJECT_RELOAD({ dirty: false, reloadFilters: true });
    }
  };

  handlePublisherFilterChange = (filter, selectedMembers, members = [], force = false, additionalProps) => {
    if (this.props.onChangeListener) {
      this.props.onLoadingListener(true);
      try {
        const result = this.props.onChangeListener(this.props.filters, force, false, [filter], additionalProps);
        this.notifyFilterChange(result, members);
      } finally {
        BimEventBus.emit(CLEAR_FILTER_EVENT, {
          filters: [filter],
        });
        this.props.onLoadingListener(false);
      }
      this.forceUpdate();
      return;
    }

    this.setState({ loading: true });
    this.props.onLoadingListener(true);
    jQuery('.map').each(function () {
      ReactDOM.unmountComponentAtNode(this);
    });

    let filterPromise = Promise.resolve();
    if (this.isMap()) {
      const mapFilters = this.props.filters
        .map((f) => {
          if (_.isEmpty(f.selectedMembers)) {
            f.selectedMembers = this.defaultValuesForFilter(f);
          }
          return encodeURIComponent(f.id + '=' + f.selectedMembers.map((m) => m.value).join(','));
        })
        .map((f) => `filter=${f}`)
        .join('&');
      let obj = jQuery.QueryString;
      delete obj.filter;
      let uri = window.location.pathname + '?' + jQuery.param(obj) + (mapFilters.length > 0 ? '&' + mapFilters : '');
      filterPromise = jQuery.get(uri).then((resp) => {
        j('#map-content-container').replaceWith(j(resp).find('#map-content-container'));
      });
    }

    filterPromise.always(() => {
      this.setState({ loading: false });
      this.props.onLoadingListener(false);
      this.forceUpdate();
      if (window.hasOwnProperty('LEAFLET_MAP_REGISTRY')) {
        Object.keys(window.LEAFLET_MAP_REGISTRY).forEach((key) => window.LEAFLET_MAP_REGISTRY[key].invalidateSize());
      }
    });
  };

  handleFilterChange = async (
    filter,
    selectedMembers,
    members = [],
    force = false,
    additionalProps = { clearFilters: false }
  ) => {
    filter.selectedMembers = selectedMembers;

    if (selectedMembers.length === 0) {
      this.setState({ currentOverflow: this.state.currentOverflow + 1 });
    }

    BimEventBus.emit(PUBLISHER_FULL_INTERACTION_EVENT, {
      source: 'FilterBar'
    });

    if (this.props.isPublisher || application.page.isMobile() || this.props.onChangeListener) {
      this.handlePublisherFilterChange(filter, selectedMembers, members, force, additionalProps);
    } else {
      this.setState({ loading: true });
      this.props.onLoadingListener(true);
      try {
        let filters;
        if (selectedMembers.length > 0) {
          filters = await FilterService.applyFilters(
            filter,
            this.props.context.msg.t('filters.applied.with.success'),
            this.props.context.msg.t('error.apply.filters')
          );
        } else {
          filters = await FilterService.clearFilters(filter, force);
        }
        BimEventBus.emit(CLEAR_FILTER_EVENT, {
          filters: [additionalProps.clearFilters ? filter : filters],
        });
        this.setState({ filters });
      } catch (e) {
        console.error(e);
        UiMsg.error(this.props.context.msg.t('error.apply.filters'), e);
      } finally {
        this.setState({ loading: false });
        this.props.onLoadingListener(false);
      }
    }
  };

  isMap() {
    return this.props.type === 'MAP';
  }

  isDashIcon() {
    return this.props.type === 'DASHBOARD_ICON';
  }

  isReactCockpitDash() {
    return this.props.type === 'REACT_COCKPIT_DASHBOARD';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.filters, this.props.filters)) {
      this.setState({ filters: this.props.filters, loading: false });
    }

    if (!this.props.noDropdown) {
      jQuery(this._filterContainer).scrollbar();
    }

    if (
      !_.isEqual(this.state.filters, prevState.filters) ||
      !_.isEqual(this.state.currentOverflow, prevState.currentOverflow)
    ) {
      this.updateFilterBarState();
    }

    const hasOverflown = this._filterItemsHolder?.offsetWidth < this._filterItemsHolder?.scrollWidth;

    if (this.state.currentOverflow >= 1 && hasOverflown && !this.props.noDropdown) {
      this.setState({ currentOverflow: this.state.currentOverflow - 1 });
    }
  }

  updateFilterBarState = () => {
    const { visibleFilters } = this.findVisibleFilters();
    const isEmpty = _.isEmpty(visibleFilters);
    const $dashWrapper = document.getElementById('dashboard-wrapper');
    if ($dashWrapper) {
      $dashWrapper.classList[isEmpty ? 'remove' : 'add']('dashboard-wrapper-with-filters');
    }
    const showFilterBar = this.props.alwaysShowFilterBar || !isEmpty;
    if (this.props.isPublisher && !showFilterBar) {
      document.querySelector('.filter-container.filter-expand').style.display = 'none';
      document.querySelector('.dashboard-filter-box').classList.remove('dashboard-filter-box');
      document
        .querySelector('.AllContentWrapper #body-dashboard-home.free-style-marker-class ')
        .classList.add('no-filter-bar');
      document.querySelector('#body-wrapper').style.paddingTop = 0;
    }
    this.setState({ showFilterBar });
  };

  itemIsFiltered(filter, customFilterMember = null) {
    if (this.isMap()) {
      return !_.isEqual(filter.selectedMembers, this.defaultValuesForFilter(filter));
    } else {
      return customFilterMember || filter.selectedMembers.length !== 0;
    }
  }

  clearFilterItemsCache() {
    window.__CURRENT_FILTER_BAR_CLEAR_ITEM_CACHE__ = false;
    Object.values(this.filterItemRefs).forEach((fi) => {
      try {
        if (fi) {
          fi.wrappedComponent.clearCache();
        }
      } catch (e) {
        console.warn(e);
      }
    });
  }

  onDragEnd = async (result) => {
    if (!result.destination) return;

    this.setState({ loading: true });
    const sourceId = result.source.droppableId;
    const destinationId = result.destination.droppableId;

    const fromIdx = this.getDragIndex(sourceId, result.source.index, true);
    const toIdx = this.getDragIndex(destinationId, result.destination.index, false, sourceId);
    if (fromIdx === toIdx) {
      this.setState({ loading: false });
      return;
    }

    const items = reorder(this.props.filters, fromIdx, toIdx);

    this.setState({ filters: items });
    const temp = [];
    items.map((item) => {
      temp.push(item.id);
    });
    await FilterService.moveFilter(temp);
    this.clearSelectedFilter();
    this.setState({ loading: false });
  };

  getDragIndex = (draggableId, index, from, sourceId = null) => {
    switch (draggableId) {
      case 'droppable-visible':
        return index;
      case 'droppable-overflown':
        const sameOrigin = !!sourceId && sourceId === draggableId;
        const needToAdd = from || sameOrigin;
        return this.state.currentOverflow + index + (needToAdd ? +1 : 0);
    }
  };

  openDialog = (methodToExecute) => {
    methodToExecute();
  };

  overflownFilterDropdown = (overflownFilters, filters, rest) => {
    const isOverflownFiltered = overflownFilters.some((filter) => filter.selectedMembers.length > 0);

    return (
      <BngDropdown
        icon="more_horiz"
        className="OverflownFiltersDropdown"
        popperClassName={`OverflownFiltersDropdownPopper OverflownDropdownPosition-${
          this.props.filterPosition.vertical || this.props.filterPosition
        }`}
        onClose={this.addDashScroll}
        keepOpen={true}
        customButton={({ openDropdown }) => {
          return (
            <>
              <BngIconButton
                icon="more_vert"
                onClick={(e) => {
                  this.removeDashScroll();
                  openDropdown(e);
                }}
                className={`OverflownFiltersDropdownButton`}
              />
              {isOverflownFiltered && <div className={`OverflownDropdownFiltered`}></div>}
            </>
          );
        }}
        customOptions={({ closeDropdown }) => {
          return (
            <ul className="OverflownFiltersList">
              <BlockUi tag="div" blocking={this.state.loading}>
                <BngDropdownSeparator title={this.props.context.msg.t('filters.more')} />
                {this.props.onEditorMode && !this.isDashIcon() ? (
                  this.renderDragNDropWrapper(
                    'droppable-overflown',
                    'vertical',
                    overflownFilters,
                    true,
                    (idx, filter, provided) => this.renderFilterItem(idx, filter, provided, rest, filters, true),
                    '.bng-dropdown-parent.OverflownFiltersDropdownPopper'
                  )
                ) : (
                  <>
                    {overflownFilters.map((filter, idx) =>
                      this.renderFilterItem(idx, filter, null, rest, filters, true)
                    )}
                  </>
                )}
              </BlockUi>
            </ul>
          );
        }}
      />
    );
  };

  editableFilterList = (visibleFilters, overflownFilters, filterSelected, filters, rest) => {
    if (this.props.onEditorMode && !this.isDashIcon()) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.renderDragNDropWrapper(
            'droppable-visible',
            'horizontal',
            visibleFilters,
            true,
            (idx, filter, provided) => this.renderFilterItem(idx, filter, provided, rest, filters)
          )}
          {overflownFilters.length > 0 && this.overflownFilterDropdown(overflownFilters, filters, rest)}
        </DragDropContext>
      );
    }

    return (
      <>
        {visibleFilters.map((filter, idx) => {
          const customMember = this.props.renderCustomMember(filter);
          return this.renderFilterItem(idx, filter, null, rest, filters, false, customMember);
        })}
        {overflownFilters.length > 0 && this.overflownFilterDropdown(overflownFilters, filters, rest)}
      </>
    );
  };

  renderDragNDropWrapper = (droppableId, direction, filterArray, placeholder, childRender, parentClassName = null) => {
    return (
      <Droppable droppableId={droppableId} direction={direction}>
        {(provided, snapshot) => {
          if (parentClassName) {
            const $parentEl = document.querySelector(parentClassName);
            if (provided.placeholder) {
              $parentEl.classList.add('growForPlaceholder');
            } else if ($parentEl?.classList.contains('growForPlaceholder')) {
              $parentEl.classList.remove('growForPlaceholder');
            }
          }

          return (
            <div ref={provided.innerRef} style={direction === 'horizontal' ? { display: 'flex' } : {}}>
              {filterArray.map((filter, idx) => {
                return (
                  <Draggable key={filter.id} draggableId={filter.id} index={idx} isDragDisabled={filter.disabled}>
                    {(provided, snapshot) => {
                      return this.optionalPortal(provided.draggableProps.style, childRender(idx, filter, provided));
                    }}
                  </Draggable>
                );
              })}
              {placeholder && provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    );
  };

  optionalPortal = (styles, element) => {
    if (styles.position === 'fixed') {
      return ReactDOM.createPortal(element, this._filterItemsHolder);
    }
    return element;
  };

  renderFilterItem = (idx, filter, provided, rest, filters, onOverflowDropdown = false, customMember) => {
    return (
      <FilterItem
        key={idx}
        filter={filter}
        ref={(ref) => (this.filterItemRefs[filter.id] = ref)}
        onChange={this.handleFilterChange}
        openDialog={this.openDialog}
        customMember={customMember || null}
        filtered={this.itemIsFiltered(filter)}
        selectedFilters={filters}
        isPublisher={this.props.isPublisher || this.isDashIcon()}
        loadingListener={(blockFilters) => {
          this.setState({ blockFilters });
          this.props.onLoadingListener(blockFilters);
        }}
        {...rest}
        provider={provided}
        className="DashboardFilter"
        onOverflowDropdown={onOverflowDropdown}
        showTruncateButton={false}
      />
    );
  };

  findVisibleFilters = () => {
    const overflownFilters = [];
    const visibleFilters = this.state.filters.filter((f, idx) => {
      if (idx > this.state.currentOverflow && !this.props.noDropdown) {
        overflownFilters.push(f);
        return false;
      }

      if (!_.isEmpty(f.selectedMembers) && !f.hide) {
        return true;
      } else if (!f.dashContainFilter && !f.notFromDash) {
        return false;
      }

      return (this.props.onEditorMode || this.isDashIcon()) || !f.hide;
    });

    return { visibleFilters, overflownFilters };
  };

  render() {
    const { isPublisher, ignoreWrapper, filterPosition, canSave, ...rest } = this.props;
    const { filters, filterSelected, showFilterBar } = this.state;

    if (!showFilterBar) {
      return null;
    }

    const { visibleFilters, overflownFilters } = this.findVisibleFilters();

    return (
      <FilterBarWrapper
        isPublisher={isPublisher || this.isDashIcon() || ignoreWrapper}
        filterPosition={filterPosition}
        canSave={canSave && filters.some((f) => !f.disabled)}
        applyScrollbar={this.applyScrollbar}
        onChangeVisibility={(contracted) => this.setState({ contracted: contracted })}
      >
        <BlockUi tag="div" blocking={this.state.blockFilters || this.state.loading}>
          <div className="row-fluid">
            <div className="filterItemsHolder" ref={(el) => (this._filterItemsHolder = el)}>
              {this.props.beforeItemsSlot}
              {this.editableFilterList(visibleFilters, overflownFilters, filterSelected, filters, rest)}
            </div>
          </div>
        </BlockUi>
      </FilterBarWrapper>
    );
  }
}

export default ContextEnhancer(FilterBar);
