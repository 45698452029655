import styles from './MktTemplatesTab.module.css'
import BimStorePage from 'components/ui/bimstore/BimStorePage';

export default function MktTemplatesTab() {
  return (
    <div className={`MktTemplatesTab ${styles.MktTemplatesTab}`} >
      <BimStorePage />
    </div>
  );
}
