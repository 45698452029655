import styles from './AccUsersTab.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import SimpleAccTableTab from 'components/ui/accounts/tabs/SimpleAccTableTab';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import Api from 'components/Api';
import Avatar from 'components/ui/Avatar';
import BngDropdown from 'components/bng/ui/BngDropdown';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import RemoveUserDialog from 'components/ui/accounts/tabs/users/RemoveUserDialog';
import InviteUsersDialog from 'components/bng/pages/admin/users/InviteUsersDialog';
import BngPasswordConfirmation from 'components/bng/ui/BngPasswordConfirmation';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import AddAdditionalDialog, { ADDITIONALS } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import { ProjectType } from 'components/bng/project/NewProjectDialog';

const buildUsersColumns = ({
  fetchUsers,
  context,
  dispatch,
  isMasterOfCurrentAccount,
  account,
  fetchAccounts,
  availableAccounts,
}) => {
  return [
    {
      key: 'id',
      label: context.msg.t('id'),
      sortable: true,
      size: 20,
      render: (row) => {
        return (
          <div className={styles.colEllipsis}>
            <span>{row.user.id}</span>
          </div>
        );
      },
    },
    {
      key: 'displayName',
      label: context.msg.t('name'),
      sortable: true,
      size: 100,
      render: (row) => {
        return (
          <div className={styles.userNameWrapper}>
            <Avatar userId={row.user.id} className={`${styles.userAvatar}`} />
            <span className={styles.colEllipsis}>{Utils.Users.displayName(row.user)}</span>
          </div>
        );
      },
    },
    {
      key: 'email',
      label: context.msg.t('email'),
      sortable: true,
      size: 100,
      render: (row) => {
        return (
          <div className={styles.colEllipsis}>
            <span>{row.user.email}</span>
          </div>
        );
      },
    },
    {
      key: 'projects',
      label: context.msg.t('projects'),
      size: 100,
      render: (row) => {
        const projects = row.projects;
        const projectsString = projects.map((p) => Utils.Project.displayName(p)).join(', ');
        return (
          <div className={styles.colEllipsis}>
            <span className={styles.projectsWrapper} title={projectsString}>
              {projectsString}
            </span>
          </div>
        );
      },
    },
    {
      key: 'manageAccount',
      label: context.msg.t('manages.account'),
      size: 10,
      render: (row) => {
        const canManageAccount = row.accountManagePermissions.find((permission) => permission.accountId === account.id);
        return (
          <div>
            <Icon icon={canManageAccount ? 'check_circle' : 'highlight_off'} />
          </div>
        );
      },
    },
    {
      key: 'action',
      label: context.msg.t('action'),
      size: 10,
      render: (row) => {
        const canManageAccount = row.accountManagePermissions.find((permission) => permission.accountId === account.id);
        const dropdownOptions = [
          {
            label: context.msg.t('delete_button'),
            icon: 'delete_outline',
            onClick: () => {
              dispatch(
                MODALS.open(RemoveUserDialog, {
                  user: row.user,
                  projects: row.projects,
                  refreshData: fetchUsers,
                })
              );
            },
          },
          {
            label: context.msg.t(canManageAccount ? 'remove.account.management' : 'activate.account.management'),
            icon: 'manage_accounts',
            visible: isMasterOfCurrentAccount,
            onClick: async () => {
              try {
                await Api.Account.toggleAccountManagePermission({
                  accountId: account.id,
                  userId: row.user.id,
                });
                UiMsg.ok(context.msg.t('account.manage.request.success'));
                await fetchUsers();
              } catch (e) {
                console.error('Error on toggleAccountManagePermission()', e);
                UiMsg.error(context.msg.t('account.manage.request.error'));
              }
            },
          },
        ];

        if (isMasterOfCurrentAccount && account.accountMasterId !== row.user.id) {
          dropdownOptions.push({
            label: context.msg.t('transfer.master.user'),
            icon: 'star',
            onClick: () => {
              dispatch(
                MODALS.open(BngPasswordConfirmation, {
                  onConfirm: async () => {
                    try {
                      await Api.Account.changeMaster(account.id, row.user.id);
                      UiMsg.ok(
                        context.msg.t('transfer.master.user.title'),
                        context.msg.t('transfer.master.user.message')
                      );
                      if (availableAccounts.length === 1) {
                        const accessUrl = Api.Bng.accessProjectUrl(context.project.name);
                        window.location.replace(accessUrl);
                      } else {
                        await fetchAccounts();
                      }
                    } catch (e) {
                      UiMsg.error(context.msg.t('transfer.master.user.error.message'), e);
                    }
                  },
                })
              );
            },
          });
        }

        return (
          <div>
            <BngDropdown
              popperOpts={{ placement: 'bottom-end' }}
              popperClassName={styles.UsersTabActions}
              options={dropdownOptions}
            />
          </div>
        );
      },
    },
  ];
};

const UsersFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    project: yup.number().nullable(),
    projectType: yup.string().nullable(),
    enabled: yup.boolean().nullable(),
  });
});

const UsersFilterDropdown = ({ rows = [], onChange = _.noop, initialValues = [], filters = {} }) => {
  const context = useBimContext();

  const projectOpts = FilterOpts({
    useIdAsValue: true,
    options: _.uniqBy(
      rows.map((user) => {
        const project = user.project;
        return { id: project.id, label: Utils.Project.displayName(project) };
      }),
      'id'
    ),
  });

  const projectTypeOpts = FilterOpts({
    options: ProjectType,
  });

  const filterFields = [
    {
      name: 'project',
      label: context.msg.t('project'),
      options: projectOpts,
      multiple: true,
    },
    {
      name: 'projectType',
      label: context.msg.t('project_type'),
      options: projectTypeOpts,
      multiple: true,
    },
    {
      name: 'enabled',
      label: context.msg.t('project.status'),
      options: [
        {
          label: context.msg.t('enabled'),
          value: 'true',
        },
        {
          label: context.msg.t('inactive'),
          value: 'false',
        },
      ],
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      dropdownSchema={UsersFilterDropdownSchema}
      initialFormValues={initialValues}
      onChange={onChange}
      selectedFilters={filters.filterButton}
    />
  );
};

const filterUsers = (filters, projectUsers) => {
  const {
    search,
    filterButton: { project, projectType, enabled },
  } = filters;
  if (search) {
    projectUsers = projectUsers.filter((pu) => {
      const user = pu.user;
      return (
        user.displayName?.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search) ||
        user.id.toString().includes(search)
      );
    });
  }

  if (project && project.length > 0) {
    projectUsers = projectUsers.filter((pu) => project.some((p) => p === pu.project.id.toString()));
  }

  if (projectType && projectType.length > 0) {
    projectUsers = projectUsers.filter((pu) => projectType.some((p) => p === pu.project.projectType));
  }

  if (enabled?.length > 0) {
    projectUsers = projectUsers.filter((pu) => enabled === pu.project.enabled.toString());
  }

  let groupedUsers = [];
  projectUsers.forEach((pu) => {
    if (!groupedUsers.find((gu) => gu.user.id === pu.user.id)) {
      groupedUsers.push({
        user: pu.user,
        projects: projectUsers
          .filter((pu2) => pu2.user.id === pu.user.id)
          .map((pu2) => {
            return { ...pu2.project, projectUser: pu2 };
          }),
        accountManagePermissions: pu.accountManagePermissions,
      });
    }
  });

  return groupedUsers;
};

const userOrderFunc = (row, prop) => {
  let value = row.user[prop];
  return _.isString(value) ? value.toLowerCase() : value;
};

const AccUsersTab = ({ account, isMasterOfCurrentAccount, fetchAccounts, availableAccounts }) => {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const fetchedUsers = await Api.Account.fetchAccountUsers(account.id);
      setProjectUsers(fetchedUsers);
    } catch (e) {
      console.error('Error on function fetchUsers()', e);
      UiMsg.error(context.msg.t('fetch.users.error'));
    } finally {
      setLoading(false);
    }
  };

  const openCreateUserDialog = async () => {
    const shouldAddResource = await Api.Account.shouldAddResource(account.id, ADDITIONALS.USERS.key);
    if (shouldAddResource) {
      dispatch(
        MODALS.open(AddAdditionalDialog, {
          featureKey: ADDITIONALS.USERS.key,
          isConsumptionAdditional: true,
        })
      );
    } else {
      dispatch(
        MODALS.open(InviteUsersDialog, {
          multipleProjects: true,
          beforeClose: fetchUsers,
          accountId: account.id,
        })
      );
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [account]);

  const cols = useMemo(
    () =>
      buildUsersColumns({
        fetchUsers,
        context,
        dispatch,
        isMasterOfCurrentAccount,
        account,
        fetchAccounts,
        availableAccounts,
      }),
    [projectUsers]
  );

  return (
    <SimpleAccTableTab
      className={`AccUsersTab ${styles.AccUsersTab}`}
      cols={cols}
      rows={projectUsers}
      filterFunc={filterUsers}
      initialFilters={{
        search: '',
        filterButton: { project: null, projectType: ['Production'], enabled: 'true' },
      }}
      filterComponent={UsersFilterDropdown}
      onReload={fetchUsers}
      emptyAlertMsg={context.msg.t('no.proposals')}
      loading={loading}
      setLoading={setLoading}
      headerButtonIcon={'add_circle'}
      headerButtonAction={openCreateUserDialog}
      headerButtonLabel={context.msg.t('create_user')}
      rowOrderFunc={userOrderFunc}
      extraHeaderComponents={[
        {
          render: ({ currentRows }) => {
            return (
              <span key={'UserCounter'} className={styles.userCount}>
                {`${currentRows.length} ${context.msg.t('users')}`}
              </span>
            );
          },
        },
      ]}
    />
  );
};

export default AccUsersTab;
