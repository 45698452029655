import './RightMenu.css';
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import ContextEnhancer from 'components/ContextEnhancer';
import Icon from 'components/ui/common/Icon';
import { availableObjectOpts } from 'components/ui/right-menu/ObjectRightMenu';
import { BngForm } from 'components/bng/form/BngForm';
import { AccordionProvider } from 'components/ui/AccordionList';
import useEventBus from 'components/hooks/useEventBus';
import KpiEditMenu, { KPI_EDIT_MENU_EVENT } from 'components/bng/pages/kpi/KpiEditMenu';
import AssistedAnalysisEditMenu, { ASSISTED_ANALYSIS_EDIT_MENU_EVENT } from 'components/bng/pages/dashboard/menus/AssistedAnalysisEditMenu';

export const RIGHT_MENU_CLOSE_SUBMENU = 'RIGHT_MENU_CLOSE_SUBMENU';

export function RightMenuItem({
  onClick = _.noop,
  title = '',
  active = false,
  disabled = false,
  className = '',
  fixed = false,
  icon = '',
  children,
}) {
  return (
    <li
      title={title}
      onClick={disabled ? _.noop : onClick}
      className={`themed-component-hover themed-component-open ${className} ${fixed ? 'FixedOption' : ''} ${
        active ? 'open' : ''
      }`}
    >
      <div className={`${disabled ? 'disabled' : ''}`}>{icon && <Icon icon={icon} />}</div>
      {children}
    </li>
  );
}

RightMenuItem.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};


const RightMenu = (props) => {
    const {options, className, loading, open, children, path, activeAccordion, ...otherProps} = props;

    const renderOpts = (opts) => {
        return opts.map((opt, idx) => {
            const {Component, key, disabled, ...optProps} = opt;
            let Comp = Component;
            if (!Comp) {
                Comp = RightMenuItem;
            }
            return <Comp key={key || idx}
                         disabled={disabled}
                         path={path}
                         active={activeAccordion === (key || idx)}
                         {...optProps}
                         {...otherProps}/>
        })
    };

    const opts = () => {
        if (!!children) return children;

        if (!options) {
            return availableObjectOpts(props);
        }
        return options || [];
    };

    const {fixedOpts, regularOpts} = opts().reduce((acc, opt) => {
        if (opt.fixed) {
            acc.fixedOpts.push(opt);
        } else {
            acc.regularOpts.push(opt);
        }
        return acc;
    }, {regularOpts: [], fixedOpts: []});

    return (
        <div id="object-right-menu" className={`BngRightMenu ${className || ''} ${open ? 'Open' : 'Close'}`}>
            <BlockUi tag="div" blocking={loading}>
                <div className="scroll-menu-right">
                    <ul id="object-right-menu-regular-options">
                        {renderOpts(regularOpts)}
                    </ul>
                    <ul id="object-right-menu-fixed-options">
                        {renderOpts(fixedOpts)}
                    </ul>
                </div>
            </BlockUi>
        </div>
    )
};

RightMenu.propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    loading: PropTypes.bool,
    open: PropTypes.bool,
    path: PropTypes.string,
};

const RightMenuWrapper = ({
                              closeAnalystMenu = _.noop,
                              handleSubmit = _.noop,
                              initialValues = {},
                              validationSchema = null,
                              onAccordionChange = _.noop,
                              showAccordion = null,
                              enableReinitialize = false,
                              onFormikRef = undefined,
                              ...props
                          }) => {
    const initState = {component: null, accordionKey: null, props: {}};
    const [accordion, setAccordion] = useState(initState);

    useEffect(() => {
        clearAccordion();
    }, [props.open]);

    useEffect(() => {
        window.__CLOSE_RIGHT_MENU = () => {
            clearAccordion();
        }
        return () => delete window.__CLOSE_RIGHT_MENU;
    }, []);

    useEffect(() => {
        onAccordionChange(accordion)
    }, [accordion]);

    useEffect(() => {
        if (showAccordion) {
            toggleAccordion(showAccordion.component, showAccordion.key);
        }
    }, [showAccordion])

    const toggleAccordion = (component, accordionKey, props) => {
        closeAnalystMenu();
        if (!component || accordion.accordionKey === accordionKey) {
            clearAccordion();
            return;
        }
        setAccordion({component, accordionKey, props});
    };

    const openAccordion = (component, accordionKey, props) => {
      setAccordion({component, accordionKey, props});
    };

    useEffect(() => {
        window.__TOGGLE_RIGHT_MENU = (component, accordionKey, props) => {
            toggleAccordion(component, accordionKey, props);
        }
        return () => delete window.__TOGGLE_RIGHT_MENU;
    }, []);

    useEventBus(RIGHT_MENU_CLOSE_SUBMENU, () => {
      clearAccordion();
    });

    useEventBus(KPI_EDIT_MENU_EVENT, ({ toggle = false, ...props } = {}) => {
      const key = 'KpiEditMenu';
      if(toggle && accordion?.accordionKey === key) {
        clearAccordion();
      } else {
        openAccordion(KpiEditMenu, 'KpiEditMenu', props);
      }
    }, [toggleAccordion]);

    useEventBus(ASSISTED_ANALYSIS_EDIT_MENU_EVENT, (props) => {
      openAccordion(AssistedAnalysisEditMenu, 'AssistedAnalysisEditMenu', props);
    }, [toggleAccordion]);

    const clearAccordion = () => {
        setAccordion(initState);
    };

    const onSubmit = async (values, actions) => {
        await handleSubmit(values, actions);
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    const AccordionComponent = accordion?.component;

    return (
        <div id="RightMenuWrapper" className={`RightMenuWrapper ${props.open ? 'Open' : 'Close'}`}>
            <AccordionProvider>
                {/* Added 'enableReinitialize' to fix https://github.com/sol7/bi-machine/issues/3928, changed in #4768 so it only apllies the changes to maps*/}
                <Formik onSubmit={onSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        enableReinitialize={enableReinitialize}
                        innerRef={onFormikRef}
                >
                    <BngForm onKeyPress={onKeyPress}>
                        <RightMenu toggleAccordion={toggleAccordion}
                                   clearAccordion={clearAccordion}
                                   activeAccordion={accordion.accordionKey}
                                   {...props}/>
                        <div id="ObjectRightMenuAccordionWrapper">
                            {AccordionComponent &&
                            <AccordionComponent toggleAccordion={toggleAccordion}
                                                clearAccordion={clearAccordion}
                                                {...props}
                                                {...accordion.props}
                            />
                            }
                        </div>
                    </BngForm>
                </Formik>
            </AccordionProvider>
        </div>
    )
};

export default ContextEnhancer(RightMenuWrapper);