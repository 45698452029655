import styles from './ConnectionsTab.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import BngDropdown from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import ConnectionsDialog from 'components/ui/in-memory/ConnectionsDialog';
import Tooltip from 'components/ui/Tooltip';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useTranslation from 'components/hooks/useTranslation';
import BngSearch from 'components/bng/ui/BngSearch';
import BngLogDialog from 'components/bng/logs/BngLogDialog';
import Utils from 'components/Utils';

const columnIconSelector = (columnType) => {
  switch (columnType) {
    case 'NUMERIC':
      return '123';
    case 'DATE':
      return 'schedule';
    case 'BOOL':
      return 'memory';
    case 'JSON':
      return '{JSON}';
    default:
      return 'abc';
  }
};

function ConnectionSelector({
  connections = [],
  selectedConnection = 0,
  fetchConnections = _.noop,
  setFieldValue = _.noop,
}) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const [opened, setOpened] = useState(false);

  const isConnectionSelected = selectedConnection > 0;

  const connection = useMemo(() => connections.find((con) => con.id === selectedConnection), [
    connections,
    selectedConnection,
  ]);

  return (
    <BngDropdown
      customButton={({ openDropdown }) => (
        <div className={`${styles.ConnectionSelectorWrapper}`}>
          <div
            className={`${styles.ConnectionSelector} opened-${opened}`}
            onClick={(event) => {
              setOpened((prev) => !prev);
              openDropdown(event);
            }}
          >
            <div className={`${styles.connectionNameWrapper}`}>
              {connection ? connection.name : msg.t('select.one.connection')}
            </div>
            <Icon icon="arrow_drop_down" />
          </div>
          {isConnectionSelected && (
            <Icon
              icon="close"
              onClick={() => {
                setFieldValue('connection', 0);
              }}
            />
          )}
        </div>
      )}
      popperOpts={{
        placement: 'bottom-start',
      }}
      className={`ConnectionSelector ${styles.ConnectionSelectorButton}`}
      onClose={() => setOpened((prev) => !prev)}
      popperClassName={`ConnectionListOptionPopper ${styles.ConnectionListOptionPopper} `}
      customOptions={({ closeDropdown }) => (
        <div className={`${styles.ConnectionsOptionsDropdown}`}>
          {connections.map((connection) => {
            return (
              <div
                className={`${styles.ConnectionListOption}`}
                onClick={() => {
                  setFieldValue('connection', connection.id);
                  closeDropdown();
                }}
              >
                {connection.id === 'BIMWarehouse' ? (
                  <>
                    <img
                      className={`ConnectionTab-ConnectionListOption-db-logo`}
                      src={Api.buildUrl('/images/data/logo.png')}
                      alt="Icon"
                    />
                    <div>
                      <div>{`${msg.t('bim.query.export.my.data.lake.connection.title')}`}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      className={`ConnectionTab-ConnectionListOption-db-logo`}
                      src={Api.buildUrl('/images/data/db.png')}
                      alt="Icon"
                    />
                    <div>
                      <div className={`${styles.connectionNameWrapper}`}>{`${msg.t('name')}: ${connection.name}`}</div>
                      <div>{connection?.type?.name}</div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
          <div
            className={`${styles.ConnectionListOption} new-database-connection`}
            onClick={() => {
              dispatch(
                MODALS.open(ConnectionsDialog, {
                  projectId: project.id,
                  onClose: () => fetchConnections(),
                })
              );
              closeDropdown();
            }}
          >
            <Icon icon="add_circle" />
            <div className={`${styles.AddConnectionLabel}`}>{msg.t('manage.connections')}</div>
          </div>
        </div>
      )}
    />
  );
}

function RenderList({
  schemas = [],
  fetchChildList = _.noop,
  childProp = 'tables',
  parent = {},
  handleSelection = _.noop,
  schema = '',
  selectedConnection = 0,
  className = styles.listWrapper,
}) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState([]);

  useEffect(() => setExpanded([]), [selectedConnection]);

  const handleExpand = (idx, schemaName, tableName) => {
    if (tableName) {
      fetchChildList(schemaName, tableName, childProp);
    }

    const expandedClone = expanded.slice();
    const indexOf = expandedClone.indexOf(idx);
    if (indexOf < 0) {
      expandedClone.push(idx);
    } else {
      expandedClone.splice(indexOf, 1);
    }
    setExpanded(expandedClone);
  };

  return (
    <>
      {schemas.map((item, idx) => {
        const isExpanded = expanded.indexOf(idx) >= 0;
        const children = item[childProp];
        const hasChild = children?.length > 0;
        const isSchema = item.hasOwnProperty('tables');
        const isTable = item.hasOwnProperty('columns');
        const schemaName = isSchema ? item.name : schema;

        let itemProps = {};

        if (!children) {
          itemProps.title = (
            <>
              <b>{item.name}</b>
              <div>
                {t('bim.query.menu.item.tooltip.from.table')}: <b>{item.key?.table}</b>
              </div>
              <div>
                {t('bim.query.menu.item.tooltip.column.name.from.table')}: <b>{item.key?.name}</b>
              </div>
              <div>
                {t('bim.query.menu.item.tooltip.column.type')}: <b>{item.type}</b>
              </div>
            </>
          );
        }

        const Tip = !children ? Tooltip : 'div';

        return (
          <Tip {...itemProps} key={idx}>
            <div
              className={`${className} ${isExpanded ? 'open' : 'closed'}`}
              draggable={!isSchema}
              onDragStart={(event) => {
                event.persist();
                handleSelection(
                  item,
                  childProp,
                  parent,
                  event.dataTransfer,
                  schemaName,
                  isTable ? item.name : null,
                  false
                );
                event.stopPropagation();
              }}
              onDragEnd={(event) => {
                event.persist();
                handleSelection(item, childProp, parent, null, schemaName, isTable ? item.name : null, false);
                event.stopPropagation();
              }}
            >
              <div
                key={`${parent.id || 0}${idx}`}
                className={`${styles.schemaName}`}
                onClick={() => handleExpand(idx, schemaName, isTable ? item.name : null)}
              >
                <Icon
                  className={`${styles.schemaNameArrowIcon} ${isExpanded ? 'open' : 'closed'} ${
                    !hasChild ? 'hide' : ''
                  }`}
                  icon="arrow_right"
                />
                <Icon
                  className={`${styles.listItemIcon} ${item.type} `}
                  icon={(isSchema && 'schema') || (isTable && 'table_view') || columnIconSelector(item.type)}
                />
                <div className={`${styles.itemName} ${!children ? 'column' : ''}`}>{item.name}</div>
                {['FOREIGN', 'PRIMARY'].includes(item?.key?.type) && (
                  <Icon className={`${styles.keyIcon} ${item.key.type}`} icon="key" />
                )}
              </div>
              <div className={`${styles.subListWrapper} ${isExpanded ? 'open' : 'closed'}`}>
                {isExpanded && (
                  <RenderList
                    schemas={children}
                    fetchChildList={fetchChildList}
                    childProp="columns"
                    parent={item}
                    handleSelection={handleSelection}
                    schema={schemaName}
                    className={isTable ? styles.columnList : styles.subList}
                  />
                )}
              </div>
              {!isSchema && (
                <Icon
                  className={`${styles.itemAddIcon}`}
                  icon={children ? 'add' : ''}
                  onClick={() =>
                    handleSelection(item, childProp, parent, null, schemaName, isTable ? item.name : null, true, false)
                  }
                />
              )}
            </div>
          </Tip>
        );
      })}
    </>
  );
}

export default function ConnectionsTab({ values, setFieldValue }) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const $intervalRef = useRef();

  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dbSchemas, setDbSchemas] = useState(null);
  const [filter, setFilter] = useState('');
  const [syncing, setSyncing] = useState({ running: false, errorMessage: '', jobId: 0 });

  const selectedConnection = values.connection;

  const connection = useMemo(() => connections.find((con) => con.id === selectedConnection), [
    connections,
    selectedConnection,
  ]);

  const filteredDbSchemas = useMemo(() => {
    if (!filter) {
      return dbSchemas;
    }

    const clone = dbSchemas.slice();
    return clone.map((schema) => {
      schema = { ...schema };
      schema.tables = schema.tables.filter((table) => table.name.toLowerCase().includes(filter.toLowerCase()));
      return schema;
    });
  }, [dbSchemas, filter]);

  useEffect(() => {
    fetchConnections();

    return () => {
      clearInterval($intervalRef.current);
    };
  }, []);

  useEffect(() => {
    clearInterval($intervalRef.current);

    setDbSchemas(null);
    const newSyncing = { ...syncing };
    if (selectedConnection !== 0) {
      newSyncing.running = true;
      syncDatabase(true);
      fetchDataBase();
    } else {
      newSyncing.running = false;
      setFieldValue('connection', 0);
    }
    setSyncing(newSyncing);
  }, [selectedConnection]);

  const fetchConnections = async () => {
    try {
      let connectionList = connections;
      const projectId = project?.id;
      const data = await Api.Connection.findAll({ projectId });
      connectionList = data.filter(con => con.type !== null).map((connection) => ({
        ...connection,
        database: connections.type?.name,
      }));
      connectionList.push({ id: 'BIMWarehouse', name: 'BIMWarehouse', type: { name: 'BIMWarehouse' } });
      setConnections(connectionList);
      const connection = connectionList.find((connection) => connection.id === values.connection);
      if (connection) {
        setFieldValue('connection', connection.id);
      }
    } catch (e) {
      console.error('Error on function fetchConnections', e);
      UiMsg.ajaxError(msg.t('bim.query.fetch.connections.error'), { e });
    }
  };

  const fetchDataBase = async () => {
    try {
      setLoading(true);
      let data;
      if (selectedConnection === 'BIMWarehouse') {
        data = await Api.Connection.fetchProjectDwSchema(project.id);
      } else {
        data = await Api.Connection.fetchSchema({ connectionId: selectedConnection, inspectionType: 'SCHEMA' });
      }
      setDbSchemas(data.schemas);
    } catch (e) {
      console.error('Error on function fetchDataBase', e);
      UiMsg.ajaxError(msg.t('bim.query.fetch.database.error'), { e });
    } finally {
      setLoading(false);
    }
  };

  const syncDatabase = async (createInterval = false) => {
    if (selectedConnection <= 0) {
      return;
    }

    if (createInterval) {
      $intervalRef.current = setInterval(syncDatabase, 30000);
    }

    const newSync = { ...syncing };

    try {
      const { job } = await Api.Connection.findSchemaInspectionJob(selectedConnection);
      if (job) {
        clearInterval($intervalRef.current);
        newSync.errorMessage = job.props.errorMessage || '';
        newSync.jobId = job.id;
      }
      if (job) {
        return;
      }

      setSyncing({ ...newSync, running: true });
      clearInterval($intervalRef.current);
      const resp = await Api.Connection.fetchSchema({
        connectionId: selectedConnection,
        inspectionType: 'PERSISTED',
      });

      if (resp.schemas !== null) {
        await fetchConnections();
        setDbSchemas(resp.schemas);
      }
    } catch (e) {
      console.error('Error on fetch FULL_SCHEMA', { e });
      UiMsg.ajaxError(msg.t('bim.query.fetch.database.error'), { e });
    } finally {
      setSyncing({ ...newSync, running: false });
    }
  };

  const createJobToSyncDataBase = async () => {
    try {
      setSyncing({ ...syncing, running: true });
      if (selectedConnection === 'BIMWarehouse') {
        const data = await Api.Connection.fetchProjectDwSchema(project.id);
        setDbSchemas(data.schemas);
        setSyncing({ ...syncing, running: false });
      } else if (selectedConnection > 0) {
        await Api.Connection.createSchemaInspectionJob(selectedConnection, project.id);
        $intervalRef.current = setInterval(syncDatabase, 30000);
      }
    } catch (e) {
      console.error('Error syncing database', { e });
      UiMsg.ajaxError('', { e });
      setSyncing({ ...syncing, running: false });
    }
  };

  const fetchChildList = async (schemaName, tableName, childProp, lockUI = false) => {
    try {
      if (!lockUI) {
        setLoading(true);
      }
      const clonedSchemas = dbSchemas.slice();
      let temp = clonedSchemas.find((schema) => schema.name === schemaName);

      if (tableName) {
        temp = temp.tables.find((table) => table.name === tableName);
      }

      if (temp[childProp].length === 0) {
        const data = await Api.Connection.fetchSchema({
          connectionId: selectedConnection,
          inspectionType: tableName ? 'COLUMN' : 'TABLE',
          schemaName: schemaName,
          tableName: tableName,
        });

        temp[childProp] = data.schemas
          .find((s) => s.name === schemaName)
          .tables.find((t) => t.name === tableName).columns;

        setDbSchemas(clonedSchemas);
      }
    } catch (e) {
      console.error('Error on fetchChildList()', { e });
      UiMsg.ajaxError(msg.t('bim.query.fetch.database.error'), { e });
    } finally {
      if (!lockUI) {
        setLoading(false);
      }
    }
  };

  const handleSelection = async (
    item,
    child,
    parent,
    dataTransfer = {},
    schemaName,
    tableName,
    fromAddButton = false,
    lockUI = false
  ) => {
    const hasChildProp = Object.hasOwn(item, child);
    if (hasChildProp) {
      await fetchChildList(schemaName, tableName, 'columns', lockUI);
    }

    const isTable = item.hasOwnProperty('columns');
    const isBIMWarehouse = selectedConnection === 'BIMWarehouse';
    let sqlSelect = '';
    if (!isTable) {
      sqlSelect = `${parent.name}.${isBIMWarehouse ? '"' : ''}${item.name}${isBIMWarehouse ? '"' : ''}`;
      dataTransfer.setData('text/plain', sqlSelect);
    } else if (dataTransfer === null) {
      const table = hasChildProp ? item : parent;
      sqlSelect = '\nSELECT';

      (hasChildProp ? table[child] : [item]).forEach((v, idx) => {
        sqlSelect += `\n${idx > 0 ? ',' : ' '} ${table.name}.${isBIMWarehouse ? '"' : ''}${v.name}${
          isBIMWarehouse ? '"' : ''
        }`;
      });

      sqlSelect += `\nFROM\n"${schemaName}".${table.name}`;
      setFieldValue('sql', values.sql + sqlSelect);
    }
  };

  const clearSyncingError = async () => {
    try {
      await Api.Connection.clearSchemaInspectionJob(selectedConnection, syncing.jobId);
      setSyncing({
        running: false,
        errorMessage: '',
        jobId: 0,
      });
    } catch (e) {
      console.error('Error on clearSyncingError()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const lastSync = connection?.databaseStructureUpdatedAt ?? 0;

  return (
    <>
      <AccordionList className={`${styles.ConnectionsTabAccordionWrapper} ObjectRightMenuAccordion`} loading={loading}>
        <Accordion title={msg.t('connection')} startOpen={true}>
          <ConnectionSelector
            connections={connections}
            selectedConnection={selectedConnection}
            fetchConnections={fetchConnections}
            setFieldValue={setFieldValue}
          />
        </Accordion>
        <Accordion
          title={msg.t('bim.query.menu.item.title.tables')}
          disabled={dbSchemas === null}
          startOpen={dbSchemas != null}
        >
          <div className={`${styles.TopButtons}`}>
            <div className={`${styles.syncingIndicatorWrapper}`}>
              <div className={`${styles.SyncButton}`} onClick={createJobToSyncDataBase}>
                <Icon className={`${styles.SyncIcon} ${syncing.running ? 'syncing' : ''}`} icon="sync" />
                {msg.t(syncing.running ? 'syncing' : 'sync')}{' '}
                {lastSync > 0 && selectedConnection !== 'BIMWarehouse' ? (
                  syncing.errorMessage ? (
                    <Icon
                      title={`${msg.t('last_update')}: ${Utils.Date.formatDateTime(lastSync)}`}
                      className={`${styles.lastSyncDateIcon}`}
                      icon="calendar_month"
                    />
                  ) : (
                    Utils.Date.formatDateTime(lastSync)
                  )
                ) : (
                  ''
                )}
              </div>
              {syncing.errorMessage && (
                <>
                  <Icon title={syncing.errorMessage} className={styles.syncingInfoIcon} icon="info" />
                  <Icon
                    title={msg.t('bim.query.database.syncing.clean.error')}
                    className={styles.syncingClearIcon}
                    icon="close"
                    onClick={clearSyncingError}
                  />
                </>
              )}
            </div>
            <BngSearch className={`${styles.SearchTable}`} onChange={(value) => setFilter(value)} />
            <div
              className={`${styles.ToJsonButton}`}
              onClick={() => {
                dispatch(
                  MODALS.open(BngLogDialog, {
                    log: JSON.stringify(dbSchemas, null, 2),
                    className: `${styles.LogDialog}`,
                    title: 'JSON',
                  })
                );
              }}
            >
              {'{JSON}'}
            </div>
          </div>
          <div className={`${styles.schemasWrapper}`}>
            {dbSchemas !== null && dbSchemas.length > 0 && (
              <RenderList
                schemas={filteredDbSchemas}
                fetchChildList={fetchChildList}
                handleSelection={handleSelection}
                selectedConnection={selectedConnection}
              />
            )}
          </div>
        </Accordion>
        {/*<BngButton className={`${styles.NextStepButton}`}>*/}
        {/*  {msg.t('bim.query.connection.configuration.next.step')}*/}
        {/*</BngButton>*/}
      </AccordionList>
    </>
  );
}
