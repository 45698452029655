import React, {Component} from 'react';
import {
    BreadcrumbToolbar,
    ExportToolbarItem,
    FavoriteToolbarItem,
    InformationToolbarItem,
    MobileToolbarItem,
    NotesToolbarItem,
    PublisherToolbarItem
} from "components/ui/breadcrumb-toolbar";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";

class OrgmapBreadcrumbToolbar extends Component {

    static defaultProps = {
        isNotOrgmapPersisted: false,
        path: '',
        caption: '',
        mobile: true,
    };

    state = {
        loading: true,
        onAjax: false,
        mobile: true,
    };

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.setState({mobile: this.props.mobile});
    }

    toggleMobile = async (event) => {
        this.setState({onAjax: true});
        try {
            const mobile = await Api.Bng.toggleOrgmapMobile();
            this.setState({mobile});
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({onAjax: false});
        }
    };

    render(){
        const {onAjax, mobile} = this.state;
        const {isNotOrgmapPersisted, path, caption} = this.props;

        return (
            <BreadcrumbToolbar loading={onAjax}>
                <ExportToolbarItem disabled={isNotOrgmapPersisted}
                                   caption={caption}
                                   path={path}/>

                <PublisherToolbarItem path={path}
                                      caption={caption}
                                      disabled={isNotOrgmapPersisted}/>

                <NotesToolbarItem disabled={isNotOrgmapPersisted}
                                  path={path}/>

                <FavoriteToolbarItem disabled={isNotOrgmapPersisted}
                                     path={path}/>

                <MobileToolbarItem disabled={isNotOrgmapPersisted}
                                   inactive={!mobile}
                                   path={path}
                                   onClick={this.toggleMobile}/>

                <InformationToolbarItem disabled={isNotOrgmapPersisted}
                                        title={this.props.context.msg.t('object.information.orgmap')}
                                        dataSource={null}
                                        path={path}/>
            </BreadcrumbToolbar>
        )
    }
}

export default ContextEnhancer(OrgmapBreadcrumbToolbar);