import styles from './ActivateBimDialog.module.css';

import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngSlider } from 'components/bng/form/BngSlider';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';

const PlanSchema = bngYup((yup) =>
  yup.object().shape({
    plan: yup.string().required().default(''),
    users: yup.number().required().default(10),
    structures: yup.number().required().default(5),
  })
);

const ActivationSolicited = ({ closeModal }) => {
  const { msg } = useBimContext();
  return (
    <div className={styles.ActivatedBody}>
      <img src={`${Api.baseUrl()}/resources/images/ProjectActivated.svg`} alt="Project activated image" />
      <h5>{msg.t('activation.request.received')}</h5>
      <span>{msg.t('contact.soon')}</span>
      <span>{msg.t('two.days.added')}</span>
      <Button
        className={styles.selectButton}
        onClick={async () => {
          await closeModal();
          window.location.reload();
        }}
      >
        {msg.t('finish')}
      </Button>
    </div>
  );
};

const COLORS = ['#00A355', '#F98900', '#005DFF', '#FD003D'];

const TableHeader = ({ plan, color, onSelect, isSelected }) => {
  const context = useBimContext();
  return (
    <div className={`tableHeader ${styles.tableHeader}`} style={{ borderTopColor: color }}>
      <span className={styles.planName}>{plan.name}</span>
      <Button className={`selectButton ${styles.selectButton}`} onClick={onSelect}>
        {isSelected ? context.msg.t('selected') : context.msg.t('select')}
      </Button>
    </div>
  );
};

const TableColumn = ({ feat, plan, color, isSelected, isLastFeat }) => {
  const { msg } = useBimContext();

  const selectedStyle = isSelected
    ? {
        borderRightColor: color,
        borderLeftColor: color,
        borderBottomColor: isLastFeat ? color : undefined,
      }
    : {};

  let rowValue = plan[feat];

  if (feat === 'dataUpdate') {
    rowValue =
      plan[feat] >= 60 ? msg.t('feature.every.hour', [plan[feat] / 60]) : msg.t('feature.every.minute', [plan[feat]]);
  }

  const typeOfFeat = typeof rowValue;
  switch (typeOfFeat) {
    case 'boolean':
      return (
        <td className={`${rowValue ? styles.enabled : styles.disabled}`} style={selectedStyle}>
          <Icon icon={rowValue ? 'done' : 'close'} />
        </td>
      );
    case 'object':
      return (
        <td style={selectedStyle}>
          {Object.entries(rowValue).map(([key, value]) => {
            return (
              <li className={`${styles.objectColumnItem} ${value ? styles.enabled : styles.disabled}`}>
                <div className={`${styles.itemLine}`}>
                  <span>
                    <Icon icon={value ? 'done' : 'close'} />
                    {msg.t(`feature.${key}`)}
                  </span>
                  {typeof value === 'string' && (
                    <Icon icon={'info'} className={`${styles.infoIcon}`} title={msg.t(`feature.${key}.${value}`)} />
                  )}
                </div>
              </li>
            );
          })}
        </td>
      );
    default:
      const labelMsg =
        typeOfFeat === 'number' || !msg.containKey(`feature.${rowValue}`) ? rowValue : msg.t(`feature.${rowValue}`);
      return (
        <td className={`${rowValue ? styles.enabled : styles.disabled}`} style={selectedStyle}>
          <span>{labelMsg}</span>
        </td>
      );
  }
};

export default function ActivateBimDialog({ closeModal, currentPlan, changePlan = false, beforeClose = _.noop }) {
  const context = useBimContext();

  const [selected, setSelected] = useState(context.projectActivated?.plan || '');
  const [activated, setActivated] = useState(false);
  const [planInfo, setPlanInfo] = useState([]);
  const [billingPlans, setBillingPlans] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = context.projectActivated
    ? {
        plan: context.projectActivated.plan,
        users: context.projectActivated.users,
        structures: context.projectActivated.structures,
      }
    : PlanSchema.default();

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const plans = await Api.Project.findProjectPlans();
      setPlanInfo(plans);
      if (currentPlan && changePlan) {
        const billingPlans = await Api.Account.fetchPlans(context.accountId);
        setBillingPlans(billingPlans);
        const selectedPlan = plans.plans.find((plan) => plan.key === currentPlan.key);
        setSelected(selectedPlan?.name);
      }
    } catch (e) {
      console.error('Error on ActivateBimDialog fetchPlans', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const activatePlan = async (values) => {
    setLoading(true);
    try {
      if (changePlan && !!billingPlans) {
        const plan = planInfo.plans.find((plan) => plan.name === values.plan);
        const billingPlan = billingPlans.availablePlans.find((p) => p.key === plan.key);
        await Api.Account.changePlan(context.accountId, { ...values, billingPlan: billingPlan });
        UiMsg.ok(context.msg.t('plan.changed.successfully'));
        await onClose();
      } else {
        await Api.Project.requestActivation(context.project.id, { ...values, id: context.projectActivated?.id });
        setActivated(true);
        UiMsg.ok(context.msg.t('activation.request.sent'));
      }
    } catch (e) {
      console.error('Error on function activatePlan()', e);
      UiMsg.error(context.msg.t('activation.request.error'));
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => {
    Utils.History.updateQuery({
      activateBIMachineDialog: null,
    });
    await beforeClose();
    closeModal();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={PlanSchema} onSubmit={activatePlan} isInitialValid={false}>
      {({ values, setFieldValue, submitForm, isValid }) => {
        const initialValuesNotChanged = _.isEqual(values, initialValues);

        return (
          <Dialog
            className={`ActivateBimDialog ${styles.ActivateBimDialog}`}
            title={context.msg.t('activate.bim')}
            hideClose={true}
            onClose={onClose}
            contentFullWidth={true}
            loading={loading}
          >
            {activated ? (
              <ActivationSolicited closeModal={closeModal} />
            ) : (
              <>
                <Dialog.Body>
                  <table className="table table-bordered scrollable">
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#333333',
                          }}
                        >
                          {context.msg.t('choose.plan')}
                        </th>
                        {planInfo.plans?.map((plan, index) => {
                          return (
                            <th
                              className={selected === plan.name ? styles.selectedColumn : ''}
                              style={selected === plan.name ? { background: COLORS[index] } : {}}
                            >
                              <TableHeader
                                key={index}
                                plan={plan}
                                color={COLORS[index]}
                                isSelected={selected === plan.name}
                                onSelect={() => {
                                  setSelected(plan.name);
                                  setFieldValue('plan', plan.name);
                                }}
                              />
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {planInfo.features?.map((feat, featIdx) => {
                        const isLastFeat = featIdx === planInfo.features.length - 1;
                        return (
                          <tr key={featIdx}>
                            <td className={styles.darkText}>{context.msg.t(`feature.${feat}`)}</td>
                            {planInfo.plans?.map((plan, planIdx) => {
                              return (
                                <TableColumn
                                  key={planIdx}
                                  feat={feat}
                                  plan={plan}
                                  color={COLORS[planIdx]}
                                  isSelected={selected === plan.name}
                                  isLastFeat={isLastFeat}
                                />
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <span className={styles.footNote}>{context.msg.t('daily.quotas.footnote')}</span>

                  <div className={styles.envDimensions}>
                    <h3>{context.msg.t('environment.dimensioning')}</h3>
                    <span className={styles.envDescription}>
                      {context.msg.t('environment.dimensioning.description')}
                    </span>

                    <div className={styles.allSlidersWrapper}>
                      <div className={styles.sliderWrapper}>
                        <span>{context.msg.t('additional.users')}</span>
                        <Field
                          name="users"
                          className={styles.slider}
                          component={BngSlider}
                          showInput={false}
                          customTooltipMsg={`${values.users} ${context.msg.t('users')}`}
                          step={1}
                          max={200}
                        />
                      </div>

                      <div className={styles.sliderWrapper}>
                        <span>{context.msg.t('additional.structures')}</span>
                        <Field
                          name="structures"
                          className={styles.slider}
                          component={BngSlider}
                          showInput={false}
                          customTooltipMsg={`${values.structures} ${context.msg.t('structures')}`}
                          step={1}
                          max={100}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Body>

                <Dialog.Footer>
                  <DefaultDialogActions
                    onClickSaveButton={async (e) => {
                      if (!initialValuesNotChanged) {
                        isValid ? await submitForm() : UiMsg.error(context.msg.t('select.a.plan'));
                      }
                    }}
                    saveButtonClassname={initialValuesNotChanged ? styles.buttonDisabled : ''}
                    closeModal={onClose}
                    context={context}
                    okLabel={changePlan ? 'change.plan' : 'request.activation'}
                    hideCancelButton={context.projectExpired}
                  />
                </Dialog.Footer>
              </>
            )}
          </Dialog>
        );
      }}
    </Formik>
  );
}
