import React from 'react';

import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';
import { MARKETPLACE_GROUPS } from 'components/bng/pages/marketplace/MarketplacePage';
import { GROUP_KEYS } from 'components/bng/pages/marketplace/MarketplaceLayout';

const ADDONS_GROUPS = [
  {
    title: 'addons.brand',
    desc: 'addons.brand.desc',
    key: GROUP_KEYS.ADDONS,
    filterFunc: (item) => item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.BRAND,
    anchorKey: `MarketplacePage-BRAND`,
  },
  {
    title: 'addons.security',
    desc: 'addons.security.desc',
    key: GROUP_KEYS.ADDONS,
    filterFunc: (item) => item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.SECURITY,
    anchorKey: `MarketplacePage-SECURITY`,
  },
  {
    title: 'addons.manage',
    desc: 'addons.manage.desc',
    key: GROUP_KEYS.ADDONS,
    filterFunc: (item) => item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.MANAGEMENT,
    anchorKey: `MarketplacePage-MANAGEMENT`,
  },
];

export default function MktAddonsTab({ ...props }) {
  return <MktDefaultTab className={'MktAddonsTab'} groupsToRender={ADDONS_GROUPS} {...props} />;
}
