import transparentBar from './BngColorPickerTransparentBackgroundBar.png';
import converter from 'color-convert';

class ColorUtils {
  hslToString(hsl) {
    return `hsl(${hsl[0]}deg, ${hsl[1]}%, ${hsl[2]}%)`;
  }

  stringToHsl(string) {
    const hslColor = string.replace(/[a-zA-Z()%]/g, '');
    return hslColor.split(',');
  }

  isValidHex(hex) {
    const regexHex = /^([0-9a-f]{3}){1,2}$/i;
    return regexHex.test(hex);
  }

  defaultToHex(c) {
    let tempColor = c?.color ?? c;
    if (Array.isArray(tempColor)) {
      tempColor = converter.hsl.hex(tempColor);
    } else if (tempColor.startsWith('hsl')) {
      tempColor = converter.hsl.hex(this.stringToHsl(tempColor));
    }

    if (tempColor.startsWith('#')) {
      return tempColor;
    } else {
      return '#' + tempColor;
    }
  }

  genTransparentGradientImg(colorArr, colorRotation = null) {
    let gradientBgString = `linear-gradient(${colorRotation !== null ? colorRotation : 90}deg`;
    if (Array.isArray(colorArr)) {
      for (const clr of colorArr) {
        let color = clr.color;
        if (color.length === 4) {
          //It needs to transform a 3 char hex in a 6 char hex to add opacity after
          color = color
            .split('')
            .map((c, idx) => (idx > 0 ? c + c : c))
            .join('');
        }
        gradientBgString += `, ${color + this.transparencyToHexValue(clr.opacity)} ${clr.position * 100}%`;
      }
    }
    gradientBgString += `), url('${transparentBar}')`;
    return gradientBgString;
  }

  transparencyToHexValue(transparencyValue) {
    return Math.floor(transparencyValue * 255)
      .toString(16)
      .padStart(2, '0');
  }
}

export default new ColorUtils();
