import React from 'react';
import { Field, useFormikContext } from 'formik';
import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import BngSwitch from 'components/bng/form/BngSwitch';
import Icon from 'components/ui/common/Icon';
import AvatarCarousel from 'components/ui/profile/AvatarCarousel';
import { isCurrentUser } from 'components/ui/profile/ProfileDialog';
import ChangePasswordDialog from 'components/ui/profile/ChangePasswordDialog';
import { MODALS } from 'components/ui/redux/Actions';
import Utils from 'components/Utils';
import HelpIcon from 'components/ui/common/HelpIcon';

const ProfileCard = ({
  context = {},
  user = {},
  newUser = false,
  avatars = [],
  mobile = true,
  allowMobileMessaging = true,
  canAlterProfileAndMobile = true,
  dispatch = _.noop,
}) => {
  const { values } = useFormikContext();
  const currentUser = isCurrentUser(user.id, context);
  const textOnline = user.online ? 'online' : 'offline';

  const logout = () => {
    Api.User.logout();
  };

  const openChangePasswordDialog = () => {
    dispatch(MODALS.open(ChangePasswordDialog, { userId: user.id }));
  };

  return (
    <div className={`profile-card ${currentUser ? 'user' : ''}`}>
      {!newUser && <div className={`profile-user-status ${textOnline}`}>{context.msg.t(`user.is.${textOnline}`)}</div>}

      <Field
        name="avatarLink"
        component={AvatarCarousel}
        newUser={newUser}
        avatars={avatars}
        defaultImage={user.avatarLink}
        disabled={!newUser && !currentUser}
      />

      <div className="profile-user-description">
        <span className="profile-user-name">
          {values.displayName}
          <BimachineBadge context={context} email={user.email} />
        </span>
        {!newUser && (
          <span className="profile-user-last-access">
            {context.msg.t('last_access')}:{' '}
            {user.lastAccess ? moment(user.lastAccess).fromNow() : context.msg.t('no.activity.register')}
          </span>
        )}
      </div>

      <div className="profile-user-configuration">
        <div className="profile-user-mobile">
          <Icon icon="phone_android" />
          {canAlterProfileAndMobile ? (
            <Field
              label={context.msg.t('user.dialog.mobile')}
              name="mobile"
              id="profile-switch-mobile"
              component={BngSwitch}
            />
          ) : (
            <React.Fragment>
              <span>{context.msg.t('user.dialog.mobile')}</span>
              <div
                style={{ marginTop: 1, padding: '0 6px' }}
                className={`profile-user-status ${mobile ? 'online' : 'offline'}`}
              >
                {mobile ? context.msg.t('enabled.label') : context.msg.t('disabled.label')}
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="profile-user-messaging">
          <div className="messaging-icons">
            <Icon className="icon-whatsapp" icon="fa fa-whatsapp" />
          </div>
          {canAlterProfileAndMobile ? (
            <Field label={context.msg.t('enable.messaging')} name="allowMobileMessaging" component={BngSwitch} />
          ) : (
            <>
              <span>{context.msg.t('enable.messaging')}</span>
              <div
                style={{ marginTop: 1, padding: '0 6px' }}
                className={`profile-user-status ${allowMobileMessaging ? 'online' : 'offline'}`}
              >
                {allowMobileMessaging ? context.msg.t('enabled.label') : context.msg.t('disabled.label')}
              </div>
            </>
          )}
          <HelpIcon className="help-icon-whatsapp" title={context.msg.t('enable.whatsapp')} />
        </div>

        {!newUser && canAlterProfileAndMobile && (
          <div className="profile-user-password" onClick={() => openChangePasswordDialog()}>
            <Icon icon="lock" />
            <span>{context.msg.t('user.dialog.password')}</span>
          </div>
        )}

        {currentUser && (
          <div className="profile-user-logout" onClick={logout}>
            <Icon icon="exit_to_app" />
            <span>{context.msg.t('logout')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const BimachineBadge = ({ context, email }) => {
  const isBimachine = Utils.Project.isBimachineEmail(email);
  if (!isBimachine) {
    return null;
  }

  return (
    <div className="BimachineBadge" title={context.msg.t('verified.bim.user')}>
      <Icon icon="icon-bim-logo" />
    </div>
  );
};

export default ContextEnhancer(ProfileCard);
