import "components/bng/ui/BngNavHeader.css";
import React from "react";
import Icon from "components/ui/common/Icon";

const BngNavHeader = ({
                          icon = '',
                          className = '',
                          onGoBack = _.noop,
                          stack = [],
                          ...props
                      }) => {
    return (
        <div className={`BngNavHeader ${className}`} {...props}>
            <div className={`BngNavHeader-Title`}>
                <Icon className="BngNavHeader-Icon" icon={icon} />
                {stack.map(({label = ''}, index) => (
                    <span className="flex-center-items" key={index}>
                        <span  className={`BngNavHeader-Item`}>{label}</span>
                        {index < (stack.length - 1) &&
                        <Icon icon="chevron_right" />
                        }
                    </span>
                ))}
            </div>
            <Icon icon="keyboard_backspace"
                  className="BngNavHeader-Back"
                  onClick={onGoBack}
            />
        </div>
    )
}

export default BngNavHeader;