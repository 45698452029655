import styles from './AccountsPage.module.css';
import TUTORIAL_IMG_1 from './assets/ada.accounts.slide.1.png';
import TUTORIAL_IMG_2 from './assets/ada.accounts.slide.2.png';
import TUTORIAL_IMG_3 from './assets/ada.accounts.slide.3.png';
import TUTORIAL_IMG_4 from './assets/ada.accounts.slide.4.png';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageLayout from 'components/bng/pages/common/layout/PageLayout';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import AccFinanceTab from 'components/ui/accounts/tabs/AccFinanceTab';
import AccProposalsTab from 'components/ui/accounts/tabs/AccProposalsTab';
import AccUsersTab from 'components/ui/accounts/tabs/AccUsersTab';
import AccStructuresTab from 'components/ui/accounts/tabs/AccStructuresTab';
import AccProjectsTab from 'components/ui/accounts/tabs/AccProjectsTab';
import AccAddonsTab from 'components/ui/accounts/tabs/AccAddonsTab';
import AccAppKeysTab from 'components/ui/accounts/tabs/AccAppKeysTab';
import PublisherFullTab from 'components/ui/accounts/tabs/PublisherFullTab';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useQueryParams from 'components/hooks/useQueryParams';
import useTranslation from 'components/hooks/useTranslation';
import UiMsg from 'components/ui/UiMsg';
import useFetchData from 'components/hooks/useFetchData';
import useBimNavigate from 'components/hooks/useBimNavigate';
import BngTag, { BetaTag } from 'components/bng/ui/BngTag';
import BngAda from 'components/bng/ui/BngAda/BngAda';
import BngMultiStep from 'components/bng/ui/BngMultiStep';
import { WhatsAppConsumption } from 'components/ui/whatsapp/WhatsAppDialog';
import EditObject from 'components/ui/edit-object/EditObject';
import AddonType from 'components/bng/accounts/AddonType';

export const TAB_QUERY_PARAM = 'currentTab';
export const ACC_QUERY_PARAM = 'currentAccount';
const ANCHOR_QUERY_PARAM = 'anchorId';

const checkAddonForAccount = ({ account, addonKeys }) => {
  const addons = account.activeForBilling ? account.billingAddons.activeAddons : account.addons;
  return addons.some((addon) => addonKeys.includes(addon.key ?? addon.addon));
};

const TAB_GROUPS = [
  {
    key: 'finance',
    label: 'accountTab.finance',
    component: AccFinanceTab,
    icon: 'credit_card',
    shouldDisable: ({ account, context }) => !account.activeForBilling && !context.user.accountManager,
    soonTag: true,
    children: [
      { title: 'contracted.plan', anchorId: 'AccFinanceTab-plan' },
      { title: 'additionals', anchorId: 'AccFinanceTab-contract' },
      { title: 'consumption', anchorId: 'AccFinanceTab-consumption' },
      { title: 'invoices.and.histories', anchorId: 'AccFinanceTab-invoices' },
    ],
  },
  {
    key: 'proposals',
    label: 'accountTab.proposals',
    component: AccProposalsTab,
    icon: 'gavel',
    shouldDisable: ({ account, context }) => !account.activeForBilling && !context.user.accountManager,
    soonTag: true,
  },
  {
    key: 'users',
    label: 'accountTab.users',
    component: AccUsersTab,
    icon: 'people',
  },
  {
    key: 'structures',
    label: 'accountTab.structures',
    component: AccStructuresTab,
    icon: 'cloud',
  },
  {
    key: 'projects',
    label: 'accountTab.projects',
    component: AccProjectsTab,
    icon: 'storage',
  },
  {
    key: 'addons',
    label: 'accountTab.addons',
    component: AccAddonsTab,
    icon: 'extension',
    children: [{ title: 'addon.whats.api.name', anchorId: '' }],
    hide: true,
  },
  {
    key: 'extensions',
    label: 'accountTab.extensions',
    component: PublisherFullTab,
    icon: 'extension',
    expandOnClick: true,
    shouldDisable: ({ account }) =>
      !checkAddonForAccount({ account, addonKeys: [AddonType.WHATS_APP.key, AddonType.PUBLISHER_FULL.key] }),
    children: [
      {
        title: 'publisher.full',
        key: 'publisher',
        component: PublisherFullTab,
        shouldNotRender: ({ account }) => !checkAddonForAccount({ account, addonKeys: [AddonType.PUBLISHER_FULL.key] }),
      },
      {
        title: 'whatsapp',
        key: 'whatsapp',
        component: WhatsAppConsumption,
        shouldNotRender: ({ account }) => !checkAddonForAccount({ account, addonKeys: [AddonType.WHATS_APP.key] }),
      },
    ],
  },
  {
    key: 'appKeys',
    label: 'accountTab.appKeys',
    component: AccAppKeysTab,
    icon: 'key',
    hide: false,
  },
];

function TabNavItem({
  tab,
  onClick = _.noop,
  onExpandTab = _.noop,
  expanded = false,
  isSelected,
  expandOnClick = false,
  disabled = false,
  soonTag = false,
  account,
}) {
  const { msg } = useBimContext();

  const containChildren = !_.isEmpty(tab.children);
  return (
    <>
      <div
        className={`${styles.tabItem}  ${isSelected ? styles.selectedTab : ''} ${disabled ? styles.disabled : ''}`}
        onClick={() => (disabled ? _.noop : expandOnClick ? onExpandTab(tab.key) : onClick(tab.key, null))}
      >
        <div className={`${styles.tabTitle}`}>
          <Icon className={`${styles.tabIcon}`} icon={tab.icon} />
          <span>{msg.t(`tabNav.${tab.key}`)}</span>
          {tab.betaTag && <BetaTag />}
        </div>
        {!disabled && containChildren && (
          <BngIconButton
            icon={expanded ? 'expand_less' : 'expand_more'}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (!disabled) {
                onExpandTab(tab.key);
              }
            }}
          />
        )}
        {disabled && soonTag && <BngTag description={msg.t('coming.soon')} className={styles.soonTag} />}
      </div>
      {containChildren &&
        tab.children
          .filter((tabChildren) => !tabChildren.shouldNotRender?.({ account }))
          .map((tabChildren, idx) => {
            const lastChild = idx + 1 === tab.children.length;
            return (
              <div
                key={idx}
                className={`${styles.tabChildren} ${expanded ? '' : styles.notVisible} ${
                  lastChild ? styles.lastChild : ''
                }`}
                onClick={() => onClick(tabChildren.key ?? tab.key, tabChildren.anchorId, !isSelected)}
              >
                <span>{msg.t(tabChildren.title)}</span>
              </div>
            );
          })}
    </>
  );
}

const DEFAULT_TAB = TAB_GROUPS[0];

function findTab({ key, tabs = TAB_GROUPS, account, context }) {
  for (const tab of tabs) {
    if (tab.key === key) {
      const disabled = tab.shouldDisable?.({ account, context }) ?? false;
      if (disabled) {
        return null;
      } else {
        return tab;
      }
    }

    if (tab.children) {
      const childTab = findTab({ key, tabs: tab.children, account, context });
      if (childTab) {
        return childTab;
      }
    }
  }
  return null;
}

export function NavPanel({
  account,
  accountOpts,
  onSelectAccount,
  tabs = TAB_GROUPS,
  defaultTab = DEFAULT_TAB,
  tabProps = {},
  accountsLoading = false,
  showSelector = true,
}) {
  const context = useBimContext();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedTabs, setExpandedTabs] = useState([]);
  const selectedTab =
    findTab({
      key: searchParams.get(TAB_QUERY_PARAM),
      tabs,
      account: account,
      context: context,
    }) || defaultTab;

  useEffect(() => {
    let queryTab = searchParams.get(TAB_QUERY_PARAM);
    if (_.isEmpty(queryTab)) {
      queryTab = defaultTab.key;
    }

    onSelectTab(queryTab, searchParams.get(ANCHOR_QUERY_PARAM));
  }, [searchParams]);

  useEffect(() => {
    const anchorId = searchParams.get(ANCHOR_QUERY_PARAM);
    if (anchorId) {
      document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [searchParams]);

  const onSelectTab = (tabKey = defaultTab.key, anchorId) => {
    searchParams.set(TAB_QUERY_PARAM, tabKey);
    if (anchorId) {
      searchParams.set(ANCHOR_QUERY_PARAM, anchorId);
    } else {
      searchParams.delete(ANCHOR_QUERY_PARAM);
    }
    setSearchParams(searchParams);
  };

  const onExpandTab = (tabKey) => {
    if (expandedTabs.includes(tabKey)) {
      expandedTabs.splice(expandedTabs.indexOf(tabKey), 1);
    } else {
      expandedTabs.push(tabKey);
    }
    setExpandedTabs([...expandedTabs]);
  };

  const CurrentTabComponent = selectedTab.component;
  const onlyOneAccount = showSelector ? accountOpts.length === 1 : 0;

  return (
    <>
      <div className={`NavPanel ${styles.NavPanel}`}>
        <div className={`${styles.accountTabs}`}>
          {tabs
            .filter((tab) => !tab.hide)
            .map((tab, idx) => {
              const disabled = tab.shouldDisable?.({ account, context }) ?? false;
              return (
                <TabNavItem
                  key={idx}
                  tab={tab}
                  onClick={onSelectTab}
                  onExpandTab={onExpandTab}
                  expandOnClick={tab.expandOnClick}
                  expanded={expandedTabs.includes(tab.key) && !_.isEmpty(tab.children)}
                  isSelected={selectedTab.key === tab.key}
                  disabled={disabled}
                  soonTag={tab.soonTag}
                  account={account}
                />
              );
            })}
        </div>
        {showSelector && (
          <BngSelectSearch
            options={accountOpts}
            form={{ setFieldValue: (name, accountId) => onSelectAccount(accountId) }}
            field={{
              value: account?.id,
              onChange: _.noop,
            }}
            previewComponent={({ toggleDropdown }) => {
              return (
                <UiBlocker block={accountsLoading} className={styles.accountsSpinner}>
                  <div
                    className={`AccountSelect ${styles.AccountSelect}`}
                    onClick={onlyOneAccount ? _.noop : toggleDropdown}
                  >
                    <div>{t('selected.account')}</div>
                    <div className={`${styles.accountName}`}>
                      {account.name}
                      {!onlyOneAccount && <Icon icon={'expand_more'} />}
                    </div>
                  </div>
                </UiBlocker>
              );
            }}
          />
        )}
      </div>
      <div className={`${styles.tabContent}`}>
        <CurrentTabComponent account={account} onSelectTab={onSelectTab} {...tabProps} />
      </div>
    </>
  );
}

function AccountsPageMultiStepInfo({ onFinish }) {
  const { t } = useTranslation();
  const handleNextClick = async ({ isLastStep }) => {
    if (isLastStep) {
      onFinish();
    }
  };

  return (
    <BngMultiStep
      className={`AccountsPageMultiStepInfo ${styles.AccountsPageMultiStepInfo}`}
      prepend={null}
      overlay={true}
      onNextClick={handleNextClick}
    >
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_1} alt={t('accounts.ada.tutorial.step1.title')} />
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step1.title')}
            <BetaTag />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>{t('accounts.ada.tutorial.step1.description')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_2} alt={t('accounts.ada.tutorial.step2.title')} />{' '}
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step2.title')}
            <Icon icon={'monetization_on'} />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>{t('accounts.ada.tutorial.step2.description')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_3} alt={t('accounts.ada.tutorial.step3.title')} />{' '}
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step3.title')}
            <Icon icon={'people'} />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>{t('accounts.ada.tutorial.step3.description')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_4} alt={t('accounts.ada.tutorial.step4.title')} />{' '}
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step4.title')}
            <Icon icon={'settings'} />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>
          {t('accounts.ada.tutorial.step4.description')}
          <a
            className={styles.tutorialLink}
            href={t('accounts.ada.tutorial.step4.link')}
            rel="noreferrer"
            target="_blank"
          >
            {t('accounts.ada.tutorial.step4.link.text')}
          </a>
        </BngMultiStep.Subtitle>
      </BngMultiStep.Step>
    </BngMultiStep>
  );
}

export default function AccountsPage() {
  const context = useBimContext();
  const { t } = useTranslation();
  const { queryParams } = useQueryParams({ listenToEvents: true });

  const navigate = useBimNavigate();
  const [loading, setLoading] = useState(false);
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [adaVisible, setAdaVisible] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  const currentAccountId =
    (searchParams.has(ACC_QUERY_PARAM) ? _.parseInt(searchParams.get(ACC_QUERY_PARAM)) : null) ?? context.accountId;

  const { data: account, reload: refreshAccount } = useFetchData(async () => {
    if (!currentAccountId) {
      return null;
    }

    return await Api.Account.findAccount(currentAccountId);
  }, [currentAccountId]);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const accounts = await Api.Account.findAvailable();

      if (accounts.length === 0) {
        navigate('/errors/403');
        return [];
      }

      const accountToSelect = accounts.find((account) => account.id === currentAccountId) ?? accounts[0];
      if (accountToSelect.id !== currentAccountId) {
        selectAccount(accountToSelect.id);
      }

      const accountOpts = accounts.map((account) => {
        return {
          value: account.id,
          label: account.name,
          isMaster: account.accountMasterId === context.user.id,
        };
      });

      accountOpts.sort((a, b) => {
        const fa = a.label.toLowerCase();
        const fb = b.label.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setAvailableAccounts(accountOpts);
    } catch (e) {
      console.error('Error on fetchAccounts()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const selectAccount = async (accountId) => {
    searchParams.set(ACC_QUERY_PARAM, accountId);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <div className={`AccountsPage ${styles.AccountsPage}`}>
      <UiBlocker block={loading} className={styles.accountsBlocker}>
        {!!account && (
          <PageLayout
            pageTitle={t('account.management')}
            titleComponent={() => {
              return <BetaTag />;
            }}
            headerButtons={() => (
              <React.Fragment>
                {context.user.accountManager && (
                  <EditObject
                    title={t('Billing')}
                    checked={account.activeForBilling}
                    onChange={async () => {
                      try {
                        await Api.Account.toggleAccountEnabledInBilling(currentAccountId);
                        await refreshAccount();
                      } catch (e) {
                        console.error('Error on toggling account enabled for Billing', e);
                        if (e.response?.status === 403) {
                          UiMsg.error(t('forbidden.action'));
                        } else if (e.response?.status === 404) {
                          UiMsg.error(t('error.fetching.data', t('account')));
                        } else {
                          UiMsg.error(t('save_error', t('account')), e);
                        }
                      }
                    }}
                  />
                )}
                <BngIconButton
                  icon={'manage_accounts'}
                  title={t('manage.users')}
                  onClick={() =>
                    navigate(
                      Api.buildUrl('/spr/bng/accounts', {
                        currentTab: 'users',
                        breadcrumb: true,
                        currentAccount: currentAccountId,
                      }),
                    )
                  }
                />
              </React.Fragment>
            )}
          >
            <div className={`${styles.navPanelWrapper}`}>
              <NavPanel
                account={account}
                accountOpts={availableAccounts}
                onSelectAccount={selectAccount}
                queryParams={queryParams}
                tabs={TAB_GROUPS}
                defaultTab={TAB_GROUPS.filter((tab) => !(tab.shouldDisable?.({ account, context }) ?? false))[0]}
                tabProps={{
                  isMasterOfCurrentAccount: availableAccounts.find((acc) => acc.value === currentAccountId)?.isMaster,
                  fetchAccounts,
                  availableAccounts,
                }}
              />
            </div>
          </PageLayout>
        )}
      </UiBlocker>
      <BngAda
        className={styles.AccountsPageAda}
        title={t('accounts.page.ada.title')}
        showInfo={!showTutorial}
        options={[
          {
            icon: 'help',
            label: t('access.tutorial'),
            onClick: () => {
              setShowTutorial(true);
            },
          },
          {
            icon: 'arrow_right_alt',
            label: t('BigTableAnalysisHelp.adaLinks.label1'),
            onClick: () => window.open(t('accounts.ada.tutorial.step4.link'), '_blank'),
          },
        ]}
        buttonPressed={adaVisible}
        onClick={() => {
          setAdaVisible(!adaVisible);
        }}
      ></BngAda>
      {showTutorial && (
        <AccountsPageMultiStepInfo
          onFinish={() => {
            setShowTutorial(false);
            setAdaVisible(false);
          }}
        />
      )}
    </div>
  );
}
