import './MobileOrientationSwitch.css';
import React from 'react';
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import Icon from "components/ui/common/Icon";
import OpConfirmation from "components/ui/OpConfirmation";


const MobileOrientationItem = ({
                                   orientation,
                                   breakpointView,
                                   changeOrientation,
                                   icon
                               }) => {
    return (
        <div className={`MobileOrientation-${orientation} ${breakpointView === orientation ? 'active' : ''} `}
             onClick={() => changeOrientation(orientation)}>
            <Icon icon={icon}/>
        </div>
    )
};

const MobileOrientationSwitch = ({
                                     context = {},
                                     breakpointView = 'DESKTOP',
                                     horizontalLikeVertical = false,
                                 }) => {

    const changeOrientation = async (breakpoint) => {
        const executeChange = async () => {
            await Api.Dash.changeBreakpointView({breakpoint});
            await Api.updateJsf();
        };

        if (horizontalLikeVertical) {
            OpConfirmation({
                title: '',
                html:
                `<div>
                          <div style="margin-bottom: 10px">${context.msg.t('mobile.orientation.switch.message')}</div>
                          <div style="margin-bottom: 10px">${context.msg.t('mobile.orientation.switch.confirmation')}</div>
                          <div style="margin-bottom: 10px; font-size: 13px;">
                            ${context.msg.t('mobile.orientation.switch.obs')}
                            <a target="_blank" href=${context.msg.t('mobile.orientation.switch.link')} rel="noreferrer">${context.msg.t('know.more')}</a>
                          </div>
                </div>`,
                msg: context.msg,
                level: 'warning',
                onConfirm: () => {
                    executeChange();
                },
            });
        } else {
            await executeChange();
        }
    };

    return (
        <div className="MobileOrientationSwitch">
            <span className="MobileOrientationLabel">{context.msg.t('orientation')}</span>
            <div className="MobileOrientationSwitchButtons">
                <MobileOrientationItem icon="smartphone"
                                       changeOrientation={changeOrientation}
                                       breakpointView={breakpointView}
                                       orientation="MOBILE" />
                <MobileOrientationItem icon="tablet"
                                       changeOrientation={changeOrientation}
                                       breakpointView={breakpointView}
                                       orientation="MOBILE_HORIZONTAL" />
            </div>
        </div>
    )
};

export default ContextEnhancer(MobileOrientationSwitch);