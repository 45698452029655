import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import OpConfirmation from "components/ui/OpConfirmation";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';

class DashboardItemMenuRemove extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    remove = async () => {
        try {
            await this.props.context.execute(`#{dashboardBean.removeItem('${this.props.item.id}')}`);
        } catch (e) {
            UiMsg.ajaxError('error', e);
        }
    };

    confirm = () => {
        OpConfirmation({
            title: this.props.context.msg.t('dashBoard_delete_object'),
            message: this.props.context.msg.t('confirm.delete.dashboard.item'),
            onConfirm: this.remove,
            msg: this.props.context.msg,
            level: 'warning'
        });
    };

    render() {
        return (
            <li onClick={this.confirm}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('dashboard_remove_item')}
                                        icon="clear"
                                        more={false}/>
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuRemove);