import './DashboardItemMenu.css';

import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import { BngDropdown, BngDropdownSeparator } from 'components/bng/ui/BngDropdown';
import Utils from 'components/Utils';
import Api from 'components/Api';

import DashboardItemMenuTextAlign from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTextAlign';
import DashboardItemMenuConfig from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuConfig';
import DashboardItemMenuCloneObject from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuCloneObject';
import DashboardItemMenuResize from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuResize';
import DashboardItemMenuRemove from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuRemove';
import DashboardItemMenuShowMDX from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuShowMDX';
import DashboardItemMenuEditLabel from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuEditLabel';
import DashboardItemMenuEditIcon from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuEditIcon';
import DashboardItemMenuEditImage from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuEditImage';
import DashboardItemMenuEditText from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuEditText';
import DashboardItemMenuLastDatasourceUpdate from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuLastDatasourceUpdate';
import DashboardItemMenuMoveElement from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuMoveElement';
import DashboardItemMenuHighlight from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuHighlight';
import DashboardItemMenuKPIMonitor from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuKPIMonitor';
import DashboardItemMenuComments from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuComments';
import DashboardItemMenuObjectInformation from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuObjectInformation';
import DashboardItemMobileView from 'components/ui/dashboard/components/itemMenus/DashboardItemMobileView';
import DashboardItemMenuCustomFilter from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuCustomFilter';
import DashboardItemMenuBackgroundTransparency from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuBackgroundTransparency';
import DashboardItemMenuLinkedItems from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuLinkedItems';
import DashboardItemMenuAccessObject from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuAccessObject';
import DashboardItemMenuShare from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuShare';
import DashboardItemMenuClearItem from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuClearItem';
import DashboardItemMenuAnalysisViewChange from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuAnalysisViewChange';
import UiMsg from 'components/ui/UiMsg';
import { BngIconButton } from 'components/bng/ui/BngIconButton';

class DashboardItemMenu extends React.Component {
  static propTypes = {
    editMode: PropTypes.bool,
    item: PropTypes.any,
    inContainer: PropTypes.bool,
    dashboardPath: PropTypes.string,
  };

  static defaultProps = {
    item: {},
    editMode: false,
    inContainer: false,
    dashboardPath: '',
  };

  state = {
    popperRef: null,
    open: false,
    drawLeft: false,
    drawTop: false,
    links: [],
    cachedLinks: false,
  };

  constructor(props) {
    super(props);
    this._refs = {};
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setItemLinks = (links) => {
    this.setState({ links, cachedLinks: true });
  };

  getBoundaryElement = () => {
    return j('#page-content, #cockpit-item-panel')[0];
  };

  onOpenDropdown = async (event) => {
    /*dirty fix popper overflow*/

    if (!this.state.cachedLinks) {
      await this.getLinks();
    }

    const height = j('.bng-dropdown.container-dash-item-newmenu').height();
    j('.dashboard-item-popper').css({ height: height });

    const el = event.target.getBoundingClientRect();
    const parentPrevent = this.getBoundaryElement().getBoundingClientRect();
    const childrenSize = 440;
    this.setState({
      drawLeft: el.x + el.width + childrenSize > parentPrevent.x + parentPrevent.width,
      drawTop: el.y + el.height + childrenSize + 100 > parentPrevent.y + parentPrevent.height,
      open: true,
    });
  };

  updateState = () => {
    this.setState({ open: false });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextState, this.state) || !_.isEqual(this.props, nextProps);
  }

  closeOthersMenus = (event, refId) => {
    Object.keys(this._refs).forEach((ref) => {
      if (ref !== refId) {
        let component = this._refs[ref]?.wrappedComponent;
        !!component && !!component.forceClose && component.forceClose();
      }
    });
  };

  renderMenuItems = ({ closeDropdown }) => {
    const availablesMenus = _.filter(this.menuItems(), (menuItem) => !menuItem.shouldNotRender({ ...this.props }));

    return availablesMenus.map((menuItem) => {
      const { Component, key, additionalProps } = menuItem;
      return (
        <Component
          ref={(el) => {
            this._refs[key] = el;
          }}
          key={key}
          onOpen={(e) => this.closeOthersMenus(e, key)}
          closeDropdown={closeDropdown}
          {...additionalProps}
          {...this.props}
        />
      );
    });
  };

  render() {
    const { item, editMode } = this.props;
    if (item.isText && !editMode) {
      return null;
    }

    const isAnalysis = Utils.Object.isAnalysis(item.path);
    const showDrillButtons = isAnalysis && (item.additionalProps?.isDrillableChartModel ?? false);
    const showEchartsButtons = isAnalysis && (item.additionalProps?.isEchartsModel ?? false);

    return (
      <div className={`dash-item-newmenu`} data-item-id={item.id}>
        <div className="drop-button dash-item-newmenu-drop-button">
          <BngDropdown
            boundariesElement={this.getBoundaryElement()}
            popperOpts={{ placement: 'bottom-start' }}
            closeOnSelect={false}
            onOpen={this.onOpenDropdown}
            onClose={this.updateState}
            className={`DashboardItemMenuDropdown ${this.state.open ? 'active' : ''} ${
              showDrillButtons ? 'withDrillButtons' : ''
            }`}
            popperClassName={`dashboard-item-popper`}
            customButton={({ openDropdown }) => {
              return (
                <div className={`MenuButtonsContainer ${this.props.item.highlight ? 'highlightedContainer' : ''}`}>
                  {showDrillButtons && <div className="DrillButtonsContainer" />}
                  {showEchartsButtons && <div className="EChartsButtonsContainer" />}
                  <BngIconButton
                    className="DashboardObjectOptsButton"
                    iconProps={{ className: 'DashboardItemOptsButton' }}
                    icon="more_horiz"
                    onClick={openDropdown}
                  />
                </div>
              );
            }}
            customOptions={({ closeDropdown }) => (
              <ul
                className={`bng-dropdown container-dash-item-newmenu ${
                  editMode ? 'container-dash-item-newmenu-edit' : ''
                } ${this.state.drawLeft ? 'prevent-children-overflow' : ''} ${
                  this.state.drawTop ? 'prevent-children-overflow-top' : ''
                }`}
              >
                {this.renderMenuItems({ closeDropdown })}
              </ul>
            )}
          />
        </div>
      </div>
    );
  }

  async getLinks() {
    if (this.state.cachedLinks) return;

    try {
      const links = await Api.Dash.getItemLinks(this.props.item.id, this.props.dashboardPath);
      if (this._mounted) {
        this.setItemLinks(links);
      }
    } catch (e) {
      console.error(e);
      UiMsg.error('Error', e);
    }
  }

  shouldNotRenderAlign = (item) => {
    switch (item.additionalProps.model) {
      case 'SQUARE':
      case 'PROGRESS_BAR':
      case 'SIMPLE_VALUE':
      case 'ONLY_VALUE':
        return true;
      default:
        return false;
    }
  };

  menuItems = () => {
    let linkedObjectsRef = [];
    this.state.links.forEach(function (link) {
      linkedObjectsRef.push({
        key: 'DashboardItemMenuLinkedItem_' + link.caption,
        Component: DashboardItemMenuLinkedItems,
        additionalProps: {
          link: link,
        },
        shouldNotRender: ({ editMode, item }) =>
          editMode || item.viewType === 'label' || item.viewType === 'icon' || link.path === item.path,
      });
    });

    return [
      {
        key: 'BngDropdownSeparator-ObjectActions',
        Component: BngDropdownSeparator,
        additionalProps: {
          title: this.props.context.msg.t('object.actions'),
        },
        shouldNotRender: ({ item }) => !Utils.Object.isObjectPath(item.path),
      },
      {
        key: 'DashboardItemMenuAccessObject',
        Component: DashboardItemMenuAccessObject,
        shouldNotRender: ({ item }) => !Utils.Object.isObjectPath(item.path),
      },
      {
        key: 'DashboardItemMenuAnalysisViewChange',
        Component: DashboardItemMenuAnalysisViewChange,
        shouldNotRender: ({ item, editMode }) =>
          editMode ||
          !Utils.Object.isAnalysis(item.path) ||
          !item.additionalProps.isShowTable ||
          !item.additionalProps.isShowChart,
      },
      {
        key: 'DashboardItemMenuShare',
        Component: DashboardItemMenuShare,
        shouldNotRender: ({ editMode }) => editMode,
      },
      {
        key: 'DashboardItemMenuKPIMonitor',
        Component: DashboardItemMenuKPIMonitor,
        shouldNotRender: ({ editMode, item }) => editMode || !Utils.Object.isKpi(item.path),
      },
      {
        key: 'DashboardItemMenuLastDatasourceUpdate',
        Component: DashboardItemMenuLastDatasourceUpdate,
        shouldNotRender: ({ editMode, item }) => editMode || item.isText || item.viewType === 'orgmap',
      },
      {
        key: 'DashboardItemMenuComments',
        Component: DashboardItemMenuComments,
        shouldNotRender: ({ editMode, item }) => editMode || item.isText,
      },
      {
        key: 'BngDropdownSeparator-ObjectVisuals',
        Component: BngDropdownSeparator,
        additionalProps: {
          title: this.props.context.msg.t('object.visuals'),
        },
        shouldNotRender: ({ editMode, item, inContainer }) => !editMode || inContainer,
      },
      {
        key: 'DashboardItemMenuHighlight',
        Component: DashboardItemMenuHighlight,
        shouldNotRender: ({ editMode, item, inContainer, style }) => !editMode || inContainer || !style.allowTheme,
      },
      {
        key: 'DashboardItemMenuMoveElement',
        Component: DashboardItemMenuMoveElement,
        shouldNotRender: ({ editMode, item, inContainer }) => !editMode || inContainer,
      },
      {
        key: 'DashboardItemMenuResize',
        Component: DashboardItemMenuResize,
        shouldNotRender: ({ editMode, item, inContainer }) => !editMode || inContainer,
      },
      {
        key: 'BngDropdownSeparator-ObjectConfig',
        Component: BngDropdownSeparator,
        additionalProps: {
          title: this.props.context.msg.t('object.config'),
        },
        shouldNotRender: ({ editMode }) => !editMode,
      },
      {
        key: 'DashboardItemMenuEditLabel',
        Component: DashboardItemMenuEditLabel,
        shouldNotRender: ({ editMode, item }) => !editMode || item.viewType !== 'label',
      },
      {
        key: 'DashboardItemMenuEditIcon',
        Component: DashboardItemMenuEditIcon,
        shouldNotRender: ({ editMode, item }) => !editMode || item.viewType !== 'icon',
      },
      {
        key: 'DashboardItemMenuEditImage',
        Component: DashboardItemMenuEditImage,
        shouldNotRender: ({ editMode, item }) => !editMode || item.viewType !== 'imageContent',
      },
      {
        key: 'DashboardItemMenuBackgroundTransparency',
        Component: DashboardItemMenuBackgroundTransparency,
        shouldNotRender: ({ editMode, item }) => !editMode || item.viewType !== 'imageContent',
      },
      {
        key: 'DashboardItemMenuEditText',
        Component: DashboardItemMenuEditText,
        shouldNotRender: ({ editMode, item }) => !editMode || (item.viewType !== 'text' && item.viewType !== 'textNew'),
      },
      {
        key: 'DashboardItemMenuTextAlign',
        Component: DashboardItemMenuTextAlign,
        shouldNotRender: ({ editMode, item }) =>
          !editMode || !Utils.Object.isKpi(item.path) || this.shouldNotRenderAlign(item),
      },
      {
        key: 'DashboardItemMenuConfig',
        Component: DashboardItemMenuConfig,
        shouldNotRender: ({ editMode, item }) =>
          !editMode ||
          item.viewType === 'text' ||
          item.viewType === 'textNew' ||
          item.viewType === 'icon' ||
          item.viewType === 'label',
      },
      {
        key: 'DashboardItemMenuCloneObject',
        Component: DashboardItemMenuCloneObject,
        shouldNotRender: ({ editMode }) => !editMode,
      },
      {
        key: 'DashboardItemMenuRemove',
        Component: DashboardItemMenuRemove,
        shouldNotRender: ({ editMode }) => !editMode,
      },
      {
        key: 'DashboardItemMenuShowMDX',
        Component: DashboardItemMenuShowMDX,
        shouldNotRender: ({ editMode, item }) =>
          !editMode ||
          item.isText ||
          Utils.Object.isOrgmap(item.path) ||
          !this.props.context.permissions.isAtLeastExpert(),
      },
      {
        key: 'DashboardItemMenuCustomFilter',
        Component: DashboardItemMenuCustomFilter,
        shouldNotRender: ({ editMode, item }) => !editMode || item.isText,
      },
      {
        key: 'DashboardItemMenuObjectInformation',
        Component: DashboardItemMenuObjectInformation,
        shouldNotRender: ({ item }) =>
          item.isText || item.viewType === 'label' || item.viewType === 'icon' || item.viewType === 'imageContent',
      },
      {
        key: 'DashboardItemMenuMobileView',
        Component: DashboardItemMobileView,
        shouldNotRender: ({ editMode, currentBreakpoint, inContainer }) =>
          !editMode || currentBreakpoint.viewBreakpoint === 'DESKTOP' || inContainer,
      },
      {
        key: 'BngDropdownSeparator-LinkedItems',
        Component: BngDropdownSeparator,
        additionalProps: {
          title: this.props.context.msg.t('linked.items'),
        },
        shouldNotRender: ({ editMode, item }) =>
          editMode ||
          item.viewType === 'label' ||
          item.viewType === 'icon' ||
          (this.state.links.length === 1 && this.state.links[0].path === item.path) ||
          this.state.links.length === 0,
      },
      ...linkedObjectsRef,
      {
        key: 'DashboardItemMenuClearItem',
        Component: DashboardItemMenuClearItem,
        additionalProps: {
          cachedLinks: this.state.cachedLinks,
          dashboardPath: this.props.dashboardPath,
        },
        shouldNotRender: ({ editMode, item }) => item.path === item.originalPath || editMode,
      },
    ];
  };
}

export default ContextEnhancer(DashboardItemMenu);
