import './LinksBox.css';
import React from "react";
import PropTypes from "prop-types";
import Api from "../../Api";

const baseUrl = Api.baseUrl();

const Link = ({path, icon, caption}) => (
    <div className="cpt-item-view-more">
        <a href={Api.loadObjectUrl({content: path, breadcrumb: true})} title={caption}>
            <div className="cpt-item-view-more-div">
                <div className="cpt-item-view-more-icon">
                    <span className={`icon ${icon}`}></span>
                </div>
                <div className="cpt-item-view-more-name">
                    {caption}
                </div>
            </div>
        </a>
    </div>
);

Link.propTypes = {
    path: PropTypes.string,
    icon: PropTypes.string,
    caption: PropTypes.string
};

const LinksBox = ({order, description, links}) => {
    return (
            <div className="orgmap-menu dropdown-menu open">
                <div>
                    <div className="view-more-painels">
                        <div className="view-more-painels-title">
                            {description}
                        </div>
                        <div className="view-more-painels-desc text-center">
                            {description}
                        </div>
                        <div className="scrollbar-inner links-container">
                            {links.map(link => <Link key={link.id} {...link}/>)}
                        </div>
                    </div>
                </div>
            </div>
    );
};

LinksBox.propTypes = {
    order: PropTypes.number,
    description: PropTypes.string,
    links: PropTypes.array,
};

export default LinksBox;
