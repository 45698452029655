import styles from './CockpitFormDialog.module.css';

import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import Icon from 'components/ui/common/Icon';
import { BngPanelHeader } from 'components/bng/ui/BngPanel';
import { BngField } from 'components/bng/form/BngField';
import { BngInput } from 'components/bng/form/BngInput';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngForm } from 'components/bng/form/BngForm';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import Api from 'components/Api';
import { MODALS } from 'components/ui/redux/Actions';
import SelectIconDialog from 'components/ui/common/SelectIconDialog';
import UiMsg from 'components/ui/UiMsg';
import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';
import { BngTable } from 'components/bng/ui/BngTable';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngSelect } from 'components/bng/form/BngSelect';
import Utils from 'components/Utils';
import HtmlItemDialog from 'components/bng/pages/cockpit/management/HtmlItemDialog';

const CockpitSettingsSchema = bngYup((yup) =>
  yup.object().shape({
    id: yup.number().integer().nullable(),
    owner: yup.string().trim().nullable(),
    itemIdx: yup.number().integer().nullable(),
    name: yup.string().trim().required().default(''),
    availableOnMobile: yup.boolean().default(true),
  })
);

const TYPES = {
  PATH_AWARE_ITEM: 'PATH_AWARE_ITEM',
  HTML_ITEM: 'HTML_ITEM',
  COVER_PAGE_ITEM: 'COVER_PAGE_ITEM',
  INDEX_PAGE_ITEM: 'INDEX_PAGE_ITEM',
};

const COVER_PAGE = {
  name: 'Capa',
  icon: 'description',
  color: '#7FB005',
  order: 1,
  type: TYPES.COVER_PAGE_ITEM,
  other: {
    content: '',
    viewType: '',
  },
};

const INDEX_PAGE = {
  name: 'Índice',
  icon: 'icon-list-ul',
  color: '#4078D3',
  order: 2,
  type: TYPES.INDEX_PAGE_ITEM,
  other: {
    content: '',
  },
};

export default function CockpitFormDialog({
  closeModal = _.noop,
  cockpitId,
  fetchData = _.noop,
  setSelectedRow = _.noop,
}) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState('icon-globe');
  const [persistentPanels, setPersistentPanels] = useState([_.cloneDeep(INDEX_PAGE)]);
  const [editRowName, setEditRowName] = useState(null);
  const [selectedRow, setSettingsSelectedRow] = useState();

  const $formikRef = useRef();

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const cockpit = cockpitId ? (await Api.Cockpit.findCockpitsList(project.name, cockpitId))[0] : null;
        const initialValues = {
          ...CockpitSettingsSchema.default(),
          id: cockpit?.id,
          owner: cockpit?.owner,
          itemIdx: cockpit?.itemIdx,
          name: cockpit?.name ?? '',
          availableOnMobile: cockpit ? cockpit.availableOnMobile : project.availableOnMobile,
        };

        $formikRef.current.resetForm({
          values: initialValues,
        });

        if (cockpit) {
          setIcon(cockpit.icon || 'icon-globe');

          const panels = cockpit.panels || [];
          for (const panel of panels) {
            const index = panels.indexOf(panel);
            if (panel.type === TYPES.PATH_AWARE_ITEM) {
              const path = panel.path ?? panel.other.content;
              const viewType = panel.viewType ?? panel.other.viewType;
              const other = {
                content: path,
                viewType: viewType,
              };
              if (Utils.Object.isAnalysis(path)) {
                const { chart, table } = await Api.Analysis.getViewType(path);
                other.isShowTable = table;
                other.isShowChart = chart;
              }
              panel.other = other;
              delete panel.path;
              delete panel.viewType;
            } else if (panel.type === TYPES.HTML_ITEM || panel.type === TYPES.COVER_PAGE_ITEM) {
              panel.other = {
                content: panel.content,
                viewType: panel.viewType,
                enableIframe: panel.enableIframe,
              };
              delete panel.path;
              delete panel.viewType;
            } else if (panel.order < 3) {
              panel.other = {
                content: panel.content,
              };
              delete panel.content;
            }
            if (panel.type !== TYPES.INDEX_PAGE_ITEM || panel.type !== TYPES.COVER_PAGE_ITEM) {
              panel.order = index + 1;
            }
          }
          setPersistentPanels(panels);
        }
        setLoading(false);
      } catch (e) {
        console.error(`Error on CockpitFormDialog while fetching cockpit=[${cockpitId}]`, e);
        UiMsg.ajaxError(null, e);
        closeModal();
      }
    })();
  }, []);

  const coverPage = persistentPanels.some((p) => p.type === TYPES.COVER_PAGE_ITEM);
  const indexPage = persistentPanels.some((p) => p.type === TYPES.INDEX_PAGE_ITEM);

  const saveCockpit = async (values) => {
    if (editRowName) {
      UiMsg.warn(msg.t('finish.editing.cockpit.object'));
      return;
    }

    setLoading(true);
    try {
      Object.entries(values).forEach(([k, v]) => {
        if (_.isString(v)) {
          values[k] = v.trim();
        }
      });

      const cockpitToSave = {
        ...values,
        enabled: true,
        generateCoverPage: coverPage,
        generateIndexPage: indexPage,
        icon: icon,
        color: 'red',
        cockpitConfig: undefined,
        accessConfig: undefined,
        persistentPanels: persistentPanels,
        availableOnMobile: values.availableOnMobile,
        enablePainelColors: false,
        cockpitId: cockpitId,
        projectName: project.name
      };

      await Api.Cockpit.saveCockpit(cockpitToSave);

      setSelectedRow(undefined);
      fetchData();
      UiMsg.ok(msg.t('cockpit.save.success'));
      closeModal();
    } catch (e) {
      console.error('Error on saveCockpit()', e);
      const errorsMsg = e.response?.status === 409 ? 'cockpit.name.already.in.use.message' : 'cockpit.save.error';
      setLoading(false);
      UiMsg.ajaxError(msg.t(errorsMsg), e);
    }
  };

  const selectIcon = () => {
    dispatch(
      MODALS.open(SelectIconDialog, {
        icon: { name: icon },
        onSelect: (event) => setIcon(event.name),
      })
    );
  };

  const reorder = (startIndex, endIndex) => {
    let result = persistentPanels;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result = result.map((item, index) => {
      item.order = index + 1;
      return item;
    });
    setPersistentPanels(result);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    if (fromIdx === toIdx) return;
    let tempItem = persistentPanels[fromIdx];
    if (tempItem.type === 'COVER_PAGE_ITEM' || tempItem.type === 'INDEX_PAGE_ITEM') return;
    tempItem = persistentPanels[toIdx];
    if (tempItem.type === 'COVER_PAGE_ITEM' || tempItem.type === 'INDEX_PAGE_ITEM') return;

    reorder(fromIdx, toIdx);
  };

  const openSelectObjectDialog = (item) => {
    dispatch(
      MODALS.open(SelectObjectDialogContainer, {
        onSelectPath: (pathObject, viewType) => {
          viewType = (viewType ? viewType : 'CHART').toUpperCase();
          if (item?.other?.isShowTable === false && viewType === 'TABLE') {
            viewType = 'CHART';
          }

          if (item?.other?.isShowChart === false && viewType === 'CHART') {
            viewType = 'TABLE';
          }

          let data = {
            icon: pathObject.icon[0],
            name: pathObject.title[0],
            order: item.order,
            type: TYPES.PATH_AWARE_ITEM,
            other: {
              content: pathObject.itemPath[0],
              viewType: viewType ? viewType : 'CHART',
              isShowTable: item?.other?.isShowTable,
              isShowChart: item?.other?.isShowChart,
            },
          };
          let temp = _.cloneDeep(persistentPanels);
          temp = temp.map((t) => {
            if (t.order === data.order) return data;
            return t;
          });
          setPersistentPanels(temp);
        },
        projectId: project.id,
      })
    );
  };

  const addObjectsDropOptions = [
    {
      icon: 'description',
      label: msg.t('generate.cover.page'),
      onClick: () => {
        if (coverPage) return;
        const panelsCopy = persistentPanels.slice();
        panelsCopy.unshift(_.cloneDeep(COVER_PAGE));
        panelsCopy.forEach((p, idx) => (p.order = idx + 1));
        setPersistentPanels(panelsCopy);
      },
      visible: !coverPage,
    },
    {
      icon: 'list',
      label: msg.t('generate.index.page'),
      onClick: () => {
        if (indexPage) return;

        const panelsCopy = persistentPanels.slice();
        const newIndexPage = _.cloneDeep(INDEX_PAGE);
        panelsCopy.splice(coverPage ? 1 : 0, 0, newIndexPage);
        panelsCopy.forEach((p, idx) => (p.order = idx + 1));
        setPersistentPanels(panelsCopy);
      },
      visible: !indexPage,
    },
    {
      icon: 'dashboard',
      label: msg.t('add.objects'),
      onClick: () => {
        return dispatch(
          MODALS.open(SelectObjectDialogContainer, {
            onSelectPath: async (pathObject, viewType) => {
              let temp = persistentPanels;
              for (let i = 0; i < pathObject.icon.length; i++) {
                const other = {
                  content: pathObject.itemPath[i],
                  viewType: viewType ? viewType : 'chart',
                  enableIframe: !!pathObject.enableIframe ?? pathObject.enableIframe[i],
                };
                if (Utils.Object.isAnalysis(pathObject.itemPath[i])) {
                  const { chart, table } = await Api.Analysis.getViewType(pathObject.itemPath[i]);
                  other.isShowChart = chart;
                  other.isShowTable = table;
                }
                let data = {
                  icon: pathObject.icon[i],
                  name: pathObject.title[i],
                  order: temp && temp.length > 0 ? temp[temp.length - 1].order + 1 : 1,
                  type: TYPES.PATH_AWARE_ITEM,
                  other,
                };
                temp.push(data);
              }
              setPersistentPanels(_.orderBy(temp, ['order'], ['asc']));
            },
            typeField: 'checkbox',
            projectId: project.id,
          })
        );
      },
    },
    {
      icon: 'code',
      label: msg.t('add.content.page'),
      onClick: async () => {
        try {
          const titleName = msg.t('HTML_COMPONENT');
          const lastPanelOrder = persistentPanels?.[persistentPanels.length - 1]?.order || 0;

          const itemData = {
            icon: 'content_copy',
            name: titleName,
            order: lastPanelOrder + 1,
            type: TYPES.HTML_ITEM,
            other: {
              content: '',
              viewType: 'chart',
            },
          };

          dispatch(
            MODALS.open(HtmlItemDialog, {
              itemData: itemData, // Pass the itemData object to the dialog
              onSave: (itemData) => {
                let htmlItem = {
                  id: itemData.id,
                  icon: itemData.icon,
                  name: itemData.name,
                  order: itemData.order,
                  type: TYPES.HTML_ITEM,
                  other: {
                    content: itemData.content,
                    viewType: 'chart',
                    enableIframe: itemData.enableIframe,
                  },
                };
                const temp = persistentPanels.slice();
                temp.push(htmlItem);
                setPersistentPanels(_.orderBy(temp, ['order'], ['asc']));
              },
            })
          );
        } catch (e) {
          console.error('Error on addObjectsDropOptions()', e);
          UiMsg.ajaxError(null, e);
        }
      },
    },
  ];

  const renameCockpitObject = (idx) => {
    if (editRowName.name?.trim().length >= 1) {
      const copy = _.cloneDeep(persistentPanels);
      copy[idx].name = editRowName.name;
      setEditRowName(null);
      setPersistentPanels(copy);
    } else {
      UiMsg.warn(msg.t('cockpit.object.rename'));
    }
  };

  const changeViewType = (idx, viewType) => {
    const copy = _.cloneDeep(persistentPanels);
    copy[idx].other.viewType = viewType;
    setPersistentPanels(copy);
  };

  return (
    <Formik
      initialValues={CockpitSettingsSchema.default()}
      validationSchema={CockpitSettingsSchema}
      onSubmit={saveCockpit}
      innerRef={$formikRef}
    >
      <Dialog
        className={`${styles.CockpitSettings} CockpitSettings`}
        backdropClassName={`${styles.modalBackdrop}`}
        onClose={() => {
          setSelectedRow(undefined);
          closeModal();
        }}
        titleComponent={() => {
          return (
            <BngPanelHeader className="flex-center-items ">
              <div
                style={{
                  fontSize: '24px',
                  marginTop: '25px',
                }}
              >
                {cockpitId ? msg.t('cockpit.configutration') : msg.t('new.cockpit')}
              </div>
            </BngPanelHeader>
          );
        }}
        loading={loading}
      >
        <BngForm>
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '772px' }}>
              <div style={{ display: 'flex' }}>
                <div className={`${styles.selectIcon}`}>
                  <label className={`${styles.iconLabel}`}>{msg.t('folder_icon')}</label>
                  <div
                    className={`${styles.selectIconButton}`}
                    onClick={() => {
                      selectIcon();
                    }}
                  >
                    <Icon className={`${styles.iconButton}`} icon={icon} />
                  </div>
                </div>
                <Field
                  name="name"
                  label={msg.t('name')}
                  component={BngField}
                  inputComponent={BngInput}
                  required
                  className={`${styles.cockpitName}`}
                  rootClassName={`${styles.cockpitNameLabel}`}
                />
              </div>
              <Field
                name="availableOnMobile"
                label={''}
                component={BngField}
                inputComponent={BngCheckbox}
                inline={true}
                asProps={{
                  label: msg.t('cockpit.management.settings.available.mobile'),
                  className: `${styles.checkBoxAlign} ${styles.checkBoxLabel}`,
                }}
              />
            </div>
            <BngDropdown
              icon={'add_circle'}
              title={msg.t('add.objects')}
              options={addObjectsDropOptions}
              className={`${styles.addObjectsButton}`}
              popperClassName={`${styles.objectOptionsOverlay} `}
              customButton={(props) => {
                return (
                  <button
                    type={'button'}
                    className={`BngIconButton ${styles.dropDownButton}`}
                    onClick={(event) => {
                      setEditRowName(null);
                      setEditRowName();
                      props.openDropdown(event);
                    }}
                  >
                    <Icon icon={'add_cicle'} className={`${styles.dropDownIconButton}`} />
                    <div className={`${styles.dropDownLabelButton}`}>{msg.t('add.objects')}</div>
                  </button>
                );
              }}
            />
            <div className={`${styles.tableList}`}>
              <BngTable
                rows={persistentPanels}
                stickyHeader
                onDropHandler={onDragEnd}
                selectedRow={{
                  selectedRowId: editRowName?.rowIdx || selectedRow,
                  rowClassName: styles.selectedRow,
                }}
                cols={[
                  {
                    colClassName: styles.iconCol,
                    render: (row, idx, rowProps, dragProps) => {
                      return (
                        <div className={`${styles.draggableIcon}`} {...(editRowName ? {} : dragProps.dragHandleProps)}>
                          {!(row.type === TYPES.COVER_PAGE_ITEM || row.type === TYPES.INDEX_PAGE_ITEM) && (
                            <Icon icon="drag_indicator" />
                          )}
                        </div>
                      );
                    },
                    rowClassName: styles.iconRow,
                  },
                  {
                    colSortable: !editRowName,
                    label: msg.t('name'),
                    render: (row, idx) => {
                      const isEditingName = editRowName?.rowIdx === idx;
                      return (
                        <div className={`${styles.nameWrapper}`}>
                          <div
                            className={`${styles.iconWrapper}`}
                            onClick={() => {
                              dispatch(
                                MODALS.open(SelectIconDialog, {
                                  icon: { name: row.icon },
                                  onSelect: (event) => {
                                    let temp = _.cloneDeep(persistentPanels);
                                    temp.map((t) => {
                                      if (t.order === row.order) t.icon = event.name;
                                    });
                                    setPersistentPanels(temp);
                                  },
                                })
                              );
                            }}
                          >
                            <Icon className={styles.listIcons} icon={row.icon} />
                          </div>
                          <NameRender
                            isEditing={isEditingName}
                            value={isEditingName ? editRowName.name : row.name}
                            onSave={() => renameCockpitObject(idx)}
                            onChange={(event) => {
                              setEditRowName({
                                ...editRowName,
                                name: event.target.value ?? '',
                              });
                            }}
                          />
                        </div>
                      );
                    },
                    rowClassName: styles.nameRow,
                  },
                  {
                    render: (row, idx) => {
                      const isEditingName = editRowName?.rowIdx === idx;
                      const isAnalysis = Utils.Object.isAnalysis(row?.other?.content ?? '');
                      const { isShowChart, isShowTable } = row?.other || {};
                      const analysisViewOptions =
                        isAnalysis && (isShowChart !== undefined || isShowTable !== undefined);

                      return (
                        <div className={`${styles.wrapperActionsButtons}`}>
                          {analysisViewOptions && (
                            <BngSelect
                              emptyOption={false}
                              style={{ width: 85, marginBottom: 0 }}
                              options={[
                                { value: 'CHART', label: msg.t('CHART'), disabled: !isShowChart },
                                { value: 'TABLE', label: msg.t('TABLE'), disabled: !isShowTable },
                              ]}
                              value={row?.other?.viewType || 'CHART'}
                              onChange={(event) => changeViewType(idx, event.target.value)}
                            />
                          )}
                          {row.type !== TYPES.INDEX_PAGE_ITEM && (
                            <ButtonEditName
                              isEditing={isEditingName}
                              onEdit={() => {
                                setEditRowName({
                                  rowIdx: idx,
                                  name: row.name,
                                });
                              }}
                              onSave={() => renameCockpitObject(idx)}
                            />
                          )}
                          {isEditingName ? (
                            <BngIconButton
                              icon="close"
                              onClick={() => {
                                setEditRowName(null);
                              }}
                            />
                          ) : (
                            <BngDropdown
                              className={`${styles.options}`}
                              options={[
                                [
                                  {
                                    icon: 'edit',
                                    label: msg.t('edit_button'),
                                    onClick: (e, { closeDropdown }) => {
                                      closeDropdown();
                                      if (
                                        row.type === TYPES.INDEX_PAGE_ITEM ||
                                        row.type === TYPES.HTML_ITEM ||
                                        row.type === TYPES.COVER_PAGE_ITEM
                                      ) {
                                        dispatch(
                                          MODALS.open(HtmlItemDialog, {
                                            itemData: {
                                              ...row,
                                              content: row.other.content,
                                            },
                                            onSave: (itemData) => {
                                              const htmlItem = {
                                                id: row.id,
                                                icon: itemData.icon,
                                                name: itemData.name,
                                                order: itemData.order,
                                                type:
                                                  row.type === TYPES.INDEX_PAGE_ITEM
                                                    ? TYPES.INDEX_PAGE_ITEM
                                                    : row.type === TYPES.HTML_ITEM
                                                    ? TYPES.HTML_ITEM
                                                    : TYPES.COVER_PAGE_ITEM,
                                                other: {
                                                  content: itemData.content,
                                                  viewType: 'chart',
                                                  enableIframe: itemData.enableIframe,
                                                },
                                              };
                                              const temp = persistentPanels.slice();
                                              const idx = temp.findIndex((i) => i.order === htmlItem.order);
                                              temp[idx] = htmlItem;
                                              setPersistentPanels(temp);
                                            },
                                          })
                                        );
                                      } else {
                                        openSelectObjectDialog(row);
                                      }
                                    },
                                  },

                                  {
                                    icon: 'image',
                                    label: msg.t('change.icon'),
                                    onClick: (e, { closeDropdown }) => {
                                      closeDropdown();
                                      dispatch(
                                        MODALS.open(SelectIconDialog, {
                                          icon: { name: row.icon },
                                          onSelect: (event) => {
                                            let temp = _.cloneDeep(persistentPanels);
                                            temp.map((t) => {
                                              if (t.order === row.order) t.icon = event.name;
                                            });
                                            setPersistentPanels(temp);
                                          },
                                        })
                                      );
                                    },
                                  },
                                ],
                                [
                                  {
                                    icon: 'delete_outline',
                                    label: msg.t('delete_button'),
                                    onClick: (e, { closeDropdown }) => {
                                      closeDropdown();
                                      const full = _.cloneDeep(persistentPanels);
                                      _.remove(full, (o) => {
                                        return o.order === row.order;
                                      });
                                      setPersistentPanels(full);
                                    },
                                  },
                                ],
                              ]}
                              popperClassName={`${styles.objectOptionsOverlay} `}
                              closeOnSelect={false}
                              onOpen={() => {
                                setEditRowName(null);
                                setSettingsSelectedRow(idx);
                              }}
                              onClose={() => setSettingsSelectedRow(undefined)}
                            />
                          )}
                        </div>
                      );
                    },
                    rowClassName: styles.rowActions,
                  },
                ]}
              />
            </div>
            <div className={`${styles.actionsButtons}`}>
              <DefaultDialogActions
                closeModal={() => {
                  setSelectedRow(undefined);
                  dispatch(MODALS.close());
                }}
                context={{ msg: msg }}
              />
            </div>
          </>
        </BngForm>
      </Dialog>
    </Formik>
  );
}

const ButtonEditName = ({ isEditing, onEdit, onSave }) => {
  const { msg } = useBimContext();
  if (isEditing) {
    return <BngIconButton icon="done" onClick={onSave} />;
  }
  return (
    <BngIconButton icon="drive_file_rename_outline" title={msg.t('cockpit.object.rename.button')} onClick={onEdit} />
  );
};

const NameRender = ({ isEditing, value, onChange, onSave }) => {
  if (isEditing) {
    return (
      <input
        type="text"
        maxLength={75}
        className={styles.objectNameEditing}
        value={value}
        onKeyPress={(e) => {
          if (e.which === 13) {
            e.preventDefault();
            onSave();
          }
        }}
        onChange={onChange}
        ref={(ref) => ref?.focus()}
      />
    );
  }
  return (
    <div
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '400px',
      }}
    >
      {value}
    </div>
  );
};
