import styles from './BimUniversityPage.module.css';
import React, { useEffect, useState } from 'react';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';

export default function BimUniversityPage() {
  const context = useBimContext();

  const [loginInfo, setLoginInfo] = useState();

  const fetchLoginInfo = async () => {
    try {
      const { link } = await Api.University.generateAccess(context.project.id);
      setLoginInfo(link);
    } catch (e) {
      console.error('Error on fetchLoginInfo()', e);
    }
  };

  useEffect(() => {
    fetchLoginInfo();
  }, []);

  return (
    <div className="BimUniversityPage">
      <iframe className={`${styles.universityFrame}`} src={loginInfo} allowFullScreen></iframe>
      <UiBlocker block={true}>
        <div className={`${styles.loadingFrame}`}></div>
      </UiBlocker>
    </div>
  );
}
