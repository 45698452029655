import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import {MODALS} from "components/ui/redux/Actions";
import DashboardIconForm from "components/ui/dashboard/components/DashboardIconForm";

class DashboardItemMenuEditIcon extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    editIcon = async () => {
        try{
            const iconItem = await Api.Dash.findIconProps(this.props.item.id);
            window.ReduxStore.dispatch(MODALS.open(DashboardIconForm, {iconItem} ));
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        }
    };

    render() {
        return (
            <li onClick={this.editIcon}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('edit.content')}
                                        icon="fa-pencil-square"
                                        more={false}
                />
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuEditIcon);