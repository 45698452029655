import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";
import FilterAccordion from "components/ui/dashboard/FilterAccordion";


const FiltersMenuItem = ({
                             context,
                             toggleAccordion,
                             ...props
                         }) => {

    const openFilterConfigDialog = () => {
        toggleAccordion(FilterAccordion, 'FiltersMenuItem');
    };

    return (
        <RightMenuItem {...props}
                       title={context.msg.t('configure.filters')}
                       className="FiltersMenuItem"
                       icon="icon-bim-filter"
                       onClick={openFilterConfigDialog}
        />
    );
};

export default ContextEnhancer(FiltersMenuItem);