import './AddToDashboardDialog.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Field, Form, Formik } from 'formik';

import Api from 'components/Api';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import Dialog from 'components/ui/Dialog';
import UiMsg from 'components/ui/UiMsg';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngField } from 'components/bng/form/BngField';
import BngRadio from 'components/bng/form/BngRadio';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import BngTreeDropdown from 'components/bng/form/BngTreeDropdown';

const AddToDashboardSchema = bngYup((yup) => {
  return yup.object().shape({
    dashboardPath: yup.string().required().default(''),
  });
});

const checkContainsDash = (obj) => {
  if (obj.leaf && Utils.Object.isDashboard(obj.href)) {
    return true;
  } else {
    return obj.children?.some((child) => checkContainsDash(child));
  }
};

const disableNodesInPaths = (obj, pathsToCompare = []) => {
  if (obj.leaf) {
    if (pathsToCompare.includes(obj.href)) {
      return {
        ...obj,
        disabled: true,
        title: 'object.already.in.dashboard',
      };
    }
  } else {
    obj.children = obj.children.map((child) => disableNodesInPaths(child, pathsToCompare));
  }

  return obj;
};

const AddToDashboardDialog = (props) => {
  const context = useBimContext();

  const [availableDashboards, setAvailableDashboards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChart, setIsChart] = useState(props.beanActiveTab === 0);

  const fetchAvailableDashboards = async () => {
    setLoading(true);
    try {
      let opts = props.folders;
      const dashsUsingPath = await Api.Dash.findDashsUsingPath(props.path);
      if (dashsUsingPath.length > 0) {
        opts = opts.map((opt) => disableNodesInPaths(opt, dashsUsingPath));
      }
      setAvailableDashboards(opts);
    } catch (e) {
      console.error('Error on fetchAvailableDashboards()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.folders !== availableDashboards || _.isEmpty(availableDashboards)) {
      fetchAvailableDashboards();
    }
  }, [props.folders]);

  const save = async (values, actions) => {
    await inLoading(async () => {
      try {
        await Api.Dash.addItem({
          ...values,
          path: props.path,
          isChart,
        });
        UiMsg.ok(context.msg.t('successfully.added.object'));
        props.closeModal();
      } catch (e) {
        actions.setSubmitting(false);
        UiMsg.ajaxError('Error', e);
      }
    });
  };

  const inLoading = async (fn) => {
    try {
      setLoading(true);
      await fn();
    } finally {
      setLoading(false);
    }
  };

  const isChartValue = isChart ? 'chart' : 'table';
  return (
    <Dialog
      open={props.open}
      className="AddToDashboardDialog"
      title={context.msg.t('add.object.to.dashboard')}
      onClose={props.closeModal}
      loading={loading}
    >
      <BlockUi tag="div">
        <Formik initialValues={AddToDashboardSchema.default()} validationSchema={AddToDashboardSchema} onSubmit={save}>
          <Form>
            <div className="row-fluid field">
              <div className="span12">
                <Field
                  component={BngField}
                  inputComponent={BngTreeDropdown}
                  name="dashboardPath"
                  label={`${context.msg.t('dashboard')}:`}
                  dataTree={availableDashboards}
                  singleSelect={true}
                  objectFilterFunction={checkContainsDash}
                  updateJsfOnSelect={false}
                />
              </div>
            </div>
            <div style={{ display: 'inline-flex' }}>
              {Utils.Object.isAnalysis(props.path) && props.showChart && props.showTable && (
                <div className="AddToDashRadioButtons flex-center-items mb-2">
                  <label>{context.msg.t('visualization')}:</label>
                  <BngRadio
                    name="chart"
                    label={context.msg.t('configureDashboard_selectMenu_chart')}
                    field={{ value: isChartValue }}
                    value={'chart'}
                    form={{ setFieldValue: () => setIsChart(true) }}
                  />
                  <BngRadio
                    name="chart"
                    label={context.msg.t('tab_data')}
                    field={{ value: isChartValue }}
                    value={'table'}
                    form={{ setFieldValue: () => setIsChart(false) }}
                  />
                </div>
              )}
            </div>

            <DefaultDialogActions {...props} />
          </Form>
        </Formik>
      </BlockUi>
    </Dialog>
  );
};

AddToDashboardDialog.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  folder: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export const AddToDashboardDialogContainer = connect((state) => {
  return {
    folders: state.menu.roots,
  };
})(AddToDashboardDialog);
