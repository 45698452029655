// css imported on CommonCssImports.js
import React, { useState } from 'react';
import Popper from 'components/bng/ui/BngPopper';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';

export function BngDropdown({
  className = '',
  popperClassName = '',
  popperStyle = {},
  icon = 'more_vert',
  container = document.body,
  closeOnSelect = true,
  options = [],
  onOpen = _.noop,
  onClose = _.noop,
  customButton = null,
  customOptions = null,
  boundariesElement = null,
  popperOpts = {},
  popperFlipBehaviour = 'flip',
  title = '',
  overDialog = false,
  disabled = false,
  btnIconProps = {},
  useContainerForPopper = true,
  keepOpen = false,
}) {
  const [popperRef, setPopperRef] = useState(null);

  if (!boundariesElement && useContainerForPopper) {
    boundariesElement = container;
  }

  const openDropdown = async (event) => {
    if (!!event && !!event.persist) event.persist();
    const target = event.currentTarget;
    await setPopperRef(target);
    await onOpen(event);
  };

  const closeDropdown = (event) => {
    setPopperRef(null);
    onClose(event);
  };

  const overDialogClass = overDialog ? 'BngDropdown-OVERDIALOG' : '';
  const popperIsOpen = !_.isEmpty(popperRef);
  const hideStyle = _.isNil(popperRef) && keepOpen ? { display: 'none' } : {};

  return (
    <div className={`BngDropdown ${className}`} title={title}>
      {!customButton && <BngIconButton icon={icon} onClick={openDropdown} disabled={disabled} {...btnIconProps} />}
      {customButton && customButton({ openDropdown, closeDropdown })}

      <Popper
        className={`bng-dropdown-parent ${popperClassName} ${overDialogClass}`}
        container={useContainerForPopper ? container : null}
        style={{ ...popperStyle, ...hideStyle }}
        open={keepOpen || popperIsOpen}
        anchorEl={popperRef}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              boundariesElement: boundariesElement,
            },
            flips: {
              behavior: popperFlipBehaviour,
            },
          },
        }}
        {...popperOpts}
      >
        <BngClickOutsideOverlay
          onClick={closeDropdown}
          container={container}
          className={`${popperClassName ? `${popperClassName}Overlay` : ''} ${
            overDialogClass ? `${overDialogClass}Overlay` : ''
          }`}
          style={hideStyle}
        />

        {customOptions && customOptions({ closeDropdown, isOpen: popperIsOpen })}

        {!customOptions && (
          <ul className="bng-dropdown unstyled">
            {options.map((opt, idx) => {
              if (Array.isArray(opt)) {
                if (opt.length === 1) {
                  opt = opt[0];
                } else {
                  return (
                    <OptionGroup
                      key={opt.key || idx}
                      group={opt}
                      closeDropdown={closeDropdown}
                      closeOnSelect={opt.closeOnSelect ?? closeOnSelect}
                    />
                  );
                }
              }

              if (opt.isSeparator) {
                return <BngDropdownSeparator className={opt.className} key={opt.key || idx} title={opt.label} />;
              } else {
                return (
                  <Option
                    key={opt.key || idx}
                    opt={opt}
                    closeDropdown={closeDropdown}
                    closeOnSelect={opt.closeOnSelect ?? closeOnSelect}
                  />
                );
              }
            })}
          </ul>
        )}
      </Popper>
    </div>
  );
}

const Option = ({ opt, closeDropdown, closeOnSelect, className = '' }) => {
  if (opt.hasOwnProperty('visible') && !opt.visible) return null;

  return (
    <li
      onClick={
        opt.disabled
          ? undefined
          : (event) => {
              const fn = opt.onClick || _.noop;
              fn(event, { closeDropdown });
              if (closeOnSelect) {
                closeDropdown();
              }
            }
      }
      title={opt.title}
      className={`${opt.className || ''} ${className} ${opt.disabled ? 'disabled' : ''}`}
    >
      {opt.hasOwnProperty('render') && opt.render({ closeDropdown })}
      {!opt.hasOwnProperty('render') && (
        <React.Fragment>
          <Icon icon={opt.icon} />
          <span>{opt.label}</span>
        </React.Fragment>
      )}
    </li>
  );
};

const OptionGroup = ({ group, closeDropdown, closeOnSelect }) => {
  return group.map((opt, idx) => {
    const isFirst = idx === 0;
    const isLast = !isFirst && group.length - 1 === idx;
    return (
      <Option
        key={opt.key || idx}
        opt={opt}
        closeDropdown={closeDropdown}
        closeOnSelect={opt.closeOnSelect ?? closeOnSelect}
        className={`GroupOption ${isFirst ? 'GroupFirst' : ''} ${isLast ? 'GroupLast' : ''} ${
          !isLast ? 'box-shadow-none' : ''
        }`}
      />
    );
  });
};

export const BngDropdownSeparator = ({ title, className = '' }) => {
  return (
    <li className={`BngDropdownSeparator ${className}`}>
      <span>{title}</span>
      <hr style={{ width: '100%' }} />
    </li>
  );
};

export default BngDropdown;
