import styles from './AccountsPage.module.css';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';

const TAB_QUERY_PARAM = 'currentTab';

function findTab(key, tabArray) {
  for (const tab of tabArray) {
    if (tab.key === key) {
      return tab;
    }

    if (tab.children) {
      const childTab = findTab(key, tab.children);
      if (childTab) {
        return childTab;
      }
    }
  }
  return null;
}

function TabNavItem({ tab, onClick = _.noop, onExpandTab = _.noop, expanded = false, isSelected }) {
  const { t } = useTranslation();

  const containChildren = !_.isEmpty(tab.children);
  return (
    <>
      <div
        className={`TabNavItem ${styles.tabItem}  ${isSelected ? styles.selectedTab : ''} ${
          tab.enabled ? '' : 'disabled'
        }`}
        onClick={() => onClick(tab.key, null)}
      >
        <div className={`${styles.tabTitle}`}>
          <Icon className={`${styles.tabIcon}`} icon={tab.icon} />
          <span>{t(tab.label)}</span>
        </div>
        {tab.component ?? (
          <Icon className={`${styles.tabIcon}`} icon="new_releases" style={{ color: '#F98900', marginRight: '15px' }} />
        )}
        {containChildren && (
          <BngIconButton
            icon={expanded ? 'expand_less' : 'expand_more'}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onExpandTab(tab.key);
            }}
          />
        )}
      </div>
      {containChildren &&
        tab.children.map((tabChildren, idx) => {
          const lastChild = idx + 1 === tab.children.length;
          return (
            <div
              key={idx}
              className={`${styles.tabChildren} ${expanded ? '' : styles.notVisible} ${
                lastChild ? styles.lastChild : ''
              }`}
              onClick={() => onClick(tabChildren.key ?? tab.key, tabChildren.anchorId, !isSelected)}
            >
              <span>{t(tabChildren.title)}</span>
            </div>
          );
        })}
    </>
  );
}

export default function NavPanel({
  account,
  accountOpts,
  onSelectAccount,
  tabs = [],
  tabProps = {},
  showSelector = true,
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [expandedTabs, setExpandedTabs] = useState([]);

  useEffect(() => {
    const queryTab = searchParams.get(TAB_QUERY_PARAM) ?? tabs[0].key;
    onSelectTab(queryTab, '', false);
  }, [searchParams]);

  useEffect(() => {
    if (selectedTab.anchorId) {
      document.getElementById(selectedTab.anchorId)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedTab]);

  const onSelectTab = (tabKey, anchorId, shouldUpdateQuery = true) => {
    const tab = findTab(tabKey, tabs) || tabs[0];

    const defaultAction = () => {
      setSelectedTab({ ...tab, anchorId: anchorId });

      if (shouldUpdateQuery) {
        searchParams.set(TAB_QUERY_PARAM, tab.key);
        setSearchParams(searchParams);
      }
    };

    if (tab.onSelect) {
      tab.onSelect({ defaultAction });
    } else if (tab.enabled) {
      defaultAction();
    }
  };

  const onExpandTab = (tabKey) => {
    if (expandedTabs.includes(tabKey)) {
      expandedTabs.splice(expandedTabs.indexOf(tabKey), 1);
    } else {
      expandedTabs.push(tabKey);
    }
    setExpandedTabs([...expandedTabs]);
  };

  const onlyOneAccount = showSelector ? accountOpts.length === 1 : 0;

  return (
    <>
      <div className={`NavPanel ${styles.NavPanel}`}>
        <div className={`${styles.accountTabs}`}>
          {tabs
            .filter((tab) => !tab.hide)
            .map((tab, idx) => (
              <TabNavItem
                key={idx}
                tab={tab}
                onClick={onSelectTab}
                onExpandTab={onExpandTab}
                expanded={expandedTabs.includes(tab.key) && !_.isEmpty(tab.children)}
                isSelected={selectedTab.key === tab.key}
              />
            ))}
        </div>
        {showSelector && (
          <BngSelectSearch
            options={accountOpts}
            form={{ setFieldValue: (name, accountId) => onSelectAccount(accountId) }}
            field={{
              value: account?.id,
              onChange: _.noop,
            }}
            previewComponent={({ toggleDropdown }) => {
              return (
                <div
                  className={`AccountSelect ${styles.AccountSelect}`}
                  onClick={onlyOneAccount ? _.noop : toggleDropdown}
                >
                  <div>{t('selected.account')}</div>
                  <div className={`${styles.accountName}`}>
                    {account?.name}
                    {!onlyOneAccount && <Icon icon={'expand_more'} />}
                  </div>
                </div>
              );
            }}
          />
        )}
      </div>
      <div className={`${styles.tabContent}`}>
        <selectedTab.component account={account} onSelectTab={onSelectTab} {...tabProps} />
      </div>
    </>
  );
}
