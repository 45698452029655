import React, { useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';

import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import bimEventBus from 'BimEventBus';
import { CHART_CLICK_EVENT_NAME } from 'components/bng/analysis/BngAnalysisDrillDownBar';
import KpiValueFormatter from 'components/ui/kpi/KpiValueFormatter';

const tooltipContent = ({
  measureCaption,
  showDesc,
  showValue,
  showPercent,
  name,
  value,
  context,
  label = false,
  currencyFormat,
  percentage,
}) => {
  let descOutput = '';
  if (!label) {
    descOutput = `<div class='row-name'>${name}</div>`;
    if (!label) {
      descOutput += `<hr/>`;
    }
  } else if (showDesc) {
    descOutput += `${name}\n`;
  }
  if (!label) {
    let measures = `<i class='icon-eye-open'></i>
      ${measureCaption}: <b class='value'>${currencyFormat} ${value}</b></br>`;
    if (percentage) {
      measures += `<i class='icon-eye-open'></i> ${context.msg.t(
        'percent'
      )} ${measureCaption}: <b class='value'>${percentage}</b>`;
    }
    descOutput += `
        <div class='measure'>
        ${measures}
        </div>`;
  } else if (showValue || showPercent) {
    let measures = '';
    measures += showValue && value ? `${currencyFormat} ${value}\n` : '';
    measures += showPercent && percentage ? `${percentage}\n` : '';
    descOutput += `${measures}`;
  }
  return descOutput;
};

const summarizeValue = (array) => {
  return array.map((item) => {
    const processedData = { ...item };
    if (typeof processedData.value === 'number') {
      processedData.value = parseFloat(processedData.value.toFixed(2));
    }
    if (typeof processedData.average === 'number') {
      processedData.average = parseFloat(processedData.average.toFixed(2));
    }
    if (typeof processedData.sum === 'number') {
      processedData.sum = parseFloat(processedData.sum.toFixed(2));
    }
    if (Array.isArray(processedData.children)) {
      processedData.children = summarizeValue(processedData.children);
    }
    return processedData;
  });
};

const findCurrencyFormat = (cell) => {
  try {
    return cell?.caption?.match(/[^\d.,]+/)?.[0]?.trim() ?? '';
  } catch (e) {
    console.error('Error on findCurrencyFormat()', { cell }, e);
  }
};

export default function TreeMap({
  className = '',
  queryResult,
  analysisData,
  toolbarContainer,
  backgroundTheme,
  disableNavigation = false,
  colorPalette,
  itemId,
  drillState,
  assistedData,
}) {
  const context = useBimContext();
  const formatLocale = context.project?.language ?? __USER_LANG ?? 'pt-BR';
  const chartContainerRef = useRef(null);

  const isPublisher = useMemo(() => application.utils.isAccessingPublicPublisher(), []);

  const $colorPalette = useFetchData(async () => {
    if (!_.isEmpty(colorPalette)) {
      return colorPalette;
    }

    if (!context.project) {
      return [];
    }
    const theme = await Api.Project.findTheme(context.project.id);
    return theme.colorPalette.map((c) => c.color);
  });

  const { echartsConfig, showLabel, keepFormatting, summarizeValues, assistedAnalysisData } = analysisData;
  const isOnLastLevel =
    (drillState?.drills?.length ?? 0) >= Math.max(assistedAnalysisData.params.dimensions.length - 1, 0);
  const labelShowValue = echartsConfig.label.showValue;
  const labelShowDesc = echartsConfig.label.showDesc;
  const labelShowPercent = echartsConfig.label.showPercent;
  const labelFontSize = echartsConfig.label.fontSize ?? 10;
  const labelFontStyle = echartsConfig.label.fontStyle ?? 1;
  const currencyFormat = keepFormatting ? findCurrencyFormat(queryResult.result.data.cells[0]) : '';

  const isDrillOnLastLevel =
    (drillState?.drills?.length ?? 0) === (assistedData?.assistedData?.params?.dimensions?.length ?? 0);
  const { measureCaption = '', rootDimensionCaption = '', processedData = [] } = useMemo(() => {
    if (!queryResult) {
      return {};
    }

    const { cells, rows, columns } = queryResult.result.data;

    let cumulativeCellIndex = 0;

    const mapDataToTreeMapFormat = (node, level) => {
      if (!node) return null;

      if (node.children.length === 0) {
        const value = cells[cumulativeCellIndex]?.value || 0;
        cumulativeCellIndex++;
        return {
          name: node.caption,
          value: value,
          sum: value,
          count: 1,
          node,
          percentage: 0,
        };
      } else {
        const children = node.children
          .map((child) => mapDataToTreeMapFormat(child, level + 1))
          .filter(Boolean)
          .filter((n) => n.value > 0);
        const value = children.reduce((acc, child) => acc + child.value, 0);
        let sum = 0;
        let count = 0;

        for (const child of children) {
          sum += child.sum;
          count += child.count;
          child.percentage = (child.value / value) * 100;
        }

        const average = count > 0 ? sum / count : 0;

        return {
          name: node.caption,
          value: value,
          children: children,
          average: average,
          sum: sum,
          count: count,
          node,
          percentage: 0,
        };
      }
    };

    let processedData = rows.nodes
      .map((node) => {
        const temp = mapDataToTreeMapFormat(node, 0);
        if (isPublisher) {
          temp.children = [];
        }
        if (isDrillOnLastLevel && !_.isEmpty(drillState?.drills)) {
          const lastDrill = drillState.drills[drillState.drills.length - 1];
          if (lastDrill?.member !== node.id) {
            temp.itemStyle = { colorAlpha: 0.5 };
          }
        }
        return temp;
      })
      .filter((node) => node.value > 0);

    let total = 0;
    for (const child of processedData) {
      total += child.value;
    }

    for (const child of processedData) {
      child.percentage = (child.sum / total) * 100;
    }

    return {
      measureCaption: columns?.nodes?.[0]?.caption ?? '',
      rootDimensionCaption: rows?.depthInfo?.hierarchies?.[0]?.caption ?? '',
      processedData: processedData ?? [],
    };
  }, [queryResult, summarizeValues]);

  useEffect(() => {
    if (
      !chartContainerRef.current ||
      chartContainerRef.current.clientWidth <= 0 ||
      chartContainerRef.current.clientHeight <= 0
    ) {
      return;
    }

    const chart = echarts.init(chartContainerRef.current);
    const chartOpts = {
      series: [
        {
          breadcrumb: {
            show: false,
          },
          name: rootDimensionCaption,
          type: 'treemap',
          drillDownIcon: '▶',
          roam: false, // roam is disabled
          nodeClick: !disableNavigation,
          silent: disableNavigation,
          // roam: true, // dragging (move and zoom)
          // roam: 'zoom', // zoom only
          // roam: 'move', // move (translation) only
          // roam: true, // both zoom and move (translation) are available

          colorMappingBy: 'index',
          data: processedData,
          levels: [
            {
              itemStyle: {
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 4,
                gapWidth: 4,
              },
            },
          ],
          label: {
            show: true,
            fontSize: labelFontSize,
            fontWeight: labelFontStyle % 2 === 0 ? 'normal' : 'bold',
            fontStyle: labelFontStyle > 1 ? 'italic' : 'normal',
            padding: 0,
            offset: [0, labelFontSize / (labelFontSize > 23 && labelShowDesc && labelShowValue ? 1 : 2)],
            formatter: function (param) {
              const { name, value, percentage } = param.data;
              const cellCurrencyFormat = findCurrencyFormat(queryResult.result.data.cells[0]);
              const output = tooltipContent({
                measureCaption,
                showDesc: labelShowDesc,
                showValue: labelShowValue,
                showPercent: labelShowPercent,
                name,
                value: KpiValueFormatter.formatValue({
                  context,
                  locale: formatLocale,
                  value,
                  summarizeValues,
                  percent: false,
                  useDecimals: !summarizeValues,
                  decimalCases: 2,
                  indicatorPercentage: false,
                  absoluteValue: Math.abs(value),
                  currencyFormat: cellCurrencyFormat,
                }),
                context,
                label: true,
                currencyFormat,
                percentage: KpiValueFormatter.formatValue({
                  context,
                  locale: formatLocale,
                  value: percentage / 100,
                  summarizeValues,
                  percent: true,
                  useDecimals: !summarizeValues,
                  decimalCases: 2,
                  indicatorPercentage: true,
                  absoluteValue: Math.abs(percentage),
                  currencyFormat: cellCurrencyFormat,
                }),
              });
              if (output) {
                return `${!isOnLastLevel ? '▶ ' : ''}${output}`;
              }
              return '';
            },
          },
          emphasis: {
            disabled: false,
            label: {
              show: true,
            },
          },
          leafDepth: 1,
          colorSaturation: [0.6, 0.5],
        },
      ],
      color: $colorPalette.data ?? [],
      tooltip: {
        confine: true,
        appendToBody: true,
        renderMode: 'html',
        show: showLabel,
        backgroundColor: '#393e42',
        borderColor: '#393e42',
        formatter: function (param) {
          const { name, value, percentage } = param.data;
          const cellCurrencyFormat = findCurrencyFormat(queryResult.result.data.cells[0]);
          const output = tooltipContent({
            measureCaption,
            showDesc: labelShowDesc,
            showValue: labelShowValue,
            showPercent: labelShowPercent,
            name: name,
            value: KpiValueFormatter.formatValue({
              context,
              locale: formatLocale,
              value,
              summarizeValues: false,
              percent: false,
              useDecimals: !summarizeValues,
              decimalCases: 2,
              indicatorPercentage: false,
              absoluteValue: Math.abs(value),
              currencyFormat: cellCurrencyFormat,
            }),
            context,
            label: false,
            currencyFormat,
            percentage: KpiValueFormatter.formatValue({
              context,
              locale: formatLocale,
              value: percentage / 100,
              summarizeValues: false,
              percent: true,
              useDecimals: !summarizeValues,
              decimalCases: 2,
              indicatorPercentage: true,
              absoluteValue: Math.abs(value),
              currencyFormat: cellCurrencyFormat,
            }),
          });

          if (output) {
            return `
              <div class='analysis-title' style="color: white">
                ${output}
              </div>
            `;
          }
          return '';
        },
      },
    };

    chart.setOption(chartOpts);

    if (!isOnLastLevel || (assistedAnalysisData.params.mdxFilters && backgroundTheme)) {
      chart.on('click', (event) => {
        bimEventBus.emit(CHART_CLICK_EVENT_NAME, {
          event,
          data: {
            col: [queryResult.result.data.columns.nodes[0].id],
            row: [event.data.node.id],
          },
          itemId,
        });
      });
    }

    return () => {
      echarts.dispose(chartContainerRef.current);
    };
  }, [
    analysisData,
    queryResult,
    chartContainerRef.current?.clientWidth,
    chartContainerRef.current?.clientHeight,
    $colorPalette.data,
  ]);

  return <div className={`TreeMap ${className}`} ref={chartContainerRef} />;
}
