import React, {Component} from 'react';
import {
    BreadcrumbToolbar,
    ExportToolbarItem,
    FavoriteToolbarItem,
    InformationToolbarItem,
    NotesToolbarItem,
    PublisherToolbarItem,
    ReloadToolbarItem,
    ResizeToolbarItem
} from "components/ui/breadcrumb-toolbar";
import ComponentFactory from "components/ComponentFactory";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import HideHeaderToolbarItem, {HideHeaderPageOpts} from "components/ui/breadcrumb-toolbar/HideHeaderToolbarItem";

class DashBreadcrumbToolbar extends Component {

    static defaultProps = {
        isNotDashPersisted: false,
        path: '',
        caption: '',
        mobile: true,
        containItems: true
    };

    componentDidMount() {
        this.setState({mobile: this.props.mobile});
    }

    state = {
        onAjax: false,
        mobile: true,
    };

    constructor(props) {
        super(props);
    }

    resizeDashboard = async (event) => {
        event.preventDefault();
        ComponentFactory.Dash.renderablePreloadResize();
    };

    reloadDashboard = async () => {
        window.__RENDERABLE_PRELOAD_CLEAR_CACHE?.();
        await Api.executeExp(`#{dashboardBean.reloadDashboard()}`);
        await Api.updateJsf();
    };

    render() {
        const {onAjax, mobile} = this.state;
        const {isNotDashPersisted, path, caption, containItems} = this.props;

        return (
            <BreadcrumbToolbar loading={onAjax}>
                <ExportToolbarItem disabled={isNotDashPersisted || !containItems}
                                   containItems={containItems}
                                   caption={caption}
                                   path={path}
                />

              {this.props.context.cockpitEnabled &&
                <HideHeaderToolbarItem location={HideHeaderPageOpts.BREADCRUMBS}/>
              }

                <ResizeToolbarItem onClick={this.resizeDashboard}
                                   disabled={false}/>

                <ReloadToolbarItem disabled={isNotDashPersisted}
                                   onClick={this.reloadDashboard}
                />

                <PublisherToolbarItem path={path}
                                      caption={caption}
                                      disabled={isNotDashPersisted}
                />

                <NotesToolbarItem disabled={isNotDashPersisted}
                                  path={path}
                />

                <FavoriteToolbarItem disabled={isNotDashPersisted}
                                     path={path}/>

                <InformationToolbarItem disabled={isNotDashPersisted}
                                        title={this.props.context.msg.t('object.information.dashboard')}
                                        dataSource={null}
                                        path={path}
                />
            </BreadcrumbToolbar>
        )
    }
}

export default ContextEnhancer(DashBreadcrumbToolbar);