import "./MobileMenuItem.css";
import React from 'react';
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";


const MobileMenuItem = ({
                            context = {},
                            viewType = 'DESKTOP',
                            clearAccordion = _.noop,
                        }) => {

    const isDesktop = viewType === 'DESKTOP';

    const toggleDashboardView = async () => {
        clearAccordion();
        await context.execute(`#{dashboardBean.toggleBreakpointView()}`);
        await Api.updateJsf();
    };

    return (
        <RightMenuItem title={context.msg.t(isDesktop ? 'view.mobile' : 'view.desktop')}
                       icon={isDesktop ? 'phone_android' : 'icon-desktop'}
                       className="MobileMenuItem"
                       onClick={toggleDashboardView}/>
    )
};

export default ContextEnhancer(MobileMenuItem);