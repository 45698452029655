let gmapStatus = 0;

export default () => {
    if (!gmapStatus) {
        gmapStatus = new Promise((res, rej) => {
            const queryParam = jQuery.param({
                sensor: false,
                v: 3,
                key: 'AIzaSyBn3ChLOJ6EmNIOJTvJ9Yzc-ESeplmPNnY'
            });
            jQuery.getScript(`//maps.google.com/maps/api/js?${queryParam}`, )
                .then(() => {
                        res(1);
                        console.info("Google Maps API loaded successfully...");
                    }, (e) => {
                        rej(0);
                        console.error("Error while trying to load Google Maps API...", e);
                    }
                );
        });
    }

    return gmapStatus;
};
