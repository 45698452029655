// css imported on CommonCssImports.js
import React, { Component } from 'react';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngColorPickerDropdown from 'components/bng/colorPicker/BngColorPickerDropdown';

const DEFAULT_COLORS = ['#2A2624', '#7B7B7B', '#DBDBDB', '#FFFFFF'].map((v) => ({ value: v }));

class BngInputColor extends Component {
  static propTypes = {};

  static defaultProps = {
    className: '',
    options: [],
    disabled: false,
    size: '',
    htmlButton: null,
    onClose: _.noop,
    onOpen: _.noop,
    defaultOptions: DEFAULT_COLORS,
    fetchProjectColors: true,
    addTransparentOptions: true,
    enablePicker: true,
    bannedOptions: [],
    buttonIcon: null,
  };

  state = {
    loading: false,
    themeColors: [],
  };

  async componentDidMount() {
    const { project } = this.props.context;
    if (this.props.fetchProjectColors && project) {
      try {
        this.setState({ loading: true });
        const theme = await Api.Project.findTheme(project.id);
        const themeColors = theme.colorPalette.map((c) => ({ value: c.color }));
        this.setState({ themeColors });
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  optionSelected = (opt) => {
    this.props.form.setFieldValue(this.props.field.name, opt.value);
  };

  render() {
    const {
      field,
      disabled,
      size,
      htmlButton,
      defaultOptions,
      addTransparentOptions,
      bannedOptions,
      enablePicker,
      paletteEnabled,
      onClose,
      buttonIcon,
      fetchProjectColors,
    } = this.props;
    const value = (_.get(field, 'value') || '').toUpperCase();

    const options = _.uniqBy(
      [...defaultOptions, ...this.state.themeColors, ...this.props.options].map((opt) => {
        opt.value = (opt.value || '').toUpperCase();
        return opt;
      }),
      'value'
    ).filter((opt) => {
      return bannedOptions.indexOf(opt.value) === -1;
    });

    if (addTransparentOptions) {
      options.push({ value: 'transparent' });
    }

    const matchSome = options.some((opt) => opt.value.toUpperCase() === value.toUpperCase());

    if (!_.isEmpty(value) && !matchSome) {
      options.push({ value });
    }

    return (
      <BngColorPickerDropdown
        htmlButton={htmlButton}
        buttonIcon={buttonIcon}
        customColorOptions={options.map((o) => o.value)}
        onApply={({ colors }) => {
          this.optionSelected({value: colors[0].color});
        }}
        color={{ colors: [{ color: value, opacity: 1 }], rotation: 0 }}
        paletteEnabled={paletteEnabled}
        gradientEnabled={false}
        transparencyEnabled={false}
        gradientRotationEnabled={false}
        btnContainerClass={`BngInputColor ${this.props.className} ${disabled ? 'Disabled' : ''} ${size}`}
        btnClass="ColorCircle"
        buttonSize={_.isNumber(size) ? size : SIZE_MAP[size] ?? 32}
        solidEnabled={enablePicker}
        disabled={disabled}
        onClose={onClose}
        fetchProjectColors={fetchProjectColors}
      />
    );
  }
}

const SIZE_MAP = {
  xxs: 12,
  sm: 22,
  md: 28,
  lg: 32,
};

export default ContextEnhancer(BngInputColor);
