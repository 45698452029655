import styles from './BimQuerySqlEditor.module.css';

import React, { useRef } from 'react';
import { useFormikContext } from 'formik';

import { BngCodeMirror } from 'components/bng/form/BngCodeMirror';

export default function BimQuerySqlEditor() {
  const { values, setFieldValue } = useFormikContext();
  const $timeOutRef = useRef();

  return (
    <div className={`BimQuerySqlEditor ${styles.BngCodeMirrorWrapper}`}>
      <BngCodeMirror
        field={{ value: values.sql, name: 'sql' }}
        form={{
          setFieldValue: (field, value) => {
            clearInterval($timeOutRef.current);
            $timeOutRef.current = setTimeout(() => {
              setFieldValue(field, value);
            }, 50);
            $timeOutRef.current;
          },
        }}
        mode="sql"
      />
    </div>
  );
}
