import './ProjectExtractorsDialog.css';

import React, { useEffect, useMemo, useState } from 'react';
import Api from 'components/Api';
import { BngTable } from 'components/bng/ui/BngTable';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import Dialog from 'components/ui/Dialog';
import OpConfirmation from 'components/ui/OpConfirmation';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import ProjectExtractorFormDialog, { SAVE_EVENT } from 'components/bng/pages/bimGateway/ProjectExtractorFormDialog';
import useEventBus from 'components/hooks/useEventBus';
import Utils from 'components/Utils';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import BngSearch from 'components/bng/ui/BngSearch';
import { BngDialogActionButton } from 'components/ui/Button';
import BimGatewayTutorialDialog from 'components/bng/pages/bimGateway/bimGatewayTutorial/BimGatewayTutorialDialog';

export default function ProjectExtractorsDialog({ className = '', closeModal }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const { project, msg } = context;

  const [extractors, setExtractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await Api.ProjectExtractor.findByProject(project.id);
      setExtractors(data);
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEventBus(SAVE_EVENT, fetchData, []);

  const openExtractorForm = async (id = undefined) => {
    dispatch(
      MODALS.open(ProjectExtractorFormDialog, {
        id,
      })
    );
  };

  const openConnectionDialog = async () => {
    dispatch(MODALS.open(BimGatewayTutorialDialog));
  };

  const openReconnectDialog = async (id) => {
    dispatch(
      MODALS.open(BimGatewayTutorialDialog, {
        id,
      })
    );
  };

  const removeExtractor = async (id) => {
    setLoading(true);
    try {
      await Api.ProjectExtractor.remove(id);
      UiMsg.ok(msg.t('remove_success', [msg.t('extractor')]));
      fetchData();
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const pingExtractor = async (key) => {
    try {
      const { success } = await Api.ProjectExtractor.ping(key);
      if (success) {
        UiMsg.ok(msg.t('extractor.ping.success'));
      } else {
        UiMsg.warn(msg.t('extractor.ping.warning'));
      }
    } catch (e) {
      console.error('Error on pingExtractor()', { key }, e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [project.id]);

  const filteredExtractors = useMemo(() => {
    if (_.isEmpty(searchTerm)) return extractors;

    return extractors.filter((e) => {
      return Utils.Strings.includesIgnoreCase(e.name, searchTerm);
    });
  }, [extractors, searchTerm]);

  return (
    <Dialog
      className={`ProjectExtractorsDialog large ${className}`}
      loading={loading}
      onClose={closeModal}
      title={msg.t('extractors')}
      contentFullWidth={true}
    >
      <div className={'conection-dialog-division top mt-1 mb-1'} />

      <div className="flex-center-items ml-5 mr-5 mb-1">
        <BngDialogActionButton icon="add_circle" onClick={openConnectionDialog}>
          {msg.t('connect.gateway')}
        </BngDialogActionButton>
        <div className="ml-auto flex-center-items flex-grow-1">
          <BngSearch alwaysOpen={false} className="flex-grow-1 ml-4" onChange={setSearchTerm} />
          <BngIconButton icon="refresh" onClick={fetchData} className="RefreshBtn" title={msg.t('update')} />
        </div>
      </div>

      <div className="TableContainer">
        <BngTable
          className="sm bt-1 asd"
          rows={filteredExtractors}
          cols={[
            {
              colClassName: 'pl-6',
              rowClassName: 'pl-6',
              label: msg.t('name'),
              render: (row) => {
                return row.name ?? '';
              },
            },
            {
              label: msg.t('lastCheck'),
              size: '200px',
              render: (row) => {
                const online = row.isOnline();
                return (
                  <div
                    key={`${row.id}-${online}`}
                    className={`${online ? '' : 'text-error'}`}
                    title={online ? '' : msg.t('extractor.offline.title')}
                  >
                    {Utils.Date.formatDateTime(row.lastCheckAt)}
                  </div>
                );
              },
            },
            {
              size: '65px',
              render: (row) => {
                return (
                  <BngDropdown
                    options={[
                      {
                        icon: 'network_ping',
                        label: msg.t('test.connection'),
                        onClick: () => pingExtractor(row.key),
                      },
                      {
                        icon: 'edit',
                        label: msg.t('edit'),
                        onClick: () => openExtractorForm(row.id),
                      },
                      {
                        icon: 'sensors',
                        label: msg.t('reconnect'),
                        onClick: () => openReconnectDialog(row.id),
                        disabled: row.isOnline(),
                      },
                      {
                        icon: 'remove',
                        label: msg.t('remove'),
                        onClick: () =>
                          OpConfirmation({
                            msg,
                            title: msg.t('remove_confirmation_title'),
                            message: msg.t('remove_confirmation_message'),
                            onConfirm: () => {
                              removeExtractor(row.id);
                            },
                          }),
                      },
                    ]}
                    overDialog
                  />
                );
              },
            },
          ]}
          stickyHeader
        />
      </div>
    </Dialog>
  );
}
