import React from "react";
import PropTypes from "prop-types";


export class KpiTarget extends React.Component {

    static propTypes = {
        textAlign: PropTypes.string,
        target: PropTypes.any,
        targetLabel: PropTypes.string,
        kpiId: PropTypes.number,
        div: PropTypes.any,
        kpiIsPercent: PropTypes.string,
        container: PropTypes.any,
        width: PropTypes.number,
        height: PropTypes.number,
        align: PropTypes.string,
        model: PropTypes.string,
    };

    static defaultProps = {
        textAlign: '',
        target: '',
        targetLabel: '',
        kpiIsPercent: 'false',
        align: 'center',
        model: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.resizeTarget(this.props.width, this.props.height);
    }

    componentDidUpdate(prevProps) {
        this.resizeTarget(this.props.width, this.props.height);
    }

    resizeTarget = (width, height) => {
        if (this.props.div) {
            Object.assign(this.$textTargetEl.style, {fontSize: "11px", lineHeight: "11px"});
            this.$iconTargetEl.style.fontSize = "13px";

            const offsetParent = this.props.container.offsetParent;

            if (this.props.align === 'left' && !!offsetParent && !!offsetParent.querySelector('.KpiInternalBody')) {
                const marginLeft = offsetParent.querySelector('.KpiInternalBody').offsetWidth;
                const isQplot = offsetParent.querySelector(".KpiBody").classList.contains('Qplot');
                Object.assign(this.$kpiTargetTable.style, {
                    marginLeft: marginLeft / (isQplot ? 1 : 1.5) + "px",
                });
            }

            Object.assign(this.props.container.style, {
                marginTop:  ['PERCENT', 'PIE'].some(el => this.props.model.includes(el)) ? '0px' : height * (0.04) + "px",
                minHeight: "16px",
            });

        }
    };


    render() {

        return (
            <table ref={el => this.$kpiTargetTable = el} className="KpiTarget" style={{margin: 'auto'}}>
                <tbody>
                <tr>
                    <td>
                        <div>
                            <span ref={el => this.$iconTargetEl = el} className="material-icons"
                                  style={{fontWeight: 'normal'}}>radio_button_checked</span>
                        </div>
                    </td>
                    <td style={{whiteSpace: 'nowrap'}}>
                        <div ref={el => this.$textTargetEl = el}>
                            {`${this.props.targetLabel} ${this.props.target}`}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}


