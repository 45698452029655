import styles from './LoginPage.module.css';
import defaultLoginBackground from './assets/defaultLoginBackground.jpg';

import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import DownloadAppNotification from 'components/bng/pages/login/DownloadAppNotification';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useBimContext from 'components/hooks/useBimContext';

const clearLocalStorage = (t) => {
  try {
    if (window.localStorage) {
      [
        'SESSION_DATA',
        'EventNotification.initialIsHidden',
        'NpsCensusAnswerLater:NPS_CENSUS_STORAGE',
        'CockpitButtons:HEADER_EXPANDED',
        'FeatureCensus:AnswerLater',
        'appStartupChecks:timeZoneCheck',
      ].forEach((item) => window.localStorage.removeItem(item));
    }
  } catch (e) {
    console.error('Error on clearLocalStorage()', e);
    UiMsg.error(t('LoginPage.enable.cookies.warn'));
  }
};

const initMetaPixel = () => {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1012583962141863');
  fbq('track', 'PageView');
};

function NewsFragment({ className = '', MAX_NEWS: maxNews = 4 }) {
  const { t } = useTranslation();
  const [currentNewsIdx, setCurrentNewsIdx] = useState(0);
  const [timerCounting, setTimerCounting] = useState(true);

  const { data: news = [], isLoading: loadingNews } = useFetchData(async () => {
    try {
      const [general] = await Api.News.allPublicNews({
        language: (window.__USER_LANG || 'pt-br').replace('_', '-').toLowerCase(),
        page: 0,
        pageSize: 5,
      });

      // Prefetch images
      general.slice(0, maxNews).forEach((news) => {
        const img = new Image();
        img.src = news.image_local_path;
      });

      return general;
    } catch (e) {
      console.error('Error while trying to fetch news', e);
      return [];
    }
  });

  useEffect(() => {
    if (news.length === 0 || !timerCounting) {
      return;
    }

    const timer = setInterval(() => {
      try {
        if (_.isEmpty(news)) {
          return;
        }

        const maxIdx = Math.min(news.length, maxNews);

        let nextIdx = currentNewsIdx + 1;
        if (maxIdx === nextIdx) {
          nextIdx = 0;
        }
        setCurrentNewsIdx(nextIdx);
      } catch (e) {
        console.error('Error on auto news rotation', e);
      }
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [news, currentNewsIdx, timerCounting]);

  if (loadingNews || _.isEmpty(news)) {
    return null;
  }

  const selectedNews = news[currentNewsIdx];
  const newsCount = Math.min(news.length, maxNews);

  return (
    <UiBlocker
      className={`NewsFragment ${styles.newsContainer} ${className}`}
      block={loadingNews}
      renderChildrenWhileBlocking={false}
    >
      {selectedNews && (
        <a href={selectedNews.link} target={'_blank'}>
          <div>
            <img src={selectedNews.image_local_path} alt="News Image" />
          </div>
        </a>
      )}

      <div className={`${styles.newsActionsContainer}`}>
        {selectedNews && (
          <a href={selectedNews.link} target={'_blank'}>
            <div className="mb-2">
              <p>{selectedNews.brief}</p>
              <h3 className="mt-2 text-overflow-ellipsis">{selectedNews.title}</h3>
            </div>
          </a>
        )}

        <div className={`flex-center-items jc-sb ${styles.bottomActionsContainer}`}>
          <div className={`flex-center-items gap-1 ${styles.dotsContainer}`}>
            {_.range(newsCount).map((idx) => (
              <a
                key={idx}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentNewsIdx(idx);
                  setTimerCounting(false);
                }}
                className={`${styles.dot} ${idx === currentNewsIdx ? styles.selected : ''}`}
              />
            ))}
          </div>

          <a className={`${styles.allNewsLink}`} href={Api.buildUrl('/api/bng/news')} target={'_blank'}>
            {t('see.all.news')}
          </a>
        </div>
      </div>
    </UiBlocker>
  );
}

const buildBgImageStyles = (loginBackgroundImage = '', loginImageDisplay = '') => {
  let additionalStyles = {};

  switch (loginImageDisplay) {
    case 'contain':
      additionalStyles.backgroundPosition = 'center center';
      additionalStyles.backgroundSize = 'contain';
      break;
    case 'cover':
      additionalStyles.backgroundSize = 'cover';
      break;
    case '100% 100%':
      additionalStyles.backgroundSize = loginImageDisplay;
      break;
  }

  return {
    backgroundColor: loginBackgroundImage ? '#292624' : undefined,
    backgroundImage: `url("${loginBackgroundImage ? Api.buildUrl(loginBackgroundImage) : defaultLoginBackground}")`,
    ...additionalStyles,
  };
};

export default function LoginPage() {
  const context = useBimContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showMobileAppAlert, setShowMobileAppAlert] = useState(application.page.isMobile());

  useEffect(() => {
    clearLocalStorage(t);
    initMetaPixel();

    let updateSearchParams = false;
    const cause = searchParams.get('cause');
    if (cause) {
      UiMsg.warn(t(cause));
      searchParams.delete('cause');
      updateSearchParams = true;
    }

    if (searchParams.has('afterAuthUrl')) {
      UiMsg.warn(t('afterAuthUrl.login.message'));
    }

    if (searchParams.has('check-passwd')) {
      UiMsg.ok(t('login_password_recovery_send_instructions'));
      searchParams.delete('check-passwd');
      updateSearchParams = true;
    }

    if (updateSearchParams) {
      setSearchParams(searchParams);
    }
  }, []);

  const appVersion = document.head.dataset.version ?? '';
  const isInbox = (document.head.dataset.inbox ?? 'false') === 'true';

  const {
    loginPosition = 'center',
    loginImageDisplay,
    loginBackgroundImage,
    headerLogo,
    loginTheme = '',
  } = context.theme;

  const loginDarkMode = loginTheme === 'DARK';

  useEffect(() => {
    document.body.classList[loginDarkMode ? 'add' : 'remove']('DarkMode');
  }, [loginDarkMode]);

  useEffect(() => {
    if (!isInbox) {
      const script = document.createElement('script');
      script.src = 'https://2h4nmbkz624p.statuspage.io/embed/script.js';
      script.integrity = 'sha384-Pwmd/I+atUg1HaI/kQHLk8m3bOpexxWStZtF/cquFmWIovD0wb4wXcnt9cQWFHrG';
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    }
  }, [isInbox]);

  return (
    <>
      <div
        className={`LoginPage flex-center-items ${styles.main} ${
          loginPosition === 'center' ? 'jc-center' : loginPosition === 'right' ? 'jc-end' : ''
        }`}
        style={buildBgImageStyles(loginBackgroundImage, loginImageDisplay)}
      >
        <UiBlocker block={loading}>
          <div className={`${styles.loginBox} flex-center-items jc-center`}>
            <div className={styles.loginBoxContentWrapper}>
              <div className="flex-center-items jc-center">
                <img
                  src={`${Api.buildUrl(
                    headerLogo && headerLogo !== 'resources/images/logo-bim-black-gray.png'
                      ? headerLogo
                      : `/resources/images/${loginDarkMode ? 'logo-new' : 'logo-bim-black-gray'}.png`
                  )}`}
                  alt="logo"
                  className={styles.logoImg}
                />
              </div>

              <div className={`mt-4 ${styles.loginBoxContent}`}>
                <Outlet context={{ loadingCallback: setLoading }} />
              </div>

              <NewsFragment className="mt-3" />
            </div>
          </div>
        </UiBlocker>
      </div>

      {appVersion && (
        <div
          className={`AppVersion ${styles.appVersion} ${
            loginDarkMode ? '' : styles.lightMode
          } flex-center-items jc-center`}
        >
          {t('version')} {appVersion}
        </div>
      )}

      {showMobileAppAlert && <DownloadAppNotification onClose={() => setShowMobileAppAlert(false)} />}

      {/*Meta Pixel Code*/}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1012583962141863&ev=PageView&noscript=1"
          alt="Meta Pixel"
        />
      </noscript>
    </>
  );
}
