import './ContainerCreator.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import Api from 'components/Api';
import Snackbar, { SnackbarVariant } from 'components/ui/common/Snackbar';
import ContextEnhancer from 'components/ContextEnhancer';
import Icon from 'components/ui/common/Icon';

const rectangleIntersects = function (r1, r2) {
  return !(r2.x >= r1.x + r1.w || r2.x + r2.w <= r1.x || r2.y >= r1.y + r1.h || r2.y + r2.h <= r1.y);
};

const CONTAINER_CREATOR_LISTENER_KEY = 'CONTAINER_CREATOR_LISTENER_KEY';

export const ContainerCreator = ContextEnhancer(
  class extends React.PureComponent {
    static propTypes = {
      className: PropTypes.string,
      dashGrid: PropTypes.any,
      container: PropTypes.any,
      initialData: PropTypes.any,
    };

    static defaultProps = {
      className: '',
      initialData: {},
    };

    state = {
      items: {},
      intersectItems: {},
      breakpoint: 'DESKTOP',
      loading: true,
    };

    getDashGrid = () => {
      const dashGrid = this.props.dashGrid();
      if (!dashGrid.state.customSelect) {
        dashGrid.toggleCustomSelect({
          onSelect: this.selectionHandler,
          itemClass: this.getItemClass,
        });
        dashGrid.addLayoutChangeListener(CONTAINER_CREATOR_LISTENER_KEY, this.onLayoutChange);
      }
      return dashGrid;
    };

    componentDidMount() {
      application.dashboards.adjustHeightForFixedFilter(false);
      j('.free-style-marker-class').addClass('OnContainerCreation');
      this.getDashGrid();
      if (!_.isEmpty(this.props.initialData)) {
        this.selectionHandler(Object.values(this.props.initialData.selectedItems));
      }
      this.setState({ loading: false });
    }

    componentWillUnmount() {
      application.dashboards.adjustHeightForFixedFilter(true);
      j('.free-style-marker-class').removeClass('OnContainerCreation');
      const dashGrid = this.getDashGrid();
      dashGrid.toggleCustomSelect();
      dashGrid.removeLayoutChangeListener(CONTAINER_CREATOR_LISTENER_KEY);
      dashGrid.forceUpdate();
      this.props.container.remove();
    }

    destroyOnClickOut = (event) => {
      this.restoreAndClose();
    };

    getItemClass = ({ i }) => {
      if (i in this.state.items) {
        return 'selected-by-container select-item-for-container';
      }
      if (i in this.state.intersectItems) {
        return 'selected-by-container conflict-item-for-container';
      }
      return '';
    };

    onLayoutChange = () => {
      // Update layout for selected items
      const layout = this.getDashGrid().getCurrentLayout();
      const items = this.state.items;
      Object.keys(items).forEach((i) => {
        const item = layout.find((item) => item.i === i);
        if (item) {
          this.state.items[i] = { layout: item };
        }
      });
      const intersectItems = this.updateIntersections(items);
      this.setState({ items, intersectItems }, () => {
        this.getDashGrid().forceUpdate();
      });
    };

    isContainer(props) {
      return props[0]?.data?.viewType === 'container';
    }

    selectionHandler = (props, event) => {
      props = (Array.isArray(props) ? props : [props]).filter((i) => !i.isContainer);
      if (props.length === 0) {
        return;
      }

      const items = this.state.items;
      let breakpoint = '';
      props.forEach((p) => {
        if ((!!p.layout && items.hasOwnProperty(p.layout.i)) || this.isContainer(props)) {
          delete items[p.layout.i];
        } else {
          items[p.layout.i] = p;
        }
        breakpoint = p.viewBreakpoint;
      });

      //update new intersect
      const intersectItems = this.updateIntersections(items);

      return this.setState(
        {
          items,
          intersectItems,
          breakpoint: breakpoint,
        },
        () => {
          this.getDashGrid().forceUpdate();
        }
      );
    };

    close = () => {
      ReactDOM.unmountComponentAtNode(this.props.container);
    };

    restoreAndClose = async () => {
      if (!_.isEmpty(this.props.initialData)) {
        let breakpoint = '';
        for (const p of Object.values(this.props.initialData.selectedItems)) {
          breakpoint = p.viewBreakpoint;
          break;
        }
        await this.saveContainer(breakpoint, this.props.initialData.selectedItems);
        Api.updateJsf();
      }
      this.close();
    };

    updateIntersections = (items = this.state.items) => {
      let intersects = {};
      if (Object.keys(items).length > 1) {
        const layout = this.getDashGrid().getCurrentLayout();
        const area = { x: Number.MAX_VALUE, y: Number.MAX_VALUE, w: 0, h: 0 };
        Object.values(items)
          .map((p) => p.layout)
          .forEach((l) => {
            area.x = Math.min(area.x, l.x);
            area.y = Math.min(area.y, l.y);
            area.w = Math.max(area.w, l.x + l.w);
            area.h = Math.max(area.h, l.y + l.h);
          });
        area.w = area.w - area.x;
        area.h = area.h - area.y;

        for (const l of layout) {
          if (l.i in items) {
            continue;
          }
          if (rectangleIntersects(area, l)) {
            intersects[l.i] = l;
          }
        }
      }
      return intersects;
    };

    haveSelectItems = () => {
      return Object.keys(this.state.items).length > 0;
    };

    save = async () => {
      this.setState({ loading: true });

      await this.saveContainer(this.state.breakpoint, this.state.items);

      Api.updateJsf();
      this.setState({ loading: false });
      this.close();
    };

    saveContainer = async (breakpoint, items) => {
      await Api.Dash.updateContainerItem({
        ...this.props.initialData,
        breakpoint,
        items: Object.values(items).map((i) => i.layout),
      });
    };

    render() {
      const haveSelectItems = this.haveSelectItems();
      const conflicts = Object.keys(this.state.intersectItems).length;

      return (
        <React.Fragment>
          {ReactDOM.createPortal(
            <div className="ContainerCreatorOverlay" onClick={this.destroyOnClickOut} />,
            document.body
          )}

          <Snackbar
            icon={haveSelectItems && conflicts === 0 ? 'check' : 'touch_app'}
            onClick={conflicts === 0 && haveSelectItems && !this.state.loading ? this.save : null}
            message={this.props.context.msg.t(
              conflicts === 0
                ? haveSelectItems
                  ? 'click.to.create.container'
                  : 'select.items.to.add.to.container'
                : 'object.in.conflict.with.container',
              conflicts
            )}
            variant={conflicts === 0 ? SnackbarVariant.Save : SnackbarVariant.Error}
          >
            <div className={'containerCreatorSnackbarClose'}>
              <Icon icon={'close'} className={`cancel-container-creation`} onClick={this.restoreAndClose} title={this.props.context.msg.t('cancel')}/>
              {conflicts > 0 && (
                <a
                  href={this.props.context.msg.t('container.conflict.support.link')}
                  target="_blank"
                  className="material-icons ConflictHelp notranslate"
                  title={this.props.context.msg.t('container.conflict.hint')}
                >
                  help_outline
                </a>
              )}
            </div>
          </Snackbar>
        </React.Fragment>
      );
    }
  }
);
