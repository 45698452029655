import { Axios, buildUrlWithAuth } from 'commonUtils';
import Utils from 'components/Utils';
import FilterService from 'components/filter/FilterService';
import Api from 'components/Api';

class ExportApi {
  exportObjectUrl({ fullUrl = false, ...props }) {
    Api.checkContent(props);
    props.uiOnError = true;
    return buildUrlWithAuth(`/spr/render/export/pdf?${j.param(props)}`);
  }

  getMobileUrlExcelExport = (path, params) => {
    try {
      const filename = Utils.Object.getObjectInPath(path);
      return buildUrlWithAuth(`/spr/analysis/export-to-excel?${j.param({ path, filename, ...params })}`);
    } catch (e) {
      console.error(e);
    }
  };

  getUrlToExportNewMap = async (props = {}) => {
    if (!props.filter) {
      let filter = new URLSearchParams(window.location.search).get('filter');
      if (!!filter) {
        props.filter = filter;
      }
    }
    try {
      const { data } = await Axios.post(`/spr/export-current-view/currentObject`, props.object);
      return data;
    } catch (error) {
      await props.onErrorExport(error);
      return null;
    }
  };

  exportCurrentObjectUrl = async ({ fullUrl = false, mdxRef = null, ...props }) => {
    Api.checkContent(props);
    if (!Utils.Object.isNewMap(props.content)) {
      const extension = !!props.analysisNotPersisted ? 'analysis' : Utils.Object.getObjectType(props.content);
      let dashStateRef;
      if (extension === 'analysis') {
        const data = await j.getJSON('/spr/ui/analysis/chart-conf');
        await j.postJSON('/spr/ui/analysis/chart-conf', {
          enabled: data.enabled,
          confs: data.conf,
        });
        await Api.updateJsf();
      } else if (extension === 'dashboard' && window.__CURRENT_DASHGRID && window.__CURRENT_DASHGRID.stateForExport) {
        const { filters, ...dashState } = window.__CURRENT_DASHGRID.stateForExport() ?? {};
        dashStateRef = await this.dashStateToDashRef(dashState);
      }

      let bigtableFilterModel = props.bigtableFilterModel;
      let bigtableSortModel = props.bigtableSortModel;

      if (Utils.Object.isBigTable(props.content)) {
        if (Array.isArray(bigtableFilterModel)) {
          bigtableFilterModel = Utils.BigTable.returnFilterModelForPath(props.content, bigtableFilterModel);
        }

        if (Array.isArray(bigtableSortModel)) {
          bigtableSortModel = Utils.BigTable.returnSortModelForPath(props.content, bigtableSortModel);
        }
      }

      const urlParams = Api.params({
        mdxRef,
        bigtableFilterModel: JSON.stringify(bigtableFilterModel),
        bigtableSortModel: JSON.stringify(bigtableSortModel),
        dashStateRef,
      });
      window.open(`${Api.baseUrl()}/spr/export-current-view/pdf/${extension}?${urlParams}`, '_blank');
    } else {
      let exportParams = await Api.Export.getUrlToExportNewMap(props);
      if (!!exportParams) {
        window.open(
          `${Api.baseUrl()}/spr/export-current-view/exportCurrentObject?${Api.params({ ...exportParams })}`,
          '_blank'
        );
      }
    }
  };

  exportCockpitObject = async ({ filters, dashState, ...params }) => {
    Api.checkContent(params);

    ['bigtableFilterModel', 'bigtableSortModel'].forEach((key) => {
      if (params[key]) {
        params[key] = JSON.stringify(params[key]);
      }
    });

    const dashStateRef = await this.dashStateToDashRef(dashState);
    const filterRef = await FilterService.createFilterRef(filters);
    const exportUrl = Api.Export.exportObjectUrl({
      filterRef,
      dashStateRef,
      ...params,
    });

    window.open(exportUrl, '_blank');
  };

  async dashStateToDashRef(dashState) {
    let dashStateRef = undefined;
    if (!_.isEmpty(dashState)) {
      dashState = {
        itemOverrides: Object.values(dashState.itemOverrides).map((io) => {
          return {
            id: io.id,
            path: io.path,
            viewType: io.viewType,
          };
        }),
        mdxOverrides: dashState.mdxOverrides ?? [],
      };
      const { key } = await Api.TemporaryStorage.put(dashState);
      dashStateRef = key;
    }
    return dashStateRef;
  }

  exportPdfFromDash = async (path, filters, item) => {
    const filterRef = await FilterService.createFilterRef(filters);

    let mdxRef;
    let viewType;
    if (item && Utils.Object.isAnalysis(path)) {
      if (application.ice.isLoaded()) {
        try {
          mdxRef = await Api.Dash.storageMDX(item.id);
        } catch (e) {}
      } else {
        const mdx = window.RENDERABLE_PRELOAD_CACHE[item.id]?.mdx;
        if (mdx) {
          const { key } = await Api.TemporaryStorage.put(mdx);
          mdxRef = key;
        }
      }

      if (item.viewType) {
        viewType = item.viewType === 'html' ? 'TABLE' : 'CHART';
      }
    }

    const exportUrl = Api.Export.exportObjectUrl({
      filterRef,
      mdxRef,
      content: path,
      viewType,
    });

    window.open(exportUrl, '_blank');
  };

  share = async (data, params = {}) => {
    let filterRef;
    let dashStateRef;
    let mdxRef;
    let kpiRef;
    let exportStateRef;
    if (
      Utils.Object.isDashboard(params.content) &&
      window.__CURRENT_DASHGRID &&
      window.__CURRENT_DASHGRID.stateForExport
    ) {
      const { filters, ...dashState } = window.__CURRENT_DASHGRID.stateForExport() ?? {};
      filterRef = await FilterService.createFilterRef(filters);
      dashStateRef = await this.dashStateToDashRef(dashState);
    } else if (Utils.Object.isAnalysis(params.content)) {
      if (application.ice.isLoaded()) {
        const response = await Api.Analysis.createMdxRef();
        mdxRef = response.mdxRef;
      } else {
        const response = await Axios.getData('/spr/ui/analysisView/mdxRef');
        mdxRef = response.mdxRef;
      }
    } else if (Utils.Object.isKpi(params.content)) {
      const mutations = window.__KPI_COMPONENT_REF?.wrappedComponent?.state?.mutations;
      if (mutations) {
        kpiRef = (await Api.TemporaryStorage.put(mutations)).key;
      }
    }

    let bigtableFilterModel = params.bigtableFilterModel;
    let bigtableSortModel = params.bigtableSortModel;

    if (Utils.Object.isBigTable(params.content)) {
      if (Array.isArray(bigtableFilterModel)) {
        bigtableFilterModel = Utils.BigTable.returnFilterModelForPath(params.content, bigtableFilterModel);
      }

      if (Array.isArray(bigtableSortModel)) {
        bigtableSortModel = Utils.BigTable.returnSortModelForPath(params.content, bigtableSortModel);
      }
    }

    if (params.exportState) {
      if (_.isObjectLike(params.exportState)) {
        params.exportState = {
          filterModel: bigtableFilterModel,
          sortModel: bigtableSortModel,
          ...params.exportState,
        };
      }
      exportStateRef = (await Api.TemporaryStorage.put(params.exportState)).key;
    }

    if (!filterRef && params.filters) {
      filterRef = await FilterService.createFilterRef(params.filters);
    }

    if (!mdxRef && params.mdx) {
      mdxRef = (await Api.TemporaryStorage.put(params.mdx)).key;
    }

    return Axios.postData('/spr/export/share', data, {
      params: {
        filterRef,
        dashStateRef,
        mdxRef,
        kpiRef,
        exportStateRef,
        shareMode: true,
        bigtableFilterModel: JSON.stringify(bigtableFilterModel),
        bigtableSortModel: JSON.stringify(bigtableSortModel),
      },
    });
  };
}

export default ExportApi;
