import PropTypes from "prop-types";
import React from "react";
import ContextEnhancer from "components/ContextEnhancer";

class DashboardItemMenuBackgroundTransparency extends React.Component{

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    toggleCheck = () => {
        this.props.context.execute(`#{dashboardBean.toggleBackgroundTransparency('${this.props.item.id}')}`);
    };

    render() {
        return(
            <li className="sub-container-dash-item-newmenu-title" onClick={this.toggleCheck}>
                    <label>
                        <input type="checkbox" onChange={_.noop} checked={ this.props.item.transparentbackground }
                               className="ace"/>
                        <span className="lbl" onClick={this.toggleCheck}>{this.props.context.msg.t('background.transparency')}</span>
                    </label>
                </li>
        )
    }

}

export default ContextEnhancer(DashboardItemMenuBackgroundTransparency);