import React, {Component} from "react";
import PropTypes from "prop-types";

import Api from "components/Api";

class CockpitOrgMapRender extends Component {

    static propTypes = {
        className: PropTypes.string,
        path: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        path: '',
    }

    state = {
        orgMap: null,
        html: null,
    };

    async componentDidMount() {
        try {
            const {orgMap, html} = await Api.OrgMap.findByPath(this.props.path);
            application.orgmaps.removeInvalidEntries();
            application.orgmaps.clearRefs(orgMap.id);

            await new Promise(res => this.setState({orgMap, html}, res));

            j(`#organization${orgMap.id}`).orgChart({
                container: j(`#orgChart${orgMap.id}`),
                speed: 'normal',
                stack: false,
                fade: true,
                interactive: true,
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        return (
            <div className={`CockpitOrgMapRender ${this.props.className}`}
                 dangerouslySetInnerHTML={{__html: this.state.html}}>
            </div>
        );
    }
}

export default CockpitOrgMapRender;
