import './BngCard.css';

import React from 'react';

export function BngCardHeader({ className = '', title = '', ...props }) {
  return (
    <div className={`BngCardHeader ${className}`} {...props}>
      <h1>{title}</h1>
    </div>
  );
}

export function BngCardBody({ className = '', children, ...props }) {
  return (
    <div className={`BngCardBody ${className}`} {...props}>
      {children}
    </div>
  );
}

export function BngCardFooter({ className = '', children, ...props }) {
  return (
    <div className={`BngCardFooter ${className}`} {...props}>
      {children}
    </div>
  );
}

export default function BngCard({ className = '', children, withShadow = false, paddingSize = 'md', ...props }) {
  return (
    <div className={`BngCard ${withShadow ? 'withShadow' : ''} ${className}`} {...props}>
      {children}
    </div>
  );
}
