import './BngNewAnalysis.css';

import React, { useState } from 'react';
import NewAnalysisService from 'components/bng/pages/newAnalysis/NewAnalysisService';
import { BngNewAnalysisHelp } from 'components/bng/pages/newAnalysis/BngNewAnalysisHelp';
import Api from 'components/Api';
import AssistedObjectPage from 'components/bng/pages/common/AssistedObjectPage';
import useBimContext from 'components/hooks/useBimContext';

export default function BngNewAnalysis({ className = '', service = NewAnalysisService, embeddedOpts }) {
  const { msg } = useBimContext();
  const [showIntro, setShowIntro] = useState(null);

  const afterFetch = async () => {
    const watchedNewAnalysisParam = await Api.UserParam.findKey(Api.UserParam.Keys.Analysis.WatchedNewAnalysis);
    const showIntro = !watchedNewAnalysisParam || watchedNewAnalysisParam.value !== 'true';
    setShowIntro(showIntro);
  };

  return (
    <AssistedObjectPage
      className={`BngNewAnalysis ${className}`}
      headerIcon="equalizer"
      headerTitle={msg.t('analysis')}
      headerMessage={msg.t('BngNewAnalysis.header.message')}
      headerDocLink={msg.t('documentation.create.analysis')}
      searchPlaceholder={msg.t('BngNewAnalysis.search.for.analysis.type')}
      createObjectLabel={msg.t('create_analisy')}
      service={service}
      afterFetch={afterFetch}
      embeddedOpts={embeddedOpts}
      afterCardsComponent={() => {
        if (!_.isNil(embeddedOpts) || _.isNil(showIntro)) {
          return null;
        }
        return <BngNewAnalysisHelp showIntro={showIntro} onFinishIntro={() => setShowIntro(false)} />;
      }}
    />
  );
}
