import React from 'react';
import Tippy from '@tippyjs/react';

import { BngIconButton } from 'components/bng/ui/BngIconButton';

const APPEND_TO = () => document.body;
export default ({
  className = '',
  icon = 'more_vert',
  onMount = _.noop,
  onHide = _.noop,
  children,
  toggleButton,
  wrapperType = 'span',
  visible = undefined,
  onClickOutside = undefined,
  ...props
}) => {
  const toggler = toggleButton ? toggleButton : <BngIconButton icon={icon} />;
  let wrapper = toggler;
  if (wrapperType) {
    const Wrap = wrapperType;
    wrapper = <Wrap>{toggler}</Wrap>;
  }

  const computedProps = {};
  const isUncontrolled =
    _.isUndefined(onClickOutside) || _.isUndefined(visible);
  if (isUncontrolled) {
    computedProps.onMount = (event) => {
      onMount({ event });
    };
    computedProps.onHide = (event) => {
      onHide({ event });
    };
  } else {
    computedProps.visible = visible;
    computedProps.onClickOutside = onClickOutside;
  }

  return (
    <Tippy
      className={`BngTippyDropdown ${className}`}
      content={children}
      placement="bottom"
      appendTo={APPEND_TO}
      theme="light-border"
      trigger={_.isUndefined(visible) ? 'click' : undefined}
      maxWidth={'none'}
      arrow
      interactive
      {...props}
      {...computedProps}
    >
      {wrapper}
    </Tippy>
  );
};
