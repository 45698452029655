import { Axios } from 'commonUtils';

class ProjectUserActivationApi {
  async findUserInfo(key) {
    const { data } = await Axios.get(
      `/api/projectUserActivations/${key}/userInfo`
    );
    return data;
  }

  async updateUserInfo(key, userInfoData) {
    const { data } = await Axios.post(
      `/api/projectUserActivations/${key}/userInfo`,
      userInfoData
    );
    return data;
  }

  async findActivationInfo(key) {
    const { data } = await Axios.get(
      `/api/projectUserActivations/${key}/activationInfo`
    );
    return data;
  }

  async updateActivationInfo(key) {
    const { data } = await Axios.post(
      `/api/projectUserActivations/${key}/activationInfo`
    );
    return data;
  }

  async findFinishInfo(key) {
    const { data } = await Axios.get(
      `/api/projectUserActivations/${key}/finish`
    );
    return data;
  }

  async updateFinishInfo(key, userInfoData) {
    const { data } = await Axios.post(
      `/api/projectUserActivations/${key}/finish`,
      userInfoData
    );
    return data;
  }

  async updateUsersDisplayName({ userInfos }) {
    const { data } = await Axios.post(
      `/api/projectUserActivations/updateUsersDisplayName`,
      {
        userInfos,
      }
    );
    return data;
  }

  async activateGuestUser(projectId, userId) {
    const { data } = await Axios.post(
        `/api/projectUserActivations/activateGuestUser`,
        {
          projectId,
          userId
        }
    );
    return data;
  }
}

export default ProjectUserActivationApi;
