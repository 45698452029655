import React from 'react';
import PropTypes from "prop-types";
import DashboardItemMenuTitle from "./DashboardItemMenuTitle";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import BimEventBus from 'BimEventBus';
import DashGridEvents from 'components/ui/dashboard/components/DashGridEvents';

class DashboardItemMenuAnalysisViewChange extends React.Component{

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    renderAnalysisInPlace = () => {
        let { viewType, id, path } = this.props.item;
        if (viewType === 'html') {
            viewType = 'image';
        } else {
            viewType = 'html';
        }

        if (application.ice.isLoaded()) {
            Api.executeExp(`#{dashboardBean.renderInPlace('${path}', '${viewType}' , '${id}')}`);
        } else {
            const item = _.cloneDeep(this.props.item);
            item.viewType = viewType;
            BimEventBus.emit(DashGridEvents.RENDER_IN_PLACE, {
                action: 'OVERRIDE',
                item: item
            });
        }
    };

    render() {

        const {item} = this.props;
        let title;
        let icon;

        if (item.additionalProps.isShowChart && !(item.viewType === 'image')) {
            title = this.props.context.msg.t('visualize.chart')
            icon = 'icon-bar-chart';
        } else if (item.additionalProps.isShowTable && !(item.viewType === 'html')) {
            title = this.props.context.msg.t('visualize.table')
            icon = 'icon-table';
        }


        return (
            <li onClick={this.renderAnalysisInPlace}>
                <DashboardItemMenuTitle title={title}
                                        icon={icon}
                                        more={false} />
            </li>
        )

    }
}
export default ContextEnhancer(DashboardItemMenuAnalysisViewChange);