import React from 'react';
import PropTypes from 'prop-types';
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import ComponentFactory from "components/ComponentFactory";

class DashboardItemMenuHighlight extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    itemHighlight = async () => {
        try {
            await Api.Dash.toggleHighlightItem(this.props.item.id);
            await Api.updateJsf();
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        return (
            <li onClick={this.itemHighlight}>
                <DashboardItemMenuTitle
                    title={this.props.item.highlight ? this.props.context.msg.t('remove.highlight.dashboard.item') : this.props.context.msg.t('highlight.dashboard.item')}
                    icon={this.props.item.highlight ? 'star' : 'star_border'}
                    more={false}/>
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuHighlight);