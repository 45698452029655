import styles from './MktDefaultTab.module.css';

import React, { useEffect, useRef, useState } from 'react';

import useTranslation from 'components/hooks/useTranslation';
import MarketplaceBanner from 'components/bng/pages/marketplace/MarketplaceBanner';
import Icon from 'components/ui/common/Icon';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngEmpty from 'components/bng/ui/BngEmpty';
import MarketplaceGroup from 'components/bng/pages/marketplace/MarketplaceGroup';
import BngButton from 'components/bng/ui/BngButton';
import useBimNavigate from 'components/hooks/useBimNavigate';

const MarketplaceSmallBanner = ({ navigate, title, description, link, color, image }) => {
  const { t } = useTranslation();

  return (
    <div className={`MarketplaceSmallBanner ${styles.itemBanner}`} style={{ background: color }}>
      <div className={styles.itemBannerInfoWrapper}>
        <span className={styles.bannerTitle}>{t(title)}</span>
        <span className={styles.bannerDescription}>{t(description)}</span>
        <BngButton
          className={styles.bannerBtn}
          onClick={() => {
            navigate(link);
          }}
        >
          {t('see.more')}
        </BngButton>
      </div>
      <div className={styles.itemBannerImage}>
        <img src={image} alt={`MarketplaceSmallBannerImage-${title}`} />
      </div>
    </div>
  );
};

export default function MktDefaultTab({
  className = '',
  groupsToRender = [],
  marketplaceItems,
  selectCard,
  selectedItems,
  accountId,
  groupProps = { singleLine: false },
}) {
  const { t } = useTranslation();
  const navigate = useBimNavigate();
  const _groupsWrapperRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showEmptyLabel, setShowEmptyLabel] = useState(false);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
    setShowEmptyLabel(false);
  };

  useEffect(() => {
    setShowEmptyLabel(_groupsWrapperRef.current?.children.length === 0);
  }, [searchTerm]);

  return (
    <div className={`MktDefaultTab ${styles.MktDefaultTab} ${className}`}>
      <div className={styles.MktDefaultTabWrapper}>
        <section className={styles.defaultHeader}>
          <MarketplaceBanner accountId={accountId} />
        </section>
        <section className={styles.searchWrapper}>
          <div className={styles.customSearch}>
            <Icon icon={'search'} />
            <input
              type={'text'}
              onChange={onSearch}
              placeholder={t('search.on.marketplace')}
              value={searchTerm}
              className={styles.marketplaceSearchField}
            />
            {searchTerm && <BngIconButton className={styles.clearSearch} icon={'close'} onClick={clearSearchTerm} />}
          </div>
        </section>
        <section className={styles.marketplaceItemsShowcase}>
          {showEmptyLabel && (
            <BngEmpty className={styles.emptyIndicator} isEmpty={true} message={t('marketplace.no.items')} />
          )}
          <div className={styles.groupsWrapper} ref={_groupsWrapperRef}>
            {groupsToRender
              .filter((group) => (!searchTerm && group.isBanner) || marketplaceItems[group.key]?.length > 0)
              .map((group, idx) => {
                if (group.isBanner) {
                  return (
                    <MarketplaceSmallBanner
                      key={`${idx}-${group.title}`}
                      title={t(group.title)}
                      description={t(group.desc)}
                      link={group.link}
                      color={group.color}
                      image={group.image}
                      navigate={navigate}
                    />
                  );
                } else {
                  let items = marketplaceItems[group.key];
                  if (group.filterFunc) {
                    items = items.filter(group.filterFunc);
                  }
                  return (
                    <MarketplaceGroup
                      key={`${idx}-${group.title}`}
                      title={t(group.title)}
                      description={t(group.desc)}
                      items={items}
                      groupKey={group.key}
                      searchTerm={searchTerm}
                      isBanner={group.isBanner}
                      selectCard={selectCard}
                      selectedItems={selectedItems}
                      activeAddons={marketplaceItems.activeAddons}
                      singleLine={groupProps.singleLine}
                      knowMore={group.knowMore}
                      anchorKey={group.anchorKey}
                    />
                  );
                }
              })}
          </div>
        </section>
      </div>
    </div>
  );
}
