import React, {Component} from "react";
import PropTypes from "prop-types";
import {Provider} from "./LeafletMap";


class ReadySource extends Component {

    static propTypes = {
        path: PropTypes.string,
        center: PropTypes.arrayOf(PropTypes.number),
        zoom: PropTypes.number,
        description: PropTypes.string,
        provider: PropTypes.string,
        geoJSON: PropTypes.object,
        legend: PropTypes.arrayOf(PropTypes.object),
        useCircles: PropTypes.bool,
        heatMapProps: PropTypes.object,
    };

    static defaultProps = {
        path: '',
        center: [0, 0],
        zoom: 2,
        description: '',
        provider: 'OPEN_STREET_MAP',
        legend: [],
        useCircles: false,
        heatMapProps: {},
    };

    constructor(props) {
        super(props);
    }

    render() {
        const props = Object.assign({}, this.props, {
            provider: Provider[this.props.provider]
        });
        return React.cloneElement(this.props.children, props);
    }

}

export default ReadySource;
