import { connect } from 'react-redux';
import BngTreeDropdown from 'components/bng/form/BngTreeDropdown';

const BngTreeDropdownContainer = connect((state) => {
  return {
      dataTree: state.menu.roots,
  };
})(BngTreeDropdown);

export default BngTreeDropdownContainer;
