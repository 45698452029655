export default {
  USER_DATA_RESTRICT : {
    key: 'USER_DATA_RESTRICT',
    name: 'addon.user.data.restrict.name',
    hasPage: true,
  },
  PRESENTATION : {
    key: 'PRESENTATION',
    name: 'addon.presentation.name',
    hasPage: true,
  },
  KPI_MONITORING : {
    key: 'KPI_MONITORING',
    name: 'addon.kpi.monitoring.name',
    hasPage: true,
  },
  PUBLISHER : {
    key: 'PUBLISHER',
    name: 'addon.publisher.name',
    hasPage: true,
  },
  VISUAL_IDENTITY : {
    key: 'VISUAL_IDENTITY',
    name: 'addon.visual.identity.name',
    hasPage: true,
  },
  DOMAIN_NAME : {
    key: 'DOMAIN_NAME',
    name: 'addon.domain.name.name',
    hasPage: true,
  },
  WHATS_APP : {
    key: 'WHATS_APP',
    name: 'addon.whats.api.name',
    hasPage: true,
  },
  WHATS_SUPPORT: {
    key: 'WHATS_SUPPORT',
    name: 'addon.WHATS_SUPPORT.name',
    hasPage: true,
  },
  TRACEABILITY: {
    key: 'TRACEABILITY',
    name: 'addon.traceability.name',
    hasPage: true,
  },
  PUBLISHER_FULL : {
    key: 'PUBLISHER_FULL',
    name: 'addon.publisher.full.name',
    hasPage: true,
  },
};
