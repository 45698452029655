import React from 'react';
import ReactDOM from 'react-dom';

import FilterBar from 'components/filter/FilterBar';
import FilterService from 'components/filter/FilterService';
import Utils from 'components/Utils';

class FilterFactory {
  renderComponent(
    container,
    opts = { filters: [], dashboardPath: '', type: 'DASHBOARD', isPublisher: true, canSave: false, onEditorMode: false }
  ) {
    try {
      if (application.page.isMobile()
        && Utils.parentWindowIsAccessible()
        && parent.__FILTER_CACHE_ON_RESIZE) {
        opts.filters = parent.__FILTER_CACHE_ON_RESIZE;
        delete parent.__FILTER_CACHE_ON_RESIZE;
      } else {
        opts.filters = FilterService.transform(opts.filters);
      }
    } catch (e) {
      console.error('Error on FilterFactory.renderComponent', e);
      opts.filters = FilterService.transform(opts.filters);
    }

    return ReactDOM.render(<FilterBar {...opts} container={container} />, container);
  }
}

const instance = new FilterFactory();

export default instance;
