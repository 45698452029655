import 'components/ui/presentation/DropdownTablePresentation.css';

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import OpConfirmation from "components/ui/OpConfirmation";
import Button from "components/ui/Button";
import {MODALS} from "components/ui/redux/Actions";
import Popper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";
import UiMsg from "components/ui/UiMsg";
import CreatePresentation from "components/ui/presentation/CreatePresentation";
import PresentationPublicLinkDialog from "components/ui/presentation/PresentationPublicLinkDialog";
import {PresentationPermissionDialog} from "components/bng/permission/PermissionDialog";
import {BngIconButton} from "../../bng/ui/BngIconButton";

class DropdownTablePresentation extends Component {

    static propTypes = {
        className: PropTypes.string,
        presentation: PropTypes.object.isRequired,
        refreshPresentations: PropTypes.func.isRequired,
        shared: PropTypes.bool,
        clickOutsideContainer: PropTypes.any,
        clickOutsideClassName: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        presentationValues: {},
        refreshPresentations: _.noop,
        shared: false,
        clickOutsideContainer: undefined,
        clickOutsideClassName: '',
    };

    state = {
        displayMenu: false,
        open: false
    };

    openConfigurePresentationDialog = (event) => {
        this.props.dispatch(MODALS.open(CreatePresentation, {
            idPresentation: this.props.presentation.id,
            refreshPresentations: this.props.refreshPresentations
        }));
        this.setState({open: false});
    };

    openPublicLinkDialog = (event) => {
        this.props.dispatch(MODALS.open(PresentationPublicLinkDialog, {idPresentation: this.props.presentation.id}));
        this.setState({open: false});
    };

    openUserShareDialog = (event) => {
        this.props.dispatch(MODALS.open(PresentationPermissionDialog, {presentation: this.props.presentation}));
        this.setState({open: false});
    };

    exportImageFromPresentation = (event) => {
        window.open(Api.Presentation.linkExportImages(this.props.presentation.id), '_blank');
        this.setState({open: false});
    };

    visualizePresentation = async (event) => {
        try {
            const link = await Api.Presentation.findLinkFor(this.props.presentation.id);
            window.open(link, '_blank');
        } catch (e) {
            console.error('Error on visualizePresentation()', {presentation: this.props.presentation}, e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({open: false});
        }
    };

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        });
    };

    removePresentation = async () => {
        try {
            await Api.Presentation.removePresentation(this.props.presentation.id);
            this.props.refreshPresentations();
            UiMsg.ok(this.props.context.msg.t('slide.show.remove.successful'));
        } catch (e) {
            console.error('Error on removePresentation()', {presentation: this.props.presentation}, e);
            UiMsg.ajaxError('error', e);
        }
    };

    attemptRemovePresentation = (event) => {
        OpConfirmation({
            title: this.props.presentation.name,
            message: this.props.context.msg.t('slide.show.remove.confirmation.message'),
            onConfirm: this.removePresentation,
            msg: this.props.context.msg
        });
        this.setState({open: false});
    };

    render() {
        const container = document.querySelector('.Presentation-list-dialog');
        const containerClickOutsideOverlay = this.props.clickOutsideContainer ?? container;
        const allowWrite = this.props.presentation.allowWrite ?? this.props.presentation.canWrite;
        return (
            <div style={{position: 'relative'}}>
                <span className="dropdown-menu-tst">
                    <div className="dropdown-presentation-table-list table-presentation" onClick={this.openOptions}>
                        <BngIconButton className="presentation-dropdown-icon-button" icon="more_vert"/>
                    </div>
                    <Popper container={container} className={`bng-dropdown-parent ${this.props.className}`}
                            open={this.state.open}
                            anchorEl={this.state.popperRef}
                            modifiers={{
                                preventOverflow: {
                                    boundariesElement: container,
                                }
                            }}>
                        <BngClickOutsideOverlay className={this.props.clickOutsideClassName}
                                                onClick={() => this.setState({open: false})}
                                                container={containerClickOutsideOverlay}/>

                        <ul className={`bng-dropdown table-presentation ${this.props.className}`}>
                            {allowWrite &&
                                <li onClick={this.openConfigurePresentationDialog}
                                    className="li-dropdown">
                                    <Button icon="settings"
                                            title={this.props.context.msg.t('edit.this.presentation')}
                                            className="btn-only-icon table-presentation">
                                        {this.props.context.msg.t('edit')}
                                    </Button>
                                </li>
                            }

                            {allowWrite &&
                                <>
                                    <li onClick={this.openUserShareDialog} className="li-dropdown">
                                        <Button icon="lock_open"
                                                title={this.props.context.msg.t('manage.user.permissions.for.this.presentation')}
                                                className="btn-only-icon table-presentation">{this.props.context.msg.t('permissions')}</Button>
                                    </li>
                                    <li onClick={this.openPublicLinkDialog} className="li-dropdown">
                                        <Button icon="link"
                                                title={this.props.context.msg.t('manage.public.links.presentation')}
                                                className="btn-only-icon table-presentation">
                                            {this.props.context.msg.t('public.links')}
                                        </Button>
                                    </li>
                                </>
                            }

                            {!this.props.clickOutsideContainer &&
                                <li onClick={this.visualizePresentation} className="li-dropdown">
                                    <Button icon="visibility"
                                            title={this.props.context.msg.t('visualize')}
                                            className="btn-only-icon table-presentation">
                                        {this.props.context.msg.t('visualize')}
                                    </Button>
                                </li>
                            }

                            {allowWrite &&
                                <>
                                    <li onClick={this.exportImageFromPresentation} className="li-dropdown">
                                        <Button icon="cloud_download"
                                                title={this.props.context.msg.t('save.copy.local.disk')}
                                                className="btn-only-icon table-presentation">
                                            {this.props.context.msg.t('download')}
                                        </Button>
                                    </li>
                                    <li onClick={this.attemptRemovePresentation} className="li-dropdown">
                                        <Button icon="delete"
                                                title={this.props.context.msg.t('delete.this.presentation')}
                                                className="btn-only-icon table-presentation">
                                            {this.props.context.msg.t('delete_button')}
                                        </Button>
                                    </li>
                                </>
                            }

                        </ul>
                    </Popper>
                </span>
            </div>
        );
    }

}

export default ContextEnhancer(connect()(DropdownTablePresentation));