import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";

export const promiseHandler = ({context, closeModal = _.noop, onFinish = _.noop}, promise) => {
    return Promise.resolve(
        promise.then(
            () => {
                onFinish();
                UiMsg.ok(context.msg.t('changes.applied.successfully'));
                Api.updateJsf();
                closeModal();
            },
            (e) => {
                onFinish({error: true});
                UiMsg.ajaxError(null, e)
            }
        ))
};

export const transformItemToPersist = (item) => {
    return {
        uuid: item.i,
        x: item.x,
        y: item.y,
        width: item.w,
        height: item.h,
    };
};

export const transformLayoutToPersist = (layout) => {
    return _.sortBy(layout, ['i'])
        .map(item => transformItemToPersist(item));
};

export const selectItem = (itemId) => {
    const $dashItem = j('.DashGridItem-' + itemId);
    if ($dashItem.length === 0) return;
    $dashItem.click(); //select object
    j('.breakpoint-MOBILE, .breakpoint-MOBILE_HORIZONTAL')
        .stop()
        .animate({
            scrollTop: $dashItem.position().top
        }, 1000);
};