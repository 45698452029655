import styles from './ConfigurationTab.module.css';

import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import BngField from 'components/bng/form/BngField';
import SelectIconDialog from 'components/ui/common/SelectIconDialog';
import { MODALS } from 'components/ui/redux/Actions';
import Icon from 'components/ui/common/Icon';
import BngTextArea from 'components/bng/form/BngTextArea';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngDropdownTagsWrapper from 'components/bng/ui/BngDropdownTagsWrapper';
import BngRadio from 'components/bng/form/BngRadio';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngButton from 'components/bng/ui/BngButton';
import useTranslation from 'components/hooks/useTranslation';

export default function ConfigurationTab({ updateFormikField }) {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const dispatch = useReduxDispatch();

  const structureTypeOpts = useMemo(() => {
    return [
      {
        label: t('analysis'),
        icon: 'equalizer',
        value: 'analysis',
      },
      {
        label: t('data'),
        icon: 'dns',
        value: 'data',
      },
    ];
  }, []);

  return (
    <AccordionList className={`ConfigurationTab ObjectRightMenuAccordion`}>
      <Accordion title={t('configuration')}>
        <div className={`${styles.NameIconWrapper}`}>
          <Field
            component={BngField}
            name="structureName"
            label={`${t('name')}:`}
            placeHoslder={t('bim.query.configuration.structure.name.placeholder')}
          />
          <Icon
            icon="cloud"
            onClick={() => {
              dispatch(
                MODALS.open(SelectIconDialog, {
                  icon: { name: values.structureIcon },
                  onSelect: (event) => updateFormikField('structureIcon', event.name),
                })
              );
            }}
          />
        </div>
        <BngDropdownTagsWrapper
          selectedGroupId={(groupId) => updateFormikField('structureTag', groupId)}
          customButton={({ openDropdown }) => (
            <div onClick={openDropdown} className={`${styles.TagSelection}`}>
              <div />
              {t('bim.query.configuration.structure.add.tag')}
            </div>
          )}
        />

        <Field
          component={BngField}
          inputComponent={BngTextArea}
          name="structureDescription"
          label={`${t('description')}:`}
        />
        <div className={`${styles.StructureTypeTitle}`}>{t('bim.query.configuration.structure.type')}:</div>
        <div className={`${styles.StructureTypeWrapper}`}>
          {structureTypeOpts.map((type, idx) => {
            return (
              <div
                key={`${type.value}-${idx}`}
                className={`${styles.StructureType} ${type.value === values.structureType ? 'selected' : ''}`}
                onClick={() => updateFormikField('structureType', type.value)}
              >
                <Icon icon={type.icon} />
                {type.label}
                <BngRadio checked={type.value === values.structureType} field={{ value: type.value }} />
              </div>
            );
          })}
        </div>
        <BngCheckbox
          label={t('snapshot.structure')}
          field={{ value: false }}
          onClick={() => updateFormikField('fullLoads', !values.fullLoads)}
          checked={values.fullLoads}
        />
      </Accordion>
      <BngButton className={`${styles.GoBackButton}`}>{t('bim.query.connection.configuration.go.back')}</BngButton>
      <BngButton className={`${styles.NextStepButton}`}>{t('bim.query.connection.configuration.next.step')}</BngButton>
    </AccordionList>
  );
}
