import React from 'react';
import { connect } from 'react-redux';

import Icon from 'components/ui/common/Icon';
import GroupRender from 'components/bng/pages/admin/structures/GroupRender';
import ContextEnhancer from 'components/ContextEnhancer';
import { MODALS } from 'components/ui/redux/Actions';
import InMemoryLoadLimitAlertDialog from 'components/bng/load-limit/inMemory/InMemoryLoadLimitAlertDialog';

const LoadBadge = ({
  type,
  icon,
  className = '',
  onClick = _.noop,
  children,
}) => (
  <span
    className={`LoadStatusBadge badge badge-${type} pointer ${className}`}
    onClick={onClick}
  >
    {!icon && children ? children : <Icon icon={icon} />}
  </span>
);

export const LoadStatusBadge = {
  ERROR: (props) => (
    <LoadBadge type={'important'} icon={`priority_high`} {...props} />
  ),
  LOADING: ({ spin = true, ...props }) => (
    <LoadBadge
      type={'warning'}
      icon={`autorenew`}
      className={spin ? 'spin-element' : ''}
      {...props}
    />
  ),
  ALERT: (props) => (
      <LoadBadge type={'alert'} icon={`priority_high`} {...props} />
  ),
  CANCELLED: (props) => (
      <LoadBadge type={'alert'} icon={'cancel'} {...props} />
  ),
  NEW_DATA_NOT_FOUND: (props) => (
      <LoadBadge type={'alert'} icon={'question_mark'} {...props} />
  ),
};

export const LimitAlertBadge = ({ row, ...props }) => {
  const count = row?.limitAlerts?.length || 0;

  if (count < 1) return null;

  const alertText = `${count > 9 ? '9+' : count}`;
  return (
    <LoadBadge type={'alert'} className={'LimitAlertBadge'} {...props}>
      <span>{alertText}</span>
    </LoadBadge>
  );
};

const StructureInfo = ({
  row,
  onClick = undefined,
  onBadgeClick = _.noop,
  showBadge = true,
  title = null,
  context: { msg },
  dispatch,
}) => {
  let BadgeComponent = showBadge ? LoadStatusBadge[row.loadStatus] : null;

  if (showBadge && !BadgeComponent && !_.isEmpty(row.limitAlerts)) {
    BadgeComponent = LimitAlertBadge;
    title = msg.t('inMemoryLoadLimitAlert.badge.title');
    onBadgeClick = () => {
      dispatch(
        MODALS.open(InMemoryLoadLimitAlertDialog, {
          initialStructure: row,
        })
      );
    };
  }

  return (
    <div className={`StructureInfo flex-center-items`}>
      <span
        onClick={onClick}
        className={`flex-center-items ${onClick ? 'pointer' : ''}`}
      >
        <div className="IconContainer" title={BadgeComponent && title}>
          <Icon icon={row.icon} className={'mr-2 StructureIcon'} />
          {BadgeComponent && (
            <BadgeComponent
              row={row}
              onClick={(e) => {
                e.stopPropagation();
                onBadgeClick(e);
              }}
            />
          )}
        </div>

        <span>{row.caption || row.name}</span>
      </span>
      {row.group && <GroupRender {...row.group} className={'ml-2'} />}
    </div>
  );
};

export default connect()(ContextEnhancer(StructureInfo));
