import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import UiMsg from "components/ui/UiMsg";

class DashboardItemMenuEditText extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    editText = async () => {
        try {
            await this.props.context.execute(`#{dashboardBean.editTextComp('${this.props.item.id}')}`);
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        }
    };

    render() {
        return (
            <li onClick={this.editText}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('edit.content')}
                                        icon="fa-pencil-square"
                                        more={false}/>
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuEditText);