import {Axios} from "commonUtils";

export default class ProjectQuotaRequestApi {

    async findRequests(projectId, limit = undefined) {
        const {data} = Axios.get(`/spr/projectQuotaRequests/${projectId}`, {
            params: {
                limit
            }
        });
        return data;
    }

    async requestQuota(projectId) {
        const {data} = Axios.post(`/spr/projectQuotaRequests/${projectId}/requestQuota`);
        return data;
    }

}