import styles from './UserFilterManagementDialog.module.css';

import React, { useMemo, useState } from 'react';

import UserFilterFormDialog from 'components/ui/filter/UserFilterFormDialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngAvatar from 'components/bng/ui/BngAvatar';
import BngTable from 'components/bng/ui/BngTable';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useTranslation from 'components/hooks/useTranslation';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import Utils from 'components/Utils';
import LinkedObjects from 'components/ui/LinkedObjects';

export default function UserFilterManagementDialog({ closeModal = _.noop, filterId = null, afterSubmit = _.noop }) {
  const context = useBimContext();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [userFilters, setUserFilters] = useState([]);
  const [usersAndGroups, setUsersAndGroups] = useState();
  const [selectedRowData, setSelectedRowData] = useState();

  const loadUsersAndGroups = async () => {
    setLoading(true);
    try {
      const data = await Api.Project.findProjectUsersAndGroups(context.project.id);
      setUsersAndGroups(data);
    } catch (e) {
      console.error('Error on loadUsersAndGroups()', e);
      UiMsg.ajaxError(t('user.group.fetch.error'), e);
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect({
    onMount: async () => {
      await loadUsersAndGroups();

      try {
        setLoading(true);
        const mdxGlobalFilter = await Api.MdxGlobalFilter.findOne(filterId);
        setFilter(mdxGlobalFilter);

        const uf = _.cloneDeep(_.orderBy(mdxGlobalFilter.userFilters, [(item) => item.accessConfig.id], ['asc']));
        uf.forEach((uf) => (uf._key = Utils.randomId()));
        setUserFilters(uf);
      } catch (e) {
        console.error('Error on fetching filter data onMount()', e);
        UiMsg.ajaxError(t('error.fetching.data.for.filters'), e);
      } finally {
        setLoading(false);
      }
    },
  });

  const openFormDialog = (row, idx) => {
    setSelectedRowData({ row, idx });
  };

  const cols = useMemo(() => {
    return [
      {
        key: 'id',
        label: t('id'),
        size: '10%',
        render: (row) => {
          return (
            <div className={styles.tableRows}>
              <span>{row.accessConfig.id}</span>
            </div>
          );
        },
      },
      {
        key: 'users',
        label: t('tabNav.users'),
        size: '30%',
        onClick: (row, idx) => openFormDialog(row, idx),
        render: (row) => {
          const groups = row.accessConfig.groups.map((id) => usersAndGroups.groups.find((group) => group.id === id));
          const users = row.accessConfig.users.map(
            (id) => usersAndGroups.projectUsers.find((pu) => pu.user.id === id)?.user
          );
          return (
            <div className="div-information-cockpit w-100">
              <span className="cockpit-item-slide-name w-100">
                <LinkedObjects
                  itemWidth={40}
                  items={[...groups, ...users].filter((m) => m)}
                  render={(member) => {
                    const isGroup = member.hasOwnProperty('users');
                    return (
                      <div
                        key={`${isGroup ? 'GROUP' : 'USER'}-${member.id}`}
                        title={`${isGroup ? t('group') : ''} ${Utils.Users.displayName(member)}`.trim()}
                        className="gap-1"
                      >
                        <BngAvatar userId={member.id} isGroup={isGroup} />
                      </div>
                    );
                  }}
                />
              </span>
            </div>
          );
        },
        rowClassName: styles.filterUsers,
      },
      {
        key: 'filteredMembers',
        label: t('filtered.members'),
        size: '50%',
        render: (row) => {
          const membersNames = row.memberReferences.map((fm) => fm.member).join(', ');
          return (
            <div className={styles.tableRows} title={membersNames}>
              {membersNames}
            </div>
          );
        },
      },
      {
        key: 'action',
        label: t('action'),
        size: '10%',
        render: (row, idx) => {
          return (
            <div className={styles.tableRows}>
              <BngDropdown
                popperOpts={{ placement: 'bottom-end' }}
                overDialog
                options={[
                  {
                    label: t('edit_button'),
                    icon: 'edit',
                    onClick: () => openFormDialog(row, idx),
                  },
                  {
                    label: t('delete_button'),
                    icon: 'close',
                    onClick: () => {
                      const copy = userFilters.slice();
                      copy.splice(idx, 1);
                      setUserFilters(copy);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [userFilters, usersAndGroups]);

  return (
    <>
      <Dialog
        className={`UserFilterManagementDialog ${styles.UserFilterManagementDialog} large`}
        title={t('user.filter.config', filter?.name)}
        loading={loading}
        onClose={closeModal}
      >
        <Dialog.Body>
          <div className={styles.containerNewUserFilter}>
            <BngIconButton
              icon="add_circle"
              text={t('new.user.filter')}
              className={styles.buttonNewUserFilter}
              iconProps={{ style: { marginRight: '5px' } }}
              onClick={() => openFormDialog()}
            />
          </div>
          <div className={styles.tableContainer}>
            <BngTable cols={cols} rows={userFilters} stickyHeader={true} rowKeyBuilder={(row) => row._key} />
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <DefaultDialogActions
            closeModal={closeModal}
            title={t('save')}
            onClickSaveButton={async () => {
              try {
                const filterCopy = _.cloneDeep(filter);
                filterCopy.userFilters = userFilters;
                await Api.MdxGlobalFilter.save(filterCopy);
                UiMsg.ok(t('save_success', [context.msg.t('filter2')]));
                closeModal();
                afterSubmit();
              } catch (e) {
                if (e.response?.status === 409) {
                  UiMsg.error(t('name_alredy_taken'));
                } else {
                  console.error(e);
                  UiMsg.error(t('error.creating.filter'), e);
                }
              }
            }}
          />
        </Dialog.Footer>
      </Dialog>

      {selectedRowData && (
        <UserFilterFormDialog
          closeModal={() => setSelectedRowData(null)}
          filter={filter}
          row={selectedRowData.row}
          usersAndGroups={usersAndGroups}
          onChange={({ groups, users, mdxMembers }) => {
            const row = selectedRowData.row
              ? _.cloneDeep(selectedRowData.row)
              : {
                  accessConfig: {},
                  memberReferences: [],
                  _key: Utils.randomId(),
                };
            row.accessConfig.groups = groups;
            row.accessConfig.users = users;

            row.memberReferences = _.orderBy(
              mdxMembers.map((member) => {
                const match = row.memberReferences.find((mr) => mr.member === member);
                if (match) {
                  return match;
                }
                return {
                  member,
                };
              }),
              ['member'],
              ['asc']
            );

            const copy = userFilters.slice();
            if (selectedRowData.row) {
              copy[selectedRowData.idx] = row;
            } else {
              copy.push(row);
            }
            setUserFilters(copy);
          }}
          onGroupChanged={loadUsersAndGroups}
        />
      )}
    </>
  );
}
