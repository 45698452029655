import "components/ui/dashboard/components/DashInputImage.css";
import React from "react";
import {promiseHandler} from "components/ui/dashboard/components/common";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";

const DashInputImage = ({
                            item = {order: 0},
                            startUpload = _.noop,
                            finishUpload = _.noop,
                            ...props
                       }) => {

    const save = ({file, width, height}) => {
        let form = {
            hashCode: item.order,
            width: width,
            height: height,
            screen: {
                width: window.width(),
                height: window.height(),
            },
        };
        startUpload();
        return promiseHandler({onFinish: finishUpload, ...props},
            Api.Upload.upload(file)
                .then((data) => form.image = data[0].path)
                .then(() => Api.Dash.updateImageItem(form))
        );
    };

    const handleFile = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!file.type.includes('image')) {
            UiMsg.error(props.context.msg.t('only.image.support'));
            return;
        }
        const img = new Image();
        img.onload = function () {
            save({file, width: this.width, height: this.height});
        };
        img.src = (window.URL || window.webkitURL).createObjectURL(file);
    };

    return (
        <input id={`add-dashboard-image ${item.order}`} type="file" accept="image/*" onChange={handleFile}/>
    )
}

export default ContextEnhancer(DashInputImage);