import './UserActivationPage.css';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BngPrimaryButton } from 'components/ui/Button';
import Api from 'components/Api';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';
import BngCard, { BngCardBody, BngCardFooter, BngCardHeader } from 'components/bng/ui/BngCard';
import CommonActivationPage from 'components/bng/pages/project/userActivation/CommonActivationPage';
import BngBigIcon from 'components/bng/ui/BngBigIcon';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import useBimContext from 'components/hooks/useBimContext';
import useBimNavigate from '../../../../hooks/useBimNavigate';

const DEFAULT_SERVICE = {
  findActivationInfo(key) {
    return Api.ProjectUserActivation.findActivationInfo(key);
  },
  updateActivationInfo(key) {
    return Api.ProjectUserActivation.updateActivationInfo(key);
  },
};

export default function UserActivationPage({ service = DEFAULT_SERVICE }) {
  const { msg } = useBimContext();
  const navigate = useBimNavigate();
  const { key } = useParams();

  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  useAsyncEffect({
    onMount: async () => {
      setLoading(true);
      try {
        await service.findActivationInfo(key);
      } catch (e) {
        navigate('/errors/403');
      }

      try {
        await service.updateActivationInfo(key);
        setFinished(true);
        setLoading(false);
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      }
    },
  });

  const okAction = async () => {
    window.location.replace(`${Api.baseUrl()}/spr/bng/login`);
  };

  return (
    <CommonActivationPage className="UserActivationPage">
      <UiBlocker block={loading}>
        <BngCard withShadow>
          <BngCardHeader />
          <BngCardBody>
            <BngBigIcon
              icon={finished ? 'done' : 'more_horiz'}
              message={msg.t(finished ? 'UserActivationPage.activation.success' : 'UserActivationPage.activating.user')}
            />
          </BngCardBody>
          <BngCardFooter className="text-right">
            <BngPrimaryButton onClick={okAction}>OK</BngPrimaryButton>
          </BngCardFooter>
        </BngCard>
      </UiBlocker>
    </CommonActivationPage>
  );
}
