import './BngBigIcon.css';
import React from 'react';

import Icon from 'components/ui/common/Icon';

export default function BngBigIcon({ icon, message = '', ...props }) {
  return (
    <div className="BngBigIcon" {...props}>
      <div className="flex-center-items jc-center">
        <div className="IconWrapper">{icon && <Icon icon={icon} />}</div>
      </div>
      <div className="MsgWrapper text-center mt-5 mb-6">
        <b>{message}</b>
      </div>
    </div>
  );
}
