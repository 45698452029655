import React from 'react';
import PropTypes from 'prop-types';

import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import Icon from "components/ui/common/Icon";
import Utils from "components/Utils";
import UiMsg from "components/ui/UiMsg";
import {MODALS} from "components/ui/redux/Actions";
import DashboardItemLinksDialog from "components/ui/dashboard/components/itemMenus/DashboardItemLinksDialog";
import { KPI_EDIT_MENU_EVENT } from 'components/bng/pages/kpi/KpiEditMenu';
import { ASSISTED_ANALYSIS_EDIT_MENU_EVENT } from 'components/bng/pages/dashboard/menus/AssistedAnalysisEditMenu';

class DashboardItemMenuConfig extends React.Component {

    static propTypes = {
        item: PropTypes.object,
        closeDropdown: PropTypes.func,
    };

    static defaultProps = {
        item: {},
        closeDropdown: _.noop
    };

    state = {
        open: false,
        popperRef: null,
        imageSize: null
    };

    imageSizes = [
        {value: 'RESIZE', label: 'image.size.box'},
        {value: 'ORIGINAL', label: 'image.size.original'},
        {value: 'PROPORTIONAL', label: 'image.size.proportional'}
    ];

    componentDidMount() {
        let imageSize = '';
        if (this.props.item.viewType === 'imageContent') {
            imageSize = 'ORIGINAL';
        } else if (this.props.item.additionalProps.proportionalSize) {
            imageSize = 'PROPORTIONAL';
        } else if (this.props.item.additionalProps.resizeContent) {
            imageSize = 'RESIZE';
        }
        this.setState({imageSize});
    }

    openOptions = (event) => {
        if (!this.state.open) this.props.onOpen(event);
        this.setState({open: !this.state.open});
    };

    forceClose = () => {
        this.setState({open: false});
    };

    configureItemLinks = () => {
      window.ReduxStore.dispatch(
        MODALS.open(DashboardItemLinksDialog, {
          dashItemId: this.props.item.id,
          currentLinks: this.props.item.itemLinks,
        })
      );
    };

    configureKPI = (event) => {
      this.props.closeDropdown();
      bimEventBus.emit(KPI_EDIT_MENU_EVENT, {
        path: this.props.item.path,
        showClose: true,
        dashboardStyle: this.props.style,
      });
    };

    configureAnalysis = () => {
        this.props.closeDropdown();
        if(this.props.item.additionalProps?.assistedData?.type?.type !== 'BLANK_ANALYSIS') {
            bimEventBus.emit(ASSISTED_ANALYSIS_EDIT_MENU_EVENT, {
                path: this.props.item.path,
                ...this.props.item.additionalProps.assistedData
            });
        } else {
            const link = this.generateLink();
            window.location.replace(link);
        }
    };

    generateLink = (openOnEditMode = undefined) => {
        return `${Api.baseUrl()}/load.iface?${j.param({
            content: this.props.item.path,
            breadcrumb: true,
            openOnEditMode: openOnEditMode
        })}`;
    };

    handleCheckResize = () => {
        this.props.context.execute(`#{dashboardBean.toggleResizeContent('${this.props.item.id}', ${!this.props.item.resizeContent})}`);
    };

    handleCheckAllowDrillOperations = () => {
        this.props.context.execute(`#{dashboardBean.toggleAllowDrill('${this.props.item.id}', ${!this.props.item.dynamic})}`);
    };

    handleAnalysisView = async () => {
        const {isShowChart, isShowTable} = this.props.item.additionalProps;
        if (isShowChart && isShowTable) {
            await this.props.context.execute(`#{dashboardBean.analysisChangeViewType('${this.props.item.path}')}`);
            await Api.updateJsf();
        }
    };

    resizeImage = async (size) => {
        if (this.state.imageSize === size) return;
        try {
            await Api.Dash.changeImageContentSize({
                id: this.props.item.id,
                size: size
            });

            this.setState({imageSize: size});

            const element = jQuery('.image-' + this.props.item.order + ' div');
            element.removeClass('imageDashOriginalSize');
            element.removeClass('imageDashProportionalSize');
            element.removeClass('imageDashResizeSize');

            const sizeClassName = size.charAt(0).toUpperCase() + size.slice(1).toLowerCase();
            element.addClass(`imageDash${sizeClassName}Size`);
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const {item} = this.props;
        const isAnalysis = Utils.Object.isAnalysis(item.path);
        const isKpi = Utils.Object.isKpi(item.path);
        const isMap = Utils.Object.isMap(item.path);
        const isBigtable = Utils.Object.isBigTable(item.path);

        return (
            <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('settings')}
                                        onClick={this.openOptions}
                                        icon="settings"
                                        more={true}/>

                {this.state.open &&
                <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-config">

                    {(item.viewType !== 'imageContent' && item.viewType !== 'label' && item.viewType !== 'icon'
                        && item.viewType !== 'text' && item.viewType !== 'textNew') &&
                    <li onClick={this.configureItemLinks}>
                        <Icon icon="icon-share"/>
                        {this.props.context.msg.t('configure.dashboard.item.links')}
                    </li>
                    }

                    {isKpi &&
                    <li onClick={this.configureKPI}>
                        <Icon icon="icon-dashboard"/>
                        {this.props.context.msg.t('configure.kpi')}
                    </li>
                    }

                    {isAnalysis &&
                    <li onClick={this.configureAnalysis}>
                        <Icon icon="icon-share"/>
                        {this.props.context.msg.t('configure.analysis')}
                    </li>
                    }

                    {isBigtable &&
                    <li>
                        <Icon icon="icon-share"/>
                        <a href={this.generateLink(true)}>{this.props.context.msg.t('configure.bigtable')}</a>
                    </li>
                    }

                    {(item.viewType !== 'imageContent' && !isKpi && !isMap && !isBigtable && item.viewType === 'html') &&
                    <li onClick={this.handleCheckResize}>
                        <label>
                            <input type="checkbox" onChange={_.noop} onClick={this.handleCheckResize}
                                   checked={item.resizeContent} className="ace"/>
                            <span className="lbl">{this.props.context.msg.t('resize.content')}</span>
                        </label>
                    </li>
                    }

                    {isAnalysis &&
                    <li className={(item.additionalProps.isShowChart && item.additionalProps.isShowTable) ? '' : 'disabledOption'}
                        onClick={this.handleAnalysisView}>
                        <Icon icon="icon-eye-open"/>
                        <span>{item.viewType === 'html' ? this.props.context.msg.t('visualize.chart') : this.props.context.msg.t('visualize.table')}</span>
                    </li>
                    }

                    {(item.viewType === 'html') &&
                    <li onClick={this.handleCheckAllowDrillOperations}>
                        <label>
                            <input type="checkbox" onChange={_.noop} checked={item.dynamic} className="ace"/>
                            <span className="lbl">{this.props.context.msg.t('allow.drill.operations.label')}</span>
                        </label>
                    </li>
                    }

                    {(item.viewType === 'imageContent') &&
                    <React.Fragment>
                        {
                            this.imageSizes.map((data, idx) => (
                                <li key={idx} onClick={() => this.resizeImage(data.value)}
                                    className={`${this.state.imageSize === data.value ? 'selected' : ''}`}>
                                    {this.props.context.msg.t(data.label)}
                                </li>
                            ))
                        }
                    </React.Fragment>
                    }

                    {isMap &&
                    <>
                        <li>
                            <Icon icon="icon-map-marker"/>
                            <a href={this.generateLink(true)}>{this.props.context.msg.t('config.new.map')}</a>
                        </li>
                        <li onClick={async () => {
                            const matchedMap = Object.values(window.LEAFLET_MAP_REGISTRY).find(leafletMapComponent => leafletMapComponent.getPath() === item.path);
                            if (matchedMap) {
                                try {
                                    const mapState = matchedMap.readMapState();
                                    await Api.Dash.updateNewMapDefaultPosition(item.id, mapState.center, mapState.zoom)
                                    await Api.updateJsf();
                                    UiMsg.ok(this.props.context.msg.t('dash.map.default.position.updated'));
                                } catch (e) {
                                    UiMsg.ajaxError(null, e)
                                }
                            }
                        }
                        }>
                            <Icon icon="radar"/>
                            <a href="#" onClick={() => false}>
                                {this.props.context.msg.t('mark.position.as.default')}
                            </a>
                        </li>

                        {item.itemProps.hasOwnProperty('mapCenter') &&
                        <li onClick={async () => {
                            try {
                                await Api.Dash.restoreNewMapDefaultPosition(item.id)
                                await Api.updateJsf();
                                UiMsg.ok(this.props.context.msg.t('dash.map.default.position.restored'));
                            } catch (e) {
                                UiMsg.ajaxError(null, e)
                            }
                        }
                        }>
                            <Icon icon="clear"/>
                            <a href="#" onClick={() => false}>
                                {this.props.context.msg.t('dash.map.restore.default.position')}
                            </a>
                        </li>
                        }
                    </>
                    }
                </ul>
                }
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuConfig);