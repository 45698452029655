import './SupportMenuButton.css';
import React from 'react';
import { connect } from 'react-redux';

import Api from 'components/Api';
import { MODALS } from 'components/ui/redux/Actions';
import ContextEnhancer from 'components/ContextEnhancer';
import MenuButton from 'components/ui/navbar/MenuButton';
import MenuLink from 'components/ui/navbar/MenuLink';
import ModalInformation from 'components/ui/ModalInformation';
import TicketForm from 'components/ui/support/TicketForm';
import TicketListDialog from 'components/bng/support/TicketListDialog';
import WhatsSupportDialog from 'components/bng/support/WhatsSupportDialog';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import AddonInfoDialog from 'components/ui/navbar/addons/AddonInfoDialog';
import NPSCensus from 'components/bng/ui/Census/NPSCensus';
import UiMsg from 'components/ui/UiMsg';

const emitConnectionError = () => {
  ModalInformation.ShowMessage({
    title: 'Oops...',
    text: 'Não foi possível contatar serviço de suporte, verifique a conexão do servidor com a internet',
    icon: 'error',
  });
};

const menuItems = (context, component, dispatch) => [
  {
    icon: 'icon-comments',
    label: context.msg.t('support.user.menu.chat'),
    className: 'colouredTabBorder',
    onClick: () =>
      openSuportChat(
        context.user.email,
        context.user.displayName,
        context.support.projectName,
        context.support.chat,
        context.project.projectType
      ),
  },
  {
    icon: 'fa fa-whatsapp',
    label: context.msg.t('whatsapp.talk.to.us'),
    className: `colouredTabBorder`,
    onClick: () => {
      if (checkAddonEnabled(AddonType.WHATS_SUPPORT.key, true)) {
        component.props.openWhatsSupportDialog();
      }
    },
  },
  {
    icon: 'icon-bim-book',
    label: context.msg.t('support.menu.help'),
    className: 'colouredTabBorder',
    link: context.support.accountConfig.knowledgeBaseUrl,
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    icon: 'icon-graduation-cap fa-graduation-cap',
    label: context.msg.t('support.menu.videos'),
    className: 'colouredTabBorder',
    onClick: async () => {
      if (context.isInbox || !context.university.enabled) {
        window.open(context.university.url, '_blank').focus();
      } else {
        try {
          Api.University.redirectToUniversityPage();
        } catch (e) {
          console.error('Could not load university...');
        }
      }
    },
  },
  {
    icon: 'icon-archive',
    label: context.msg.t('support.dialog.call.title'),
    className: 'colouredTabBorder',
    onClick: async (event) => {
      event.stopPropagation();
      const { canAccess } = await Api.Support.checkStatus();
      if (canAccess) {
        component.props.openTicketListDialog();
      } else {
        emitConnectionError();
      }
      MenuButton.closeAllMenus();
    },
  },
  {
    icon: 'icon-question',
    label: context.msg.t('support.user.menu.call'),
    className: 'colouredTabBorder',
    onClick: async (event) => {
      event.stopPropagation();
      const { canAccess } = await Api.Support.checkStatus();
      if (canAccess) {
        component.props.openNewTicketDialog();
      } else {
        emitConnectionError();
      }
      MenuButton.closeAllMenus();
    },
  },
  {
    icon: 'architecture',
    label: context.msg.t('find.specialist'),
    className: 'colouredTabBorder',
    visible: context.showHireSpecialist,
    onClick: async (event) => {
      event.stopPropagation();
      j('.SpecialistMenuButton').click();
      MenuButton.closeAllMenus();
    },
  },
  {
    icon: 'how_to_vote',
    label: context.msg.t('support.menu.voting.forum'),
    className: 'colouredTabBorder',
    visible: context.showVotingForum.enabled,
    link: context.showVotingForum.urlVotingForum,
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    icon: 'icon-stethoscope',
    label: context.msg.t('status.page'),
    className: 'colouredTabBorder',
    rel: 'noreferrer',
    onClick: async (event) => {
      event.stopPropagation();
      const statusPageUrl = await Api.Support.findStatusPageUrl();
      window.open(statusPageUrl, '_blank');
      MenuButton.closeAllMenus();
    },
  },
  {
    icon: 'how_to_vote',
    label: context.msg.t('answer.nps'),
    className: 'colouredTabBorder',
    onClick: async (event) => {
      event.stopPropagation();

      const currentDate = new Date();
      const nextEvaluationDate = new Date(context.nps.lastAvaliationDate);
      nextEvaluationDate.setDate(nextEvaluationDate.getDate() + 1);
      nextEvaluationDate.setHours(0, 0, 0, 0);
      const isAbleToAnswer = currentDate >= nextEvaluationDate && context.nps.lastAvaliationDate !== null;
      if (isAbleToAnswer) {
        component.props.openNpsCensusDialog();
      } else {
        const timeRemaining = nextEvaluationDate - currentDate;
        const hoursRemaining = new Date(timeRemaining).getUTCHours();
        const minutesRemaining = new Date(timeRemaining).getUTCMinutes();
        UiMsg.warn(
          context.msg.t('error.already.voted'),
          context.msg.t('error.already.voted.detail', [hoursRemaining, minutesRemaining])
        );
      }

      MenuButton.closeAllMenus();
    },
  },
];

class SupportMenuButton extends React.Component {
  render() {
    return (
      <MenuButton className={'SupportMenuButton'} icon="message" title={this.props.context.msg.t('support.menu.title')}>
        {menuItems(this.props.context, this, this.props.dispatch)
          .filter((i) => !i.hasOwnProperty('visible') || i.visible)
          .map(({ visible, ...menuItem }, i) => (
            <MenuLink key={i} {...menuItem} />
          ))}
      </MenuButton>
    );
  }
}

export default ContextEnhancer(
  connect(
    (state) => {
      return {};
    },
    (dispatch) => {
      return {
        openNewTicketDialog: () => dispatch(MODALS.open(TicketForm)),
        openTicketListDialog: () => dispatch(MODALS.open(TicketListDialog)),
        openWhatsSupportDialog: () => dispatch(MODALS.open(WhatsSupportDialog)),
        openAddonInfoDialog: () => dispatch(MODALS.open(AddonInfoDialog, { addonKey: AddonType.WHATS_SUPPORT.key })),
        openNpsCensusDialog: () => dispatch(MODALS.open(NPSCensus)),
      };
    }
  )(SupportMenuButton)
);
