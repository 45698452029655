import './MobileMenu.css';

import React, {useEffect, useState} from 'react';
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import ComponentFactory from "components/ComponentFactory";
import Accordion from "components/ui/Accordion";
import ActionList from "components/ui/dashboard/components/ActionList";
import UiMsg from "components/ui/UiMsg";
import MobileOrientationSwitch from "components/ui/dashboard/components/MobileOrientationSwitch";
import BngSwitch from "components/bng/form/BngSwitch";
import AccordionList from "components/ui/AccordionList";
import {selectItem} from "components/ui/dashboard/components/common";
import BngMobileDrawerHelp from "components/bng/pages/dashboard/mobileDrawer/BngMobileDrawerHelp";
import OpConfirmation from 'components/ui/OpConfirmation';
import BngButton from 'components/bng/ui/BngButton';

const MobileMenu = ({
                        itemsData = {},
                        layout = [],
                        context = {},
                        breakpointView = 'MOBILE',
                        dashboardPath = '',
                        selectedItem = '',
                        onLayoutChange = _.noop,
                        layouts = {},
                        horizontalLikeVertical = false,
                        ...props
                    }) => {
    const [loading, setLoading] = useState(false);
    const [availableOnMobile, setAvailableOnMobile] = useState(null);
    const [mobileItems, setMobileItems] = useState([]);
    const [showIntro, setShowIntro] = useState(null);

    useEffect(() => {
        fetchAvailableOnMobile();
    }, []);

    useEffect(() => {
        async function loadWatchedMobileDrawerParam() {
            try {
                const watchedMobileDrawerParam = await Api.UserParam.findKey(Api.UserParam.Keys.Analysis.WatchedMobileDrawer);
                const value = (_.isEmpty(watchedMobileDrawerParam) ? true : false);
                setShowIntro(value);

            } catch (e) {
                console.error(e);
                UiMsg.ajaxError(null, e);
            }
        }

        loadWatchedMobileDrawerParam();
    }, []);

    const fetchAvailableOnMobile = async () => {
        try {
            const available = await Api.Mobile.availableOnMobile({path: dashboardPath});
            setAvailableOnMobile(available);
        } catch (e) {
            console.error(e);
            setAvailableOnMobile(false);
        }
    };

    useEffect(() => {
        let items = [];

        for (let key in itemsData) {
            const item = itemsData[key];
            const itemLayout = _.find(layout, {i: key});
            if (notCurrentBreakpointItem(item)) continue;
            const itemText = item.additionalProps.title || item.additionalProps.label || item.additionalProps.value;
            items.push({
                key,
                availableOnMobile: item.availableOnMobile,
                y: itemLayout ? itemLayout.y : null,
                x: itemLayout ? itemLayout.x : null,
                description: item.caption || `${!!itemText ? itemText + ' - ' : ''} ${context.msg.t(item.viewType)}`,
                onClick: () => item.availableOnMobile && selectItem(item.id),
                id: `MobileItem-${key}`,
                className: `${item.availableOnMobile ? 'active' : 'inactive'} ${selectedItem === item.id ? 'ItemSelected' : ''}`,
                actions: [
                    {
                        icon: "remove_red_eye",
                        onClick: () => toggleMobileViewItem(item),
                        className: "MobileToggleItem",
                        title: context.msg.t(item.availableOnMobile ? 'monitor.disable' : 'monitor.enable')
                    },
                ]
            });
        }

        //Remove container items
        for (let key in itemsData) {
            const item = itemsData[key];
            if (notCurrentBreakpointItem(item) || item.viewType.toLowerCase() !== 'container') continue;
            for (let containerItem of item.additionalProps.items) {
                _.remove(items, (i => i.key === containerItem.i))
            }
        }
        setMobileItems(_.orderBy(items, ['availableOnMobile', 'y', 'x'], ['desc', 'asc', 'asc']));
    }, [itemsData, layout, horizontalLikeVertical]);

    const toggleMobileViewItem = async (item) => {
        try {
            await Api.Dash.changeMobileItemView({id: item.id, availableOnMobile: !item.availableOnMobile});
            await Api.updateJsf();
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    const notCurrentBreakpointItem = (item) => {
        const isContainer = item.additionalProps && !!item.additionalProps.breakpoint;
        if (isContainer) {
            if (breakpointView === 'MOBILE_HORIZONTAL' && item.additionalProps.breakpoint === 'MOBILE' && horizontalLikeVertical) {
                return false;
            }
            return item.additionalProps.breakpoint !== breakpointView;
        }
        return false;
    };

    const handleSwitchMobileChange = async (event, toggleAccordion) => {
        setLoading(true)
        try {
            const enabled = await Api.Dash.toggleMobile();
            await toggleAccordion();
            setAvailableOnMobile(enabled);
            UiMsg.ok(context.msg.t(`mobile.view.${enabled ? 'enabled' : 'disabled'}`));
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeItemsOrder = async (itemsOrdered, {fromIdx, toIdx}) => {
        const forward = Math.min(fromIdx, toIdx) === fromIdx;

        let movedItemLayout = _.find(layout, {i: itemsOrdered[toIdx].props.item.key});
        const oldItemLayout = _.find(layout, {i: itemsOrdered[forward ? toIdx - 1 : toIdx + 1].props.item.key});

        if (!movedItemLayout || !oldItemLayout) return;

        let ll = [];
        movedItemLayout.y = oldItemLayout.y - (forward ? (movedItemLayout.h - oldItemLayout.h) : 0);
        ll.push(movedItemLayout);

        const start = forward ? fromIdx : toIdx + 1;
        const end = forward ? toIdx - 1 : fromIdx;

        for (let i = start; i <= end; i++) {
            let currentItemLayout = _.find(layout, {i: itemsOrdered[i].props.item.key});
            if (forward) {
                currentItemLayout.y -= movedItemLayout.h;
            } else {
                currentItemLayout.y += movedItemLayout.h;
            }
            ll.push(currentItemLayout);
        }
        const mergedLayouts = layout.map(l => {
            const itemL = ll.find(layoutItem => layoutItem === l.i);
            if (!!itemL) {
                return itemL;
            }
            return l;
        });
        await onLayoutChange(mergedLayouts, true);
        ComponentFactory.Dash.renderablePreloadResize();
    };

    if (availableOnMobile === null) {
        return null;
    }

    const resetMobileLayout = async () => {
        setLoading(true);
        try {
            await Api.Dash.resetMobileLayout(breakpointView);
            setLoading(false);
            await Api.updateJsf();
        } catch (e) {
            console.error('Error on resetMobileLayout()', e);
            setLoading(false);
        }
    }

    return (
        <AccordionList className="MobileMenu ObjectRightMenuAccordion"
                       loading={loading}>
            <Accordion className="MobileMenuAccordion"
                       startOpen={availableOnMobile}
                       customTitle={(toggleAccordion) => {
                           return (
                               <div className="AccordionTitle AccordionCustomTitle">
                                    <span className="AccordionDescription">
                                        {context.msg.t('mobile.menu.item')}
                                    </span>
                                   <BngSwitch id="availableMobile"
                                              checked={availableOnMobile}
                                              onChange={(e) => handleSwitchMobileChange(e, toggleAccordion)}/>
                               </div>
                           )
                       }}>
                <div className="MobileMenu">
                    <MobileOrientationSwitch itemsData={itemsData}
                                             horizontalLikeVertical={horizontalLikeVertical}
                                             layout={layout}
                                             breakpointView={breakpointView}
                    />
                    <div className="MobileMenuItemView">
                        <div className="MobileMenuTitle flex-center-items">
                            <div>
                                {context.msg.t('objects')}
                            </div>
                        </div>
                        <ActionList className="MobileMenuList"
                                    onChangeOrder={handleChangeItemsOrder}
                                    items={mobileItems}
                                    draggable={true}/>
                        <BngButton className="bng-button"
                                   onClick={() => {
                                       OpConfirmation({
                                           title: context.msg.t('restore.default.view'),
                                           html: context.msg.t('restore.default.view.message'),
                                           onConfirm: resetMobileLayout,
                                           level: 'warning',
                                       })
                                   }}
                        >
                            {context.msg.t('restore.default.view')}
                        </BngButton>
                    </div>
                </div>
            </Accordion>
            {(showIntro) &&
            <BngMobileDrawerHelp showIntro={showIntro}
                                 onFinishIntro={() => setShowIntro(false)}/>}
        </AccordionList>
    )
};

export default ContextEnhancer(MobileMenu);