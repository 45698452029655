import './PresentationMenuButton.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'components/ui/Button';
import ContextEnhancer from 'components/ContextEnhancer';
import MenuButton from 'components/ui/navbar/MenuButton';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import CreatePresentation from 'components/ui/presentation/CreatePresentation';
import PresentationListDialog from 'components/ui/presentation/PresentationListDialog';
import Icon from 'components/ui/common/Icon';
import MyPresentationList from 'components/ui/presentation/MyPresentationList';
import DropdownTablePresentation from 'components/ui/presentation/DropdownTablePresentation';


class PresentationMenuButton extends Component {

    static propTypes = {
        presentations: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
                isOwner: PropTypes.bool,
                ownerUser: PropTypes.string,
                canWrite: PropTypes.bool
            })
        ),
        badgeValue: PropTypes.any
    };

    static defaultProps = {
        presentations: [],
        badgeValue: 0
    };

    state = {
    }

    openCreatePresentationDialog = () => {
        this.props.dispatch(MODALS.open(CreatePresentation, (this.props)));
    };

    openCreatePresentationListDialog = () => {
        this.props.dispatch(MODALS.open(PresentationListDialog));
    };

    removePresentation = async (id) => {
        try {
            await Api.Presentation.removePresentation(id);
            UiMsg.ok(this.props.context.msg.t('fem.remove_success', [this.props.context.msg.t('presentation')]));
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError('error', e)
        }
    };

    renderActions(_presentation) {
        if (!_presentation.canWrite || !this.props.context.permissions.canManagePresentations()) return null;

        return (
            <div>
                <DropdownTablePresentation className="presentationDropdown"
                                           presentation={_presentation}
                                           refreshPresentations={MyPresentationList.findPresentations}
                                           clickOutsideContainer={document.querySelector('.PresentationMenuButton')}
                                           clickOutsideClassName="PresentationMenuClickOutsideOverlay"
                />
            </div>
        )
    }

    renderBottomActions() {
        return (
            <li className="no-hover">
                <div className="menu-bottom-btn">
                    <Button className="btn-link btn-small"
                            onClick={() => {
                                this.openCreatePresentationListDialog();
                                MenuButton.closeAllMenus();
                            }}>
                        {this.props.context.msg.t('see.all.presentations')}
                    </Button>
                </div>
            </li>

        );
    }

    render() {
        return (
            <MenuButton className={'PresentationMenuButton'}
                        icon="airplay"
                        badge={{value: this.props.badgeValue}}
                        onOpen={async () => {
                            await this.props.findPresentations()
                            return true;
                        }}
                        title={this.props.context.msg.t('presentations')}
                        headerText={null}
                        buttonActionEnable={this.props.context.permissions.canManagePresentations()}
                        buttonActionLabel={this.props.context.msg.t('create')}
                        buttonActionClick={() => {
                            this.openCreatePresentationDialog();
                            MenuButton.closeAllMenus();
                        }}
            >

                {this.props.presentations.map((_presentation) => {

                    return (
                        <li key={_presentation.id} className="colouredTabBorder">
                            <a href="#" className={`item-link ${!_presentation.isOwner ? 'shared' : ''}`}>

                                <div className="row-fluid presentation-item-container">
                                    <div className="presentation-item" onClick={() => window.open(`${Api.baseUrl()}${_presentation.link}`)}>
                                        <Icon icon="local_movies"/>
                                        <span className="sched-name">
                                            {_presentation.name}
                                            {!_presentation.isOwner &&
                                                <Icon title={this.props.context.msg.t('shared.by.user', _presentation.ownerUser)} icon="group" />
                                            }
                                        </span>
                                    </div>

                                    <div className="presentation-buttons">
                                        {this.renderActions(_presentation)}
                                    </div>

                                </div>

                            </a>
                        </li>
                    );
                })}
                {this.renderBottomActions()}
            </MenuButton>
        );
    }

}


export default ContextEnhancer(connect()(PresentationMenuButton));