import styles from './AddonDisabledDialog.module.css';

import React, { useEffect, useState } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngPasswordConfirmation from 'components/bng/ui/BngPasswordConfirmation';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import AddAdditionalDialog from 'components/bng/accounts/additionals/AddAdditionalDialog';

export const checkAddonEnabled = (addonKey, openAddonDialog = false, enabledAction) => {
  const context = useBimContext();
  const addonEnabled = context.accountEnabledForBilling
      ? context.billingAddons.find((addon) => addon.feature.key === addonKey)
      : context.addons.includes(addonKey);
  if (!addonEnabled && openAddonDialog) {
    const dispatch = useReduxDispatch();
    if (context.accountEnabledForBilling) {
      dispatch(
        MODALS.open(AddAdditionalDialog, {
          featureKey: addonKey,
          isConsumptionAdditional: false,
          onActivate: () => window.location.reload(),
        })
      );
    } else {
      dispatch(
        MODALS.open(AddonDisabledDialog, {
          addonKey: addonKey,
        })
      );
    }
  } else if (enabledAction) {
    enabledAction();
  }
  return addonEnabled;
};

export default function AddonDisabledDialog({ closeModal = _.noop, addonKey }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [addon, setAddon] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAddonInfo();
  }, []);

  const fetchAddonInfo = async () => {
    setLoading(true);
    try {
      const addons = await Api.Account.findAddonsInfo(addonKey);
      setAddon(addons);
    } catch (e) {
      console.error('Error on AddonDisabledDialog.fetchAddonInfo()', e);
      UiMsg.ajaxError(context.msg.t('addon.fetch.error'), e);
    } finally {
      setLoading(false);
    }
  };

  const registerAddonNotEnabled = () => {
    try {
      Api.Account.addonNotEnabled(addonKey, context.project.id);
    } catch (e) {
      console.error('Error on registerAddonNotEnabled()', e);
      //Error not shown in GUI
    }
  };

  const onSubmitRequest = async () => {
    setLoading(true);
    try {
      if (context.permissions.isAdmin()) {
        dispatch(
          window.Actions.MODALS.open(BngPasswordConfirmation, {
            onConfirm: async () => {
              const billingPricingId = addon.billingAdditional?.pricings?.[0]?.id;
              await Api.Account.changeAddonRequestStatus(addonKey, context.project.id, true, null, {
                ...addon.billingAdditional,
                billingPricingId,
              });
              UiMsg.ok(context.msg.t('addon.enabled.successfully'));
              closeModal();
              window.location.reload();
            },
          })
        );
      } else {
        await Api.Account.requestAddon(addonKey, context.project.id);
        UiMsg.ok(context.msg.t('addon.request.success.not.master'));
        closeModal();
      }
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('addon.request.error'));
    } finally {
      setLoading(false);
    }
  };

  const isAdmin = context.permissions.isAdmin();

  return (
    <Dialog
      className={`${styles.AddonDisabledDialog} AddonDisabledDialog`}
      loading={loading}
      onClose={() => {
        registerAddonNotEnabled();
        closeModal();
      }}
      titleComponent={() => {
        return (
          <div className={styles.AddonDisabledDialogHeader}>
            <img src={`${Api.baseUrl()}/resources/images/addon_stars.svg`} width="100%" />
            <Icon icon={addon.iconName} className={styles.titleIcon} />
          </div>
        );
      }}
      contentFullWidth
    >
      <div className={styles.textWrapper}>
        <span className={styles.addonTitle}>{context.msg.t(addon.nameKey || 'loading')}</span>
        <span
          className={styles.addonDescription}
          dangerouslySetInnerHTML={{ __html: context.msg.t(addon.longDescKey || 'loading') }}
        ></span>
      </div>

      <div className={styles.buttonsWrapper}>
        <a href={context.msg.t(addon.linkKey)} target="_blank">
          <Button
            loading={loading}
            className={`${styles.knowMoreButton} ${styles.addonButtons} AddonDisabledKnowMoreButton`}
          >
            {context.msg.t('know.more')}
          </Button>
        </a>
        <Button
          loading={loading}
          onClick={onSubmitRequest}
          className={`${styles.submitButton} ${styles.addonButtons} AddonDisabledSubmitButton`}
        >
          {context.msg.t(isAdmin ? 'activate.addon' : 'request.activation')}
        </Button>
      </div>
    </Dialog>
  );
}
