import './MobileUserPermission.css';

import React from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from 'components/ContextEnhancer';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Button from 'components/ui/Button';
import Dialog from 'components/ui/Dialog';
import Checkbox from 'components/ui/Checkbox';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';

class MobileUserPermission extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func,
    onMaximize: PropTypes.func,
    projectId: PropTypes.number,
  };

  static defaultProps = {
    projectId: 0,
  };

  state = {
    users: [],
    newMembers: [],
    searchInput: '',
    search: '',
  };

  addEnableUsers = (member, propToUpdate = {}) => {
    const newMembers = this.state.newMembers.slice();

    if (this.verifyUserExists(member)) {
      for (let i = 0; i < newMembers.length; i++) {
        const m = newMembers[i];
        if (m.id === member.id) {
          newMembers[i] = {
            ...m,
            ...propToUpdate,
          };
          break;
        }
      }
    } else {
      newMembers.push({ ...member, ...propToUpdate });
    }

    this.setState({ newMembers });
  };

  verifyUserExists = (member) => this.state.newMembers.some((el) => member.id === el.id);

  createTableItem = (user, i) => {
    const userNewMember = this.state.newMembers.find((el) => user.id === el.id);
    const userEnable = userNewMember ? userNewMember.enable : user.enable;
    const allowMobileMessaging = userNewMember ? userNewMember.allowMobileMessaging : user.allowMobileMessaging;

    return (
      <tr className="usermobile-table-tr" style={{ height: 46 }} key={i} id={i}>
        <td className="publisherlist-table-td">
          <div title={user.name} className="div-information-publisher">
            {user.name || user.email}
          </div>
        </td>
        <td className="publisherlist-table-td">
          <div title={user.email} className="div-information-publisher">
            {user.email}
          </div>
        </td>
        <td className="publisherlist-table-td">
          <div title={user.phone} className="div-information-publisher">
            {user.phone}
          </div>
        </td>
        <td className="publisherlist-table-td">
          <div style={{ paddingTop: 10, textAlign: 'center' }}>
            <Checkbox
              id={`enableuser${user.id}`}
              selected={userEnable}
              onChange={(e) => {
                this.addEnableUsers(user, { enable: e.target.checked });
              }}
              className="enableuser-mobile-checkbox"
            />
          </div>
        </td>
        <td className="publisherlist-table-td">
          <div style={{ paddingTop: 10, textAlign: 'center' }}>
            <Checkbox
              id={`enableusermobilemessaging${user.id}`}
              selected={allowMobileMessaging}
              onChange={(e) => {
                this.addEnableUsers(user, { allowMobileMessaging: e.target.checked });
              }}
              disabled={_.isEmpty(user.phone)}
              className="enableuser-mobile-checkbox"
            />
          </div>
        </td>
      </tr>
    );
  };

  async componentDidMount() {
    try {
      const { usersMobile } = await Api.Project.getUserMobile(this.props.projectId);
      this.setState({
        users: usersMobile ?? [],
      });
    } catch (error) {
      console.error('Error on findUsers()', error);
      UiMsg.error(this.context.msg.t('user.fetch.error'), error);
    }
  }

  saveUsers = async () => {
    try {
      let result = await Api.Project.saveMobileUsers(this.props.projectId, this.state.newMembers);
      if (result) {
        UiMsg.ok(this.props.context.msg.t('mobile.users.save.sucess'));
        this.props.closeModal();
      } else {
        UiMsg.error(this.props.context.msg.t('mobile.users.save.error'));
      }
    } catch (e) {
      console.error(e);
      UiMsg.error(this.props.context.msg.t('mobile.users.save.error'));
    }
  };

  pressKeytoSearch = (e) => {
    if (e.key === 'Enter') {
      this.runSearch();
    }
  };

  runSearch = () => {
    this.setState({ search: this.state.searchInput });
  };

  render() {
    const filteredUsers = _.isEmpty(this.state.search)
      ? this.state.users
      : this.state.users.filter((u) => Utils.Strings.includesIgnoreCase(u.name, this.state.search));
    return (
      <Dialog
        contentFullWidth={true}
        className="mobile-user-enable large"
        open={this.props.open}
        title={this.props.context.msg.t('configure.user.project.functionalities.dialog.title')}
        onClose={this.props.closeModal}
      >
        <div className="row-fluid">
          <input
            name="content"
            id="input-search-user"
            style={{ width: 770, marginLeft: 10, marginRight: 10 }}
            type="text"
            className="fill-w"
            onKeyDown={this.pressKeytoSearch}
            onKeyUp={this.pressKeytoSearch}
            onChange={(e) => this.setState({ searchInput: e.target.value })}
            placeholder={this.props.context.msg.t('search.for.a.user')}
          />

          <button
            onClick={this.runSearch}
            style={{
              width: 43,
              height: 30,
              marginTop: -10,
              justifyContent: 'center',
              display: 'inline-flex',
            }}
            className="btn btn-primary iceCmdBtn groups-search-btn-marker btn-inverse"
          >
            <Icon icon="search" />
          </button>
        </div>

        <div style={{ height: '400px', overflow: 'auto' }}>
          <table style={{ border: '1px solid #E4E4E4' }} className="table table-condensed table-border table-hover">
            <thead>
              <tr>
                <th
                  style={{
                    paddingLeft: 14,
                    height: 37,
                    lineHeight: '37px',
                  }}
                >
                  {this.props.context.msg.t('username')}
                </th>
                <th
                  style={{
                    paddingLeft: 14,
                    height: 37,
                    lineHeight: '37px',
                  }}
                >
                  {this.props.context.msg.t('email')}
                </th>
                <th
                  style={{
                    paddingLeft: 14,
                    height: 37,
                    lineHeight: '37px',
                  }}
                >
                  {this.props.context.msg.t('phone')}
                </th>
                <th
                  style={{
                    paddingLeft: 14,
                    height: 37,
                    lineHeight: '37px',
                  }}
                >
                  {this.props.context.msg.t('mobile.menu.item')}
                </th>
                <th
                  style={{
                    paddingLeft: 14,
                    height: 37,
                    lineHeight: '37px',
                  }}
                >
                  {this.props.context.msg.t('enable.messaging')}
                </th>
              </tr>
            </thead>
            <tbody>{filteredUsers?.map(this.createTableItem)}</tbody>
          </table>
        </div>
        <div className="row-fluid">
          <div className="span12 text-right btn-fix">
            <Button onClick={this.props.closeModal} className="bng-button cancel">
              {this.props.context.msg.t('cancel_button')}
            </Button>
            <Button style={{ width: 130 }} className="bng-button save" onClick={this.saveUsers}>
              {this.props.context.msg.t('save_button')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ContextEnhancer(MobileUserPermission);
