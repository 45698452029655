import React from 'react';
import ObjectRightMenu from 'components/ui/right-menu/ObjectRightMenu';
import useBimContext from 'components/hooks/useBimContext';
import BimQueryConfigurationMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConfigurationMenuItem';
import BimQueryConnectionsMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConnectionsMenuItem';

export default function BimQueryRightMenu({ ...props }) {
  const context = useBimContext();

  return (
    <ObjectRightMenu
      context={context}
      open={true}
      save={(data) => {
        console.log({ data });
      }}
      options={[
        {
          key: BimQueryConnectionsMenuItem.KEY,
          Component: BimQueryConnectionsMenuItem,
        },
        {
          key: BimQueryConfigurationMenuItem.KEY,
          Component: BimQueryConfigurationMenuItem,
          icon: 'menu_open',
        },
      ]}
      {...props}
    />
  );
}
