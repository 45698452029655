import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useFormikContext } from 'formik';

import { MODALS } from 'components/ui/redux/Actions';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import SaveAsDialogContainer from 'components/ui/common/SaveAsDialogContainer';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export default function SaveMenuItem({
  isPersisted = true,
  save = _.noop,
  openSaveAs = _.noop,
  saveAs = _.noop,
  clearAccordion = _.noop,
  dirtyCheckFunction,
  ...props
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [isDirty, setDirty] = useState(props.dirty);
  const { submitForm, values, initialValues, resetForm } = useFormikContext();

  useEffect(() => {
    window.__OBJECT_RELOAD = ({ dirty, reset = true, reloadFilters = false }) => {
      setDirty(dirty);
      if (reset) {
        resetForm();
        if (_.isFunction(window.__FILTERS_FORM_RESET)) {
          window.__FILTERS_FORM_RESET(reloadFilters);
        }
      }
    };
    return () => delete window.__OBJECT_RELOAD;
  }, []);

  useEffect(() => {
    setDirty(props.dirty);
  }, [props.dirty]);

  useEffect(() => {
    if (dirtyCheckFunction) {
      setDirty(dirtyCheckFunction({ values, initialValues }) || props.dirty);
    } else {
      setDirty(!_.isEqual(values, initialValues) || props.dirty);
    }
  }, [values, initialValues]);

  const handleSave = async () => {
    if (!isPersisted) {
      dispatch(
        MODALS.open(SaveAsDialogContainer, {
          onSubmit: saveAs,
          ...props,
        })
      );
      return;
    }
    await save({ submitForm, values });
    clearAccordion();
  };

  return (
    <RightMenuItem
      {...props}
      onClick={handleSave}
      title={context.msg.t('save')}
      icon="save"
      className={`SaveMenuItem ${isDirty ? 'right-menu-icon-glow' : ''}`}
    >
      {isDirty && <div className="blink" title={context.msg.t('dashboard.pending.changes')} />}
    </RightMenuItem>
  );
}

SaveMenuItem.propTypes = {
  path: PropType.string,
  isPersisted: PropType.bool,
  dirty: PropType.bool,
  save: PropType.func,
  saveAs: PropType.func,
  clearAccordion: PropType.func,
  openSaveAs: PropType.func,
  generalConfigRef: PropType.any,
  dirtyCheckFunction: PropType.func,
};
