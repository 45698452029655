import React from 'react';

import NavbarContainer from 'components/ui/navbar/NavbarContainer';
import MenuContainer from 'components/ui/menu/MenuContainer';
import BreadcrumbContainer from 'components/ui/breadcrumb/BreadcrumbContainer';
import ModalRoot from 'components/ModalRoot';
import { UiMsgContainer } from 'components/ui/UiMsg';
import EventNotification from 'components/ui/event-notification/EventNotification';
import { VIEW } from 'bng/routes';
import BngErrorBoundary from 'components/bng/ui/BngErrorBoundary';

export default function DefaultLayout({ view, children }) {
  const showMenu = !view || view === VIEW.Discover;
  const showBreadcrumb = !view || view === VIEW.Discover;
  return (
    <>
      <BngErrorBoundary>
        <EventNotification />
      </BngErrorBoundary>

      <BngErrorBoundary>
        <NavbarContainer view={view} />
      </BngErrorBoundary>

      {showMenu && (
        <BngErrorBoundary>
          <MenuContainer view={view} />
        </BngErrorBoundary>
      )}

      {showBreadcrumb && (
        <BngErrorBoundary>
          <BreadcrumbContainer />
        </BngErrorBoundary>
      )}

      <div id="right-menu-container" className="hide"></div>
      <div id="floating-object-container" className="floating-object-actions on-react hide" />

      {children}

      <BngErrorBoundary>
        <ModalRoot />
      </BngErrorBoundary>

      <BngErrorBoundary>
        <UiMsgContainer />
      </BngErrorBoundary>
    </>
  );
}
