import styles from './BngUserItemSelect.module.css';

import React, { useState } from 'react';
import { BngSelectSearch, DEFAULT_SEARCH_FUNCTION } from 'components/bng/form/BngSelectSearch';

import BngItemSelect from 'components/bng/form/itemSelect/BngItemSelect';
import Utils from 'components/Utils';
import BngDropdown from 'components/bng/ui/BngDropdown';
import useBimContext from 'components/hooks/useBimContext';
import BngTag from 'components/bng/ui/BngTag';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

export default function BngUserItemSelect({ form, field, ...props }) {
  const { msg } = useBimContext();

  const [open, setOpen] = useState(false);

  return (
    <BngItemSelect
      className={`BngUserItemSelect ${styles.selectSearch}`}
      buttonLabel={msg.t(`BngItemSelect.label.recipients`)}
      tagComponent={() => <UserTagComponent form={form} field={field} />}
      value={field.value}
      openComponent={
        open
          ? () => {
              return (
                <BngSelectSearch
                  form={form}
                  field={field}
                  popperClassName={`${styles.selectSearchPopper}`}
                  closeAfterSelect={false}
                  itemSearchFn={(data) => DEFAULT_SEARCH_FUNCTION(data) || data.item.member?.email?.includes(data.search)}
                  onChange={(v) => {
                    if (field.value.some((i) => i.id === v.id)) {
                      form.setFieldValue(field.name, [...field.value.filter((i) => i.id !== v.id)]);
                    } else {
                      form.setFieldValue(field.name, [...field.value, v]);
                    }
                  }}
                  open={open}
                  onToggle={({ open }) => {
                    setOpen(open);
                  }}
                  customPreview={true}
                  {...props}
                />
              );
            }
          : undefined
      }
      onOpen={() => {
        setOpen(true);
      }}
      showTags={field.value?.length > 0}
    />
  );
}

function UserTagComponent({ form, field }) {
  const context = useBimContext();

  const remove = (item) => {
    form.setFieldValue(field.name, [...field.value.filter((i) => i !== item)]);
  };

  const changeDeliveryType = (type, item) => {
    const updatedItem = { ...item };
    updatedItem.deliveryType = type;
    form.setFieldValue(
      'sendTo',
      field.value.map((value) => (value.id === updatedItem.id ? updatedItem : value))
    );
  };

  const disableWhatsapp = (item, deliveryType) => {
    if (deliveryType === Utils.Scheduling.DELIVERY_TYPE.WHATSAPP) {
      if (!checkAddonEnabled(AddonType.WHATS_APP.key)) return true;
      return !item.allowMobileMessaging && item.type !== 'GROUP';
    }
    return false;
  };

  return (
    <React.Fragment>
      {field.value.map((item, idx) => {
        const itemDeliveryType = item.deliveryType;
        return (
          <BngTag
            key={`key_${idx}_${item?.id ?? item?.name ?? ''}`}
            icon={item.type === 'USER' ? 'person' : 'group'}
            description={item.name}
            onClose={() => remove(item)}
            textEllipsis={true}
          >
            <div className={`userItemTag ${styles.userItemTag}`}>
              <BngDropdown
                icon={Utils.Scheduling.iconForMsgType(itemDeliveryType)}
                btnIconProps={{ className: styles.userDeliveryDropdownBtn }}
                popperOpts={{ placement: 'bottom-start' }}
                overDialog={true}
                options={[
                  {
                    label: context.msg.t('send.via'),
                    isSeparator: true,
                    className: styles.separatorOpt,
                  },
                  ...Object.values(Utils.Scheduling.DELIVERY_TYPE).map((deliveryType) => {
                    return {
                      label: context.msg.t(deliveryType),
                      title: disableWhatsapp(item, deliveryType)
                        ? !checkAddonEnabled(AddonType.WHATS_APP.key)
                          ? context.msg.t('need.addon.whatsapp.scheduling')
                          : context.msg.t('schedule.whatsapp.user.disabled')
                        : deliveryType === Utils.Scheduling.DELIVERY_TYPE.TELEGRAM
                        ? context.msg.t('coming.soon')
                        : '',
                      icon: Utils.Scheduling.iconForMsgType(deliveryType),
                      disabled:
                        deliveryType === Utils.Scheduling.DELIVERY_TYPE.TELEGRAM || disableWhatsapp(item, deliveryType),
                      onClick:
                        deliveryType === itemDeliveryType ? _.noop : () => changeDeliveryType(deliveryType, item),
                      closeOnSelect: true,
                      className: `${styles.dropdownOpt} ${
                        deliveryType === itemDeliveryType ? styles.dropdownOptSelected : ''
                      }`,
                    };
                  }),
                ]}
              />
            </div>
          </BngTag>
        );
      })}
    </React.Fragment>
  );
}
