import { useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';

import BngAda from 'components/bng/ui/BngAda/BngAda';
import BngVideoModal from 'components/bng/ui/BngVideoModal';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';

const BngAddonActivatedHelp = ({
  addonKey,
  helpVideoUrl,
  closeAda = _.noop,
  videoTitle,
  showHelpVideo = true,
  container,
}) => {
  const context = useBimContext();
  const [addon, setAddon] = useState({});
  const [showOptions, setShowOptions] = useState(!showHelpVideo);
  const [showModal, setShowModal] = useState(true);

  const fetchAddon = async () => {
    try {
      const addon = await Api.Account.findAddonsInfo(addonKey);
      setAddon(addon);
    } catch (e) {
      console.error('Error on fetchAddon()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  useEffect(() => {
    fetchAddon();
  }, []);

  const adaOpts = [
    {
      icon: 'help_outline',
      label: context.msg.t('new.analysis.help.documentation'),
      onClick: () => {
        window.open(context.msg.t(addon.linkKey), '_blank');
        closeAda();
      },
    },
    {
      icon: 'close',
      label: context.msg.t('close'),
      onClick: () => closeAda(),
    },
  ];

  if (showHelpVideo) {
    adaOpts.unshift({
      icon: 'play_circle_outline',
      label: context.msg.t('watch.tutorial'),
      onClick: () => {
        setShowModal(true);
        setShowOptions(false);
      },
    });
  }

  const portalContainer = useMemo(() => {
    return container ?? document.getElementById('page-content');
  }, [container]);

  return ReactDOM.createPortal(
    <React.Fragment>
      <BngAda
        className={`AddonActivatedHelp`}
        title={showOptions && context.msg.t('addon.ada.title', context.msg.t(addon.nameKey))}
        description={showOptions && context.msg.t('addon.ada.desc')}
        options={adaOpts}
        showInfo={showOptions}
      />
      {showHelpVideo && showModal && (
        <BngVideoModal
          videoUrl={helpVideoUrl}
          title={videoTitle}
          onClose={() => {
            setShowModal(false);
            setShowOptions(true);
          }}
        />
      )}
    </React.Fragment>,
    portalContainer
  );
};

export default BngAddonActivatedHelp;
