import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import Utils from "components/Utils";
import {MODALS} from "components/ui/redux/Actions";
import UiMsg from "components/ui/UiMsg";
import BimEventBus from 'BimEventBus';
import DashGridEvents from 'components/ui/dashboard/components/DashGridEvents';
import BngExportDialog from "components/bng/exportScheduling/BngExportDialog";

class DashboardItemMenuLinkedItems extends React.Component {

    state = {
        open: false
    };

    static propTypes = {
        item: PropTypes.object,
        link: PropTypes.object,
        id: PropTypes.number,
        context: PropTypes.object,
        onOpen: PropTypes.func,
        forceClose: PropTypes.func,
        selectedFilters: PropTypes.array,
        dashGridItemRef: PropTypes.func,
    };

    static defaultProps = {
        item: {},
        link: {},
        id: null,
        context: null,
        onOpen: null,
        forceClose: null,
        selectedFilters: [],
        dashGridItemRef: _.noop,
    };

    exportFilters = () => {
        const { ref } = this.props.dashGridItemRef() ?? {};
        return ref?.getFilters() ?? this.props.selectedFilters;
    }

    openOptions = (event) => {
        if (!this.state.open) this.props.onOpen(event);
        this.setState({open: !this.state.open});
    }

    forceClose() {
        this.setState({open: false});
    }

    exploreObject = async (path) => {
        await Utils.Object.openObject(path, this.exportFilters(), this.props.selectedFilters);
    }

    openShareDialog = (link) => {
        window.ReduxStore.dispatch(MODALS.open(BngExportDialog, {
            contents: [{
                name: Utils.Object.fileName(link.path),
                path: link.path,
                exportType: Utils.Scheduling.EXPORT_TYPE.PDF,
            }],
            schedulingType: Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE,
        }));
    };

    renderInPlace = (event, link) => {
        event.preventDefault();
        if (link.path !== this.props.item.path) {
            if (application.ice.isLoaded()) {
                Api.executeExp(`#{dashboardBean.renderInPlace('${link.path}', '${this.props.item.id}')}`);
            } else {
                const item = _.cloneDeep(this.props.item);
                item.path = link.path;
                BimEventBus.emit(DashGridEvents.RENDER_IN_PLACE, {
                    action: 'OVERRIDE',
                    item
                });
            }
        }
    };

    renderAnalysisInPlace = (event, link, viewType) => {
        event.preventDefault();
        if (link.path !== this.props.item.path || this.props.item.viewType !== viewType) {
            if (!this.props.isMobile && application.ice.isLoaded()) {
                Api.executeExp(`#{dashboardBean.renderInPlace('${link.path}', '${viewType}' , '${this.props.item.id}')}`);
            } else {
                const item = _.cloneDeep(this.props.item);
                item.path = link.path;
                item.viewType = viewType;
                BimEventBus.emit(DashGridEvents.RENDER_IN_PLACE, {
                    action: 'OVERRIDE',
                    item
                });
            }
        }
    };

    exportExcel = async () => {
        try {
            await Api.Analysis.exportExcelFromDash(this.props.link.path, this.exportFilters());
        } catch (e) {
            console.error("Error on exportExcel()", e);
            UiMsg.ajaxError(null, e);
        }
    }

    exportCsv = async () => {
        try {
            await Api.BigTable.exportCsvFromDash(this.props.link.path, this.exportFilters());
        } catch (e) {
            console.error("Error on exportCsv()", e);
            UiMsg.ajaxError(null, e);
        }
    }

    exportPdf = async () => {
        try {
            await Api.Export.exportPdfFromDash(this.props.link.path, this.exportFilters(), this.props.item);
        } catch (e) {
            console.error("Error on exportPdf()", e);
            UiMsg.ajaxError(null, e);
        }
    }

    render() {
        const {link, context, item} = this.props;
        const isAnalysis = Utils.Object.isAnalysis(link.path);
        const isDashboard = Utils.Object.isDashboard(link.path);
        const isBigTable = Utils.Object.isBigTable(link.path);
        const disableRenderInPlace = link.path === item.path;

        return (
            <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`}>
                <DashboardItemMenuTitle title={link.caption}
                                        onClick={this.openOptions}
                                        icon={Utils.Object.getObjectIcon(link.path)}
                                        more={true}/>
                {this.state.open &&
                <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-config">
                    <li className={'bt-item-new-menu-inside'}>
                        <DashboardItemMenuTitle
                            title={context.msg.t('go.to.this.object')}
                            className="newmenu-explorer-action-share"
                            icon="icon-share"
                            onClick={(e) => this.exploreObject(link.path)}
                        />
                    </li>
                    {!isAnalysis && !isDashboard && !disableRenderInPlace &&
                    <li className={'bt-item-new-menu-inside'}>
                        <DashboardItemMenuTitle
                            title={context.msg.t('visualize')}
                            onClick={(e) => this.renderInPlace(e, link)}
                            icon="icon-eye-open"
                        />
                    </li>
                    }
                    {(isAnalysis && link.props.showTable) && !(disableRenderInPlace && item.viewType === 'html') &&
                    <li className={'bt-item-new-menu-inside'}>
                        <DashboardItemMenuTitle
                            title={context.msg.t('visualize.table')}
                            onClick={(e) => this.renderAnalysisInPlace(e, link, 'html')}
                            icon="icon-table"
                        />
                    </li>
                    }
                    {(isAnalysis && link.props.showChart) && !(disableRenderInPlace && item.viewType === 'image') &&
                    <li className={'bt-item-new-menu-inside'}>
                        <DashboardItemMenuTitle
                            title={context.msg.t('visualize.chart')}
                            onClick={(e) => this.renderAnalysisInPlace(e, link, 'image')}
                            icon="icon-bar-chart"
                        />
                    </li>
                    }
                    <li className={'bt-item-new-menu-inside'}>
                        <DashboardItemMenuTitle
                            title={context.msg.t('share')}
                            icon={"icon-share-alt"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.openShareDialog(link)
                            }}
                        />
                    </li>
                    <li className={'bt-item-new-menu-inside'}>
                        <DashboardItemMenuTitle title={this.props.context.msg.t('printer.export.to.pdf')}
                                                onClick={this.exportPdf}
                                                icon="insert_drive_file"
                                                more={false} />
                    </li>
                    <li className={'bt-item-new-menu-inside'}>
                        {isBigTable &&
                        <DashboardItemMenuTitle title={this.props.context.msg.t('export.to.csv')}
                                                onClick={this.exportCsv}
                                                icon="grid_on"
                                                more={false} />
                        }
                    </li>
                    <li className={'bt-item-new-menu-inside'}>
                        {isAnalysis &&
                        <DashboardItemMenuTitle title={this.props.context.msg.t('export.to.excel')}
                                                onClick={this.exportExcel}
                                                icon="grid_on"
                                                more={false} />
                        }
                    </li>
                </ul>
                }
            </li>
        );

    }
}

export default ContextEnhancer(DashboardItemMenuLinkedItems);