// css imported on CommonCssImports.js
import React, {Component} from "react";
import ReactDOM from 'react-dom'
import L from "leaflet/dist/leaflet";
import "leaflet.gridlayer.googlemutant";
import "./HeatMap";
import Legend from "./Legend";
import InitGmap from "./GoogleMapsAPI";
import {Portal} from "react-overlays";
import Api from "../../Api";

export const Provider = {
    GOOGLE: async () => {
        await InitGmap();
        return L.gridLayer.googleMutant({
            type: 'roadmap' // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        });
    },
    OPEN_STREET_MAP: async () => {
        const protocol = location ? location.protocol : 'http:';
        const TILES_URL = `${protocol}//{s}.tile.osm.org/{z}/{x}/{y}.png`;
        return L.tileLayer(TILES_URL, {minZoom: 1});
    }
};

const DEFAULT_ICON = `${Api.baseUrl()}/resources/images/green.png`;

const icons = {};
const iconLoads = [];

const makeIcon = (iconStr) => {
    const isStringIcon = _.isString(iconStr);
    let iconKey = isStringIcon ? iconStr : `${iconStr.icon}-${iconStr.color}`;
    let icon = icons[iconKey];
    if (!icon) {
        if (isStringIcon) {
            icon = L.icon({iconUrl: iconStr, iconSize: [15, 26], iconAnchor: [8, 26], popupAnchor: [0, -26]});

            iconLoads.push(
                new Promise((res, rej) => {
                    let img = new Image();
                    img.onload = function () {
                        const opts = {
                            iconUrl: iconStr,
                            iconSize: [this.width, this.height],
                            iconAnchor: [this.width / 2, this.height],
                            popupAnchor: [0, -this.height]
                        };
                        icons[iconKey] = L.icon(opts);
                        res(opts);
                    };
                    img.onerror = function () {
                        rej(false);
                    };
                    img.src = iconStr;
                })
            );
        } else {
            //bounce
            icon = L.divIcon({
                html: `<i class='fa ${iconStr.icon} ' style="font-size: 26px; color: ${iconStr.color}; text-shadow: 0 0 1px white;"></i>`,
                iconSize: [15, 26],
                iconAnchor: [8, 26],
                popupAnchor: [0, -26]
            });
        }
        icons[iconKey] = icon;
    }

    return icon;
};

const updateIcons = (markers) => {
    Promise.all(iconLoads).then(() => {
        Object.keys(icons).forEach(key => {
            (markers[key] || []).forEach(feature => {
                feature.properties.marker.setIcon(icons[key]);
            });
        });
    });
};

const createEmptyGeoJSON = () => ({type: "FeatureCollection", features: [], cols: [], geoMdx: '', dataMdx: ''});

let idGen = 1;

window.LEAFLET_MAP_REGISTRY = {};

class LeafletMap extends Component {

    static propTypes = {};

    static defaultProps = {
        path: '',
        center: [0, 0],
        zoom: 2,
        provider: Provider.OPEN_STREET_MAP,
        geoJSON: createEmptyGeoJSON(),
        description: '',
        legend: [],
        useCircles: false,
        enableHeatMap: false,
        heatMapBlur: 15,
        heatMapRadius: 25,
        heatMapMinOpacity: 50,
        showMarkers: true,
        showCaption: true,
        location: '',
        isPresentation: false,
        exportView: false,
    };

    state = {
        ploted: {},
        legend: [],
        enableHeatMap: false,
        showMarkers: true,
        legendField: '',
    };

    constructor(props) {
        super(props);
        this.id = idGen;
        window.LEAFLET_MAP_REGISTRY[idGen] = this;
        idGen++;
        this.rendered = false;
        this.state.enableHeatMap = this.props.enableHeatMap;
        this.state.showMarkers = this.props.showMarkers;
    }

    isExporter() {
        return application.utils.isExporter();
    }

    isNewMap() {
        return window.location.href.includes('/api/mobile/newmap')
          || (application.utils.isAccessingPublisher() && window.location.href.includes('/newmap'));
    }

    async componentDidMount() {
        this.setupSizes();
        const isExporter = this.isExporter();
        this.lMap = L.map(this.$mapContainer, {
            zoomControl: !isExporter,
            preferCanvas: true
        });
        if (isExporter === false) {
            //limita a visualização do mapa na horizontal.
            this.lMap.setMaxBounds(L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)));
        }
        this.setView(this.props.center, this.props.zoom);
        (await this.props.provider())
            .addTo(this.lMap)
            .on('load', () => jQuery(this.$container).addClass('loaded'));

        j(window).on('resize', () => {
            this.setupSizes();
            this.invalidateSize();
        });
        this.fetchData(this.props);
        this.changeMobileStyle();
    }

    setupSizes() {
        const $map = jQuery(this.$mapContainer);
        const mapContainerHeight = $map.parents('.item-content-container').height();

        if (!this.isNewMap()) {
            const $mapLegend = jQuery(ReactDOM.findDOMNode(this.$mapLegend));

            let heightLegend = 20;

            if (this.isExporter()) {
                if ($mapLegend.height() > 20 && !jQuery('#DashGridComponent').length > 0) {
                    heightLegend = $mapLegend.height();
                } else {
                    heightLegend = 30;
                }
            }

            $map.height(mapContainerHeight - heightLegend + this.bottomHeight());
        } else {
            $map.height(mapContainerHeight);
        }
    }

    bottomHeight = () => {
        if (this.props.location === 'cockpit') {
            return application.page.isMobile()
                ? 10
                : 20
        }

        return -15;
    }

    componentDidUpdate(prevProps) {
        this.props.updateSelectedLegends(this.state.legend);

        if (!_.isEqual(this.props, prevProps)) {
            const updateFn = () => {
                this.clearMarkers();
                this.fetchData(this.props);
            };
            if (this.props.enableHeatMap !== prevProps.enableHeatMap || this.props.showMarkers !== prevProps.showMarkers) {
                this.setState({
                    enableHeatMap: this.props.enableHeatMap,
                    showMarkers: this.props.showMarkers
                }, updateFn);
            } else {
                updateFn();
            }
        } else {
            this.setupSizes();
        }
    }

    clearMarkers(clearHeatMap = true) {
        if (this.state.ploted) {
            this.lMap.eachLayer(layer => {
                if ('_appLayer' in layer && (!('_heatMap' in layer) || clearHeatMap)) {
                    this.lMap.removeLayer(layer);
                }
            });
        }
    }

    componentWillUnmount() {
        this.markers = null;
        this.lMap.remove();
        this.lMap = null;
        jQuery(this.container).remove();
        delete window.LEAFLET_MAP_REGISTRY[this.id];
    }

    legendItemToggled = (item, idx) => {
        const legend = this.state.legend;
        const cItem = legend[idx];
        cItem.selected = !cItem.selected;
        this.setState({legend});
        this.plotMapMarkers(legend, this.state.ploted, this.state.showMarkers, this.state.enableHeatMap);
    };

    plotMapMarkers = (legend, plotted, showMarkers = true, enableHeatMap = false) => {
        this.clearMarkers();

        const heatData = [];

        legend
            .filter(l => l.selected)
            .forEach(l => {
                (plotted[l.icon] || []).forEach(i => {
                    if (showMarkers) {
                        i.properties?.marker?.addTo(this.lMap);
                    }
                    if (enableHeatMap) {
                        heatData.push({feature: i, latlng: i.properties.marker.getLatLng()});
                    }
                });
            });

        if (enableHeatMap) {
            this.createHeatLayer(heatData);
        }
    };

    invalidateSize() {
        if (this.lMap) {
            this.lMap.invalidateSize();
        }
    }

    getPath() {
        return this.props.path;
    }

    readMapState() {
        const map = this.lMap;
        return {
            center: map.getCenter(),
            zoom: map.getZoom()
        };
    }

    setView(center, zoom) {
        if(Array.isArray(center)) {
            center = {
                lat: center[0],
                lng: center[1]
            };
        }

        this.lMap.setView(L.latLng(center.lat, center.lng), zoom);
    }

    changeMobileStyle = () => {
        const desktopClassName = 'leaflet-top leaflet-left';
        const mobileClassName = 'leaflet-top leaflet-left mobile';
        if (this.props.mobile) {
            const leftLeaf = Array.from(this.$container.getElementsByClassName(desktopClassName));
            if (leftLeaf.length > 0) {
                leftLeaf.pop().className = mobileClassName;
            }
        } else {
            const leftLeaf = Array.from(this.$container.getElementsByClassName(mobileClassName));
            if (leftLeaf.length > 0) {
                leftLeaf.pop().className = desktopClassName;
            }
        }
    }

    render() {
        return (
            <div className="LeafletMap legacy-map" ref={ref => this.$container = ref}>
                <div ref={ref => this.$mapContainer = ref} className={`map-container leafmap-${this.id}`}/>
                {!this.isExporter() &&
                    <div className={`MapControls leaflet-touch ${this.props.mobile ? 'mobile' : ''}`}>
                        <div className="leaflet-bar leaflet-control">
                            <a href="#" onClick={this.toggleMarkers}
                               className={this.state.showMarkers ? '' : 'disabled'}>
                                <i className={'icon icon-map-marker'}></i>
                            </a>
                            <a href="#" onClick={this.toggleHeatMapLayer}
                               className={this.state.enableHeatMap ? '' : 'disabled'}>
                                <i className={'icon icon-fire'}></i>
                            </a>
                        </div>
                    </div>
                }
                <Portal
                    container={() => {
                        if (this.isExporter()) {
                            const lc = document.getElementById('legend-container');
                            if (lc) {
                                return lc;
                            }
                        }
                        return this.$container;
                    }}>
                    <Legend description={this.props.description}
                            ploted={this.state.ploted}
                            onToggle={this.legendItemToggled}
                            legend={this.state.legend}
                            useCircles={this.props.useCircles}
                            stats={this.state.legendStats}
                            onUpdate={async () => {
                                if (!this.props.useCircles) {
                                    await Promise.all(iconLoads);
                                }
                                this.setupSizes();
                            }}
                            ref={ref => this.$mapLegend = ref}
                            mobile={this.props.mobile}
                            isPresentation={this.props.isPresentation}
                            showCaption={this.props.showCaption}
                            exportView={this.props.exportView}
                    />
                </Portal>
            </div>
        );
    }

    createHeatLayer(rawData = []) {
        if (this._heatLayer) {
            this.lMap.removeLayer(this._heatLayer);
            this._heatLayer = null;
        }


        if (_.isEmpty(rawData)) {
            rawData = [];
            this.state.legend
                .filter(l => l.selected)
                .forEach(l => {
                    !_.isEmpty(this.state.ploted[l.icon]) && this.state.ploted[l.icon].forEach(i => {
                        rawData.push({feature: i, latlng: i.properties.marker.getLatLng()});
                    });
                });
        }

        const data = [];

        let max = Number.MIN_VALUE;

        rawData.forEach(({feature, latlng}) => {
            let intensity = _.get(feature, 'properties.data.heatMapIntensity.value', 0.2);
            max = Math.max(max, intensity);
            data.push([latlng.lat, latlng.lng, intensity]);
        });

        if (max < 1) {
            max = 1;
        }

        data.forEach(p => {
            p[2] = p[2] / max;
        });

        const heatLayer = L.heatLayer(data, {
            blur: this.props.heatMapBlur,
            radius: this.props.heatMapRadius,
            minOpacity: this.props.heatMapMinOpacity / 100,
        });

        heatLayer._appLayer = true;
        heatLayer._heatMap = true;
        this._heatLayer = heatLayer;
        heatLayer.addTo(this.lMap);
    }

    toggleMarkers = (e) => {
        e.preventDefault();
        const {showMarkers, enableHeatMap} = this.state;
        if (showMarkers) {
            this.clearMarkers(false);
        } else {
            this.plotMapMarkers(this.state.legend, this.state.ploted, true, enableHeatMap);
        }
        this.setState({showMarkers: !showMarkers});
    };

    toggleHeatMapLayer = (e) => {
        e.preventDefault();
        const {enableHeatMap} = this.state;
        if (enableHeatMap) {
            this.lMap.removeLayer(this._heatLayer);
            this._heatLayer = null;
        } else {
            this.createHeatLayer()
        }
        this.setState({enableHeatMap: !enableHeatMap});
    };

    fetchData(props) {
        const isExporter = this.isExporter();
        const markers = {};
        const stats = {
            total: 0,
            icon: {}
        };

        const {enableHeatMap} = this.state;

        const styleTagId = `leafmap-${this.id}-s`;
        jQuery(`#${styleTagId}`).remove();
        const updateSizeFn = (e) => {
            if (jQuery(`#${styleTagId}`).length > 0 || e.popup._content.indexOf('<table>') === -1) return;
            let container = e.popup._container;
            jQuery(`<style id="leafmap-${this.id}-s" type="text/css"> .leafmap-${this.id} .leaflet-popup { width: ${container.clientWidth * 1.25}px; } </style>`).appendTo('body');
            setTimeout(() => e.popup.update(), 100);
        };

        const heatMapData = [];
        const opts = {
            onEachFeature: (feature, layer) => {
                if (feature.properties && !_.isEmpty(feature.properties.popupContent)) {
                    layer.bindPopup(feature.properties.popupContent, {
                        maxWidth: 'auto'
                    }).on('popupopen', updateSizeFn);
                }
            },
            pointToLayer: (feature, latlng) => {
                stats.total++;

                if (enableHeatMap) {
                    heatMapData.push({feature, latlng});
                }

                const itemIcon = feature.properties.icon || DEFAULT_ICON;
                const itemKey = _.isString(itemIcon) ? itemIcon : itemIcon.color;
                if (!stats.icon[itemKey]) {
                    stats.icon[itemKey] = 1;
                } else {
                    stats.icon[itemKey]++;
                }

                if (isExporter && !this.lMap.getBounds().contains(latlng)) {
                    return null;
                }

                if (!markers[itemKey]) {
                    markers[itemKey] = [];
                }

                markers[itemKey].push(feature);
                const markerOpts = {
                    keyboard: false
                };

                const applyTitle = !isExporter && feature.properties.title;

                if (this.props.useCircles) {
                    markerOpts.radius = 5;
                    markerOpts.stroke = false;
                    markerOpts.fillOpacity = 0.7;
                    markerOpts.color = itemKey;
                    feature.properties.marker = L.circleMarker(latlng, markerOpts);
                    if (applyTitle) {
                        const onClick = () => {
                            feature.properties.marker.bindTooltip().closeTooltip();
                        }
                        feature.properties.marker
                            .bindTooltip(feature.properties.title, {direction: 'top'})
                            .bindPopup(feature.properties.title, {direction: 'top'})
                            .on('popupopen', onClick);
                    }
                } else {
                    if (applyTitle) {
                        markerOpts.title = feature.properties.title;
                    }
                    markerOpts.icon = makeIcon(itemIcon);
                    feature.properties.marker = L.marker(latlng, markerOpts);
                }

                feature.properties.marker._appLayer = true;
                return feature.properties.marker;
            }
        };
        if (isExporter) {
            delete opts.onEachFeature;
        }

        L.geoJSON(props.geoJSON, opts);

        if (enableHeatMap) {
            this.createHeatLayer(heatMapData);
        }

        const legend = this.buildLegends(props, stats);

        legend.filter(l => l.selected)
            .forEach(l => {
                (markers[l.icon] || []).forEach(i => {
                    if (this.state.showMarkers) {
                        i.properties.marker.addTo(this.lMap);
                    }
                });
            });

        this.setState({
            ploted: markers,
            legend,
            legendStats: stats
        });
    }

    // Build legend
    buildLegends(props, stats) {
        const legend = props.legend.map((el, idx) => {
            const stateLegend = this.state.legend.find(l => l.uniqueName === el.uniqueName);
            return {
                ...el,
                id: idx,
                markers: stats.icon[el.icon] || 0,
                selected: this.state.legend.length > 0 && stateLegend ? stateLegend.selected : el.selected,
                icon: el.icon
            }
        });

        if(legend.length === 0) {
            Object.keys(stats.icon).forEach((key,idx) => {
                legend.push({
                    id: idx,
                    icon: key,
                    description: '',
                    markers: stats.icon[key],
                    selected: true
                });
            });
        }
        return legend;
    }

}

LeafletMap.renderCircle = Legend.renderCircle;

LeafletMap.emptyGeoJson = createEmptyGeoJSON;

export default LeafletMap;