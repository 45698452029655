import React, {Component} from "react";
import PropTypes from "prop-types";
import BlockUi from "react-block-ui";

import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import {DefaultDialogActions, Field, Validations, wrapForm} from "components/ui/FormUtils";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import Checkbox from "components/ui/Checkbox";
import BngRadio from "components/bng/form/BngRadio";
import Utils from "components/Utils";
import {MODALS} from "components/ui/redux/Actions";
import {NewFolderDialog} from "components/bng/folder/NewFolderDialog";
import Button from "components/ui/Button";
import BngTreeDropdown from "components/bng/form/BngTreeDropdown";

class MenuDashboardMenuItemDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        path: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        mobile: PropTypes.bool
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true),
        path: '',
        name: '',
        description: '',
        mobile: true
    };

    state = {
        availableFolders: [],
        loading: false,
        value: true,
        isChartChecked: false,
        folder: null,
        folderError: null,
    };

    constructor(props) {
        super(props);
        this.state.isChartChecked = this.props.beanActiveTab === 0;
    }

    componentDidMount() {
        this.props.initialize({
            name: this.props.name,
            description: this.props.description,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({availableFolders: nextProps.folders});
    }

    save = async (newDashboard) => {
        this.setState({loading: true});
        try {
            if(!this.state.folder) {
                this.setState({
                    folderError: true,
                })
                return;
            }

            newDashboard.folder = this.state.folder;
            newDashboard.dashboardItemPath = this.props.path;
            newDashboard.mobile = this.state.value;

            const dash = await Api.Dash.newDashboardWithObject(newDashboard, this.state.isChartChecked);
            await Api.updateJsf();
            this.props.closeModal();
            window.location = dash;
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e)
        } finally {
            this.setState({loading: false});
        }
    };

    handleChange = (event) => {
        const value = event.target.checked;
        this.setState({value});
    };

    handleChangeFolder = (fieldName, value) => {
        this.setState({[fieldName]: value});
        if(fieldName === 'folder') {
            this.setState({
                folderError: _.isEmpty(value)
            });
        }
    }

    render() {
        if (!this.props.initialized) return null;

        const {availableFolders, loading} = this.state;

        const isChartValue = this.state.isChartChecked ? 'chart' : 'table';
        const showFolderError = (this.props.submitFailed || this.state.folderError) && !this.state.folder;
        return (
            <Dialog open={this.props.open}
                    title={this.props.context.msg.t('new.dashboard')}
                    onClose={this.props.closeModal}
                    loading={loading}>
                <BlockUi tag="div">
                    <form onSubmit={this.props.handleSubmit(this.save)}>
                        <div className="row-fluid field">
                            <div className="span12 d-flex">
                                <div className={`form-group ${showFolderError ? 'has-error' : ''} w-100 mb-1`}>
                                    <label className="control-label">{this.props.context.msg.t('folder')}</label>
                                    <BngTreeDropdown name="folder"
                                                     className="form-control"
                                                     dataTree={availableFolders}
                                                     field={{
                                                         name: "folder",
                                                         value: this.state.folder,
                                                     }}
                                                     form={{
                                                         setFieldValue: this.handleChangeFolder
                                                     }}
                                                     initialValue={this.state.folder}
                                                     selectFolders
                                    />
                                    {showFolderError &&
                                    <span className="help-block mb-0 mt-1">
                                        {this.props.context.msg.t('javax.faces.component.UIInput.REQUIRED')}
                                    </span>
                                    }
                                </div>
                                <Button disabled={_.isEmpty(this.state.folder)}
                                        className="button-create-folder ml-2"
                                        icon="add"
                                        title={`${this.props.context.msg.t('button.create.icon.title')}`}
                                        style={{height: '42px', top: '24px'}}
                                        onClick={() => {
                                            this.props.dispatch(
                                                MODALS.open(NewFolderDialog, {
                                                    parentFolder: this.state.folder,
                                                    onCreateFolder: folderData => {
                                                        this.props.initialize({
                                                            folder: folderData.path,
                                                        })
                                                    },
                                                })
                                            )
                                        }}
                                />
                            </div>
                        </div>

                        <div className="row-fluid field">
                            <div className="span12">
                                <Field style={{width: 510}}
                                       name="name"
                                       autoComplete="off"
                                       className="input-xxlarge"
                                       label={`${this.props.context.msg.t('name')}:`}
                                       validate={Validations.required}
                                       maxLength={100}
                                       type="text"
                                />
                            </div>
                        </div>

                        <div className="row-fluid">
                            <div className="span12">
                                <Field style={{width: 510}}
                                       name="description"
                                       autoComplete="off"
                                       label={`${this.props.context.msg.t('description')}:`}
                                       validate={Validations.required}
                                       className="input-xxlarge no-resize"
                                       rows={5}
                                       maxLength={1024}
                                       componentClass="textarea"
                                />
                            </div>
                        </div>
                        <div>
                            {(Utils.Object.isAnalysis(this.props.path) && this.props.showChart && this.props.showTable) &&
                                <div className="AddToDashRadioButtons flex-center-items mb-2">
                                    <label>{this.props.context.msg.t('visualization')}:</label>
                                    <BngRadio
                                        name="chart"
                                        label={this.props.context.msg.t('configureDashboard_selectMenu_chart')}
                                        field={{value: isChartValue}}
                                        value="chart"
                                        form={{setFieldValue: () => this.setState({isChartChecked: true})}}
                                    />
                                    <BngRadio
                                        name="chart"
                                        label={this.props.context.msg.t('tab_data')}
                                        field={{value: isChartValue}}
                                        value="table"
                                        form={{setFieldValue: () => this.setState({isChartChecked: false})}}
                                    />
                                </div>
                            }
                        </div>

                        <div className="row-fluid">
                            <div className="span12">
                                <Checkbox name="mobile"
                                          className="p-0"
                                          value={{
                                              value: this.state.value,
                                              label: this.props.context.msg.t('avaliable.on.mobile')
                                          }}
                                          selected={this.state.value}
                                          onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        <hr/>

                        <DefaultDialogActions
                            {...this.props}
                        />
                    </form>
                </BlockUi>
            </Dialog>
        );
    }

}

export default wrapForm({
    component: ContextEnhancer(MenuDashboardMenuItemDialog),
    initialValues: {
        folder: '',
        name: '',
        description: ''
    },
    name: 'menuDashboardMenuItemDialog'
});