import React from 'react';
import Api from "components/Api";
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import BimEventBus from 'BimEventBus';
import DashGridEvents from 'components/ui/dashboard/components/DashGridEvents';

class DashboardItemMenuClearItem extends React.Component{

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    clearItemOverride = async () => {
        if (application.ice.isLoaded()) {
            Api.executeExp(`#{dashboardBean.clearItemOverride('${this.props.item.id}')}`);
        } else {
            BimEventBus.emit(DashGridEvents.RENDER_IN_PLACE, {
                action: 'CLEAR',
                item: this.props.item
            });
        }
    };

    render() {

        return (
            <li className={'clear-item'}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('restore.default.view')}
                                        onClick={this.clearItemOverride}
                                        icon="icon-eraser"
                />
            </li>
        );
    }
}

export default ContextEnhancer(DashboardItemMenuClearItem);