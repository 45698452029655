import React from "react";
import DashGrid from "components/ui/dashboard/components/DashGrid";
import MobileMenu from "components/ui/dashboard/components/MobileMenu";
import DashboardItemMenu from "components/ui/dashboard/components/DashboardItemMenu";
import ContainerMenuWrapper from "components/ui/dashboard/components/ContainerMenuEditor";

class DashGridEditor extends React.Component {

    render() {
        return (
            <DashGrid ref={ref => this.dashGridRef = ref}
                      {...this.props}
                      mobileMenuComponent={MobileMenu}
                      dashboardItemMenuComponent={DashboardItemMenu}
                      containerMenuComponent={ContainerMenuWrapper}
            />
        )
    }
}

export default DashGridEditor;