import './CockpitReactSwitch.css';

import React, { useState } from 'react';

import EditObject from 'components/ui/edit-object/EditObject';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { MODALS } from 'components/ui/redux/Actions';
import TicketForm from 'components/ui/support/TicketForm';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export default function CockpitReactSwitch({ className = '' }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [cockpitReact, setCockpitReact] = useState(window.location.href.includes(Api.Cockpit.cockpitReactUri));

  if (!context.bimResources.cockpitReact) {
    return null;
  }

  return (
    <div className={`CockpitReactSwitch flex-center-items ${className}`}>
      <EditObject
        className="ToggleBtn"
        title={context.msg.t('LabsFeature.COCKPIT_REACT.label')}
        checked={cockpitReact}
        onChange={async (e) => {
          setCockpitReact(!cockpitReact);
          const uri = Api.Cockpit.findCockpitUri(!cockpitReact);
            setTimeout(() => {
                window.location.replace(`${Api.baseUrl()}${uri}${window.location.search}`);
            }, 200)
        }}
      />
      <BngIconButton
        className="FeedbackBtn"
        icon={'support_agent'}
        title={context.msg.t('cockpit.beta.feedback.title')}
        onClick={() =>
          dispatch(
            MODALS.open(TicketForm, {
              title: context.msg.t('cockpit.beta.feedback.ticket.title'),
              type: '00e22d60debbcb79e1dc109c379c4a14',
              priority: 1,
            })
          )
        }
      />
    </div>
  );
}
