import { Axios } from 'commonUtils';
import Utils from 'components/Utils';

class BimIntegrationApi {
  findInfoEndpoint = Utils.Cache.memoize(async (endpoint) => {
    const { data } = await Axios.get(`/spr/bimintegration/endpoint/info`, { params: { endpoint } });
    return data;
  });

  findEndpoints = Utils.Cache.memoize(async () => {
    const { data } = await Axios.get(`/spr/bimintegration/integrations`);
    return data;
  });

  findExternalEndpoints = Utils.Cache.memoize(async () => {
    const { data } = await Axios.get(`/spr/bimintegration/external-integrations`);
    return data;
  });

  validateCredentials = async (config) => {
    const { data } = await Axios.post(`/spr/bimintegration/validate-credentials`, config);
    return data;
  };

  invalidateCredentials = async () => {
    await Axios.post(`/spr/bimintegration/invalidate-credentials`);
  };

  addWsParams = async ({ connectionId = 0, ignoreLoadErrors = true, props = {}, fromScheduler = false }) => {
    await Axios.post(
      `/spr/bimintegration/params`,
      {
        connectionId,
        ignoreLoadErrors,
        props,
      },
      { params: { fromScheduler } }
    );
  };

  updateForm() {
    return new Promise((res) => {
      if (!application.ice.isLoaded()) {
        res();
      }
      const listenerName = 'updateDataOriginLoadDataInfoButton-listener';
      if (application.ice.containListener(listenerName)) {
        res();
        return;
      }

      application.ice.addNewListener(listenerName, _.noop, function () {
        application.ice.removeListener(listenerName);
        res();
      });

      const btn = jQuery('.updateDataOriginLoadDataInfoButton')[0];
      if (btn) {
        btn.click();
      }
    });
  }

  findOauthConfigs = async () => {
    return await Axios.getData(`/spr/bimintegration/oauth-configs`);
  };

  findMetaAccounts = async (token) => {
    return await Axios.getData('/spr/bimintegration/meta/accounts', {
      params: { token },
    });
  };

  findExcelWorksheets = async (token) => {
    return await Axios.getData('/spr/bimintegration/excel/worksheets', {
      params: { token },
    });
  };

  testGAParam = async (param = {refresh_token: '', dimensions: '', metrics: ''}) => {
    return await Axios.getData('/spr/origins/google/testGa', {
      params: { ...param },
    });
  };
}

export default BimIntegrationApi;
