import "./DashboardIconForm.css";

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog";
import {DefaultDialogActions} from "components/ui/FormUtils";
import Api from "components/Api";
import {promiseHandler} from "./common";
import {MODALS} from "components/ui/redux/Actions";
import {ErrorMessage, Field, Formik} from "formik";
import {BngField} from "components/bng/form/BngField";
import {BngSelect} from "components/bng/form/BngSelect";
import Icon from "components/ui/common/Icon";
import SelectIconDialog from "components/ui/common/SelectIconDialog";
import BngInputColor from "components/bng/form/BngInputColor";
import IconText from "components/ui/dashboard/components/IconText";
import {bngYup} from "components/bng/form/yup/BngYup";
import {ContentContainer} from "components/bng/ui/ContentContainer";
import FilterBar from "components/filter/FilterBar";
import FilterService from "components/filter/FilterService";
import {BngForm} from "components/bng/form/BngForm";
import BngCheckbox from "components/bng/form/BngCheckbox";
import Utils from "components/Utils.js";
import SelectObjectDialogContainer from "components/ui/common/SelectObjectDialogContainer";
import FilterDialog from 'components/filter/FilterDialog';
import {BngDropdown} from 'components/bng/ui/BngDropdown';
import BngSearch from 'components/bng/ui/BngSearch';
import UiMsg from "components/ui/UiMsg";
import { VALID_IMAGE_TYPES } from 'components/bng/pages/newAnalysis/paramTypes/KpiImage';
import UiBlocker from 'components/bng/ui/UiBlocker';

const textLimit = 30;


const ActionTypes = {
    NONE: 'NONE',
    OPEN_LINK: 'OPEN_LINK',
    OPEN_OBJECT: 'OPEN_OBJECT',
    APPLY_FILTER: 'APPLY_FILTER',
    EXPORT_OBJECT: 'EXPORT_OBJECT',
};


const FONTS = [
    {value: 'font-family-roboto', label: 'Roboto'},
    {value: 'font-family-open-sans', label: 'Open Sans'},
    {value: 'font-family-heveltica-neue', label: 'Heveltica Neue'},
    {value: 'font-family-georgia', label: 'Georgia'},
    {value: 'font-family-palatino-linotype', label: 'Palatino Linotype'},
    {value: 'font-family-times-new-roman', label: 'Times New Roman'},
    {value: 'font-family-arial', label: 'Arial'},
    {value: 'font-family-arial-black', label: 'Arial Black'},
    {value: 'font-family-comic-sans', label: 'Comic Sans MS'},
    {value: 'font-family-impact', label: 'Impact'},
    {value: 'font-family-lucida-sans-unicode', label: 'Lucida Sans Unicode'},
    {value: 'font-family-tahoma', label: 'Tahoma'},
    {value: 'font-family-trebuchet', label: 'Trebuchet MS'},
    {value: 'font-family-verdana', label: 'Verdana'},
    {value: 'font-family-courier-new', label: 'Courier New'},
    {value: 'font-family-lucida-console', label: 'Lucida Console'},
];


const FONT_SIZE = _.range(8, 41).map(value => ({value, label: `${value}`}));

FONT_SIZE.unshift({value: 0, label: 'Auto'});

const ExportTypes = {
    PDF: 'PDF',
    EXCEL: 'EXCEL',
    CSV: 'CSV',
};


const LabelFontStyle = [
    {value: 'normal', label: 'label.font.normal'},
    {value: 'italic', label: 'label.font.italic'},
    {value: 'bold', label: 'label.font.bold'},
    {value: 'underline', label: 'label.font.underline'}
];


const ExportTypesList = [
    {value: ExportTypes.PDF, label: ExportTypes.PDF, visible: () => true},
    {value: ExportTypes.EXCEL, label: ExportTypes.EXCEL, visible: (path) => Utils.Object.isAnalysis(path)},
    {value: ExportTypes.CSV, label: ExportTypes.CSV, visible: (path) => Utils.Object.isBigTable(path)},
];

const ActionTypesList = [
    {value: ActionTypes.NONE},
    {value: ActionTypes.OPEN_LINK},
    {value: ActionTypes.OPEN_OBJECT},
    {value: ActionTypes.APPLY_FILTER},
    {value: ActionTypes.EXPORT_OBJECT},
];

const Positions = ['TOP', 'RIGHT', 'BOTTOM', 'LEFT',];

const IconContentSchema = bngYup(yup => {
    return yup.object().shape({
        label: yup.string().max(30).trim().default(''),
        labelFont: yup.string().required().trim().default(FONTS[0].value),
        labelFontSize: yup.number().default(FONT_SIZE[0].value),
        labelFontStyle: yup.string().required().trim().default(LabelFontStyle[0].value),
        labelColor: yup.string().trim(),
        icon: yup.string().trim().default('sentiment_very_satisfied'),
        color: yup.string().trim(),
        position: yup.string().oneOf(Positions).default('BOTTOM'),
        action: yup.string().max(30).oneOf(Object.values(ActionTypes)).default('NONE'),
        actionProps: yup.object({
            applyFilters: yup.boolean().default(false),
            exportType: yup.string().oneOf(Object.values(ExportTypes)).default('PDF'),
            link: yup.string().url(),
            path: yup.string(),
            filters: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    members: yup.array(),
                })
            )
        }).default({
            link: '',
            path: '',
            filters: [],
        })
    });
});

class DashboardIconForm extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        findFilters: PropTypes.func,
        findDashboardPath: PropTypes.func,
        findStyle: PropTypes.func,
        iconItem: PropTypes.object,
    };

    static defaultProps = {
        itemHash: 0,
        filters: [],
        findFilters: () => window.___LAST_DASHBOARD_OPTS.availableFilters,
        findDashboardPath: () => window.___LAST_DASHBOARD_OPTS.dashboardPath,
        findStyle: () => window.___LAST_DASHBOARD_OPTS.style,
        iconItem: null,
        availableFilters: [],
    };

    state = {
        loading: false,
        previewLoading: false,
        filters: [],
        boundariesElement: document.getElementById('page-content'),
        filterMembers: [],
        searchTerm: '',
    };


    constructor(props, context) {
        super(props, context);
        this.initialValues = IconContentSchema.default();
        Object.assign(
            this.initialValues,
            this.findDefaultColors()
        );
        if (this.props.iconItem) {
            const actionProps = this.initialValues.actionProps;
            this.initialValues = _.merge({}, this.initialValues, this.props.iconItem);
            this.initialValues.actionProps = this.initialValues.actionProps || actionProps;
            this.initialValues.labelFontSize = `${this.initialValues.labelFontSize}`
        } else {
            props.findFilters()
                .filter(f => !_.isEmpty(f.selectedMembers))
                .forEach(f => {
                    this.initialValues.actionProps.filters.push({
                        id: f.id,
                        members: f.selectedMembers.map(m => m.value)
                    })
                });
        }
    }

    fetchFilters = async (currentFilters, currentObjectDatasource) => {
        this.setState({loading: true});

        try {
            const globalFilters = await Api.MdxGlobalFilter.findAll(this.props.context.project.id, true);
            const filterMembers = globalFilters.filter(filter => {
                return !currentFilters.some(stateFilter => stateFilter.id === filter.id)
            }).map(filter => {
                const isApplicable = !!filter.mdxFilters.find((mdxFilter) => {
                    return currentObjectDatasource ? currentObjectDatasource === mdxFilter.datasource : true;
                });

                return {
                    ...filter,
                    label: filter.displayName,
                    value: filter.id,
                    type: filter.type,
                    visible: true,
                    selectedMembers: [],
                    isApplicable: isApplicable,
                }
            });

            this.setState({filterMembers});
        } catch (e) {
            console.error('Error on fetchFilters()', e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    }

    openFilterDialog = async (filterId, onSelect) => {
        try {
            const selectedFilter = this.state.filterMembers.find(filter => filter.value === filterId);
            const isTimeFilter = selectedFilter.type === 'TIME';
            const filterMembers = await Api.Filter.findMembers(filterId);

            const filterDialogProps = {
                filter: selectedFilter,
                enableModeChange: isTimeFilter,
                onChange: async (data, force, additionalProps) => {
                    await onSelect(selectedFilter, data);
                    additionalProps.closeModal();
                },
                showModal: isTimeFilter,
                items: filterMembers.members,
                dynamicItems: isTimeFilter ? filterMembers.dynamicMembers : [],
                maxElements: 20,
                onClose: this.closeDialog,
                availableDateRange: filterMembers.availableDateRange,
                containRestriction: filterMembers.containRestriction,
                restrictionType: filterMembers.restrictionType,
                restrictionMembers: filterMembers.restrictionMembers,
                dataRestrictionMembers: filterMembers.dataRestrictionMembers,
                disabled: false,
            }

            window.ReduxStore.dispatch(
                MODALS.open(FilterDialog, filterDialogProps)
            );
        } catch (e) {
            console.error('Error on openFilterDialog()', {filterId}, e);
            UiMsg.ajaxError(null, e);
        }
    }

    filtersForUpdate = async (filterInfo, members, currentFilters) => {
        try {
            const filterDetailed = await Api.MdxGlobalFilter.findOne(filterInfo.id);
            filterDetailed.caption = filterDetailed.displayName;
            filterDetailed.selectedMembers = members;
            filterDetailed.notFromDash = true;
            return currentFilters.concat([filterDetailed]);
        } catch (e) {
            console.error('Error on filtersForUpdate()', {filterInfo, members, currentFilters}, e);
        }
    }

    findDefaultColors = () => {
        const style = this.props.findStyle();
        const backgroundTheme = style.backgroundTheme;

        let color;
        let labelColor;
        if (['CORPORATIVE', 'BLACK'].includes(backgroundTheme)) {
            color = '#FFFFFF';
            labelColor = '#FFFFFF';
        } else {
            color = '#333333';
            labelColor = '#333333';
        }

        return {
            color,
            labelColor
        };
    }

    searchForPathInfo = (path, item) => {
        if (!item) {
            for (const folder of this.props.folders) {
                const result = this.searchForPathInfo(path, folder);
                if (result) {
                    return result;
                }
            }
        }

        if (item) {
            if (item.leaf) {
                if ((item.path || item.href) === path) {
                    return item;
                }
            } else {
                for (const child of item.children) {
                    const result = this.searchForPathInfo(path, child);
                    if (result) {
                        return result;
                    }
                }
            }
        }

    };

    transformFilterResult(filter) {
        return {
            id: filter.id,
            members: filter.selectedMembers.map(member => member.value)
        }
    }

    save = async (values, actions) => {
        values = _.cloneDeep(values);

        try {
            switch (values.action) {
                case ActionTypes.OPEN_LINK: {
                    if (_.isEmpty(_.get(values, 'actionProps.link'))) {
                        actions.setFieldError('actionProps.link', this.props.context.msg.t('yup.mixed.required'));
                        return;
                    }
                    break;
                }
                case ActionTypes.OPEN_OBJECT: {
                    if (_.isEmpty(_.get(values, 'actionProps.path'))) {
                        const msg = this.props.context.msg.t('select.object.to.open');
                        actions.setFieldError('actionProps.path', msg);
                        return;
                    }
                    break;
                }
                case ActionTypes.APPLY_FILTER: {
                    if (_.isEmpty(_.get(values, 'actionProps.filters'))) {
                        const msg = this.props.context.msg.t('select.at.least.one.filter');
                        actions.setFieldError('actionProps.filters', msg);
                        return;
                    }
                    break;
                }
                case ActionTypes.EXPORT_OBJECT: {
                    if (_.isEmpty(_.get(values, 'actionProps.path'))) {
                        const msg = this.props.context.msg.t('select.object.to.export');
                        actions.setFieldError('actionProps.path', msg);
                        return;
                    }
                    break;
                }
            }
            values.hashCode = _.get(this.props, 'iconItem.itemHash', 0);
            if (values.actionProps.filterIds === null) {
                values.actionProps.filterIds = []
            }

            values.actionProps.filters = values.actionProps.filters
              .filter(filter => values.action === ActionTypes.APPLY_FILTER ? !filter.notFromDash : true)
              .map(filter => {
                  return this.transformFilterResult(filter);
              });
            await Api.Dash.updateIconItem(values);
            UiMsg.ok(this.props.context.msg.t('changes.applied.successfully'));
            await Api.updateJsf();
            this.props.closeModal();
        } catch (e) {
            console.error('Error on DashboardIconForm.save()', {values}, e);
            UiMsg.ajaxError(null, e);
        } finally {
            actions.setSubmitting(false);
        }
    };

    async componentDidMount() {
        this.setState({loading: true});
        try {
            let filters = JSON.deepClone(this.props.findFilters());
            if (this.props.iconItem) {
                const currentFilters = _.get(this, 'initialValues.actionProps.filters', []);
                for (const filter of currentFilters) {
                    let filterDetailed = filters.find(f => f.id === filter.id);
                    if (!filterDetailed) {
                        const members = filter.members.map(m => ({
                            value: m,
                            label: m
                        }));
                        filters = await this.filtersForUpdate(filter, members, filters);
                    } else {
                        filterDetailed.selectedMembers = filterDetailed ? filter.members.map(m => ({
                            value: m,
                            label: m
                        })) : [];
                    }
                }
            }
            const transformedFilters = FilterService.transform(filters);
            this.setState({
                filters: transformedFilters
            });
            this.initialValues.actionProps.filters = transformedFilters;
        } finally {
            this.setState({loading: false});
        }
    }

    handleSearch(value) {
        this.setState({searchTerm: value});
    }

    render() {
        return (
            <Formik initialValues={this.initialValues}
                    validationSchema={IconContentSchema}
                    onSubmit={this.save}>
                {({values, setFieldValue, isSubmitting}) => {

                    const selectedObject = _.get(values, 'actionProps.path', '');
                    const objectInfo = values.action === ActionTypes.OPEN_OBJECT || values.action === ActionTypes.EXPORT_OBJECT && selectedObject ? this.searchForPathInfo(selectedObject) : {};

                    const length = _.get(values, 'label.length', 0);

                    const darkBackground = (values.color === '#FFFFFF' || values.labelColor === '#FFFFFF');

                    const disableExportType = !Utils.Object.isAnalysis(values.actionProps.path) && !Utils.Object.isBigTable(values.actionProps.path);
                    const showFilterSelection = values.action === ActionTypes.APPLY_FILTER
                        || ((values.action === ActionTypes.OPEN_OBJECT || values.action === ActionTypes.EXPORT_OBJECT) && values.actionProps.applyFilters);

                    const isApplyFilterAction = values.action === ActionTypes.APPLY_FILTER;
                    const formFilters = isApplyFilterAction
                      ? values.actionProps.filters.filter(filter => !filter.notFromDash)
                      : values.actionProps.filters;
                    const filterIds = values.actionProps.filterIds || [];

                    return (
                        <Dialog open={this.props.open}
                                newDialogLayout={true}
                                className="DashboardIconForm dash-icon-component"
                                title={this.props.context.msg.t('icon.component.dialog.title')}
                                onClose={this.props.closeModal}
                                loading={this.state.loading || isSubmitting}>
                            <BngForm>
                                <Dialog.Body>
                                    <div className="row-fluid">
                                        <div className="span8 Data">
                                            <div>
                                                <label>
                                                    {this.props.context.msg.t('text')}
                                                </label>
                                                <label className="textLength">
                                                    <span className="textLengthSpan">{`${length}/${textLimit}`}</span>
                                                </label>
                                                <Field name="label"
                                                       withLabel={false}
                                                       component={BngField}
                                                       maxLength={textLimit}
                                                />
                                            </div>

                                            <div className="row-fluid">
                                                <div className="span4">
                                                    <Field name='labelFont'
                                                           label={this.props.context.msg.t('font')}
                                                           component={BngField}
                                                           inputComponent={BngSelect}
                                                           emptyOption={false}
                                                           options={FONTS}
                                                    />
                                                </div>
                                                <div className="span2">
                                                    <Field name='labelFontSize'
                                                           label={this.props.context.msg.t('size')}
                                                           component={BngField}
                                                           inputComponent={BngSelect}
                                                           emptyOption={false}
                                                           options={FONT_SIZE}
                                                    />
                                                </div>
                                                <div className="span3">
                                                    <Field name='labelFontStyle'
                                                           label={this.props.context.msg.t('style')}
                                                           component={BngField}
                                                           inputComponent={BngSelect}
                                                           emptyOption={false}
                                                           options={LabelFontStyle.map(option => ({
                                                               value: option.value,
                                                               label: this.props.context.msg.t(option.label)
                                                           }))}
                                                    />
                                                </div>
                                                <div className="span3 text-center">
                                                    <Field name='labelColor'
                                                           label={this.props.context.msg.t('label.color')}
                                                           component={BngField}
                                                           inputComponent={BngInputColor}
                                                           size="lg"
                                                    />
                                                </div>
                                            </div>


                                            <div className="row-fluid">
                                                <div className="span9">
                                                    <Field name='position'
                                                           component={BngField}
                                                           inputComponent={BngSelect}
                                                           emptyOption={false}
                                                           options={Positions.map(option => ({
                                                               value: option,
                                                               label: this.props.context.msg.t(option)
                                                           }))}
                                                    />
                                                </div>
                                                <div className="span3 text-center">
                                                    <Field name='color'
                                                           label={this.props.context.msg.t('icon.color')}
                                                           component={BngField}
                                                           inputComponent={BngInputColor}
                                                           size="lg"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row-fluid">

                                                <div
                                                    className={values.action === ActionTypes.EXPORT_OBJECT ? 'span8' : 'span12'}>
                                                    <Field name="action"
                                                           label={this.props.context.msg.t('on.click')}
                                                           component={BngField}
                                                           inputComponent={BngSelect}
                                                           emptyOption={false}
                                                           options={ActionTypesList.map(option => ({
                                                               ...option,
                                                               label: this.props.context.msg.t(`IconContent.Action.${option.value}`)
                                                           }))}
                                                    />
                                                </div>
                                                <div className="span4">
                                                    {(values.action === ActionTypes.EXPORT_OBJECT) &&
                                                        <div className="export-analysis-type">
                                                            <Field name="actionProps.exportType"
                                                                   component={BngField}
                                                                   disabled={disableExportType}
                                                                   label={this.props.context.msg.t('export.type')}
                                                                   inputComponent={BngSelect}
                                                                   emptyOption={false}
                                                                   options={ExportTypesList.filter(option => option.visible(values.actionProps.path))}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {values.action === ActionTypes.OPEN_LINK &&
                                                <div>
                                                    <Field name="actionProps.link"
                                                           withLabel={false}
                                                           component={BngField}
                                                           placeholder="Link (Ex: https://google.com)"
                                                    />
                                                </div>
                                            }

                                            {(values.action === ActionTypes.OPEN_OBJECT || values.action === ActionTypes.EXPORT_OBJECT) &&
                                                <React.Fragment>
                                                    <div className="open-object-container">
                                                        <Button
                                                            className={`bng-button ${selectedObject ? 'save' : 'btn-white'}`}
                                                            onClick={() => this.props.dispatch(
                                                                MODALS.open(SelectObjectDialogContainer, {
                                                                    onSelectPath: path => {
                                                                        setFieldValue('actionProps.path', path.itemPath[0]);
                                                                        setFieldValue('actionProps.exportType', 'PDF');
                                                                    },
                                                                    object: selectedObject,
                                                                    required: true,
                                                                })
                                                            )}>

                                                            {!selectedObject &&
                                                                <span>{this.props.context.msg.t('select.obj')}</span>
                                                            }

                                                            {selectedObject &&
                                                                <span>
                                                        {Icon.iconForPath(selectedObject)}
                                                                    {' '}
                                                                    {(objectInfo ? objectInfo.text : '') || Utils.Object.fileName(selectedObject)}
                                                    </span>
                                                            }
                                                        </Button>

                                                        <div className="apply-filters-container">
                                                            <Field name="actionProps.applyFilters"
                                                                   component={BngField}
                                                                   inputComponent={BngCheckbox}
                                                                   asProps={{label: this.props.context.msg.t('apply.filters.in.object.selected')}}
                                                                   withLabel={false}
                                                            />
                                                            <i className="fa fa-info-circle"
                                                               title={this.props.context.msg.t('apply.filters.in.object.description')}/>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="actionProps.path"
                                                                  className="help-inline error"
                                                                  component="span"
                                                    />
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div className="span4 Preview" style={{pointerEvents: this.state.previewLoading ? 'none' : undefined}}>
                                            <label>
                                                {this.props.context.msg.t('preview')}
                                            </label>
                                            <UiBlocker block={this.state.previewLoading}>
                                                <ContentContainer
                                                    className={`IconPreview ${darkBackground ? 'DarkBackground' : ''}`}>
                                                    <IconText icon={values.icon}
                                                              color={values.color}
                                                              label={values.label}
                                                              position={values.position}
                                                              action={values.action}
                                                              actionProps={values.actionProps}
                                                              labelFont={values.labelFont}
                                                              labelFontSize={Number(values.labelFontSize)}
                                                              labelFontStyle={values.labelFontStyle}
                                                              labelColor={values.labelColor}
                                                              onClick={_.noop}
                                                    />
                                                </ContentContainer>
                                            </UiBlocker>

                                            <div className="ChangeIcon"
                                                 onClick={() => this.props.dispatch(
                                                     MODALS.open(SelectIconDialog, {
                                                         icon: {name: values.icon, type: Icon.MATERIAL},
                                                         onSelect: icon => setFieldValue('icon', icon.name),
                                                         canBeEmpty: true,
                                                     })
                                                 )}>
                                                <div className="ChangeIconBg"/>
                                                <div className="ChangeIconText">
                                                    {this.props.context.msg.t('change.icon')}
                                                </div>
                                            </div>

                                            <div className="ChangeIcon Image" onClick={() => this.$imageUploadRef.click()}>
                                                <div className="ChangeIconBg"/>
                                                <div className="ChangeIconText">
                                                    {this.props.context.msg.t('select.image')}
                                                </div>
                                                <input type="file"
                                                    hidden
                                                    ref={ref => this.$imageUploadRef = ref}
                                                    onChange={async (e) => {
                                                      try {
                                                          this.setState({
                                                              previewLoading: true
                                                          });

                                                        const file = e.target.files[0];
                                                        if(!file) {
                                                            return;
                                                        }

                                                        if (!VALID_IMAGE_TYPES.includes(file.type)) {
                                                          UiMsg.warn(this.props.context.msg.t('attention'), this.props.context.msg.t('invalid.image.type'));
                                                          return;
                                                        }

                                                        if (file.size > 2097152) {
                                                          UiMsg.warn(this.props.context.msg.t('attention'), this.props.context.msg.t('image.size.limit.exceded', ['2mb']));
                                                          return;
                                                        }


                                                        const [uploadResult] = await Api.Upload.upload(file, {
                                                          temp: true,
                                                        });

                                                        setFieldValue('icon', uploadResult.path);
                                                      } catch (e) {
                                                        console.error('Error while uploading file', e);
                                                        UiMsg.ajaxError(null, e);
                                                      } finally {
                                                          this.setState({
                                                              previewLoading: false
                                                          });
                                                      }
                                                    }}
                                                    accept={VALID_IMAGE_TYPES.join(',')}
                                                  />
                                            </div>

                                        </div>
                                    </div>
                                    {showFilterSelection &&
                                        <>
                                            <ContentContainer className="Filters">
                                                <FilterBar ref={ref => this._filterBarInstance = ref}
                                                           alwaysShowFilterBar={true}
                                                           beforeItemsSlot={(
                                                               <div className="filterBarOptionsWrapper">
                                                                   <div className="filter-block FilterBarSlotButton"
                                                                        title={this.props.context.msg.t('apply.dashboard.filters')}
                                                                        onClick={e => {
                                                                            const currentFilters = this.props.findFilters();
                                                                            const value = [];
                                                                            this._filterBarInstance.wrappedComponent.updateFilters(filters => {
                                                                                filters.forEach(filter => {
                                                                                    if (!filter.notFromDash) {
                                                                                        const currentFilter = currentFilters.find(f => f.id === filter.id);
                                                                                        filter.selectedMembers = currentFilter ? currentFilter.selectedMembers : [];
                                                                                        value.push(filter);
                                                                                    }
                                                                                });
                                                                            });
                                                                            setFieldValue(
                                                                                'actionProps.filters',
                                                                                value,
                                                                            );
                                                                            setFieldValue(
                                                                              'actionProps.filterIds',
                                                                              currentFilters.map(currentFilter => currentFilter.id),
                                                                            );
                                                                        }}>
                                                                       <Icon icon="settings_backup_restore"/>
                                                                   </div>
                                                                   {!isApplyFilterAction &&
                                                                     <BngDropdown icon="edit"
                                                                                  className="block"
                                                                                  popperClassName={`IconAddFilterDropdown`}
                                                                                  customButton={({openDropdown}) => {
                                                                                      return (
                                                                                        <div
                                                                                          className="filter-block FilterBarSlotButton"
                                                                                          title={this.props.context.msg.t('add.icon.filters')}
                                                                                          onClick={async (e) => {
                                                                                              openDropdown(e);
                                                                                              await this.fetchFilters(formFilters, objectInfo?.datasource);
                                                                                          }}>
                                                                                            <Icon icon="add"/>
                                                                                        </div>
                                                                                      );
                                                                                  }}
                                                                                  customOptions={({closeDropdown}) => {
                                                                                      const filteredMembers = this.state.filterMembers.filter((filterMember) => {
                                                                                          const lowerSearch = this.state.searchTerm.toLowerCase();
                                                                                          return (!this.state.searchTerm
                                                                                            || filterMember.displayName.toLowerCase().includes(lowerSearch));
                                                                                      });

                                                                                      return (
                                                                                        <div>
                                                                                            <BngSearch alwaysOpen={true}
                                                                                                       onChange={(values) => this.handleSearch(values)}
                                                                                                       simple={true}/>
                                                                                            <ul className="filterDropdownList">
                                                                                                {filteredMembers.map((filterMember) => {
                                                                                                    return (
                                                                                                      <li key={filterMember.id}
                                                                                                          className="filterDropdownItem"
                                                                                                          onClick={() => this.openFilterDialog(filterMember.id, async (filterInfo, members) => {
                                                                                                              const newFilters = await this.filtersForUpdate(filterInfo, members, formFilters);
                                                                                                              setFieldValue('actionProps.filters', newFilters);
                                                                                                              closeDropdown();
                                                                                                          })}>
                                                                                                          {filterMember.displayName || filterMember.name}
                                                                                                          {!filterMember.isApplicable &&
                                                                                                              <Icon title={this.props.context.msg.t('filter.without.object.relation')}
                                                                                                                    icon="warning"
                                                                                                              />
                                                                                                          }
                                                                                                      </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </div>
                                                                                      );
                                                                                  }}
                                                                     />
                                                                   }
                                                               </div>
                                                           )}
                                                           customFilterSwitch={{
                                                               enabled: (filter) => {
                                                                   return !filter.notFromDash;
                                                               },
                                                               disableOnCheck: true,
                                                               checked: (filter) => filterIds.includes(filter.id),
                                                               label: this.props.context.msg.t('keep.dash.filter.member')
                                                           }}
                                                           renderCustomMember={(filter) => {
                                                               return filterIds.includes(filter.id) ? this.props.context.msg.t('dash.filter.member') : null;
                                                           }}
                                                           filters={formFilters}
                                                           onChangeListener={(filters, force, bool, changedFilters, additionalProps) => {
                                                               const mainChangedFilter = changedFilters.length === 1 && changedFilters[0];

                                                               // Changes that keep the dashboard filters dynamically are saved in actionProps.filterIds
                                                               if (additionalProps.customSwitchChecked && !filterIds.includes(mainChangedFilter.id)) {
                                                                   setFieldValue('actionProps.filterIds', [
                                                                       ...filterIds.filter(f => f !== -1),
                                                                       mainChangedFilter.id
                                                                   ])
                                                               } else if (filterIds.includes(mainChangedFilter.id)) {
                                                                   setFieldValue('actionProps.filterIds', [
                                                                       ...filterIds.filter(f => f !== mainChangedFilter.id)
                                                                   ])
                                                               }

                                                               if (additionalProps?.clearFilters) {
                                                                   // Remove the filter from the bar if it's not from the dashboard
                                                                   if (mainChangedFilter && filters.find(filter => filter.id === mainChangedFilter.id).notFromDash) {
                                                                       filters = filters.filter(filter => {
                                                                           return !(filter.notFromDash && filter.selectedMembers.length === 0);
                                                                       })
                                                                       setFieldValue(
                                                                         'actionProps.filters',
                                                                         filters
                                                                       );
                                                                   } else {
                                                                       setFieldValue(
                                                                         'actionProps.filterIds',
                                                                         filterIds.filter(f => f !== mainChangedFilter.id)
                                                                       );
                                                                   }
                                                               }

                                                               return filters;
                                                           }}
                                                           noDropdown={true}
                                                           type={'DASHBOARD_ICON'}
                                                />
                                            </ContentContainer>
                                            <ErrorMessage name="actionProps.filters"
                                                          className="help-inline error"
                                                          component="span"
                                            />
                                        </>
                                    }

                                </Dialog.Body>

                                <Dialog.Footer>
                                    <DefaultDialogActions {...this.props}/>
                                </Dialog.Footer>
                            </BngForm>
                        </Dialog>
                    );
                }}

            </Formik>
        )
    }

}

export default connect(state => ({folders: state.menu.roots}))(ContextEnhancer(DashboardIconForm));
