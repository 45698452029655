import './ConfigureLayouPublisher.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import { BngField } from 'components/bng/form/BngField';
import BngButton from 'components/bng/ui/BngButton';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngRadio from 'components/bng/form/BngRadio';
import BngSwitch from 'components/bng/form/BngSwitch';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import Api from 'components/Api';

const SNIPPETS = [
  {
    lang: 'Php',
    image: Api.buildUrl('/resources/images/php-icon.png'),
    imageClassName: 'php-icon',
    generateSnippet(link) {
      return (
        <>
          <span className="commandStyle">{`<?php`}</span>
          <br />
          <span className="variableStyle">$</span>curl = curl_init();
          <br />
          curl_setopt_array(<span className="variableStyle">$</span>curl, [<br />
          <span className="variableStyle">CURLOPT_URL</span> => "<span className="hiperlinkStyle">{link}</span>",
          <br />
          <span className="variableStyle">CURLOPT_RETURNTRANSFER</span> => true,
          <br />
          <span className="variableStyle">CURLOPT_ENCODING</span> => "",
          <br />
          <span className="variableStyle">CURLOPT_MAXREDIRS</span> => 10,
          <br />
          <span className="variableStyle">CURLOPT_TIMEOUT</span> => 30,
          <br />
          <span className="variableStyle">CURLOPT_HTTP_VERSION</span> =>{' '}
          <span className="variableStyle">CURL_HTTP_VERSION_1_1</span>,<br />
          <span className="variableStyle">CURLOPT_CUSTOMREQUEST</span> => "<span className="hiperlinkStyle">GET</span>",
          <br />
          <span className="variableStyle">CURLOPT_HTTPHEADER</span> => ["
          <span className="hiperlinkStyle">accept: application/json</span>"],
          <br />
          ]);
          <br />
          <span className="variableStyle">$</span>response = curl_exec(<span className="variableStyle">$</span>curl);
          <br />
          <span className="variableStyle">$</span>err = curl_error(<span className="variableStyle">$</span>curl);
          <br />
          curl_close(<span className="variableStyle">$</span>curl);
          <br />
          <span className="importStyle">if</span> (<span className="variableStyle">$</span>
          {`err) {`}
          <br />
          <span className="importStyle">echo</span> "cURL Error #:" . <span className="variableStyle">$</span>err;
          <br />
          {'}'} <span className="importStyle">else</span>
          {' {'}
          <br />
          <span className="importStyle">echo</span> <span className="variableStyle">$</span>response;
          <br />
          {'}'}
        </>
      );
    },
  },
  {
    lang: 'Python',
    image: Api.buildUrl('/resources/images/python-icon.png'),
    imageClassName: 'python-icon',
    snippetComponent() {
      return <pre>python -m pip install requests</pre>;
    },
    generateSnippet(link) {
      return (
        <>
          <span className="importStyle">import</span> requests
          <br />
          <br />
          url = <span className="hiperlinkStyle">"{link}"</span>
          <br />
          <br />
          headers = {<span className="hiperlinkStyle">"accept": "application/json"</span>}
          <br />
          <br />
          response = requests.<span className="commandStyle">get</span>(url, headers = headers)
          <br />
          <br />
          <span className="commandStyle">print</span>(response.text);
        </>
      );
    },
  },
  {
    lang: 'Java',
    image: Api.buildUrl('/resources/images/java-icon.png'),
    imageClassName: 'java-icon',
    generateSnippet(link) {
      return (
        <>
          HttpRequest request = HttpRequest.<span className="commandStyle">newBuilder</span>()
          <br />.<span className="commandStyle">uri</span>(URI.<span className="commandStyle">create</span>(
          <span className="hiperlinkStyle">"{link}"</span>))
          <br />.<span className="commandStyle">header</span>(
          <span className="hiperlinkStyle">"accept", "application/json"</span>)<br />.
          <span className="commandStyle">method</span>(<span className="hiperlinkStyle">"GET"</span>,
          HttpRequest.BodyPublishers.<span className="commandStyle">noBody()</span>)<br />.
          <span className="commandStyle">build</span>();
          <br />
          <br />
          HttpResponse{`<String>`} response = HttpClient.<span className="commandStyle">newHttpClient</span>()
          <br />.<span className="commandStyle">send</span>(request, HttpResponse.BodyHandlers.
          <span className="commandStyle">ofString</span>());
          <br />
          <br />
          System.out.<span className="commandStyle">println</span>(response.<span className="commandStyle">body</span>
          ());
        </>
      );
    },
  },
];

class ConfigureLayouPublisher extends Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    color: PropTypes.string,
    path: PropTypes.string,
    enableTable: PropTypes.bool,
    enableChart: PropTypes.bool,
    enableFixedSize: PropTypes.bool,
    enablePublisherFrame: PropTypes.bool,
  };

  static defaultProps = {
    color: '',
    path: '',
  };

  state = {
    selectedSnippetIdx: 0,
  };

  render() {
    const isAnalysisPath = Utils.Object.isAnalysis(this.props.path);

    const viewOpts = ['DEFAULT'];

    if (isAnalysisPath) {
      viewOpts.shift();
      viewOpts.push('CHART');
      viewOpts.push('TABLE');
    }

    const isBigTable = Utils.Object.isBigTable(this.props.path);
    if (isAnalysisPath || isBigTable || Utils.Object.isKpi(this.props.path) || Utils.Object.isNewMap(this.props.path)) {
      viewOpts.push('JSON');
      if (isBigTable) {
        viewOpts.push('CSV');
      }
    }

    const snippet = SNIPPETS[this.state.selectedSnippetIdx ?? 0];
    const snippetLink = `${window.location.origin}${Api.buildUrl(`/api/publisher${this.props.link}`)}`;
    return (
      <div className="ConfigureLayouPublisher publisher-tab-container">
        <div className="configColumn">
          <div className="publisher-itens-align">
            <div className="publisher-layout-title fixed-size">{this.props.context.msg.t('fixed.size')}</div>
            <div className="container-enablefixsize">
              <Field
                label=""
                onClick={this.handleChangefixedsize}
                id="pubisher-fix-size"
                name="enablefixsize"
                component={BngSwitch}
              />
            </div>
          </div>

          {this.props.enableFixedSize && (
            <div className="container-fixed-size">
              <div className="publisher-layout-title width-publisher">
                {this.props.context.msg.t('width')}
                <Field label="" name="width" type="number" className="publisher-field-size" component={BngField} />
              </div>

              <div className="fixed-size x-title">x</div>

              <div className="publisher-layout-title height-publisher">
                {this.props.context.msg.t('height')}
                <Field label="" name="height" type="number" className="publisher-field-size" component={BngField} />
              </div>
            </div>
          )}

          <div className="publisher-itens-align">
            <div className="publisher-layout-title publisher-frame">{this.props.context.msg.t('publisher.frame')}</div>
            <div className="container-enablepublisherframe">
              <Field
                label=""
                onClick={this.handleChangeframe}
                id="publisherframe1"
                name="enablepublisherframe"
                component={BngSwitch}
              />
            </div>
          </div>
          {this.props.enablePublisherFrame && (
            <div className="container-frame">
              <div className="publisher-layout-title publisher-frame-color">
                {this.props.context.msg.t('publisher.config')}
              </div>

              <div className="config-options mt-2">
                <Field
                  label=""
                  name="showtitle"
                  component={BngField}
                  title={this.props.context.msg.t('show.title')}
                  inputComponent={BngCheckbox}
                  disabled={this.props.enablePublisherFrame ? '' : 'disabled'}
                  asProps={{ label: this.props.context.msg.t('show.title') }}
                />

                <Field
                  label=""
                  rootClassName="mt-2"
                  className="container-showdateupdate"
                  name="showdateupdate"
                  component={BngField}
                  title={this.props.context.msg.t('show.update.date')}
                  inputComponent={BngCheckbox}
                  disabled={this.props.enablePublisherFrame ? '' : 'disabled'}
                  asProps={{ label: this.props.context.msg.t('show.update.date') }}
                />
              </div>
            </div>
          )}

          <div className="publisher-layout-title analysis-type">{this.props.context.msg.t('type')}</div>
          <div className="container-sharingchart">
            {viewOpts.map((opt) => {
              return (
                <Field
                  key={opt}
                  name="view"
                  component={BngField}
                  inputComponent={BngRadio}
                  asProps={{
                    label: this.props.context.msg.t(opt),
                    value: opt,
                    disabled:
                      (opt === 'CHART' && !this.props.enableChart) || (opt === 'TABLE' && !this.props.enableTable),
                  }}
                  withLabel={false}
                />
              );
            })}
          </div>
        </div>
        {this.props.values?.view === 'JSON' && (
          <div className="publisher-json-formatter">
            <div className="json-formatter-language">
              {SNIPPETS.map((snippet, idx) => (
                <BngButton
                  key={snippet.lang}
                  className={`${this.state.selectedSnippetIdx === idx ? 'selected' : ''}`}
                  onClick={() => this.setState({ selectedSnippetIdx: idx })}
                >
                  <img className={snippet.imageClassName} alt={snippet.lang} src={snippet.image} />
                  {snippet.lang}
                </BngButton>
              ))}
            </div>
            <pre className="json-formatter-path">
              {snippet.snippetComponent && <snippet.snippetComponent />}
              <div className="code-snippet">{snippet.generateSnippet(snippetLink)}</div>

              <BngIconButton
                icon="content_copy"
                onClick={async () => {
                  try {
                    const snippetEl = document.querySelector('.ConfigureLayouPublisher .code-snippet');
                    await Utils.copyToClipboard(snippetEl?.innerText);
                    UiMsg.ok(this.props.context.msg.t('copy.clipboard'));
                  } catch (e) {
                    console.error('Error while trying to copy text to clipboard', e);
                    UiMsg.error(this.props.context.msg.t('copy.clipboard.fail'));
                  }
                }}
              />
            </pre>
          </div>
        )}
      </div>
    );
  }
}

export default ContextEnhancer(ConfigureLayouPublisher);
