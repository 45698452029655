import './UserInfoPage.css';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Field, Formik} from 'formik';
import BngAlert from 'components/bng/ui/BngAlert';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import {BngPrimaryButton} from 'components/ui/Button';
import BngTextArea from 'components/bng/form/BngTextArea';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import bngYup from 'components/bng/form/yup/BngYup';
import UiMsg from 'components/ui/UiMsg';
import BngCard, {BngCardBody, BngCardFooter, BngCardHeader} from 'components/bng/ui/BngCard';
import CommonActivationPage from 'components/bng/pages/project/userActivation/CommonActivationPage';
import BngBigIcon from 'components/bng/ui/BngBigIcon';
import BngAvatarUploadInput from 'components/bng/form/BngAvatarUploadInput';
import BngPhoneInput from 'components/bng/form/BngPhoneInput';
import {passwordValidationFunction, passwordYupRule} from 'components/ui/profile/ChangePasswordDialog';
import useBimContext from 'components/hooks/useBimContext';
import useBimNavigate from 'components/hooks/useBimNavigate';

const CommonSchema = bngYup((yup) =>
  yup.object({
    displayName: yup.string().required().default(''),
    email: yup.string().required().default(''),
    phone: yup.string().default(''),
    occupationArea: yup.string().required().default(''),
  })
);

const InfoFormSchema = bngYup((yup) =>
  yup
    .object({
      message: yup.string().required().default(''),
    })
    .concat(CommonSchema)
);

const FinishFormSchema = bngYup((yup) =>
  yup
    .object({
      password: passwordYupRule,
      passwordConfirmation: yup.string().required().default(''),
    })
    .concat(CommonSchema)
);

const DEFAULT_SERVICE = {
  findFinishInfo(key) {
    return Api.ProjectUserActivation.findFinishInfo(key);
  },
  findUserInfo(key) {
    return Api.ProjectUserActivation.findUserInfo(key);
  },
  findOccupationAreas() {
    return Api.Bng.findOccupationAreas();
  },
  updateFinishInfo(key, values) {
    return Api.ProjectUserActivation.updateFinishInfo(key, values);
  },
  updateUserInfo(key, values) {
    return Api.ProjectUserActivation.updateUserInfo(key, values);
  },
};

const UserInfoPage = ({
  finishStep = false,
  isSubmitting = false,
  service = DEFAULT_SERVICE,
  status = { finished: false },
  ...props
}) => {
  const { msg } = useBimContext();
  const navigate = useBimNavigate();
  const { key } = useParams();
  const userInfoFetch = useFetchData(async () => {
    try {
      const data = await (finishStep ? service.findFinishInfo(key) : service.findUserInfo(key));
      props.resetForm({
        values: {
          displayName: data.user.displayName ?? '',
          email: data.user.email,
          phone: data.user.phone ?? '',
          occupationArea: data.user.occupationArea,
          avatar: data.containAvatar ? { link: `${Api.baseUrl()}${data.user.avatarLink}` } : null,
        },
      });
      props.setFieldValue('state', {
        key,
        finishStep,
      });
      return data;
    } catch (e) {
      navigate('/errors/403');
    }
  }, [key]);

  const occupationAreasFetch = useFetchData(async () => {
    const data = await service.findOccupationAreas();
    return data.map((d) => ({ value: d.value, label: msg.t(d.translationKey) }));
  });

  const validatePassword = useMemo(() => passwordValidationFunction(() => msg), []);

  return (
    <CommonActivationPage className="UserInfoPage">
      <UiBlocker block={isSubmitting || userInfoFetch.isLoading || occupationAreasFetch.isLoading}>
        <BngForm>
          <BngCard withShadow>
            <BngCardHeader title={status.finished ? '' : msg.t('welcome.to.bim')} />

            {status.finished && !finishStep ? (
              <>
                <BngBigIcon icon="done" message={msg.t('UserInfoPage.activation.request.sent.msg')} />
              </>
            ) : (
              <>
                <BngCardBody>
                  {!finishStep && (
                    <BngAlert className="mb-6">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: msg.t('UserInfoPage.alert', [
                            userInfoFetch.data?.invitedByUser?.displayName ?? '?',
                            userInfoFetch.data?.project.displayName ?? '?',
                          ]),
                        }}
                      />
                    </BngAlert>
                  )}

                  <div className="d-flex" style={{ gap: 20 }}>
                    <div className="flex-grow-1">
                      <table className="FieldsTable w-100">
                        <colgroup>
                          <col width={0} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>
                              <label className="control-label">{msg.t('full_name')}</label>
                            </th>
                            <td>
                              <Field name="displayName" component={BngField} withLabel={false} />
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="control-label">{msg.t('email')}</label>
                            </th>
                            <td>
                              <Field name="email" component={BngField} withLabel={false} readOnly />
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="control-label">{msg.t('phone')}</label>
                            </th>
                            <td>
                              <Field
                                name="phone"
                                component={BngField}
                                inputComponent={BngPhoneInput}
                                userLanguage={userInfoFetch.data?.user.preferredLanguage ?? 'pt_BR'}
                                withLabel={false}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <label className="control-label">{msg.t('field_of_work')}</label>
                            </th>
                            <td>
                              <Field
                                name="occupationArea"
                                label={msg.t('field_of_work')}
                                component={BngField}
                                inputComponent={BngSelectSearch}
                                options={occupationAreasFetch.data ?? []}
                                withLabel={false}
                              />
                            </td>
                          </tr>
                          {finishStep && (
                            <>
                              <tr>
                                <th>
                                  <label className="control-label">{msg.t('password')}</label>
                                </th>
                                <td>
                                  <Field
                                    name="password"
                                    component={BngField}
                                    type="password"
                                    withLabel={false}
                                    validate={validatePassword}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <label className="control-label">
                                    {msg.t('createNewUser_label_confirmPassword')}
                                  </label>
                                </th>
                                <td>
                                  <Field
                                    name="passwordConfirmation"
                                    type="password"
                                    component={BngField}
                                    withLabel={false}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th></th>
                                <td>
                                  <div className="PasswordInfo">
                                    <p className="mb-1">{msg.t('UserInfoPage.password.msg')}</p>
                                    <ul>
                                      <li>{msg.t('UserInfoPage.password.req1')}</li>
                                      <li>{msg.t('UserInfoPage.password.req2')}</li>
                                      <li>{msg.t('UserInfoPage.password.req3')}</li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <Field name="avatar" component={BngAvatarUploadInput} />
                    </div>
                  </div>
                  {!finishStep && (
                    <div>
                      <Field
                        name="message"
                        component={BngField}
                        inputComponent={BngTextArea}
                        rows={5}
                        placeholder={msg.t('UserInfoPage.message.placeholder')}
                      />
                    </div>
                  )}
                </BngCardBody>
                <BngCardFooter>
                  <div className="text-right">
                    <BngPrimaryButton type="submit">
                      {msg.t(finishStep ? 'confirm' : 'request.access')}
                    </BngPrimaryButton>
                  </div>
                </BngCardFooter>
              </>
            )}
          </BngCard>
        </BngForm>
      </UiBlocker>
    </CommonActivationPage>
  );
};

export default function UserInfoPageFormik(props) {
  const { msg } = useBimContext();
  const initialValues = props.finishStep ? FinishFormSchema.default() : InfoFormSchema.default();
  const validationSchema = props.finishStep ? FinishFormSchema : InfoFormSchema;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ state, ...values }, formik) => {
        try {
          const service = props.service ?? DEFAULT_SERVICE;
          if (state.finishStep) {
            if (!values.password || values.password !== values.passwordConfirmation) {
              formik.setFieldError('passwordConfirmation', msg.t('passwords_dont_match'));
              formik.setSubmitting(false);
              return;
            }
            await service.updateFinishInfo(state.key, values);
            window.location.replace(`${Api.baseUrl()}/spr/bng/login`);
          } else {
            await service.updateUserInfo(state.key, values);
            formik.setStatus({ finished: true });
          }
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
          formik.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => <UserInfoPage {...formikProps} {...props} />}
    </Formik>
  );
}
