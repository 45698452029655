import './DatabaseConnectionDialog.css';

import React, { useEffect, useState } from 'react';
import { Field, FieldArray, Formik, useFormikContext } from 'formik';
import { BngInput } from 'components/bng/form/BngInput';
import { arrayToOptionsI18n, BngSelect } from 'components/bng/form/BngSelect';
import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { bngYup } from 'components/bng/form/yup/BngYup';
import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import { BngAddButton } from 'components/bng/ui/BngAddButton';
import { BngTable } from 'components/bng/ui/BngTable';
import { fetchDatabases } from 'components/ui/in-memory/ConnectionsDialog';
import BngSwitch from 'components/bng/form/BngSwitch';
import { MODALS } from 'components/ui/redux/Actions';
import TestConnectionDialog from 'components/ui/in-memory/TestConnectionDialog';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

const MAX_CONNECTION_NAME_LENGTH = 100;
const ProjectLanguage = ['pt_BR', 'en_US', 'es_ES'];

const ConnectionSchema = bngYup((yup) =>
  yup.object().shape({
    id: yup.number().nullable().default(0),
    name: yup.string().max(MAX_CONNECTION_NAME_LENGTH).required().default(''),
    type: yup.string().required().default('PostgreSQL'),
    editCredentials: yup.boolean().default(false),
    username: yup.string().when('editCredentials', {
      is: true,
      then: yup.string().required().default(''),
      otherwise: yup.string().default(''),
    }),
    password: yup.string().when('editCredentials', {
      is: true,
      then: yup.string().required().default(''),
      otherwise: yup.string().default(''),
    }),
    url: yup.string().required().default('jdbc:postgresql://<host>:<port>/<database>'),
    language: yup.string().required().oneOf(ProjectLanguage).default('en_US'),
    schedulerConfig: yup.object().shape({
      id: yup.string().nullable(),
      url: yup.string().nullable(),
    }),
    properties: yup.array().of(
      yup.object().shape({
        key: yup.string().required().default(''),
        value: yup.string().required().default(''),
      })
    ),
  })
);

const ButtonOptions = ({
                         context = {},
                         setViewProperties = _.noop,
                         viewProperties = false,
                         testConnection = _.noop
                       }) => {
  return (
    <div className={'ConnectionButtonsContainer'}>
      <Button
        className={`ConnectionProperties ${viewProperties && 'active'}`}
        onClick={() => setViewProperties(!viewProperties)}
        type={'button'}
      >
        <Icon icon={'settings'} className="New-Connection__form__icon" />
        <div>{context.msg.t('properties')}</div>
      </Button>
      <Button className={'TestConnection'} onClick={testConnection} type={'button'}>
        <Icon icon={'check_box'} className="New-Connection__form__icon" />
        <div>{context.msg.t('test.connection')}</div>
      </Button>
    </div>
  );
};

const DatabaseConnectionBody = ({
  context = {},
  closeModal = _.noop,
  connection = {},
  databaseListOrdered = [],
  databases = [],
  testConnection = _.noop,
  globalConnection = false,
  forReplication = false,
  extractorOpts = [],
}) => {
  const { resetForm, values, setFieldValue } = useFormikContext();

  const [viewProperties, setViewProperties] = useState(false);

  const currentDatabase = _.find(databases, { name: values.type });

  useEffect(() => {
    if (_.isEmpty(connection)) return;
    const connectionType = _.find(databases, { name: connection.type?.name });
    if (_.isEmpty(connectionType)) return;
    resetForm({
      values: {
        ...connection,
        type: connectionType?.name,
      },
    });
  }, [connection, databases]);

  const handleDatabaseChange = (event) => {
    const value = event.target.value;
    const database = _.find(databases, { name: value });
    setFieldValue('url', database.jdbcUrl);
    if (database.dwCredentials) {
      setFieldValue('editCredentials', false);
    }
  };

  const renderKeyField = (row, index) => {
    return (
      <Field id={`properties.${index}.key`} name={`properties.${index}.key`} required={true} component={BngInput} />
    );
  };
  const renderValueField = (row, index) => {
    return (
      <Field id={`properties.${index}.value`} name={`properties.${index}.value`} required={true} component={BngInput} />
    );
  };
  const renderActions = (row, index, rowProps) => {
    return (
      <div className="remove-property-button" onClick={() => rowProps.remove(index)}>
        {context.msg.t('delete')}
      </div>
    );
  };

  const propertiesColumns = [
    { label: context.msg.t('key'), render: (row, index) => renderKeyField(row, index) },
    { label: context.msg.t('value'), render: (row, index) => renderValueField(row, index) },
    { label: '', render: (row, index, rowProps) => renderActions(row, index, rowProps) },
  ];

  return (
    <BngForm>
      <Dialog.Body>
        <Field
          name="name"
          label={context.msg.t('name')}
          component={BngField}
          inputComponent={BngInput}
          required
          maxLength={MAX_CONNECTION_NAME_LENGTH}
          inline={true}
          readOnly={forReplication}
        />

        <Field
          name="type"
          label={context.msg.t('database')}
          component={BngField}
          inputComponent={BngSelect}
          emptyOption={false}
          options={databaseListOrdered}
          onChange={handleDatabaseChange}
          inline={true}
        />

        {!_.isEmpty(extractorOpts) && (
          <Field
            name="projectExtractorId"
            label={context.msg.t('extractor')}
            component={BngField}
            inputComponent={BngSelect}
            options={extractorOpts}
            inline={true}
          />
        )}

        {!!currentDatabase && !currentDatabase.disableEdit && (
          <Field name="url" label={'URL'} component={BngField} inputComponent={BngInput} required inline={true} />
        )}
        {values.type === 'Oracle' && context.isInbox && (
          <Field
            name="language"
            label={context.msg.t('language')}
            component={BngField}
            inputComponent={BngSelect}
            emptyOption={false}
            options={arrayToOptionsI18n(context, ProjectLanguage)}
            inline={true}
          />
        )}

        {globalConnection && (
          <>
            <Field
              name="schedulerConfig.id"
              label={context.msg.t('scheduler.id')}
              component={BngField}
              inputComponent={BngInput}
              required
              inline={true}
            />
            <Field
              name="schedulerConfig.url"
              label={context.msg.t('scheduler.url')}
              component={BngField}
              inputComponent={BngInput}
              required
              inline={true}
            />
          </>
        )}
        <div className="BlockContent">
          <div className="flex-center-items">
            <Icon className="New-Connection__form__icon" icon="vpn_key" />
            <Field
              name="editCredentials"
              rootClassName="EditCredentials"
              label={context.msg.t('credentials')}
              component={BngField}
              inputComponent={BngSwitch}
              inline={true}
            />
          </div>
          <hr />

          {values.editCredentials && (
            <>
              <Field
                name="username"
                label={context.msg.t('user')}
                component={BngField}
                inputComponent={BngInput}
                required={values.editCredentials}
                inline={true}
                disabled={!values.editCredentials}
              />
              <Field
                name="password"
                label={context.msg.t('password')}
                component={BngField}
                inputComponent={BngInput}
                type="password"
                required={values.editCredentials}
                inline={true}
                disabled={!values.editCredentials}
              />
            </>
          )}
        </div>

        <ButtonOptions
          context={context}
          connection={connection}
          setViewProperties={setViewProperties}
          viewProperties={viewProperties}
          testConnection={() => testConnection(values)}
        />

        {viewProperties && (
          <FieldArray
            name="properties"
            render={(arrayHelpers) => (
              <div>
                <BngAddButton className="AddProperties" onClick={() => arrayHelpers.push({ key: '', value: '' })}>
                  <span>{context.msg.t('add')}</span>
                </BngAddButton>
                <div style={{ maxHeight: 100, overflowY: 'auto', width: '100%' }}>
                  <BngTable cols={propertiesColumns} rows={values.properties} rowProps={arrayHelpers} />
                </div>
              </div>
            )}
          />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <DefaultDialogActions context={context} closeModal={closeModal} />
      </Dialog.Footer>
    </BngForm>
  );
};

export default function DatabaseConnectionDialog({
  closeModal = _.noop,
  connectionId = null,
  fetchConnections = _.noop,
  databaseList = null,
  globalConnection = false,
  forReplication = false,
  connectionData,
  defaultType = 'PostgreSQL',
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [connection, setConnection] = useState({});
  const [databases, setDatabases] = useState([]);
  const [extractorOpts, setExtractorOpts] = useState([]);
  const [loading, setLoading] = useState(false);

  const edit = !!connectionId;

  const testConnection = async (values) => {
    dispatch(
      MODALS.open(TestConnectionDialog, {
        closeModal,
        context,
        connectionInfo: {
          projectId: context.project.id,
          ...values
        },
        forReplication,
      })
    );
  };

  const fetchDatabasesList = async () => {
    if (!!databaseList) {
      setDatabases(databaseList);
      return;
    }
    const databases = await fetchDatabases(setLoading);
    setDatabases(databases);
  };

  const fetchConnectionInfo = async () => {
    try {
      setLoading(true);
      const connectionInfo = forReplication ? _.cloneDeep(connectionData) : await Api.Connection.findById(connectionId);
      setConnection(connectionInfo);
      return connectionInfo;
    } catch (e) {
      console.error('Error on fetchConnectionInfo()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const fetchExtractors = async () => {
    try {
      setLoading(true);
      const extractors = await Api.ProjectExtractor.findByProject(context.project.id);
      setExtractorOpts(extractors.map((e) => ({ value: e.id, label: e.name ?? e.key })));
    } catch (e) {
      console.error('Error on fetchExtractors()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchDatabasesList();
      let bimGatewayEnabled = context.permissions.canAccessBimGateway();
      if (connectionId || forReplication) {
        const ci = await fetchConnectionInfo();
        if (!bimGatewayEnabled && ci.projectExtractorId) {
          bimGatewayEnabled = true;
        }
      }

      // Only fetch extractorOpts for connection projects
      if (bimGatewayEnabled && !_.isEmpty(context.project)) {
        fetchExtractors();
      }
    })();
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);
      if (!edit) {
        await Api.Connection.saveNewConnection({
          projectId: context.project.id,
          forReplication,
          ...values
        });
      } else {
        await Api.Connection.updateConnection({
          projectId: context.project.id, ...values
        }, connectionId);
      }
      UiMsg.ok(context.msg.t('fem.save_success', context.msg.t('connection')));
      fetchConnections();
      setLoading(false);
      await Api.updateJsf();
      closeModal();
    } catch (e) {
      if (e.response?.status === 409) {
        UiMsg.warn(context.msg.t('name_alredy_taken'));
      } else if (e.response?.status === 400) {
        UiMsg.error(context.msg.t('object.not.avaliable.on.mobile.title'), e);
      } else {
        console.error(e);
        UiMsg.error('', e);
      }
    } finally {
      setLoading(false);
      actions.setSubmitting(false);
    }
  };

  const databaseListOrdered = _.orderBy(
    databases.map((database) => ({
      value: database.name,
      label: database.name,
    })),
    ['value']
  );

  const initialValues = {
    ...ConnectionSchema.default(),
    editCredentials: !edit,
    type: defaultType,
  };

  return (
    <Dialog
      title={context.msg.t(edit || forReplication ? 'editing.connection' : 'new.connection')}
      className="NewConnection"
      onClose={closeModal}
      loading={loading}
    >
      <Formik initialValues={initialValues} validationSchema={ConnectionSchema} onSubmit={handleSubmit}>
        <DatabaseConnectionBody
          closeModal={closeModal}
          context={context}
          connectionId={connectionId}
          connection={connection}
          databaseListOrdered={databaseListOrdered}
          databases={databases}
          testConnection={testConnection}
          globalConnection={globalConnection}
          forReplication={forReplication}
          extractorOpts={extractorOpts}
        />
      </Formik>
    </Dialog>
  );
}
