import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Api from "components/Api";

class DashboardItemMenuCloneObject extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    openDialog = () => {
        const itemToClone = this.props.item;
        const folder = itemToClone.path.substring(0, itemToClone.path.lastIndexOf('/'));
        if (itemToClone.path === '' || itemToClone.viewType === 'text') {
            this.cloneItem(itemToClone)
        } else {
            window.ComponentFactory.default.Others.openSaveAsDialog(
                itemToClone.path,
                folder,
                itemToClone.name,
                itemToClone.description,
                this.cloneItem
            );
        }
    }

    cloneItem = async (cloneProps) => {
        const itemToClone = this.props.item;
        try {
            await Api.Dash.cloneItem({
                id: itemToClone.id,
                folder: cloneProps.folder,
                name: cloneProps.name,
                description: cloneProps.description
            });
            UiMsg.ok(this.props.context.msg.t('object.clone.success'));
            await Api.updateJsf();
        } catch (e) {
            UiMsg.ajaxError('error', e);
        }
    };

    render() {
        let shorted = application.page.isMacOS() ? 'CMD+V' : 'CTRL+V';
        return (
            <li className="bt-item-new-menu-inside">
                <DashboardItemMenuTitle title={this.props.context.msg.t('clone.object')}
                                        icon="reply_all"
                                        more={false}
                                        onClick={this.openDialog}
                                        shorted={shorted}
                />
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuCloneObject);