import './Aggregation.css';
import React, {Component} from 'react';
import * as ReactDOM from "react-dom";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Checkbox from "components/ui/Checkbox";
import UiMsg from "components/ui/UiMsg";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import BngPopper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";


const AggregationInput = ({className = '', title = '', name = '', selected = false, onChange = _.noop}) => {
    return (
        <li className={`AggregationInput ${className}`}>
            <Checkbox value={{value: '', label: title}}
                      name={name}
                      title={title}
                      selected={selected}
                      onChange={onChange} />
        </li>
    )
};

const AggregationTitle = ({icon = '', className = '', title = ''}) => {
    return (
        <li className={`AggregationTitle ${className}`}>
            <i className={icon}></i>
            {title}
        </li>
    )
};

const AggregationMeasures = ({measures = [], onChange = _.noop}) => {
    return (
        measures.map((m, idx) =>
            <AggregationInput
                key={idx}
                title={m.name}
                name={m.dataExp}
                onChange={(e) => onChange(e, m)}
                selected={m.enabled}
            />
        )
    )
};

const Aggregation  = ContextEnhancer(
    class extends Component {

    static propTypes = {};

    static defaultProps = {};

    state = {
        loading: true,
        aggregateCol: false,
        aggregateRow: false,
        measures: [],
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        try {
            const {aggregateCol, aggregateRow, measuresTotalizer} = await Api.Analysis.getAggregation();
            const measures = this.getMeasures({measuresTotalizer, aggregateCol, aggregateRow});
            this.setState({aggregateCol, aggregateRow, measures});
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    }

    isRowOriented = () => {
        return j('.mdx-table .measure').parent().next().find('span[data-exp]').length === 0;
    };

    getMeasures = ({measuresTotalizer = [], aggregateCol, aggregateRow}) => {
        const rowOriented = this.isRowOriented();
        const measures = [];
        const $els = rowOriented ? j('*:not(.fixed-columns) > .mdx-table:not(.headersArea) tbody th span[data-exp]') : j('.mdx-table:not(.headersArea) .measure').parent().next().find('span[data-exp]');
        const aggregateAll = rowOriented && aggregateRow || !rowOriented && aggregateCol;

        $els.each(function (idx) {
            const dataExp = j(this).attr('data-exp');
            const name = j(this).text();
            if(!!dataExp && dataExp.includes('[Measures]') && !_.find(measures, (m) => m.dataExp === dataExp) ) {
                const enabled = aggregateAll || _.includes(measuresTotalizer, dataExp);
                measures.push({name, dataExp, enabled});
            }
        });

        return measures;
    };

    aggregateAllColumns = async (event) => {
        await this.handleAggregate({event, axis: "COLUMN", rowOriented: this.isRowOriented()});
    };

    aggregateAllRows = async (event) => {
        await this.handleAggregate({event, axis: "ROW", rowOriented: this.isRowOriented()});
    };

    handleAggregateMeasure = async (event, measure = {}) => {
        let measures = this.state.measures;
        let mIdx = measures.findIndex(m => m.dataExp === measure.dataExp);

        measures[mIdx].enabled = !measure.enabled;

        const enabledMeasures = measures.filter(m => m.enabled);
        const disabledAxis = enabledMeasures.length < measures.length ? this.isRowOriented() ? "ROW" : "COLUMN" : "";

        await this.handleAggregate({
            event,
            axis: measure.dataExp,
            enabledMeasures : enabledMeasures.map(m => m.dataExp).join(', '),
            enabled: measure.enabled,
            disabledAxis,
        });
    };

    handleAggregate = async ({event, ...props}) => {
        event.preventDefault();
        this.setState({loading: true});
        try {
            const {aggregateCol, aggregateRow, measuresTotalizer} = await Api.Analysis.toggleAggregate({...props});
            const measures = this.getMeasures({measuresTotalizer, aggregateCol, aggregateRow});
            this.setState({aggregateCol, aggregateRow, measures});
            await Api.updateJsf();
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    render() {

        const rowOriented = this.isRowOriented();

        return (
            <ul className="Aggregation">
                <UiBlocker block={this.state.loading}>
                    <AggregationTitle
                        title={this.props.context.msg.t('enable.col.aggregation')}
                        className="Columns"
                        icon="icon-sort-by-attributes"
                    />

                    <AggregationInput
                        title={this.props.context.msg.t('all.genderless')}
                        onChange={this.aggregateAllColumns}
                        name="aggregate-all-columns"
                        selected={this.state.aggregateCol}
                    />

                    {!rowOriented &&
                        <AggregationMeasures measures={this.state.measures} onChange={this.handleAggregateMeasure}/>
                    }

                    <AggregationTitle
                        title={this.props.context.msg.t('enable.row.aggregation')}
                        className="Rows"
                        icon="icon-sort-by-attributes"
                    />

                    <AggregationInput
                        title={this.props.context.msg.t('all.no.gender')}
                        onChange={this.aggregateAllRows}
                        name="aggregate-all-lines"
                        selected={this.state.aggregateRow}
                    />

                    {rowOriented &&
                        <AggregationMeasures measures={this.state.measures} onChange={this.handleAggregateMeasure}/>
                    }

                </UiBlocker>
            </ul>
        );
    }
});

export default class AggregationPopper extends React.PureComponent {

    static defaultProps = {
        anchorEl: null,
        container: null,
    };

    destroyView = () => {
        ReactDOM.unmountComponentAtNode(this.props.container);
        j(this.props.container).remove();
    };

    render() {
        const {anchorEl, container, ...props} = this.props;
        return (
            <BngPopper className="AggregationPopper bng-dropdown-parent"
                       open={true}
                       anchorEl={anchorEl}
                       modifiers={{
                           preventOverflow: {
                               boundariesElement: document.body,
                           }
                       }}>
                <BngClickOutsideOverlay style={{zIndex: 21}} onClick={this.destroyView}/>
                <Aggregation {...props}/>
            </BngPopper>
        );
    }
}