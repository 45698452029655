import "components/ui/dashboard/DashLayoutAccordion.css";
import React, {useEffect, useState} from 'react';
import {Field, useFormikContext} from "formik";

import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";

import Button from "components/ui/Button";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {BngField} from "components/bng/form/BngField";
import {BngInput} from "components/bng/form/BngInput";
import BngImageUpload from "components/bng/form/BngImageUpload";
import {BngSelect} from "components/bng/form/BngSelect";
import {BngSlider} from "components/bng/form/BngSlider";
import BngSwitch from "components/bng/form/BngSwitch";
import Accordion from "components/ui/Accordion";
import AccordionList from "components/ui/AccordionList";
import HelpIcon from "components/ui/common/HelpIcon";
import {bngYup} from "components/bng/form/yup/BngYup";
import BngLineInputColor from 'components/bng/form/BngLineInputColor';

const HIGHLIGHT_COLORS = [
    "#6925e8",
    "#528de3",
    "#269c59",
    "#fec447",
    "#ffffff",
    "#eb354d",
].map(color => ({value: color, label: color}));

const LAYOUT_DIVISIONS = (context) => {
    return [
        12,
        24,
        48,
        96,
    ].map(d => ({value: d, label: `${d} ${context.msg.t('divisions')}`}));
};

const THEMES = (context) => {
    return [
        'WHITE',
        'BLACK',
        'CORPORATIVE',
    ].map(theme => ({value: theme, label: context.msg.t(`css.background.theme.${theme.toLowerCase()}`)}));
};

const BORDERS = (context) => {
    return [
        "DEFAULT",
        "SIMPLE",
        "THICK",
        "DOUBLE_LINE",
        "DOTTED",
        "POSTFIX",
        "SHADOW",
        "NO_BORDER",
    ].map(border => ({value: border, label: context.msg.t(border)}));
};

export const DashLayoutSchema = bngYup(yup => {
    return yup.object().shape({
        backgroundTheme: yup.string().default('WHITE'),
        highlightColor: yup.string().default("#528de3"),
        backgroundImage: yup.mixed().nullable().default(null),
        backgroundColor: yup.string().default(''),
        divisions: yup.number().default(24),
        transparency: yup.number().default(0),
        itemsMargin: yup.boolean().default(true),
        margin: yup.number().min(0).max(10).default(6),
        containerMargin: yup.boolean().default(true),
        containerMarginValue: yup.number().min(0).max(10).default(6),
        itemsShadow: yup.boolean().default(false),
        allowTheme: yup.boolean().default(true),
        borderRadius: yup.boolean().default(true),
        borderStyle: yup.string().default('DEFAULT'),
        borderColor: yup.string().default(''),
        migrateToFreeLayout: yup.boolean().default(false),
        visibleBorder: yup.object().shape({
            top: yup.boolean().default(false),
            bottom: yup.boolean().default(false),
            left: yup.boolean().default(false),
            right: yup.boolean().default(false),
        })
    });
});

export const dashLayoutInitialValues = (dashboardStyle = null) => {
    if (!dashboardStyle) return DashLayoutSchema.default();

    return {
        backgroundColor: dashboardStyle.background.color,
        backgroundTheme: dashboardStyle.backgroundTheme,
        highlightColor: dashboardStyle.highlightBoxColor.toUpperCase(),
        backgroundImage: dashboardStyle.backgroundImage,
        itemsMargin: dashboardStyle.allowMargin,
        margin: dashboardStyle.margin,
        containerMargin: dashboardStyle.allowContainerMargin,
        containerMarginValue: dashboardStyle.containerMargin,
        borderRadius: dashboardStyle.borderRadius,
        allowTheme: dashboardStyle.allowTheme,
        itemsShadow: dashboardStyle.allowBoxShadow,
        divisions: dashboardStyle.layout.divisions,
        transparency: dashboardStyle.itemTransparency,
    };
};

export const LabelHelper = ({label, helpLabel}) => {
    return (
        <>
            <span>{label}</span>
            {' '}
            <HelpIcon title={helpLabel}/>
        </>
    )
}

const ThemeAccordion = ({theme, setFieldValue, allowTheme, context}) => {
    //Handle allowTheme
    useEffect(() => {
        if (allowTheme && theme === 'NONE') {
            setFieldValue('dashLayout.backgroundTheme', 'WHITE');
            setFieldValue('dashLayout.margin', 6);
            setFieldValue('dashLayout.itemsMargin', true);
            setFieldValue('dashLayout.containerMarginValue', 6);
            setFieldValue('dashLayout.containerMargin', true);
            setFieldValue('dashLayout.borderRadius', true);
        }
    }, [allowTheme]);

    //Handle default theme schema
    const handleThemeChange = (event) => {
        switch (event.target.value) {
            case 'WHITE':
                setFieldValue('dashLayout.highlightColor', '#528de3');
                setFieldValue('dashLayout.itemsShadow', false);
                setFieldValue('dashLayout.transparency', 0);
                break;
            case 'BLACK':
                setFieldValue('dashLayout.highlightColor', '#6925e8');
                setFieldValue('dashLayout.itemsShadow', true);
                setFieldValue('dashLayout.transparency', 60);
                break;
            case 'CORPORATIVE':
                setFieldValue('dashLayout.highlightColor', '#6925e8');
                setFieldValue('dashLayout.itemsShadow', false);
                setFieldValue('dashLayout.transparency', 70);
                break;
        }
    }

    return (
        <Accordion title={context.msg.t('theme')} id="ThemeAccordion">
            <Field name="dashLayout.backgroundTheme"
                   component={BngField}
                   withLabel={false}
                   inputComponent={BngSelect}
                   emptyOption={false}
                   options={THEMES(context)}
                   onChange={handleThemeChange}
            />
            <Field label={context.msg.t('highlight')}
                   name="dashLayout.highlightColor"
                   component={BngField}
                   rootClassName="HighlightColor"
                   inputComponent={BngLineInputColor}
                   enablePicker={false}
                   defaultOptions={[]}
                   fetchProjectColors={false}
                   addTransparentOptions={false}
                   options={HIGHLIGHT_COLORS}
            />
        </Accordion>
    )
};

const BackgroundAccordion = ({context}) => {
    return (
        <Accordion startOpen={false} title={context.msg.t('background')} id="BackgroundAccordion">
            <Field label={context.msg.t('background.image')}
                   name="dashLayout.backgroundImage"
                   component={BngField}
                   inputComponent={BngImageUpload}
                   asProps={{height: 150, width: '100%'}}
            />
        </Accordion>
    )
};

const ProportionsAccordion = ({isFreeLayout, migrateToFreeLayout, setFieldValue, context, divisions}) => {
    const disableDivisions = !isFreeLayout && !migrateToFreeLayout;
    const [value, setValue] = useState(divisions);
    return (
        <Accordion startOpen={false} title={context.msg.t('proportions')} id="ProportionsAccordion">
            <label className="control-label" style={{display: 'flex', alignItems: 'center'}}>
                <LabelHelper helpLabel={context.msg.t('dashboard.divisions.hint')}
                             label={context.msg.t('divisions')}/>
            </label>
            <Field name="dashLayout.divisions"
                   disabled={disableDivisions}
                   title={disableDivisions ? context.msg.t('dash.option.available.new.layout') : ''}
                   component={BngField}
                   withLabel={false}
                   emptyOption={false}
                   inputComponent={BngSelect}
                   options={LAYOUT_DIVISIONS(context)}
                   onChange={(event) => setValue(event.target.value)}
                   value={value}
            />
            {disableDivisions &&
            <Button className="bng-button save" style={{width: '100%', margin: '10px 0 0'}}
                    onClick={() => setFieldValue('dashLayout.migrateToFreeLayout', true)}>
                {context.msg.t('dash.migrate.layout')}
            </Button>
            }
        </Accordion>
    )
};

const AppearanceAccordion = ({context}) => {
    return (
        <Accordion startOpen={false} title={context.msg.t('appearance')} id="AppearanceAccordion">
            <Field name="dashLayout.transparency"
                   label={<LabelHelper helpLabel={context.msg.t('dashboard.transparency.hint')}
                                       label={context.msg.t('transparency')}/>}
                   component={BngField}
                   inputComponent={BngSlider}
                   rootClassName="TransparencyField"
            />
            <div className="CheckInputOption">
                <Field name="dashLayout.itemsMargin"
                       label={<LabelHelper helpLabel={context.msg.t('dashboard.add.margin.hint')}
                                           label={context.msg.t('dashboard.add.margin')}/>}
                       component={BngCheckbox}
                />
                <Field name="dashLayout.margin"
                       type="number"
                       min={0} max={10}
                       component={BngInput}
                />
            </div>
            <div className="CheckInputOption">
                <Field name="dashLayout.containerMargin"
                       label={<LabelHelper context={context}
                                           helpLabel={context.msg.t('dash.container.margin.hint')}
                                           label={context.msg.t('dash.container.margin')}/>}
                       component={BngCheckbox}
                />
                <Field name="dashLayout.containerMarginValue"
                       type="number"
                       min={0} max={10}
                       component={BngInput}
                />
            </div>
            <Field name="dashLayout.itemsShadow"
                   className="DashItemShadow"
                   label={<LabelHelper context={context}
                                       helpLabel={context.msg.t('dashboard.add.shadow.box.hint')}
                                       label={context.msg.t('dashboard.add.shadow.box')}/>}
                   component={BngCheckbox}
            />
            <Field name="dashLayout.borderRadius"
                   label={context.msg.t('dash.border.rounded')}
                   component={BngCheckbox}
            />
        </Accordion>
    )
};

const AdvancedAccordion = ({allowTheme, context}) => {
    return (
        <Accordion title={context.msg.t('advanced')} id="AdvancedAccordion">
            <Field name="dashLayout.allowTheme"
                   label={allowTheme ? context.msg.t('disable.themes') : context.msg.t('enable.themes')}
                   component={BngSwitch}
            />
        </Accordion>
    )
};

const DashboardAccordion = ({context}) => {
    return (
        <Accordion title={context.msg.t('dashboard')} id="DashboardAccordion">
            <Field label={context.msg.t('background.color')}
                   name="dashLayout.backgroundColor"
                   component={BngField}
                   inputComponent={BngLineInputColor}
                   fetchProjectColors={false}
                   addTransparentOptions={false}
            />
            <Field name="dashLayout.borderStyle"
                   component={BngField}
                   label={context.msg.t('borders')}
                   emptyOption={false}
                   inputComponent={BngSelect}
                   options={BORDERS(context)}
            />
            <Field label=""
                   name="dashLayout.borderColor"
                   component={BngField}
                   inputComponent={BngLineInputColor}
                   fetchProjectColors={false}
                   addTransparentOptions={false}
            />
            <label>{context.msg.t('border.visibility')}</label>
            <Field name="dashLayout.visibleBorder.top"
                   label={context.msg.t('TOP')}
                   component={BngCheckbox}
            />
            <Field name="dashLayout.visibleBorder.right"
                   label={context.msg.t('RIGHT')}
                   component={BngCheckbox}
            />
            <Field name="dashLayout.visibleBorder.bottom"
                   label={context.msg.t('BOTTOM')}
                   component={BngCheckbox}
            />
            <Field name="dashLayout.visibleBorder.left"
                   label={context.msg.t('LEFT')}
                   component={BngCheckbox}
            />
        </Accordion>
    )
};

const DashLayoutAccordion = ({
                                 dashboardStyle = {},
                                 dirty,
                                 ...props
                             }) => {
    const {
        values,
        initialValues,
        isSubmitting,
        setFieldValue,
        setSubmitting,
    } = useFormikContext();

    const applyDashLayout = async () => {
        try {
            let submitValues = Object.assign({}, values.dashLayout);

            if (!!submitValues.backgroundImage && typeof submitValues.backgroundImage !== 'string') {
                const links = await Api.Upload.upload(submitValues.backgroundImage);
                submitValues.backgroundImage = links[0].link;
            }

            await Api.Dash.saveStyle(submitValues);
            await Api.updateJsf();
            UiMsg.ok(props.context.msg.t('dash.layout.applied.success'));
        } catch (e) {
            console.error('Error on applyDashLayout()', e);
            UiMsg.ajaxError(null, e);
        } finally {
            setSubmitting(false);
        }
    };

    if (dashboardStyle === null) {
        return null;
    }

    return (
        <>
            <AccordionList className="ObjectRightMenuAccordion DashboardLayoutAccordion HasFixedButton">
                {values.dashLayout.allowTheme &&
                <>
                    <ThemeAccordion setFieldValue={setFieldValue}
                                    allowTheme={values.dashLayout.allowTheme}
                                    theme={values.dashLayout.backgroundTheme}
                                    highlightColor={values.dashLayout.highlightColor}
                                    {...props}/>
                    <BackgroundAccordion {...props}/>
                </>
                }
                <ProportionsAccordion isFreeLayout={!dashboardStyle.layout.freeLayout}
                                      migrateToFreeLayout={values.dashLayout.migrateToFreeLayout}
                                      setFieldValue={setFieldValue}
                                      divisions={initialValues.dashLayout.divisions}
                                      {...props}/>

                {values.dashLayout.allowTheme &&
                <AppearanceAccordion {...props}/>
                }

                {!values.dashLayout.allowTheme &&
                <>
                    <DashboardAccordion {...props}/>
                    <AdvancedAccordion allowTheme={values.dashLayout.allowTheme} {...props}/>
                </>
                }

            </AccordionList>
            <Accordion className="ApplyDashLayout AccordionFixedButton" customTitle={() => null}>
                <Button className="bng-button save"
                        style={{margin: 0}}
                        type="button"
                        onClick={applyDashLayout}
                        disabled={_.isEqual(values.dashLayout, initialValues.dashLayout)}
                        loading={isSubmitting}>
                    {props.context.msg.t('apply')}
                </Button>
            </Accordion>
        </>
    )
};

export default ContextEnhancer(DashLayoutAccordion);