// css imported on CommonCssImports.js
import React from 'react';
import PropTypes from 'prop-types';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class RightFragment extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        label: PropTypes.string,
        target: PropTypes.number,
        targetLabel: PropTypes.string,
        preffix: PropTypes.string,
        suffix: PropTypes.string,
        value: PropTypes.any,
        image: PropTypes.string,
        color: PropTypes.string,
        percentage: PropTypes.number,
        model: PropTypes.string,
        useBands: PropTypes.bool,
        imageType: PropTypes.string,
        kpiId: PropTypes.number,
        bandsInformations: PropTypes.array,
        growthViewModel: PropTypes.object,
        measureAsTarget: PropTypes.bool,
        growthInfoRender: PropTypes.bool,
        viewBands: PropTypes.bool,
        minValue: PropTypes.number,
        isPercent: PropTypes.bool,
        invert: PropTypes.bool,
        error: PropTypes.string,
        useDecimals: PropTypes.bool,
        decimalCasesIndicator: PropTypes.number,
        colorTarget: PropTypes.string,
        showNegativeVariationOption: PropTypes.bool,
        percentValueVariation: PropTypes.bool,
        textAlign: PropTypes.string,
        div: PropTypes.any,
        shouldChangeColor: PropTypes.bool,
        renderTable: PropTypes.bool,
        container: PropTypes.any,
        align: PropTypes.string,
        titleFormattedValue: PropTypes.string,
    };

    static defaultProps = {
        title: '',
        label: '',
        target: 0,
        targetLabel: '',
        preffix: '',
        suffix: '',
        value: 0,
        image: '',
        color: '',
        percentage: '',
        model: '',
        useBands: false,
        imageType: '',
        kpiId: 0,
        bandsInformations: [],
        growthViewModel: {},
        measureAsTarget: false,
        growthInfoRender: false,
        viewBands: true,
        minValue: 0,
        isPercent: false,
        invert: false,
        error: '',
        useDecimals: false,
        decimalCasesIndicator: 2,
        colorTarget: '',
        showNegativeVariationOption: false,
        percentValueVariation: false,
        textAlign: '',
        shouldChangeColor: false,
        renderTable: true,
        align: 'center',
        titleFormattedValue: '',
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.resizeText(this.props.width, this.props.height);
    }

    componentDidUpdate(prevProps) {
        this.resizeText(this.props.width, this.props.height);
    }

    resizeFont = (height, width) => {
        const valuePercentFontSize = this.props.label.length === 0 ? 0.21 : 0.13;
        const valueMinFontSize = 16;
        const valuePercentLineHeight = valuePercentFontSize + 0.06;
        const valueMinFontLineHeight = 30;

        const $kpiInternalBody = this.props.container;
        const sizes = BaseKpi.keepProportion({width, height, model: ''});

        height = sizes.height;

        if (this.props.align === 'left' && !!$kpiInternalBody.offsetParent) {
            const mainDiv = $kpiInternalBody.offsetParent.querySelector(".KpiBody");
            let divValueWidth = mainDiv.offsetWidth - $kpiInternalBody.offsetWidth;
            this.$containerValues.style.width = divValueWidth * 0.9 + 'px';
        }

        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 14, multiplier: 0.12, refSize: height},
            fontWeight: {value: 'bold'},
        });

        resizeEl(this.$kpiValueEl, {
            fontSize: {minValue: valueMinFontSize, multiplier: valuePercentFontSize, refSize: height},
            lineHeight: {minValue: valueMinFontLineHeight, multiplier: valuePercentLineHeight, refSize: height},
            fontWeight: {value: 'bold'},
        });

        const containerWidth = this.$containerValues.offsetWidth;
        if (this.$kpiValueEl.offsetWidth >= containerWidth) {
            const newFontSize = height * (containerWidth / this.$kpiValueEl.offsetWidth);
            resizeEl(this.$kpiValueEl, {
                fontSize: {minValue: valueMinFontSize, multiplier: valuePercentFontSize, refSize: newFontSize},
                lineHeight: {minValue: valueMinFontLineHeight, multiplier: valuePercentLineHeight, refSize: newFontSize},
                fontWeight: {value: 'bold'},
            });
        }

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 10, multiplier: 0.06, refSize: height},
            lineHeight: {minValue: 11, multiplier: 0.08, refSize: height},
            fontWeight: {value: 'bold'},
        });
    };

    resizeText = (width, height, checkScroll = true) => {
        if (this.props.div) {
            this.resizeFont(height, width);
            const widthPreview = this.props.width;
            if (checkScroll && widthPreview < this.props.div.scrollWidth) {
                let x = (widthPreview / this.props.div.scrollWidth);
                height = height * (x);
                this.resizeText(this.props.width, height, false);
            }
        }
    };

    renderContent = () => {
        const {title, color, textAlign, preffix, value, suffix, label, titleFormattedValue} = this.props
        const _title = this.props.value.toString().includes(':') ? value : titleFormattedValue;

        return (
            <div className="kpi-body-values">
                <div className="kpititle" title={title} ref={el => this.$kpiTitleEl = el}>
                    {title}
                </div>
                <div className="kpivalue" title={_title}>
                    <div className="infobox-data-number" style={{whiteSpace: 'nowrap'}}>
                        <span ref={el => this.$kpiValueEl = el}
                              style={{textAlign: textAlign, color: color, whiteSpace: 'nowrap'}}
                              className="KpiValueSpan">{preffix} {value} {suffix} </span>
                    </div>
                </div>
                <div className="kpidescription" ref={el => this.$kpiDescriptionEl = el} title={label}>
                    {label}
                </div>
            </div>
        )
    };

    render() {
        return (
            <div className="KpiRightFragment" ref={el => this.$containerValues = el}>
                {this.renderContent()}
            </div>
        )
    }

}
