import "./CreatePresentation.css"

import React from "react";
import PropTypes from "prop-types";
import {Form, Formik} from "formik";

import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import {Tab, TabSet} from "components/ui/TabSet";
import {bngYup} from "components/bng/form/yup/BngYup";
import {DefaultDialogActions} from "components/ui/FormUtils";
import UiMsg from "components/ui/UiMsg";
import PresentationProperties from "components/ui/presentation/PresentationProperties";
import PresentationAddObject from "components/ui/presentation/PresentationAddObject";
import {DropdownSlideType} from "components/ui/presentation/DropdownSlideType";
import Utils from "components/Utils";
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

const transitionStyle = ['DEFAULT', 'CUBE', 'PAGE', 'CONCAVE', 'ZOOM', 'LINEAR', 'FADE', 'NONE'];

const NewPresentationSchema = bngYup(yup => {
    return yup.object().shape({
        logotypeImageUpload: yup.object().nullable().default(null),
        backgroundImageUpload: yup.object().nullable().default(null),
        namePresentation: yup.string().required().trim().default(''),
        theme: yup.string().default('#575bd8'),
        transition: yup.string().required().oneOf(transitionStyle).default('DEFAULT'),
        executiveTemplate: yup.boolean().default(false),
        openObject: yup.boolean().default(false),
        refreshPresentation: yup.number().default(0),
    });
});

const buildUploadUrl = (fileName) => {
    if (_.isEmpty(fileName)) {
        return ''
    }
    return `/api/presentations/uploads?${new URLSearchParams({content: fileName})}`;
}

class CreatePresentation extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func,
        idPresentation: PropTypes.number,
        fromCockpit: PropTypes.bool,
        itemsCockpit: PropTypes.array
    };

    state = {
        items: [],
        loading: true,
        enableButtonAddItems: false
    };

    static defaultProps = {
        refreshPresentations: () => Promise.resolve(true),
        idPresentation: 0,
        fromCockpit: false,
        itemsCockpit: []
    };

    async componentDidMount() {
        if (!this.props.context.permissions.canCreatePresentations()) {
            UiMsg.warn(this.props.context.msg.t('access.denied'), this.props.context.msg.t('user.dont.have.access.feature'));
            this.props.closeModal();
            return;
        }

        try {
            if (this.props.idPresentation > 0) {
                const values = await Api.Presentation.findOne(this.props.idPresentation);
                if (!_.isEmpty(values)) {
                    await this.buildConfigData(values);
                }
            }

            if (this.props.fromCockpit) {
                const {itemsCockpit} = this.props;
                if (!_.isEmpty(itemsCockpit)) {
                    await this.buildSlidesItems(itemsCockpit);
                }
            }
            this.setState({loading: false})
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError('', e);
            this.props.closeModal();
        }
    }

    initialFormValues = NewPresentationSchema.default();

    constructor(props) {
        super(props);
    }

    checkValidInput = (selector, message, details) => {
        let validationInputs = {element: {value: ""}, valid: true, message, details};
        j(selector).each(function () {
            const element = j(this)[0];
            if (!element.checkValidity()) {
                validationInputs.element = element;
                validationInputs.valid = false;
                return false;
            }
        });
        return validationInputs;
    };

    areSlidesValid = () => {
        let fieldLinks = this.checkValidInput('.input-link-presentation', 'url');

        if (fieldLinks.valid) {
            fieldLinks = this.checkValidInput('.input-time-presentation', 'presentation.time.column.label', 'presentation.invalid.time.seconds');
        }

        if (!fieldLinks.valid) {
            let errorMsg = !!fieldLinks.details ? 'invalid.field.input.details' : 'invalid.field.input';
            UiMsg.error(this.props.context.msg.t(errorMsg, [
                this.props.context.msg.t(fieldLinks.message),
                fieldLinks.element.value,
                this.props.context.msg.t(fieldLinks.details)
            ]));
        }

        return fieldLinks.valid;
    };

    isValidOnlyPresentation = () => {
        let fieldLinks = this.checkValidInput('.presentation-input-refreshPresentation', 'presentation.refresh.time');
        if (!fieldLinks.valid) {
            UiMsg.error(this.props.context.msg.t('invalid.field.input', [this.props.context.msg.t(fieldLinks.message), fieldLinks.element.value]));
        }
        return fieldLinks.valid;
    };

    onTabChange = ({next}) => {
        switch (next) {
            case 0:
                this.setState({enableButtonAddItems: false});
                return this.areSlidesValid();
            case 1:
                this.setState({enableButtonAddItems: true});
                return this.isValidOnlyPresentation();
            default:
                return this.areSlidesValid() && this.isValidOnlyPresentation();
        }
    };

    beforeSave = async (values, actions) => {
        if (this.state.items.length === 0) {
            UiMsg.error(this.props.context.msg.t('presentation.no.slide'));
            actions.setSubmitting(false);
            return;
        }

        if (!!this.state.items.find(el => el.path === '')) {
            UiMsg.error(this.props.context.msg.t('presentation.save.slides.incomplete.data'));
            actions.setSubmitting(false);
            return;
        }

        if (!this.onTabChange({})) {
            return;
        }

        if (checkAddonEnabled(AddonType.PRESENTATION.key, true)) {
            await this.save(values)
        }
    };

    save = async (values) => {
        this.setState({loading: true});

        if (values.refreshPresentation < 0) {
            values.refreshPresentation = 0;
        }

        values.id = this.props.idPresentation;
        values.presentationItems = this.state.items;
        values.accountId = this.props.context.project.accountId;

        try {
            if (values.backgroundImageUpload !== null && typeof values.backgroundImageUpload !== 'string') {
                const links = await Api.Upload.upload(values.backgroundImageUpload);
                values.backgroundImageUpload = links[0].path;
            }

            if (values.logotypeImageUpload !== null && typeof values.logotypeImageUpload !== 'string') {
                const links = await Api.Upload.upload(values.logotypeImageUpload);
                values.logotypeImageUpload = links[0].path;
            }
            await Api.Presentation.savePresentation(values);
            UiMsg.ok(this.props.context.msg.t('presentation.save.sucess'));
            await this.props.refreshPresentations();
            this.props.closeModal();
        } catch (e) {
            console.error('Error on save()', {values}, e);
            const errorMsg = e.status === 409
              ? 'presentation.name.already.exists'
              : 'presentation.save.error';
            UiMsg.ajaxError(this.props.context.msg.t(errorMsg), e);
            this.setState({loading: false});
        }
    };

    itemsChanged = (items) => {
        this.setState({items: items});
    };

    getColorByTheme = (theme) => {
        switch (theme) {
            case "":
                return "#575bd8";
            case "skin-7":
                return "#2C6AA0";
            case "skin-2":
                return "#e89702";
            case "skin-3":
                return "#393939";
            case "skin-4":
                return "#CE171F";
            case "skin-5":
                return "#4DB748";
            case "skin-6":
                return "#AAAAAA";
        }
    };


    getObjectNameByPath = async (path) => {
        let name = "";
        try {
            name = await Api.Bng.getPathCaption(path);
        } catch (e) {
            UiMsg.ajaxError('error', e);
        }
        return name;
    };

    buildSlidesItems = async (slides) => {
        let items = [];

        for (let i = 0; i < slides.length; i++) {
            let slide = slides[i];

            let itemValues = {
                id: slide.id,
                visualization: slide.viewType,
                time: slide.timeout,
                type: slide.type,
                path: slide.content
            };

            switch (slide.type) {
                case 'APPLICATION_OBJECT':
                    itemValues.enableViews = await Utils.Object.getViewType(slide.content);
                    itemValues.slide = await this.getObjectNameByPath(slide.content);
                    break;
                case 'EXTERNAL_LINK':
                    itemValues.slide = this.props.context.msg.t('link');
                    break;
                case 'HTML_COMPONENT':
                    itemValues.slide = this.props.context.msg.t('page');
                    break;
                default:
                    break;
            }
            items.push(itemValues);
        }

        this.setState({items: items});
    };


    buildConfigData = async (valuesConfig) => {
        this.initialFormValues.namePresentation = valuesConfig.name;
        this.initialFormValues.transition = valuesConfig.style.transitionStyle;
        this.initialFormValues.theme = this.getColorByTheme(valuesConfig.style.theme);
        this.initialFormValues.logotypeImageUpload = buildUploadUrl(valuesConfig.style.logo);
        this.initialFormValues.backgroundImageUpload = buildUploadUrl(valuesConfig.style.backgroundImage);
        this.initialFormValues.executiveTemplate = valuesConfig.executive;
        this.initialFormValues.openObject = valuesConfig.viewObject;
        this.initialFormValues.refreshPresentation = valuesConfig.refreshPresentation;
        await this.buildSlidesItems(valuesConfig.slides)
    };


    render() {
        const title = this.props.idPresentation > 0 ? 'edit.presentation' : 'new.presentation';

        for (const item of this.state.items) {
            if (!item.hasOwnProperty('uuid')) {
                item.uuid = Utils.randomId();
            }
        }

        return (
            <Formik initialValues={this.initialFormValues}
                    validationSchema={NewPresentationSchema}
                    onSubmit={this.beforeSave}>
                {({errors, submitCount}) => {
                    return (
                        <Dialog contentFullWidth={true}
                                className="CreatePresentationDialog large"
                                open={this.props.open}
                                loading={this.state.loading}
                                title={this.props.context.msg.t(title)}
                                onClose={this.props.closeModal}>

                            {this.state.enableButtonAddItems &&
                                <DropdownSlideType onChange={this.itemsChanged}
                                                   items={this.state.items}
                                />
                            }

                            <Form noValidate={true}>
                                <TabSet className="grey-bg" bodyRadius={false} vertical={false}
                                        beforeChange={this.onTabChange}>
                                    <Tab label={this.props.context.msg.t('properties_button')}
                                         containError={!!errors.namePresentation}
                                         icon="settings">
                                        <PresentationProperties transitionStyle={transitionStyle}/>
                                    </Tab>
                                    <Tab label={this.props.context.msg.t('presentation.slides.config')}
                                         icon="airplay">
                                        <PresentationAddObject onChange={this.itemsChanged}
                                                               items={this.state.items}/>
                                    </Tab>
                                </TabSet>
                                <DefaultDialogActions contentFullWidth={true} {...this.props}/>
                            </Form>

                        </Dialog>
                    )
                }}
            </Formik>

        )
    }

}

export default ContextEnhancer(CreatePresentation);