import React, { useEffect, useState } from 'react';
import Api from 'components/Api';
import ObjectRightMenu from 'components/ui/right-menu/ObjectRightMenu';
import useEventBus from 'components/hooks/useEventBus';
import { KPI_EDIT_MENU_EVENT } from 'components/bng/pages/kpi/KpiEditMenu';
import useQueryParams from 'components/hooks/useQueryParams';

export default function KpiRightMenu(props) {
  const [mutations, setMutations] = useState();
  const { queryParams } = useQueryParams();

  const saveKpi = async ({ submitForm = _.noop }) => {
    if (mutations) {
      const kpi = await Api.Kpi.update(props.path, mutations);
      setMutations(null);
      bimEventBus.emit(Api.Kpi.KPI_UPDATED_EVENT, kpi);
    }

    await submitForm();
  };

  const removeKpi = async () => {
    await Api.executeExp(`#{viewKpiMB.delete()}`);
  };

  useEventBus(Api.Kpi.KPI_EDIT_MENU_APPLY_EVENT, ({ mutations }) => {
    setMutations(mutations);
  });

  useEffect(() => {
    if (queryParams.get('edit') === 'true') {
      bimEventBus.emit(KPI_EDIT_MENU_EVENT, { path: props.path, mutations });
    }
  }, []);

  useEffect(() => {
    if (props.open) {
      bimEventBus.emit(KPI_EDIT_MENU_EVENT, { path: props.path, mutations });
    }
  }, [props.open]);

  return (
    <ObjectRightMenu
      {...props}
      handleSubmit={saveKpi}
      save={saveKpi}
      saveAs={async (values) => {
        values = {
          ...values,
          mutations,
        };
        return await Api.Kpi.saveAs(values);
      }}
      remove={removeKpi}
      dirty={!_.isNil(mutations)}
      kpiMutations={mutations}
    />
  );
}
