import React, {useCallback, useEffect, useRef, useState} from "react";

import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import ContextEnhancer from "components/ContextEnhancer";
import {ConfigureSchema} from "components/ui/common/ConfigureAccordion";
import {dashLayoutInitialValues, DashLayoutSchema} from "components/ui/dashboard/DashLayoutAccordion";
import {bngYup} from "components/bng/form/yup/BngYup";
import ObjectRightMenu from "components/ui/right-menu/ObjectRightMenu";
import {FilterSchema} from "components/ui/dashboard/FilterAccordion";
import {ConfigureFilterSchema} from "components/ui/dashboard/ConfigureFilterAccordion";
import useEventBus from "components/hooks/useEventBus";
import useRightMenuStore from "components/ui/dashboard/components/useRightMenuStore";

const ValidationSchema = bngYup(yup => {
    return yup.object().shape({
        dashLayout: DashLayoutSchema,
        objectConfig: ConfigureSchema,
        filterConfig: FilterSchema,
    })
});

const fetchStyle = async () => {
    try {
        const result = await Api.Dash.getStyle();
        result.persisted = dashLayoutInitialValues(result.persisted);
        return result;
    } catch (e) {
        console.error('Error getting dashboardStyle', e);
        UiMsg.error(null, e);
    }
};

const DashRightMenu = ({renderId, ...props}) => {
    const $formikRef = useRef();
    const [dashboardStyles, setDashboardStyles] = useState(null);
    const [printerConfig, fetchPrinterConfig] = useRightMenuStore(state => [
        state.printerConfig,
        state.fetchPrinterConfig
    ]);

    useEffect(() => {
        (async () => {
            const result = await fetchStyle();
            setDashboardStyles(result);
        })();
    }, [renderId]);


    const currentDashboardStyle = dashboardStyles?.current;

    const [projectFilters, setProjectFilters] = useState([]);
    const [dataSources, setDataSources] = useState([]);
    const [filterConfig, setFilterConfig] = useState(null);
    const [initialValues, setInitialValues] = useState({
        dashLayout: dashLayoutInitialValues(currentDashboardStyle),
    });
    const [filters, setFilters] = useState(null);
    const [isReloaded, setIsReloaded] = useState(false);
    const [lastDashOpts = {}, setLastDashOpts] = useState(window.___LAST_DASHBOARD_OPTS);
    const {availableFilters = {}, itemsData = {}} = lastDashOpts;

    useEffect(() => {
        fetchData();
    }, []);

    const skipRender = filterConfig === null || dashboardStyles === null || filters === null;

    const fetchFilterConfig = async () => {
        try {
            let data = await Api.Dash.getFiltersConfig(props.path);
            setFilterConfig(data);
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
    };

    const fetchProjectFilters = async () => {
        try {
            const mdxGlobalFilters = await Api.MdxGlobalFilter.findAll(props.context.project.id, true);
            const _filters = mdxGlobalFilters.map(mgf => {
                return {
                    id: mgf.id,
                    caption: mgf.displayName || mgf.name,
                    name: mgf.name,
                    timeFilter: mgf.type === 'TIME',
                    datasources: mgf.mdxFilters.map(mf => mf.datasource),
                };
            });
            setProjectFilters(_filters);
            return _filters;
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
        return [];
    };

    const fetchFilters = async () => {
        try {
            const result = availableFilters
              .filter(af => lastDashOpts.filters.hasOwnProperty(af.id))
              .map(af => {
                    return _.merge(ConfigureFilterSchema.default(), {
                        id: af.id,
                        caption: af.caption,
                        hide: af.hide,
                        filterLink: af.filterLink,
                        required: af.required,
                        fixed: af.fixed,
                        periodRestriction: af.periodRestriction,
                        restrict: {
                            enabled: af.restrictMembers,
                            restrictionType: af.restrictionType,
                            members: (af.requiredFilterMembers || []).slice(),
                        },
                        type: af.type
                    });
            });
            setFilters(result);
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
    };

    const fetchData = () => {
        Promise.allSettled([
            fetchFilterConfig(),
            fetchProjectFilters(),
            fetchFilters(),
            fetchPrinterConfig(props.path),
        ]);
        $formikRef.current?.resetForm();
    };

    useEffect(() => {
        if (skipRender) {
            return;
        }

        buildInitialState();

        window.__FILTERS_FORM_RESET = (reloadFilters = false) => {
            setIsReloaded(true);
            if (reloadFilters) setLastDashOpts(window.___LAST_DASHBOARD_OPTS);
            fetchData();
        }
    }, [currentDashboardStyle, filterConfig, filters, itemsData]);


    const buildInitialState = () => {
        setInitialValues({
            dashLayout: dashLayoutInitialValues(currentDashboardStyle),
            filterConfig: {
                ...FilterSchema.default(),
                startRetracted: filterConfig.startRetracted,
                position: filterConfig.filterPosition,
                filters: filters,
                customFilters: buildCustomFilters(itemsData)
            },
        });
    }

    const fetchDataSources = async () => {
        try {
            const fetchDataSources = await Api.Dash.getDataSources();
            setDataSources(fetchDataSources);
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
    };

    useEffect(() => {
        if (skipRender) return;
        (async () => {
            await fetchFilters();
            window.requestAnimationFrame(() => {
                buildInitialState()
            });
        })();
    }, [lastDashOpts, skipRender]);

    const buildCustomFilters = () => {
        let customFilters = [];
        for (const key in itemsData) {
            const item = itemsData[key];
            if (item.isText || !item.restrictFilters) continue;

            customFilters.push({
                itemId: key,
                filters: item.restrictFilterIds.map(id => ({id})),
            });
        }
        return customFilters;
    };

    const saveDashboard = async ({submitForm = _.noop}) => {
        const {msg} = props.context;
        try {
            await Api.executeExp(`#{dashboardBean.saveChangesCurrentDashboard()}`);
            UiMsg.ok(msg.t('save_success', msg.t('dashboard')));
            await submitForm();
        } catch (e) {
            UiMsg.error(null, e);
        }
    };


    const dashSaveAs = async (newProps) => {
        if (printerConfig === null) {
            await fetchPrinterConfig(props.path);
        }
        try {
            const persistedDash = await Api.Dash.saveAs(newProps, printerConfig);
            UiMsg.ok(props.context.msg.t('object.save.success'));
            window.location.href = Api.loadObjectUrl({content: persistedDash.path});
        } catch (e) {
            UiMsg.error(null, e);
        }
    }

    const removeDashboard = () => {
        try {
            Api.executeExp(`#{dashboardBean.deleteDashboard()}`);
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    const dirtyCheckFunction = useCallback(({initialValues}) => {
        if (props.dirty) return true;
        if (!dashboardStyles) return false;
        return !_.isEqual(initialValues.dashLayout, dashboardStyles.persisted);
    }, [props.dirty, dashboardStyles]);

    const handleFilterSubmit = async (values) => {
        const {loaded, ...filterData} = Object.assign({}, values.filterConfig);
        try {
            await Api.Dash.applyFilterConfig({filterData: filterData});
            await Api.updateJsf();
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
    };

    useEventBus('Change:___LAST_DASHBOARD_OPTS', (opts) => {
        setLastDashOpts(opts);
    });

    useEffect(() => {
        if (!$formikRef.current) return;

        let formValues;
        if (_.isEqual($formikRef.current?.values?.filterConfig, initialValues.filterConfig)) {
            formValues = {...($formikRef.current?.values ?? {}), ...(initialValues ?? {})};
        } else {
            formValues = {...(initialValues ?? {}), ...($formikRef.current?.values ?? {})};
        }

        if (_.isEqual($formikRef.current.values, formValues)) return;

        $formikRef.current.resetForm({values: formValues});
    }, [initialValues, $formikRef.current])

    if (skipRender) {
        return null;
    }

    return (
        <ObjectRightMenu {...props}
                         initialValues={initialValues}
                         isReloaded={isReloaded}
                         setIsReloaded={setIsReloaded}
                         dirtyCheckFunction={dirtyCheckFunction}
                         validationSchema={ValidationSchema}
                         dashboardStyle={currentDashboardStyle}
                         save={saveDashboard}
                         remove={removeDashboard}
                         saveAs={dashSaveAs}
                         availableFilters={availableFilters}
                         itemsData={itemsData}
                         projectFilters={projectFilters}
                         fetchFilters={fetchProjectFilters}
                         dataSources={dataSources}
                         fetchDataSources={fetchDataSources}
                         handleFilterSubmit={handleFilterSubmit}
                         onFormikRef={$formikRef}
                         printer={printerConfig}
        />
    );
}

export default ContextEnhancer(DashRightMenu);