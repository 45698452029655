import styles from './BimQueryPage.module.css';

import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import BimQueryRightMenu from 'components/bng/pages/admin/structures/bimQuery/BimQueryRightMenu';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BimQuerySqlEditor from 'components/bng/pages/admin/structures/bimQuery/BimQuerySqlEditor';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { Tab, TabSet } from 'components/ui/TabSet';
import AssistedMode from 'components/bng/pages/admin/structures/bimQuery/AssistedMode';
import BngDropdown from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import QueryResultPreview from 'components/bng/pages/admin/structures/bimQuery/menuTabs/QueryResultPreview';
import ColumnsConfiguration from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ColumnsConfiguration';
import LogPreview from 'components/bng/pages/admin/structures/bimQuery/menuTabs/LogPreview';
import bimEventBus from 'BimEventBus';
import BngIconButton from 'components/bng/ui/BngIconButton';

const Schema = bngYup((yup) => {
  return yup.object({
    sql: yup.string().required().default(''),
    fieldConfigs: yup.array(yup.object()).default([]),
    connection: yup.number().required().default(0),
    structureName: yup.string().required().default(''),
    structureIcon: yup.string().required().default('cloud'),
    structureGroup: yup.number().default(0),
    structureDescription: yup.string().required().default(''),
    structureType: yup.string().required().default(''),
    fullLoads: yup.boolean().default(false),
  });
});

export default function BimQueryPage() {
  const { msg, project, labs } = useBimContext();

  const $runningTimeRef = useRef();

  const [accordionOpened, setAccordionOpened] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [resultLimit, setResultLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [runningTime, setRunningTime] = useState('00:00');
  const [queryExecutionLog, setQueryExecutionLog] = useState(null);

  const initialValues = Schema.default();

  useEffect(() => {
    if (labs.features.some((feature) => feature === 'BIM_QUERY')) {
      return;
    }
    window.location.replace(Api.buildUrl('/pages/errors/403.iface'));
  }, []);

  const startRunningTimer = () => {
    let time = 0;
    const executionTimer = () => {
      time += 1;
      const minutes = '' + Math.floor(time / 60);
      const seconds = '' + (time - minutes * 60);
      setRunningTime(`${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`);
    };
    executionTimer();
    $runningTimeRef.current = setInterval(executionTimer, 1000);
  };

  const stopRunningTimer = () => {
    clearInterval($runningTimeRef.current);
  };

  return (
    <div className={`BimQueryPage ${styles.BimQueryPage}`}>
      <Formik
        enableReinitialize={false}
        validationSchema={Schema}
        initialValues={initialValues}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, setFieldValue }) => {
          const queryProps = {
            connectionId: values.connection === 'BIMWarehouse' ? 0 : values.connection,
            sql: values.sql,
            fromScheduler: false,
            projectId: project.id,
          };

          const validateQuery = async () => {
            if (_.isEmpty(values.sql)) {
              return;
            }

            try {
              setLoading(true);

              await Api.BimQuery.validateQuery(queryProps);
              UiMsg.ok(msg.t('inmemory.sql.syntax.valid'));
              setQueryExecutionLog(null);
            } catch (e) {
              console.error('Error on validateQuery()', { e });
              setQueryExecutionLog(e.response.data.message);
              return false;
            } finally {
              setLoading(false);
            }
            return true;
          };

          const executeQuery = async () => {
            if (!(await validateQuery())) {
              return;
            }

            startRunningTimer();
            try {
              setLoading(true);
              const response = await Api.BimQuery.executeQuery({
                ...queryProps,
                pageSize: resultLimit,
              });

              setQueryResult(response.result);
              setFieldValue('fieldConfigs', response.fields);
              setQueryExecutionLog(null);
            } catch (e) {
              console.error('Error on executeQuery()', e);
              setQueryExecutionLog(e.response.data.message);
            } finally {
              setLoading(false);
              stopRunningTimer();
            }
          };

          const hasError = !_.isEmpty(queryExecutionLog);
          return (
            <UiBlocker block={loading} className={`${accordionOpened ? 'menu-opened' : 'menu-closed'}`}>
              <Form>
                <TabSet internal={true} tabToOpenIndex={1} className={`${styles.TopTabsWrapper}`}>
                  <Tab
                    icon="outbound"
                    label={msg.t('bim.query.tab.title.assisted.mode')}
                    className={`${styles.AssistedModeTab}`}
                  >
                    <AssistedMode />
                  </Tab>
                  <Tab icon="code" label={msg.t('bim.query.tab.title.sql.mode')}>
                    <BimQuerySqlEditor />
                  </Tab>
                </TabSet>
                <div className={`${styles.CenterButtonsWrapper}`}>
                  <div className={`${styles.ActionsButtons} ${accordionOpened ? 'menu-opened' : 'menu-closed'}`}>
                    <BngDropdown
                      popperClassName={`${styles.QueryResultLinesAmountPopper} `}
                      customButton={({ openDropdown }) => (
                        <div className={`${styles.ExecuteQueryButton}`}>
                          <div onClick={executeQuery}>
                            <Icon icon="play_circle" />
                            {msg.t('execute')}
                          </div>
                          <Icon
                            icon="arrow_drop_down"
                            onClick={(event) => {
                              openDropdown(event);
                            }}
                          />
                        </div>
                      )}
                      options={[20, 50, 100, 1000].map((limit) => ({
                        className: styles.queryResultLinesOption,
                        label: `${limit} ${msg.t('bim.query.dropdown.line.selector.option')}`,
                        icon: `${resultLimit === limit ? 'done' : ''}`,
                        onClick: () => setResultLimit(limit),
                      }))}
                    />
                    <div className={`${styles.Timer}`}>{runningTime}</div>
                  </div>
                  <div className={`${styles.OptionsLeftSide}`}>
                    <BngIconButton
                      className={`${styles.ClearSQLEditor}`}
                      icon="delete_forever"
                      onClick={() => setFieldValue('sql', '')}
                    />
                    <div
                      className={`${styles.ExportViewButton}`}
                      onClick={() => {
                        bimEventBus.emit(QueryResultPreview.EXPORT_CSV_EVENT);
                      }}
                    >
                      {msg.t('bim.query.export.preview.button.label')} <Icon icon="csv" />
                    </div>
                  </div>
                </div>
                <TabSet internal={true} tabToOpenIndex={0} className={`${styles.TabSetWrapper}`}>
                  <Tab icon="settings" label={msg.t('configuration')} className={`${styles.ColumnsConfigurationTab}`}>
                    {!_.isEmpty(values.fieldConfigs) && <ColumnsConfiguration />}
                  </Tab>
                  <Tab icon="visibility" label={msg.t('preview')} className={`${styles.QueryResultPreviewTab}`}>
                    {!_.isEmpty(queryResult) && <QueryResultPreview queryResult={queryResult} />}
                  </Tab>
                  <Tab
                    icon="description"
                    label={msg.t('log')}
                    className={`${styles.LogPreviewTab}`}
                    props={{ alert: hasError }}
                    tabClassName={`${styles.LogPreviewTabButton}`}
                  >
                    {hasError && <LogPreview messageLog={queryExecutionLog} />}
                  </Tab>
                </TabSet>
                <div className={`BimQueryRightMenuWrapper ${styles.BimQueryRightMenuWrapper}`}>
                  <BimQueryRightMenu
                    values={values}
                    onAccordionChange={({ accordionKey }) => {
                      setAccordionOpened(!_.isNil(accordionKey));
                    }}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </Form>
            </UiBlocker>
          );
        }}
      </Formik>
    </div>
  );
}
