import './KeyFieldSelector.css';

import React, { useEffect, useMemo, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import BngSearch from 'components/bng/ui/BngSearch';
import { FixedSizeList } from 'react-window';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import { Axios } from 'commonUtils';

export default function KeyFieldSelector({ className = '', renderDate = 0, disabled = false, ...props }) {
  const { msg } = useBimContext();

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await Axios.get('/spr/ui/inMemoryBean/fields');
      setFields(data ?? []);
    } catch (e) {
      console.error('Error on KeyFieldSelector.fetchData', e);
    } finally {
      setLoading(false);
      window.requestAnimationFrame(() => {
        application.components.views.inMemory.form.structureStep();
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [renderDate]);

  const updateKeyFields = async (selectedFields = []) => {
    try {
      await Axios.post('/spr/ui/inMemoryBean/keyFields', { keyFields: selectedFields });
      await fetchData();
    } catch (e) {
      console.error('Error on KeyFieldSelector.updateKeyFields', { selectedFields }, e);
    }
  };

  const keyFields = fields.filter((f) => f.key);
  const disableActions = loading || disabled;

  return (
    <div className={`KeyFieldSelector flex-center-items ${className}`} {...props}>
      <b className="Label">{msg.t('key.field')}:</b>
      <div className="ml-1 KeyFieldContainer">
        {_.isEmpty(keyFields) ? (
          <a href="#">{msg.t('none')}</a>
        ) : (
          keyFields.map((f, idx) => (
            <React.Fragment key={idx}>
              <a href="#" className="KeyField">
                '{f.caption}'
              </a>
              {idx + 1 !== keyFields.length ? ',' : ''}
            </React.Fragment>
          ))
        )}
      </div>

      <div className="flex-center-items ml-4">
        <BngDropdown
          popperClassName="KeyFieldSelectorDropPopper BngDropdownCheckboxPopper"
          overDialog
          customButton={({ openDropdown }) => (
            <Button
              icon="key"
              className={`bng-button ${_.isEmpty(keyFields) ? 'cancel' : 'save'} btn-small fix`}
              onClick={openDropdown}
              disabled={disableActions}
            >
              {msg.t(_.isEmpty(keyFields) ? 'define.key.field' : 'edit.key.field')}
            </Button>
          )}
          customOptions={({ closeDropdown, isOpen }) => {
            const [selectedFields, setSelectedFields] = useState([]);
            const [search, setSearch] = useState('');
            const [dropdownLoading, setDropdownLoading] = useState(false);

            useEffect(() => {
              if (!isOpen) return;

              setSelectedFields(keyFields.map((f) => f.fieldName));
              setSearch('');
            }, [isOpen]);

            const filteredOptions = useMemo(() => {
              let result = fields.filter((field) => field.selected);
              if (search) {
                const s = search.toLowerCase();
                result = result.filter((m) => {
                  return `${m.fieldName}${m.description}${m.caption}`.toLowerCase().includes(s);
                });
              }
              return result;
            }, [fields, search]);

            return (
              <UiBlocker block={dropdownLoading}>
                <div>
                  <label className="fw-500">{msg.t('choose.key.fields')}:</label>
                  <div className="FilterMembers">
                    <div>
                      <BngSearch
                        value={search}
                        onChange={setSearch}
                        side="right"
                        maxLength="128"
                        inline
                        placeholder={msg.t('search')}
                      />
                    </div>
                    <div className="MembersContainer mt-2">
                      <FixedSizeList height={200} itemCount={filteredOptions.length} itemSize={23} width="100%">
                        {({ index, style }) => {
                          const field = filteredOptions[index];
                          const isSelected = selectedFields.includes(field.fieldName);
                          return (
                            <div style={style}>
                              <BngCheckbox
                                key={field.fieldName}
                                className="no-wrap"
                                field={{
                                  value: isSelected,
                                  onChange: () => {
                                    const copy = selectedFields.slice();
                                    if (isSelected) {
                                      copy.splice(copy.indexOf(field.fieldName), 1);
                                    } else {
                                      copy.push(field.fieldName);
                                    }
                                    setSelectedFields(copy);
                                  },
                                }}
                                label={field.caption}
                                title={field.caption}
                                disabled={!isSelected && selectedFields.length >= 10}
                                showTitleOnlyOnOverflow
                              />
                            </div>
                          );
                        }}
                      </FixedSizeList>
                    </div>
                  </div>
                </div>

                <div className="mt-2 d-flex">
                  <Button
                    className={'bng-button cancel btn-small'}
                    disabled={disableActions}
                    onClick={async () => {
                      setDropdownLoading(true);
                      try {
                        await updateKeyFields([]);
                        closeDropdown();
                      } catch (e) {
                        console.error(e);
                      } finally {
                        setDropdownLoading(false);
                      }
                    }}
                  >
                    {msg.t('clear')}
                  </Button>
                  <Button
                    className={`bng-button save btn-small ml-auto`}
                    disabled={disableActions}
                    onClick={async () => {
                      setDropdownLoading(true);
                      try {
                        await updateKeyFields(selectedFields);
                        closeDropdown();
                      } catch (e) {
                        console.error(e);
                      } finally {
                        setDropdownLoading(false);
                      }
                    }}
                  >
                    {msg.t('apply')}
                  </Button>
                </div>
              </UiBlocker>
            );
          }}
        />
        <Icon className="HelpIcon ml-1" icon="info" title={msg.t('define.key.field.help')} />
      </div>
    </div>
  );
}
