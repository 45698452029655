import './StructuresPage.css';

import React, { useEffect, useMemo, useState } from 'react';

import Api from 'components/Api';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import DataOriginsDialog, { openCustomDialog } from 'components/ui/in-memory/DataOriginsDialog';
import OpTextConfirmation from 'components/ui/OpTextConfirmation';
import GeoreferenceEditor from 'components/ui/map/editor/GeoreferenceEditor';
import { OriginPermissionDialog } from 'components/bng/permission/PermissionDialog';
import StructureDetailsDialog from 'components/bng/pages/admin/structures/StructureDetailsDialog';
import GroupsDialog from 'components/ui/group/GroupsDialog';
import PipeDriveForm from 'components/ui/in-memory/PipeDriveForm';
import PipeRunForm from 'components/ui/in-memory/PipeRunForm';
import GitHubForm from 'components/ui/in-memory/GitHubForm';
import Api4ComForm from 'components/ui/in-memory/Api4ComForm';
import Utils from 'components/Utils';
import useEventBus from 'components/hooks/useEventBus';
import StructureInfo, { LimitAlertBadge, LoadStatusBadge } from 'components/bng/pages/admin/structures/StructureInfo';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngSearch from 'components/bng/ui/BngSearch';
import ConnectionsDialog from 'components/ui/in-memory/ConnectionsDialog';
import InMemoryLoadLimitAlertDialog from 'components/bng/load-limit/inMemory/InMemoryLoadLimitAlertDialog';
import FiltersDialog from 'components/ui/filter/FiltersDialog';
import BngDropdownTagsWrapper, { fetchAndProcessGroups } from 'components/bng/ui/BngDropdownTagsWrapper';
import UiMsg from 'components/ui/UiMsg';
import useQueryParams from 'components/hooks/useQueryParams';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import ProjectExtractorsDialog from 'components/bng/pages/bimGateway/ProjectExtractorsDialog';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import BngInMemoryLogs from 'components/bng/pages/admin/structures/logs/BngInMemoryLogs';
import BngQuotaViewer from 'components/ui/project/BngQuotaViewer';
import BngImageButton from 'components/bng/ui/BngImageButton';

export const TypeIcon = [
  {
    name: 'ANALYTICAL',
    icon: 'equalizer',
  },
  {
    name: 'DATA',
    icon: 'dns',
  },
];

export const WsTypeModal = {
  PIPEDRIVE_DEALS: PipeDriveForm,
  PIPERUN_DEALS: PipeRunForm,
  GIT_HUB: GitHubForm,
  API4COM_CDRS: Api4ComForm,
};

let __LAST_FETCHED_STRUCTURES = [];

const openStructureDialog = (dispatch, projectId, initialSearch = '') => {
  dispatch(
    MODALS.open(DataOriginsDialog, {
      projectId,
      initialSearch,
      onClose() {
        Utils.History.updateQuery({
          dataOriginsDialog: null,
        });
      },
    })
  );

  Utils.History.updateQuery(
    {
      dataOriginsDialog: initialSearch,
    },
    { replace: true }
  );
};

export const DATA_ORIGINS_DIALOG_QUERY_PARAM = 'dataOriginsDialog';
export const NEW_STRUCTURE_QUERY_PARAM = 'newStructure';

export default function StructuresPage() {
  const context = useBimContext();
  const { msg, project } = context;
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filters, setFilters] = useState({ search: '', filterButton: {} });
  const [structures, setStructures] = useState(__LAST_FETCHED_STRUCTURES);
  const [bimIntegrationEndpoints, setBimIntegrationEndpoints] = useState([]);
  const [tableSortMode, setTableSortMode] = useState({ name: 'ASC' });
  const { queryParams } = useQueryParams();

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await Api.Project.findStructures(project.id);
      setStructures(data);
      __LAST_FETCHED_STRUCTURES = data;

      Api.InMemoryLoadLimit.findForProject({ projectId: project.id })
        .then((limits) => {
          data.forEach((inm) => {
            inm.limitAlerts = limits.filter((l) => l.inMemoryId === inm.id);
          });
          setStructures(data);
          __LAST_FETCHED_STRUCTURES = data;
        })
        .catch((e) => console.error('Error while fetching limits', e));

      Api.BimIntegration.findEndpoints()
        .then((endpoints) => setBimIntegrationEndpoints(endpoints))
        .catch((e) => console.error('Error while fetching BimIntegration endpoints', e));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();

      if (queryParams.has(NEW_STRUCTURE_QUERY_PARAM)) {
        Utils.History.updateQuery({
          [DATA_ORIGINS_DIALOG_QUERY_PARAM]: null,
        });
        const newStructure = queryParams.get(NEW_STRUCTURE_QUERY_PARAM) ?? '';
        const [type = '', name = ''] = newStructure.split('-');
        if (type === 'cus' && WsTypeModal.hasOwnProperty(name)) {
          openCustomDialog(name, project.id);
        }
      } else if (queryParams.has(DATA_ORIGINS_DIALOG_QUERY_PARAM)) {
        const initialSearchValue = queryParams.get(DATA_ORIGINS_DIALOG_QUERY_PARAM);
        openStructureDialog(dispatch, project.id, initialSearchValue);
      }
    })();
  }, []);

  useEventBus('InMemoryEvent', fetchData);
  useEventBus('InMemorySchedulingEvent', fetchData);

  const tableColumns = useMemo(
    () =>
      buildTableColumns({
        dispatch,
        msg,
        project,
        fetchData,
        bimIntegrationEndpoints,
        context,
        setLoading,
      }),
    [bimIntegrationEndpoints]
  );

  const filteredStructures = filterStructures(structures, filters, tableSortMode, msg);

  const headerButtons = () => {
    return (
      <div className="flex-center-items justify-content-flex-end">
        <div className={`QuotaViewerContainer mr-4 ${showSearch ? 'Fade' : ''}`}>
          <BngQuotaViewer projectId={project.id} />
        </div>
        <div className={`position-relative ${showSearch ? 'SearchVisible' : ''}`}>
          <BngSearch
            value={filters.search}
            stateListener={({ open }) => setShowSearch(open)}
            onChange={(val) => {
              setFilters({ ...filters, search: val });
            }}
            title={msg.t('search.object.name')}
          />
        </div>
        <div>
          <StructureFilterDropdown
            onChange={(values) => setFilters({ ...filters, filterButton: values })}
            initialValues={filters.filterButton}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={`StructuresPage`}>
      <CrudPageLayout
        fetchData={fetchData}
        hasSearch={false}
        filters={filters}
        tableRows={filteredStructures}
        loading={loading}
        emptyAlertMessage={context.msg.t('currently.no.structure.available')}
        pageTitle={context.msg.t('data.and.integration')}
        tableSortMode={tableSortMode}
        setFilters={setFilters}
        tableColumns={tableColumns}
        selectedRows={structures}
        headerButtons={headerButtons}
        setTableSortMode={setTableSortMode}
        pageButton={() => <FloatActionButtonDropdown fetchData={fetchData} />}
      />
    </div>
  );
}

function FloatActionButtonDropdown({ fetchData }) {
  const { msg, project, permissions, labs } = useBimContext();
  const dispatch = useReduxDispatch();

  const [open, setOpen] = useState(false);
  const [boundaryElement] = useState(document.getElementById('page-content'));
  const canAccessBimQuery = labs.features.some((feat) => feat === 'BIM_QUERY');

  return (
    <BngDropdown
      className={`FloatActionButton`}
      boundariesElement={boundaryElement}
      popperOpts={{
        placement: 'bottom-start',
      }}
      popperClassName={`FloatActionButtonDropdown`}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      customButton={({ openDropdown }) => {
        return <BngIconButton icon={'add'} className={`info ${open ? 'Open' : ''}`} onClick={openDropdown} />;
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={`ButtonsContainer ${open ? 'Show' : ''}`}>
            <div>
              <BngIconButton
                id="StructuresPage:datasource-button"
                icon={'cloud'}
                className={`success`}
                onClick={() => {
                  openStructureDialog(dispatch, project.id);
                  closeDropdown();
                }}
              />
              <label htmlFor="StructuresPage:datasource-button">{msg.t('pageTitleDataSource')}</label>
            </div>
            {canAccessBimQuery && (
              <div>
                <BngImageButton
                  id="StructuresPage:bimquery-button"
                  image={Api.buildUrl(`/images/data/db.png`)}
                  className={`success StructuresPageBimQueryButton`}
                  onClick={async () => {
                    try {
                      const link = Api.buildUrl('/spr/bng/project/structures/bimQuery');
                      await Api.Bng.loadProjectInfo(project.name);
                      window.location.replace(link);
                    } catch (e) {
                      console.error('Error while trying to access bim query', e);
                      UiMsg.ajaxError(null, e);
                    }
                  }}
                />
                <label htmlFor="StructuresPage:datasource-button">{msg.t('bim.query.title')}</label>
              </div>
            )}
            <div>
              <BngIconButton
                id="StructuresPage:connections-button"
                icon={'dns'}
                className={`success`}
                onClick={() => {
                  dispatch(
                    MODALS.open(ConnectionsDialog, {
                      projectId: project.id,
                    })
                  );
                  closeDropdown();
                }}
              />
              <label htmlFor="StructuresPage:connections-button">{msg.t('connections')}</label>
            </div>
            {permissions.canAccessBimGateway() && (
              <div>
                <BngIconButton
                  id="StructuresPage:extractors-button"
                  icon={'cloud_sync'}
                  className={`success`}
                  onClick={() => {
                    dispatch(
                      MODALS.open(ProjectExtractorsDialog, {
                        projectId: project.id,
                      })
                    );
                    closeDropdown();
                  }}
                />
                <label htmlFor="StructuresPage:extractors-button">{msg.t('extractors')}</label>
              </div>
            )}
            <div>
              <BngIconButton
                id="StructuresPage:import-button"
                icon={'publish'}
                className="success"
                onClick={() => {
                  Api.executeExp(`#{projectSelectionMB.inMemoryCRUD.importStructureForProject(${project.id})}`);
                  closeDropdown();
                }}
              />
              <label htmlFor="StructuresPage:import-button">{msg.t('import')}</label>
            </div>
            <div>
              <BngIconButton
                id="StructuresPage:tags-button"
                icon={'local_offer'}
                className={`warning`}
                onClick={() => {
                  dispatch(
                    MODALS.open(GroupsDialog, {
                      projectId: project.id,
                      fetchData: fetchData,
                    })
                  );
                  closeDropdown();
                }}
              />
              <label htmlFor="StructuresPage:tags-button">{msg.t('tags')}</label>
            </div>
            <div>
              <BngIconButton
                id="StructuresPage:filters-button"
                icon={'filter_alt'}
                className={`warning`}
                onClick={() => {
                  dispatch(
                    MODALS.open(FiltersDialog, {
                      projectId: project.id,
                    })
                  );
                  closeDropdown();
                }}
              />
              <label htmlFor="StructuresPage:filters-button">{msg.t('filters')}</label>
            </div>
          </div>
        );
      }}
    />
  );
}

const openEditDialog = (row, project, dispatch) => {
  if (row.wsType) {
    const modalComponent = WsTypeModal[row.wsType];
    if (modalComponent) {
      const modalProps = {
        projectId: project.id,
        inMemoryId: row.id,
        edit: true,
        namecube: row.name,
      };
      dispatch(MODALS.open(modalComponent, modalProps));
      return;
    }
  }

  return Api.executeExp(`#{projectSelectionMB.inMemoryCRUD.editById(${row.id}, ${project.id})}`);
};

const openLogsDialog = (dispatch, id) => {
  dispatch(
    MODALS.open(BngInMemoryLogs, {
      structureId: id,
    })
  );
};

const openSchedulingsDialog = (id) => {
  return Api.executeExp(
    `#{inMemorySchedulingManagementMB.openManagementDialogById(${id}, projectSelectionMB.currentProjectSupplier())}`
  );
};

const filterStructures = (structures, filters, tableSortMode, msg) => {
  const {
    search,
    filterButton: { tag, status, type, sourceType, limitAlerts },
  } = filters;
  if (search) {
    structures = structures.filter((structure) =>
      [structure.name, structure.caption].some((n) => n.toLowerCase().includes(search.toLowerCase()))
    );
  }

  if (tag) {
    const tagId = _.parseInt(tag);
    if (tagId) {
      structures = structures.filter((s) => s.group?.id === tagId);
    } else {
      structures = structures.filter((s) => !s.group);
    }
  }

  if (status) {
    structures = structures.filter((s) => (status === 'ALERT' ? !_.isEmpty(s.limitAlerts) : s.loadStatus === status));
  }

  if (type) {
    structures = structures.filter((s) => s.type === type);
  }

  if (sourceType) {
    structures = structures.filter(
      (s) => s.sourceType === sourceType || s.wsType === sourceType || s.sourceProps.subType === sourceType
    );
  }

  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    structures = _.orderBy(
      structures,
      [
        (structure) => {
          const value = structure[prop];
          switch (prop) {
            case 'type': {
              return msg.t(value);
            }
            case 'sourceType': {
              return structure.wsType && msg.containKey(structure.wsType) ? msg.t(structure.wsType) : msg.t(value);
            }
            case 'dataUpdatedAt': {
              return value?.valueOf() || -1;
            }
          }
          return _.isString(value) ? value.toLowerCase() : value;
        },
      ],
      [direction.toLowerCase()]
    );
  });
  return structures;
};

const applyGroup = async (groupId, rowId, fetchData, closeDropdown) => {
  try {
    await Api.Group.applyGroup(groupId, rowId);
    await closeDropdown();
    await fetchData();
  } catch (e) {
    console.error(e);
    UiMsg.error(null, e);
  }
};

const buildTableColumns = ({ dispatch, msg, project, fetchData, bimIntegrationEndpoints, context, setLoading }) => {
  const openDetailsDialog = (row) =>
    dispatch(
      MODALS.open(StructureDetailsDialog, {
        structure: row,
      })
    );

  const isBimIntegration = (row) => {
    return row.wsType && row.wsType === 'BIM_INTEGRATION';
  };

  return [
    {
      key: 'name',
      label: msg.t('name'),
      sortable: true,
      render: (row) => {
        return (
          <StructureInfo
            row={row}
            onClick={() => openDetailsDialog(row)}
            onBadgeClick={() => openLogsDialog(dispatch, row.id)}
            title={row.loadStatus ? msg.t(`StructuresPage.${row.loadStatus}`) : undefined}
          />
        );
      },
    },
    {
      key: 'type',
      label: msg.t('type'),
      sortable: true,
      size: 130,
      render: (row) => <TypeOption type={row.type} />,
    },
    {
      key: 'sourceType',
      label: msg.t('origin'),
      sortable: true,
      size: 200,
      render: (row) => {
        let msgKey;
        if (row.wsType) {
          if (isBimIntegration(row)) {
            msgKey = row.wsTypeIntegration;
            const endpoint = bimIntegrationEndpoints.find((endpoint) => msgKey === endpoint.route);
            return endpoint ? endpoint.name : msgKey || 'Web Service';
          }

          msgKey = msg.containKey(row.wsType) ? row.wsType : row.wsTypeIntegration;
        } else {
          msgKey = row.sourceProps.captionOverride;
        }

        if (!msgKey) {
          msgKey = row.sourceType;
        }
        return msg.t(msgKey);
      },
    },
    {
      key: 'tableSize',
      label: msg.t('size'),
      sortable: true,
      size: 120,
      render: (row) => (row.tableSize ? Utils.fileSizeSI(row.tableSize, 0) : ''),
    },
    {
      key: 'dataUpdatedAt',
      label: msg.t('updated'),
      sortable: true,
      size: 160,
      render: (row) => {
        return (
          <div className={`flex-center-items`}>
            {row.dataUpdatedAt && (
              <div
                className={`d-flex align-items-flex-end cursor-help`}
                title={row.dataUpdatedAt.format('DD/MM/YYYY HH:mm:ss')}
              >
                <Icon icon={'access_time'} className={'mr-1 TimeIcon'} />
                <span className={'mr-2 no-wrap'}>{row.dataUpdatedAt.fromNow()}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: `schedulesCounter`,
      label: msg.t('schedules'),
      size: 60,
      sortable: false,
      render: (row) => {
        const enabledScheds = row.schedulings.filter((s) => s.enabled);
        let key = `${enabledScheds.length}-${row.id}-${row.schedulings
          .map((item) => `${item.name}${item.type}${item.loadType}`)
          .join('-')}`;
        return (
          <div className={`flex-center-items`}>
            <SchedulingCounter row={row} key={key} />
          </div>
        );
      },
    },
    {
      size: 70,
      render: (row) => {
        return (
          <>
            <BngDropdown
              popperOpts={{ placement: 'bottom-end' }}
              popperClassName="StructureMenuPopper"
              closeOnSelect={false}
              options={[
                [
                  {
                    label: msg.t('edit'),
                    icon: 'edit',
                    onClick: () => {
                      openEditDialog(row, project, dispatch);
                    },
                  },
                  {
                    render: () => {
                      const BadgeComponent = LoadStatusBadge[row.loadStatus];

                      return (
                        <>
                          <Icon icon={'description'} />
                          <span>{msg.t('logs')}</span>
                          <div title={msg.t(`StructuresPage.${row.loadStatus}`)}>
                            {BadgeComponent && <BadgeComponent />}
                          </div>
                        </>
                      );
                    },
                    onClick: () => {
                      openLogsDialog(dispatch, row.id);
                    },
                  },
                  {
                    label: msg.t('detailing'),
                    icon: 'info',

                    onClick: () => openDetailsDialog(row),
                  },
                ],
                [
                  {
                    label: msg.t('permissions'),
                    icon: 'lock',
                    visible: row.allowExplore,
                    onClick: () => {
                      dispatch(
                        MODALS.open(OriginPermissionDialog, {
                          originId: row.id,
                          cubeName: row.name,
                          projectId: project.id,
                          lockCurrentUser: false,
                        })
                      );
                    },
                  },
                  {
                    label: msg.t('in.memory.calculated.measures'),
                    icon: 'functions',
                    visible: row.allowExplore,
                    disabled: !row.successfullyLoadedOnce,
                    title: `${msg.t('advanced')}${
                      row.successfullyLoadedOnce ? '' : ` - ${msg.t('option.enabled.after.data.load.title')}`
                    }`,
                    onClick: () => {
                      Api.executeExp(`#{projectSelectionMB.inMemoryCRUD.openAdvancedById(${row.id}, ${project.id})}`);
                    },
                  },
                  {
                    label: msg.t('export.json'),
                    icon: 'import_export',
                    onClick: () => {
                      window.open(`${Api.baseUrl()}/spr/origins/${row.id}/export`, '_blank');
                    },
                  },
                  {
                    label: msg.t('inMemory_georeference'),
                    icon: 'place',
                    visible: row.containGeoFields,
                    onClick: () => {
                      dispatch(
                        MODALS.open(GeoreferenceEditor, {
                          sourcedata: {
                            sourceobjectname: row.name,
                            projectid: project.id,
                            address: '',
                            zero: false,
                          },
                        })
                      );
                    },
                  },
                  {
                    visible: row.sourceProps.allowSchedulings,
                    render: () => {
                      const enabledScheds = row.schedulings.filter((s) => s.enabled);
                      let key = enabledScheds.length + row.id + '';
                      row.schedulings.forEach((item) => {
                        key += item.name + item.type + item.loadType + '';
                      });
                      return (
                        <>
                          <Icon icon={`autorenew`} />
                          <span>{msg.t('number.schedules.from.project')}</span>

                          <SchedulingCounter row={row} key={key} />
                        </>
                      );
                    },
                    onClick: () => openSchedulingsDialog(row.id),
                  },
                  {
                    icon: 'play_arrow',
                    label: msg.t('list_label_load_data'),
                    visible: !row.sourceProps.allowSchedulings,
                    onClick: () => Api.executeExp(`#{projectSelectionMB.inMemoryCRUD.openLoadDialogById(${row.id})}`),
                  },
                  {
                    render: () => {
                      return (
                        <>
                          <Icon icon={`warning`} />
                          <span>{msg.t('InMemoryLoadLimitAlertDialog.title')}</span>
                          <LimitAlertBadge row={row} />
                        </>
                      );
                    },
                    onClick: () =>
                      dispatch(
                        MODALS.open(InMemoryLoadLimitAlertDialog, {
                          initialStructure: row,
                        })
                      ),
                  },
                  {
                    visible: context.user.superUser || context.permissions.isConsultant(),
                    render: () => {
                      return (
                        <BngDropdown
                          className="w-100"
                          popperClassName="StructureAdvancedMenuDrop"
                          popperOpts={{ placement: 'left' }}
                          customButton={({ openDropdown }) => {
                            return (
                              <div className="flex-center-items w-100" onClick={openDropdown}>
                                <Icon icon={`settings`} />
                                <span>{msg.t('advanced')}</span>
                                <Icon icon="chevron_right" className="ml-auto" />
                              </div>
                            );
                          }}
                          customOptions={() => {
                            const dropOpts = ['AUTO', 'ENABLED', 'DISABLED'].map((opt) => ({
                              render: () => {
                                const selectedStyle =
                                  row.mdxRemoveUnusedMembers === opt ? { color: '#095dff' } : undefined;
                                return (
                                  <div
                                    onClick={
                                      selectedStyle
                                        ? undefined
                                        : async () => {
                                            try {
                                              await Api.Origin.updateMdxRemoveUnusedMembersConfig(row.id, opt);
                                              row.mdxRemoveUnusedMembers = opt;
                                            } catch (e) {
                                              console.error('Error on updateMdxRemoveUnusedMembersConfig()', e);
                                              UiMsg.ajaxError(null, e);
                                            }
                                          }
                                    }
                                    style={selectedStyle}
                                  >
                                    <span>{msg.t(`ConfigOption.${opt}`)}</span>
                                  </div>
                                );
                              },
                            }));
                            return (
                              <ul className="bng-dropdown unstyled">
                                <li>
                                  <BngDropdown
                                    className="w-100"
                                    popperClassName="StructureUnusedMdxMembersMenuDrop"
                                    popperOpts={{ placement: 'right-start' }}
                                    customButton={({ openDropdown }) => {
                                      return (
                                        <div className="flex-center-items w-100" onClick={openDropdown}>
                                          <span>{msg.t('mdx.remove.unused.members')}</span>
                                          <Icon icon="chevron_right" className="ml-auto" />
                                        </div>
                                      );
                                    }}
                                    options={dropOpts}
                                  />
                                </li>
                              </ul>
                            );
                          }}
                        />
                      );
                    },
                  },
                  {
                    className: 'TagsDropdownGroupOption',
                    render: ({ closeDropdown }) => {
                      return (
                        <BngDropdownTagsWrapper
                          selectedGroupId={row?.group?.id}
                          applyGroup={(item) => applyGroup(item?.group.id, row.id, fetchData, closeDropdown)}
                          customButton={({ openDropdown }) => (
                            <div onClick={openDropdown} className="item GroupOption" style={{ whiteSpace: 'nowrap' }}>
                              <Icon icon={`local_offer`} />
                              <span>{msg.t('add.tag')}</span>
                            </div>
                          )}
                        />
                      );
                    },
                  },
                ],
                [
                  {
                    label: msg.t('clear.data'),
                    icon: 'cleaning_services',
                    onClick: (event, { closeDropdown }) => {
                      closeDropdown();
                      const confirmationOptions = {
                        msg,
                        title: msg.t('attention'),
                        html: msg.t('clear.data.alert', [row.caption, row.name]),
                        nameToDelete: row.name,
                        onConfirm: async ({ checkboxValue }) => {
                          setLoading(true);
                          try {
                            await Api.Origin.clearData(row.id, checkboxValue || false);
                            UiMsg.ok(msg.t('clear.data.success', [row.caption]));
                            fetchData();
                          } catch (e) {
                            console.error('Error while trying to clean origin data', { row }, e);
                            setLoading(false);
                            UiMsg.ajaxError(msg.t('clear.data.error', [row.caption]), e);
                          }
                        },
                        validationMessage: msg.t('confirm.delete.data.structure.error'),
                      };

                      if (isBimIntegration(row)) {
                        confirmationOptions.showCheckbox = true;
                        confirmationOptions.checkboxLabel = msg.t('want.delete.credentials');
                      }

                      OpTextConfirmation(confirmationOptions);
                    },
                  },
                  {
                    label: msg.t('delete'),
                    icon: 'delete_outline',
                    onClick: async () => {
                      try {
                        setLoading(true);
                        const response = await Api.Project.findSourceObjectCount(project.id, row.name);
                        const canRemove = response.affectedOrigins.length === 0;
                        let message = '';
                        let confirmText = undefined;
                        let confirmColor = undefined;
                        if (canRemove) {
                          message =
                            response.count > 0
                              ? msg.t('confirm.delete.data.structure.with.text', [
                                  row.caption,
                                  response.count,
                                  row.name,
                                ])
                              : msg.t('confirm.delete.data.structure.with.text.zero.intelligence', [
                                  row.caption,
                                  row.name,
                                ]);
                        } else {
                          confirmText = msg.t('aware');
                          confirmColor = '#007bff';
                          const affectedOrigins = response.affectedOrigins
                            .map(
                              (origin) => `<li style='text-align: left'><b>${origin.caption || origin.name}</b></li>`
                            )
                            .join('');
                          message = `${msg.t('confirm.delete.data.structure.with.dependents', [
                            row.caption,
                            row.name,
                          ])}<ul>${affectedOrigins}</ul>`;
                        }

                        OpTextConfirmation({
                          msg,
                          title: msg.t('attention'),
                          html: message,
                          nameToDelete: row.name,
                          confirmText,
                          confirmColor,
                          onConfirm: canRemove
                            ? () => {
                                Api.executeExp(`#{projectSelectionMB.inMemoryCRUD.deleteById(${row.id})}`);
                              }
                            : undefined,
                          validationMessage: msg.t('confirm.delete.data.structure.error'),
                        });
                      } catch (e) {
                        console.error(e);
                      } finally {
                        setLoading(false);
                      }
                    },
                  },
                ],
              ]}
            />
          </>
        );
      },
    },
  ];
};

function SchedulingCounter({ row }) {
  const { msg } = useBimContext();
  if (!row.sourceProps.allowSchedulings) return null;

  const enabledScheds = row.schedulings.filter((s) => s.enabled);
  let title;
  if (_.isEmpty(enabledScheds)) {
    const containDisabledSchedulings = enabledScheds.length !== row.schedulings.length;
    title = msg.t(
      containDisabledSchedulings ? 'StructuresPage.NONESCHEDULE.UPDATED' : 'StructuresPage.NONESCHEDULE.NEW'
    );
  } else {
    title = enabledScheds
      .map((schedule, idx) => {
        return `
            ${
              idx === 0
                ? `<span style="font-size:14px">
                      ${msg.t('StructuresPage.SCHEDULES')}
                   </span>
                   <hr />`
                : ''
            } 
            <div class="${idx > 0 ? 'mt-2' : ''}">
                <span>${schedule.cronDescription}</span>
            </div>
            `;
      })
      .join('');
  }

  return (
    <span
      className={`badge pointer ${_.isEmpty(enabledScheds) ? 'SchedulingCounterEmpty' : 'SchedulingCounter'}`}
      role="button"
      onClick={() => openSchedulingsDialog(row.id)}
      title={title}
    >
      {enabledScheds.length}
    </span>
  );
}

const StructureFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    tag: yup.string().nullable().default(''),
    status: yup.string().nullable().default(''),
    type: yup.string().nullable().default(''),
    sourceType: yup.string().nullable().default(''),
    limitAlerts: yup.boolean().nullable().default(false),
  });
});

function StructureFilterDropdown({ onChange = _.noop, initialValues = {} }) {
  const { project, msg } = useBimContext();

  const [loading, setLoading] = useState(false);
  const sourceTypeOpts = FilterOpts({
    options: _.orderBy([
      'CSV',
      'UMovMe',
      'REMOTE_FILE_SOURCE',
      'SQL',
      'SQL_APP',
      'JSON_SOURCE',
      'GOOGLE_SHEETS',
      'WS',
      'XML',
    ]),
  });

  const statusOpts = FilterOpts({
    options: [
      { name: 'ERROR', value: 'ERROR', label: msg.t('ERROR'), badge: LoadStatusBadge.ERROR },
      { name: 'ALERT', value: 'ALERT', label: msg.t('loadAlerts'), badge: LoadStatusBadge.ALERT },
      { name: 'CANCELLED', value: 'CANCELLED', label: msg.t('cancelled'), badge: LoadStatusBadge.CANCELLED },
      { name: 'LOADING', value: 'LOADING', label: msg.t('in.execution'), badge: LoadStatusBadge.LOADING },
      {
        name: 'NEW_DATA_NOT_FOUND',
        value: 'NEW_DATA_NOT_FOUND',
        label: msg.t('new.data.not.found'),
        badge: LoadStatusBadge.NEW_DATA_NOT_FOUND,
      },
    ],
    customIcon: (obj) => <StatusOption Component={obj.badge} spin={false} />,
  });

  const typeOpts = FilterOpts({
    options: TypeIcon,
    hasIcon: true,
  });

  const [groupOpts, setGroupOpts] = useState([]);

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const groupOpts = await fetchAndProcessGroups(project.id, msg);
      setGroupOpts(groupOpts);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const initialFormValues = useMemo(() => _.merge({}, StructureFilterDropdownSchema.default(), initialValues), [
    initialValues,
  ]);

  const filterFields = [
    {
      name: 'tag',
      options: groupOpts,
    },
    {
      name: 'status',
      options: statusOpts,
    },
    {
      name: 'type',
      options: typeOpts,
    },
    {
      name: 'sourceType',
      label: msg.t('origin'),
      options: sourceTypeOpts,
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      initialFormValues={initialFormValues}
      dropdownSchema={StructureFilterDropdownSchema}
      onChange={onChange}
      loading={loading}
      onOpen={() => {
        if (_.isEmpty(groupOpts)) {
          fetchGroups();
        }
      }}
    />
  );
}

const StatusOption = ({ Component, ...props }) => {
  return (
    <div className="flex-center-items">
      <div>
        <Component {...props} />
      </div>
    </div>
  );
};

export function TypeOption({ className = '', type, withLabel = true }) {
  const { msg } = useBimContext();
  const typeLabel = msg.t(type);
  return (
    <div className={`TypeOption flex-center-items Type-${type} ${className}`}>
      <div>
        <Icon icon={TypeIcon[type]} className={'mr-1'} title={withLabel ? undefined : typeLabel} />
      </div>
      {withLabel && <div>{typeLabel}</div>}
    </div>
  );
}
