import React, {PureComponent} from 'react';
import PropType from "prop-types";
import { connect } from "react-redux";
import Api from "components/Api";
import Utils from "components/Utils";
import ContextEnhancer from "components/ContextEnhancer";
import { MODALS } from "components/ui/redux/Actions";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";
import OrgmapPropertiesDialog from "components/ui/orgmap/OrgmapPropertiesDialog";
import { KPI_EDIT_MENU_EVENT } from 'components/bng/pages/kpi/KpiEditMenu';

class ConfigureMenuItem extends PureComponent {

    static propTypes = {
        path: PropType.string,
    };

    static defaultProps = {
        path: '',
    };

    openEditDialog = async (event) => {
        const {path, clearAccordion, context, openPropertiesDialog, kpiMutations} = this.props;

        clearAccordion();
        const type = Utils.Object.getObjectType(path).toUpperCase();
        switch (type) {
            case 'DASHBOARD':
                context.execute(`#{dashboardBean.editCurrentDashboard()}`);
                break;
            case 'MAPS':
                context.execute(`#{mapsCRUD.editMap("${path}")}`);
                break;
            case 'KPI':
                bimEventBus.emit(KPI_EDIT_MENU_EVENT, {path, mutations: kpiMutations, toggle: true});
                break;
            case 'ORGMAP':
                const properties = await Api.OrgMap.getProperties();
                openPropertiesDialog(properties);
                break;
            case 'ANALYSIS':
                context.execute(`#{analysisBean.openAnalysisPropertiesDialog()}`);
                break;
            default:
                console.warn(`Configuration Dialog not configure for object of type ${type}`);
        }
    };

    render() {
        const {context, ...props} = this.props;
        return (
            <RightMenuItem {...props}
                                className="ConfigureMenuItem"
                                title={context.msg.t('properties')}
                                icon="settings"
                                onClick={this.openEditDialog}/>
        );
    }
}

export default connect(
    () => ({}),
    dispatch => ({
        openPropertiesDialog(properties) {
            dispatch(
                MODALS.open(OrgmapPropertiesDialog, {
                    initialFormValues: properties
                })
            );
        }
    })
)(ContextEnhancer(ConfigureMenuItem));
