import './ConnectionsDialog.css';
import React, { useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngSearch from 'components/bng/ui/BngSearch';
import OpConfirmation from 'components/ui/OpConfirmation';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import DataOriginsDialog from 'components/ui/in-memory/DataOriginsDialog';
import DatabaseConnectionDialog from 'components/ui/in-memory/DatabaseConnectionDialog';
import IntegrationConnectionDialog from 'components/ui/in-memory/IntegrationConnectionDialog';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import DetailsDialog from 'components/ui/common/DetailsDialog';

export const fetchDatabases = async (setLoading = _.noop) => {
  setLoading(true);
  let databases = [];
  try {
    databases = await Api.Connection.getDatabaseList();
  } catch (e) {
    console.error('Error on fetchDatabases()', e);
    UiMsg.ajaxError(null, e);
  } finally {
    setLoading(false);
  }
  return databases;
};

export function countConnections(connectionList) {
  const countConnections = {};

  connectionList.forEach((connection) => {
    if (!connection.type) {
      const name = connection.props.name;
      countConnections[name] = (countConnections[name] || 0) + 1;
    } else {
      countConnections.Database = (countConnections.Database || 0) + 1;
    }
  });

  return countConnections;
}

export default function ConnectionsDialog({
  dialogComponent = Dialog,
  closeModal = _.noop,
  forReplication = false,
  onClose = _.noop,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [connections, setConnections] = useState([]);
  const [search, setSearch] = useState('');
  const [databases, setDatabases] = useState([]);
  const [countConnection, setCountConnection] = useState({});
  const [loading, setLoading] = useState(false);

  const globalConnections = _.isEmpty(context.project);

  useAsyncEffect({
    onMount: async () => {
      setLoading(true);
      try {
        if (globalConnections && !context.user.superUser) {
          console.error(`You don't have permission to change global connections`);
          closeModal();
        }
        await Promise.allSettled([fetchConnections(), fetchDatabaseOpts()]);
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchDatabaseOpts = async () => {
    const databases = await fetchDatabases(setLoading);
    setDatabases(databases);
  };

  const fetchConnections = async () => {
    setLoading(true);
    try {
      const data = await Api.Connection.findAll({ projectId: context.project.id });

      const connectionList = await Promise.all(
        data.map(async (connection) => {
          const iconImg = connection?.type?.name === 'UMovMe' ? 'umov-me' : 'db';
          let updatedConnection = {
            ...connection,
            icon: `${Api.baseUrl()}/images/data/${iconImg}.png`,
            database: connection.type?.name,
          };

          if (!updatedConnection.type) {
            try {
              const { props } = updatedConnection;
              if (props.name) {
                const bimIntegrationData = await Api.BimIntegration.findInfoEndpoint(props.name);
                updatedConnection = {
                  ...updatedConnection,
                  icon: bimIntegrationData.logo,
                  database: bimIntegrationData.name,
                };
              }
            } catch (e) {
              console.error(`Error fetching BimIntegration data for connection: ${updatedConnection.id}`, e);
            }
          }

          return updatedConnection;
        })
      );

      const count = countConnections(connectionList);

      setCountConnection(count);
      setConnections(connectionList);
    } catch (e) {
      console.error('Error on fetchConnections()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const removeItem = (row) => {
    OpConfirmation({
      title: context.msg.t('attention'),
      message: context.msg.t('delete.confirmation.message'),
      onConfirm: () => deleteConnection(row),
      msg: context.msg,
    });
  };

  const deleteConnection = async (row) => {
    try {
      await Api.Connection.deleteConnection(row.id || 0, forReplication, row.name);
      await fetchConnections();
      UiMsg.ok(context.msg.t('connection.remove.success'));
    } catch (e) {
      console.error(`Error on deleteConnection(id=[${row.id}])`, e);
      UiMsg.ajaxError(null, context.msg.t('connection.remove.error'));
    }
  };

  const buildDropdownOpts = (row) => {
    return [
      { icon: 'edit', label: context.msg.t('edit'), onClick: () => openDialogConnections(row) },
      {
        icon: 'remove',
        label: context.msg.t('remove'),
        onClick: () => removeItem(row),
      },
      {
        icon: 'info',
        label: context.msg.t('detailing'),
        onClick: async () => {
          const createdBy = row.createdBy ? await Api.User.findUser(row.createdBy, row.projectId) : null;
          const updatedBy = row.updatedBy ? await Api.User.findUser(row.updatedBy, row.projectId) : null;
          dispatch(
            MODALS.open(DetailsDialog, {
              createdAt: createdBy ? row.createdAt : '',
              createdBy: createdBy ?? context.msg.t('no.history'),
              updatedAt: updatedBy ? row.updatedAt : '',
              updatedBy: updatedBy ?? context.msg.t('no.history'),
              title: row.name,
            })
          );
        },
      },
    ];
  };

  const cols = [
    {
      label: context.msg.t('name'),
      render: (row) => <span>{row.name}</span>,
      size: '40%',
    },
    {
      label: context.msg.t('DataOriginsDialog.connection.type'),
      render: (row) => (
        <div>
          <img src={row.icon} alt={'Icon'} className="IconConnectionList" />
          <span className={'d-l-m'}>{row.database}</span>
        </div>
      ),
      size: '40%',
    },
    {
      label: context.msg.t('actions'),
      render: (row) => <BngDropdown options={buildDropdownOpts(row)} overDialog />,
      size: '10%',
    },
  ];

  const openDialogConnections = (row) => {
    if (row.type) {
      dispatch(
        MODALS.open(DatabaseConnectionDialog, {
          connectionId: row?.id,
          fetchConnections,
          databaseList: databases,
          globalConnection: globalConnections,
          forReplication,
          connectionData: row,
        })
      );
    } else {
      const { props } = row;
      const initialValues = { name_connection: row.name, ...props };
      dispatch(
        MODALS.open(IntegrationConnectionDialog, {
          name: props.name,
          initialValues: initialValues,
          connectionId: row.id,
          fetchConnections,
        })
      );
    }
  };

  const openDataOrigins = (row) => {
    dispatch(
      MODALS.open(DataOriginsDialog, {
        projectId: context.project.id,
        isConnections: true,
        connectionsProps: {
          connectionId: row?.id,
          fetchConnections,
          databaseList: databases,
          globalConnection: globalConnections,
          forReplication,
          connectionData: row,
          countConnection,
        },
      })
    );
  };

  const filteredConnections = useMemo(() => {
    if (search) {
      return connections.filter((item) => {
        const name = item.name.toUpperCase();
        const upperSearch = search.toUpperCase();
        const database = (item.database ?? item.type?.name ?? '').toUpperCase();
        return name.includes(upperSearch) || database.includes(upperSearch);
      });
    }

    return connections;
  }, [search, connections]);

  const DialogComponent = dialogComponent;
  return (
    <DialogComponent
      title={context.msg.t('connection.dialog.list')}
      className="ConnectionsDialog xlarge"
      onClose={() => {
        onClose?.();
        closeModal();
      }}
      contentFullWidth={true}
      loading={loading}
    >
      <div
        className={'groups-header'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      >
        <div className="new-connection-btn" onClick={() => openDataOrigins()}>
          <Icon icon="add_circle" />
          <div style={{ padding: '0 5px', width: '135px' }}>{context.msg.t('new.connection')}</div>
        </div>
        <BngSearch alwaysOpen={false} className="ConnectionSearch" onChange={(search) => setSearch(search ?? '')} />
      </div>
      <div className={'conection-dialog-division top'} />

      <Dialog.Body>
        <BngTable className="ConnectionsList" rows={filteredConnections} cols={cols} />
      </Dialog.Body>
    </DialogComponent>
  );
}
