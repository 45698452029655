import {Axios} from "commonUtils";


class OrgMapApi {

    async findByPath(path) {
        const {data} = await Axios.get(`/spr/ui/orgmap`, {params: {path}});
        return data;
    };

    saveAs = async ({folder, name, description}) => {
        const {data} = await Axios.post(`/spr/ui/orgmap/save-as`, {folder, name, description});
        return data;
    };

    getProperties = async (path) => {
        const {data} = await Axios.get(`/spr/ui/orgmap/properties`, {params: {path}});
        return data;
    };

    saveProperties = async (properties) => {
        const {data} = await Axios.post(`/spr/ui/orgmap/properties`, properties);
        return data;
    };
}

export default OrgMapApi;