import styles from './DumpPage.module.css';

import React, { useMemo, useRef, useState } from 'react';
import { Field } from 'formik';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Utils, { OBJECT_TYPES } from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import bngYup from 'components/bng/form/yup/BngYup';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngField from 'components/bng/form/BngField';
import DeleteFileDialog from 'components/bng/pages/admin/folders/DeleteFileDialog';
import { MODALS } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import OpConfirmation from 'components/ui/OpConfirmation';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import useAsyncEffect from 'components/hooks/useAsyncEffect';

export default function DumpPage() {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const $pageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [deletedPaths, setDeletedPaths] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const [users, setUsers] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [tableSortMode, setTableSortMode] = useState({ createdAt: 'DESC' });

  const fetchData = async () => {
    setLoading(true);
    try {
      const deletedPathsData = await Api.Dump.findDeletedPaths(context.project.id);
      setDeletedPaths(deletedPathsData);

      const uniqueNames = [...new Set(deletedPathsData.map((item) => item.userName))];
      setUsers(uniqueNames);
    } catch (e) {
      console.error('Error on DumpPage.fetchData()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect({
    onMount: async () => {
      if (context.cockpitEnabled) {
        await Api.Bng.loadProjectInfo(context.project.name, true);
        window.location.reload();
        return;
      }

      fetchData();
    },
  });

  const filteredRows = useMemo(() => {
    return filterRows(deletedPaths, filters, tableSortMode);
  }, [deletedPaths, filters, tableSortMode]);

  const deleteNodes = async (paths = []) => {
    setLoading(true);
    try {
      await Api.Dump.removeDeletedPaths(paths);
      await fetchData();
      UiMsg.ok(context.msg.t('dump.clear.success'));
    } catch (e) {
      console.error('Could not remove nodes');
      UiMsg.error(context.msg.t('dump.clear.error'));
    } finally {
      setLoading(false);
    }
  };

  const cleanSelectedRows = () => {
    setSelectedRows([]);
    setCheckAll(false);
  };

  const deleteAllNodes = async () => {
    setLoading(true);
    try {
      await Api.Dump.removePathsForProject(context.project.id);
      await fetchData();
      cleanSelectedRows();
      UiMsg.ok(context.msg.t('dump.clear.success'));
    } catch (e) {
      console.error('Could not remove nodes');
      UiMsg.error(context.msg.t('dump.clear.error'));
    } finally {
      setLoading(false);
    }
  };

  const fetchVersionForPath = async (path) => {
    try {
      const versions = await Api.Dump.versionsFor(path);
      return versions;
    } catch (e) {
      console.error('Could not fetch versions backup');
    }
  };

  const restoreVersion = async (restoreVersions = []) => {
    try {
      const errors = await Api.Dump.restoreVersion(restoreVersions);
      await fetchData();
      cleanSelectedRows();

      if (_.isEmpty(errors)) {
        UiMsg.ok(context.msg.t('version.restored.successfully'));
        bimEventBus.emit('ContentChangeEvent', {});
      } else {
        UiMsg.warn(context.msg.t('version.failed.to.find.resource'));
      }
    } catch (e) {
      console.error('Could not restore versions');
    } finally {
      Api.updateJsf();
    }
  };

  const tableColumns = useMemo(
    () =>
      buildTableColumns({
        context,
        filteredRows,
        selectedRows,
        setSelectedRows,
        checkAll,
        setCheckAll,
        dispatch,
        deleteNodes,
        fetchVersionForPath,
        restoreVersion,
        $pageRef: $pageRef.current,
        hasDeletedPaths: _.isEmpty(deletedPaths),
      }),
    [filteredRows, selectedRows, setSelectedRows, checkAll, deletedPaths, $pageRef.current]
  );

  const headerButtons = () => (
    <DumpFilterDropdown
      onChange={(values) => setFilters({ ...filters, filterButton: values })}
      initialValues={filters.filterButton}
      users={users}
    />
  );

  const optionalButtons =
    selectedRows.length === 0
      ? null
      : () => {
          return (
            <div className="flex-center-items">
              <BngIconButton
                icon="remove_done"
                onClick={cleanSelectedRows}
                title={context.msg.t('uncheck.selected.objects')}
              />
              <BngIconButton
                icon="settings_backup_restore"
                title={context.msg.t('restore.selected.action')}
                onClick={() =>
                  restoreVersion({
                    paths: selectedRows.map((row) => row.path),
                  })
                }
              />
              <BngIconButton
                icon="delete"
                title={context.msg.t('remove.selected')}
                onClick={() => {
                  dispatch(
                    MODALS.open(DeleteFileDialog, {
                      onConfirm: async () => {
                        await deleteNodes(selectedRows.map((row) => row.path));
                        cleanSelectedRows();
                      },
                      nodesToDelete: selectedRows.map((row) => {
                        return {
                          value: row.path,
                          text: row.caption,
                          icon: Utils.Object.getObjectIcon(row.path, 'showChart'),
                          leaf: true,
                        };
                      }),
                      showWarning: false,
                    })
                  );
                }}
              />
            </div>
          );
        };

  return (
    <div className={`DumpPage`}>
      <CrudPageLayout
        className={`${styles.dumpPage}`}
        fetchData={fetchData}
        $tableScrollRef={$pageRef}
        filters={filters}
        tableRows={filteredRows}
        loading={loading}
        emptyAlertMessage={context.msg.t('no.dump.found')}
        pageTitle={context.msg.t('dump')}
        titleComponent={() => (
          <div className={`${styles.removalHelper}`}>
            {context.msg.t('dump.automatic.removal.warning', context.dumpConfig.daysToRemoval)}
          </div>
        )}
        tableSortMode={tableSortMode}
        setFilters={setFilters}
        tableColumns={tableColumns}
        selectedRows={selectedRows}
        headerButtons={headerButtons}
        showItemsCount={true}
        optionalButtons={optionalButtons}
        setTableSortMode={setTableSortMode}
        pageButton={() => <FloatButton loading={loading} deleteAllNodes={deleteAllNodes} deletedPaths={deletedPaths} />}
      />
    </div>
  );
}

const FloatButton = ({ loading, deleteAllNodes, deletedPaths }) => {
  const context = useBimContext();
  return (
    <BngIconButton
      icon={`${loading ? 'refresh' : 'delete_forever'}`}
      className={`error ${loading ? `${styles.SpinElement}` : ''} ${styles.delete_forever_icon}`}
      onClick={() => {
        if (loading) {
          return;
        }

        if (!_.isEmpty(deletedPaths)) {
          return OpConfirmation({
            title: context.msg.t('clear.dump'),
            html: context.msg.t('dump.clear.message'),
            onConfirm: deleteAllNodes,
            msg: context.msg,
            confirmText: context.msg.t('definitive.delete'),
            reverseButtons: true,
          });
        } else {
          UiMsg.warn(context.msg.t('dump.is.empty'));
        }
      }}
      title={context.msg.t('clear.dump')}
    />
  );
};

const buildTableColumns = ({
  context,
  filteredRows,
  selectedRows,
  setSelectedRows,
  checkAll,
  setCheckAll,
  dispatch,
  deleteNodes = _.noop,
  fetchVersionForPath = _.noop,
  restoreVersion = _.noop,
  $pageRef,
  hasDeletedPaths,
}) => {
  //This will update the selection of a row, whether its checkbox is marked or not
  const changeSelectedRow = (row, isSelected) => {
    isSelected
      ? (selectedRows = selectedRows.filter((selectedRow) => selectedRow.path !== row.path))
      : selectedRows.push(row);
    setSelectedRows([...selectedRows]);
  };

  const selectAllRows = () => {
    let newVal;
    if (checkAll) {
      newVal = [];
      setCheckAll(false);
    } else {
      newVal = selectedRows.slice();
      filteredRows.forEach((row) => {
        newVal.push(row);
      });
      setCheckAll(true);
    }
    setSelectedRows(newVal);
  };

  return [
    {
      key: 'rowCheckbox',
      label: (
        <BngCheckbox
          field={{
            onChange: selectAllRows,
            value: checkAll,
          }}
          size="lg"
          disabled={hasDeletedPaths}
        />
      ),
      size: 4,
      render: (row) => {
        const isSelected = selectedRows.some((selectedRow) => selectedRow.path === row.path);
        return (
          <div className="CheckInputOption">
            <BngCheckbox
              field={{
                onChange: () => {
                  changeSelectedRow(row, isSelected);
                },
                value: isSelected,
              }}
              size="lg"
            />
          </div>
        );
      },
    },
    {
      key: 'caption',
      label: context.msg.t('name'),
      size: 500,
      render: (row) => {
        return (
          <div className={`${styles.captionContainer}`}>
            <div className={`${styles.captionIcon}`}>
              <Icon
                icon={Utils.Object.getObjectIcon(row.path, 'showChart')}
                className={`${styles.dumpTableCaptionIcon}`}
              />
            </div>
            <span className="caption">{row.caption}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      label: context.msg.t('type'),
      size: 300,
      render: (row) => {
        return (
          <div>
            <span className="type">{context.msg.t(row.type)}</span>
          </div>
        );
      },
    },
    {
      key: 'removedBy',
      label: context.msg.t('removed.by'),
      size: 300,
      render: (row) => {
        return (
          <div>
            <span className="removedBy">{row.userName}</span>
          </div>
        );
      },
    },
    {
      key: 'createdAt',
      label: context.msg.t('removal.date'),
      size: 300,
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span className="createdAt">{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: context.msg.t('actions'),
      size: 100,
      render: (row) => {
        return (
          <div className={`${styles.buttonContainer}`}>
            <RestoreVersionDropdown
              fetchVersionForPath={fetchVersionForPath}
              row={row}
              restoreVersion={restoreVersion}
              boundaryElement={$pageRef}
            />
            <BngIconButton
              icon="delete"
              className="deleteIcon"
              title={context.msg.t('clear.versions.title')}
              onClick={() => {
                dispatch(
                  MODALS.open(DeleteFileDialog, {
                    onConfirm: async () => await deleteNodes([row.path]),
                    nodesToDelete: [
                      {
                        value: row.path,
                        text: row.caption,
                        icon: Utils.Object.getObjectIcon(row.path, 'showChart'),
                        leaf: true,
                      },
                    ],
                    showWarning: false,
                  })
                );
              }}
            />
          </div>
        );
      },
    },
  ];
};

const filterRows = (deletedPaths, filters, tableSortMode) => {
  const {
    searchTerm,
    filterButton: { type, removedBy, startDate, endDate },
  } = filters;

  deletedPaths = deletedPaths.filter((deletedPath) => deletedPath.type !== 'cockpit');

  if (!_.isEmpty(searchTerm)) {
    deletedPaths = deletedPaths.filter((deletedPath) =>
      Utils.Strings.includesIgnoreCase(deletedPath.caption, searchTerm)
    );
  }

  if (!_.isEmpty(type)) {
    deletedPaths = deletedPaths.filter((deletedPath) => {
      if (type === 'maps') {
        return deletedPath.type === type || deletedPath.type === 'newmap';
      }
      return deletedPath.type === type;
    });
  }

  if (!_.isEmpty(removedBy)) {
    deletedPaths = deletedPaths.filter((deletedPath) => deletedPath.userName === removedBy);
  }

  if (!_.isEmpty(startDate)) {
    deletedPaths = deletedPaths.filter((deletedPath) => new Date(deletedPath.createdAt) >= new Date(startDate));
  }

  if (!_.isEmpty(endDate)) {
    deletedPaths = deletedPaths.filter((deletedPath) => new Date(deletedPath.createdAt) <= new Date(endDate));
  }

  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;
    deletedPaths = _.orderBy(deletedPaths, [prop], [direction.toLowerCase()]);
  });

  return deletedPaths;
};

const DumpFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    type: yup.string().nullable().default(''),
    removedBy: yup.string().nullable().default(''),
    startDate: yup.string().nullable().default(''),
    endDate: yup.string().nullable().default(''),
  });
});

const DumpFilterDropdown = ({ onChange = _.noop, initialValues = {}, users = [] }) => {
  const { msg } = useBimContext();

  const typeOpts = FilterOpts({ options: OBJECT_TYPES.filter((filter) => filter !== 'newmap'), hasIcon: true });

  const userOpts = FilterOpts({ options: users });

  const initialFormValues = useMemo(() => _.merge({}, DumpFilterDropdownSchema.default(), initialValues), [
    initialValues,
  ]);

  const buildFields = [
    {
      name: 'type',
      options: typeOpts,
    },
    {
      name: 'removedBy',
      label: msg.t('removed.by'),
      options: userOpts,
    },
    {
      render: ({ values }) => (
        <Field
          name="startDate"
          component={BngField}
          label={msg.t('initial.date')}
          type="date"
          max={values.endDate && values.endDate !== '' ? values.endDate : null}
          className={`${styles.filterDropdownField} ${styles.dateField}`}
        />
      ),
    },
    {
      render: ({ values }) => (
        <Field
          name="endDate"
          component={BngField}
          label={msg.t('final.date')}
          type="date"
          min={values.startDate && values.startDate !== '' ? values.startDate : null}
          className={`${styles.filterDropdownField} ${styles.dateField}`}
        />
      ),
    },
  ];

  return (
    <FilterDropdown
      fields={buildFields}
      initialFormValues={initialFormValues}
      dropdownSchema={DumpFilterDropdownSchema}
      onChange={onChange}
    />
  );
};

const RestoreVersionDropdown = ({ fetchVersionForPath, restoreVersion, row, boundaryElement }) => {
  const context = useBimContext();
  const { msg } = context;
  const [itemsToRender, setItemsToRender] = useState([]);

  const fetchData = async (path) => {
    const data = await fetchVersionForPath(path);
    setItemsToRender(data);
  };

  return (
    <BngDropdown
      icon="settings_backup_restore"
      boundariesElements={boundaryElement}
      popperOpts={{
        placement: 'bottom-end',
      }}
      popperClassName={`${styles.restoreWrapper}`}
      className="restoreIcon"
      title={msg.t('restore.action.title')}
      onOpen={async () => {
        await fetchData(row.path);
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={styles.restoreVersionDropdownBody}>
            <span className={styles.restoreVersionDropdownTitle}>
              {msg.t(_.isEmpty(itemsToRender) ? 'version.failed.to.find' : 'select.a.version.to.restore')}
            </span>
            {!_.isEmpty(itemsToRender) && (
              <>
                <hr />
                <div className={styles.restoreVersionDropdownWrapper}>
                  {itemsToRender.map((version, idx) => {
                    return (
                      <div
                        key={`version-${version.id}`}
                        className={styles.restoreVersionDropdownItem}
                        style={{ backgroundColor: `${idx % 2 === 0 ? '#f6f6f6' : '#fff'}` }}
                        onClick={async () => {
                          await restoreVersion(version.id);
                          closeDropdown();
                        }}
                      >
                        <Icon icon="settings_backup_restore" />
                        <span>{moment(version.date).format('DD/MM/YYYY HH:mm')}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      }}
    />
  );
};
