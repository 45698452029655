import './OrgMapSelectObjectTree.css';
import React, { useEffect, useMemo, useState } from 'react';

import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';

function BypassComponent({ className = '', children }) {
  return <div className={className}>{children}</div>;
}

export default function OrgMapSelectObjectTree() {
  const { $val } = useMemo(() => {
    const $val = document.querySelector('.OrgMapItemLinksJsonField');
    return {
      $val,
    };
  }, []);

  const [selectedItems, setSelectedItems] = useState(
    JSON.parse($val.value ?? '[]')
  );

  useEffect(() => {
    $val.value = JSON.stringify(selectedItems);
  }, [$val, selectedItems]);

  return (
    <SelectObjectDialogContainer
      className={'OrgMapSelectObjectTree TransparentBg'}
      dialogComponent={BypassComponent}
      onChangeListener={({ path: selectedPaths } = { path: [] }) => {
        let currentContents = _.cloneDeep(selectedItems);
        for (const p of selectedPaths) {
          const match = currentContents.find((c) => c.path === p);
          if (!match) {
            currentContents.push({
              path: p,
            });
          }
        }
        currentContents = currentContents.filter((c) =>
          selectedPaths.includes(c.path)
        );
        setSelectedItems(currentContents);
      }}
      object={selectedItems.map((sc) => sc.path)}
      typeField="checkbox"
    />
  );
}
