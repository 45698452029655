import "./FilterAccordion.css";
import React, {useEffect, useState} from "react";
import {Field, useFormikContext} from "formik";
import ContextEnhancer from "components/ContextEnhancer";
import AccordionList from "components/ui/AccordionList";
import Accordion from "components/ui/Accordion";
import ActionList, {ActionListEmpty, ActionTagItem} from "components/ui/dashboard/components/ActionList";
import {BngField} from "components/bng/form/BngField";
import {BngSelect} from "components/bng/form/BngSelect";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {bngYup} from "components/bng/form/yup/BngYup";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";
import ConfigureFilterAccordion, {ConfigureFilterSchema} from "components/ui/dashboard/ConfigureFilterAccordion";
import Button from "components/ui/Button";
import Icon from "components/ui/common/Icon";
import HelpIcon from "components/ui/common/HelpIcon";
import BngDropdownCheckbox from "components/bng/ui/BngDropdownCheckbox";
import {BngIconButton} from "components/bng/ui/BngIconButton";
import {FormikListener} from "components/bng/form/formik/FormikListener";


const filterPositionType = [
    {
        label: 'filter.position.fixed',
        positions: [
            {value: 'filter.top.fixed', label: 'filter.top'},
            {value: 'filter.bottom.fixed', label: 'filter.bottom'}
        ]
    },
    {
        label: 'filter.position.float',
        positions: [
            {value: 'filter.top.left', label: 'filter.top.left'},
            {value: 'filter.top.right', label: 'filter.top.right'},
            {value: 'filter.bottom.left', label: 'filter.bottom.left'},
            {value: 'filter.bottom.right', label: 'filter.bottom.right'}
        ]
    },
];

const filterPositionBox = (context) => {
    let opts = [];
    for (let type of filterPositionType) {
        for (let position of type.positions) {
            opts.push({
                value: position.value,
                label: `${context.msg.t(type.label)} - ${context.msg.t(position.label)}`,
            })
        }
    }
    return opts;
};

export const FilterSchema = bngYup(yup => {
    return yup.object().shape({
        position: yup.string().required().default('filter.top.fixed'),
        startRetracted: yup.boolean().required().default(false),
        filters: yup.array().default([]),
        customFilters: yup.array().of(
            yup.object().shape({
                itemId: yup.string().default(''),
                filters: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                    })
                ).default([]),
            })
        ).default([]),
    });
});

const LayoutAccordion = ({context, customFilterState = false, values}) => {
    const isFixedPosition = values?.filterConfig?.position?.includes('fixed');
    return (
        <>
            <FormikListener onChange={(formik) => {
                if (isFixedPosition && values?.filterConfig?.startRetracted) {
                    formik.setFieldValue('filterConfig.startRetracted', false);
                }
            }}/>
            <Accordion title={context.msg.t('layout')}
                       startOpen={!customFilterState}>
                <Field name="filterConfig.position"
                       label={context.msg.t('position')}
                       component={BngField}
                       inputComponent={BngSelect}
                       options={filterPositionBox(context)}
                       emptyOption={false}
                />
                <Field name="filterConfig.startRetracted"
                       component={BngField}
                       withLabel={false}
                       inputComponent={BngCheckbox}
                       asProps={{
                           label: context.msg.t('filter.start.retracted'),
                           disabled: isFixedPosition
                       }}
                />
            </Accordion>
        </>
    )
};

const ListAccordion = ({
                           context,
                           filters = [],
                           projectFilters = [],
                           configureFilter = _.noop,
                           toggleFilter = _.noop,
                           setFieldValue = _.noop,
                           customFilterState = false,
                           dataSources = [],
                       }) => {
    const buildItem = ({filter, isSelected, otherActions = []}) => {
        const projectFilter = projectFilters.find(pf => pf.id === filter.id);
        let actions = [];
        if (!!projectFilter && _.intersection(dataSources, projectFilter.datasources).length === 0) {
            actions.push({
                icon: "warning",
                onClick: _.noop,
                className: `InvalidDatasourceFilter`,
                title: context.msg.t('filter.without.dashboard.relation')
            });
        }
        actions = actions.concat(otherActions)
            .concat([
                {
                    icon: isSelected ? "check_box" : "check_box_outline_blank",
                    onClick: () => toggleFilter(filter),
                    className: `CheckboxFilter-${isSelected ? 'Selected' : 'Unselected'}`,
                }
            ]);
        return {
            key: filter.id,
            filterProps: {...filter},
            description: filter.caption,
            className: !isSelected && "HideDraggable",
            id: `FilterItem-${filter.id}`,
            actions: actions
        }
    }

    const buildItems = () => {
        let items = [];
        for (let filter of filters) {
            const item = buildItem({
                filter,
                isSelected: true,
                otherActions: [{
                    icon: "settings",
                    onClick: () => configureFilter(filter),
                    className: "ConfigureFilter",
                    title: context.msg.t('filter.configuration.title')
                }]
            })
            items.push(item);
        }
        for (let filter of _.differenceBy(projectFilters, filters, 'id')) {
            const item = buildItem({
                filter,
                isSelected: false,
            })
            items.push(item);
        }
        return items;
    };

    const onChangeOrder = async (itemsOrdered) => {
        const filtersOrdered = itemsOrdered
            .filter(i => !!filters.find(df => df.id === Number(i.key)))
            .map(i => filters.find(df => df.id === Number(i.key)));
        setFieldValue('filterConfig.filters', filtersOrdered);
    };

    return (
        <Accordion id="FilterListAccordion"
                   title={context.msg.t('filters')}
                   startOpen={!customFilterState}>
            <ActionList className="FilterList LastChildBorderless"
                        items={buildItems()}
                        draggable={true}
                        onChangeOrder={onChangeOrder}
            />
        </Accordion>
    )
};

const CustomFiltersAccordion = ({
                                    context,
                                    customFilters = [],
                                    filters = [],
                                    itemsData = {},
                                    setFieldValue = _.noop,
                                    removeItemFilter = _.noop,
                                    removeCustomFilter = _.noop,
                                    customFilterState = false,
                                    customFilterAdd = null,
                                }) => {
    const [boundariesElement] = useState(document.getElementById('page-content'));

    useEffect(() => {
        if (!!customFilterAdd) {
            addCustomItem([customFilterAdd]);

            let accordion = j('.CustomFiltersAccordion')[0];
            if (accordion.hasClassName('AccordionClose')) {
                accordion.addClassName('AccordionOpen');
                accordion.removeClassName('AccordionClose');
            }

        }
    }, [customFilterAdd]);

    const addCustomFilter = (item = {}, customItemFilters = []) => {
        if (customItemFilters.length === 0) return;
        const _customFilters = customFilters.map(cf => {
            if (cf.itemId !== item.id) return cf;
            return {
                ...cf,
                filters: cf.filters.concat(customItemFilters.map(cif => {
                    return {id: cif.key}
                }))
            };
        });
        setFieldValue('filterConfig.customFilters', _customFilters)
    };

    const addCustomItem = (selectedItems = []) => {
        if (selectedItems.length === 0) return;
        const newFilters = selectedItems
            .filter(i => !customFilters.find(cf => cf.itemId === (i.key || i.id)))
            .map(i => {
                return {itemId: i.key || i.id, filters: []}
            });
        setFieldValue('filterConfig.customFilters', customFilters.concat(newFilters))
    };

    const buildItems = () => {
        let items = [];
        for (let customFilter of customFilters) {
            const item = itemsData[customFilter.itemId];
            if (!item) continue;
            const itemDescription = item.additionalProps.title || item.additionalProps.label || item.additionalProps.value;
            const availableFilters = _.differenceBy(filters, (customFilter.filters || []), 'id').map(f => {
                return {
                    key: f.id,
                    label: f.caption || f.name,
                }
            });

            items.push({
                key: customFilter.itemId,
                icon: item.icon,
                description: item.caption || itemDescription || '',
                id: `CustomFilters-${customFilter.itemId}`,
                className: `${(customFilterAdd && customFilterAdd.id === customFilter.itemId) ? 'quick-highlight' : ''}`,
                actions: () => (
                    <>
                        <BngDropdownCheckbox
                            boundariesElement={boundariesElement}
                            customButton={({openDropdown}) => (
                                <BngIconButton icon="add"
                                               type="button"
                                               className="ActionListAdd"
                                               title={context.msg.t('add.custom.filter')}
                                               onClick={openDropdown}
                                />
                            )}
                            onApply={data => addCustomFilter(item, data)}
                            items={availableFilters}
                        />
                        <BngIconButton icon="close"
                                       type="button"
                                       className="ActionListRemove"
                                       title="Remover Filtro"
                                       onClick={() => removeCustomFilter(item)}/>
                    </>
                ),
                tags: customFilter.filters.map(f => {
                    const filter = filters.find(ff => f.id === ff.id);
                    if (!!filter) {
                        return (
                            {
                                onClose: () => removeItemFilter({item, filter}),
                                className: "CustomFiltersTag",
                                description: filter.caption || filter.name,
                                icon: 'icon-bim-filter'
                            }
                        )
                    }
                }),
            });
        }
        return items;
    };

    const availableItems = Object.values(itemsData)
        .filter(i => !i.isText && !customFilters.find(cf => i.id === cf.itemId))
        .map(item => ({key: item.id, label: item.caption, title: item.additionalProps?.title}));

    return (
        <Accordion className="CustomFiltersAccordion"
                   startOpen={customFilterState}
                   customTitle={(toggleAccordion) => (
                       <div className="AccordionTitle AccordionCustomTitle">
                           <Icon className="AccordionIcon" icon="arrow_drop_up" onClick={toggleAccordion}/>
                           <span className="AccordionDescription" onClick={toggleAccordion}>
                                {context.msg.t('custom.filters')}
                            </span>
                           <a href={context.msg.t('custom.filters.help.link')}
                              target="_blank"
                              rel="noreferrer">
                               <HelpIcon title={context.msg.t('custom.filters.help.description')}/>
                           </a>
                       </div>
                   )}>
            <ActionList items={buildItems()}
                        className="ActionTagList"
                        emptyComponent={() => (
                            <ActionListEmpty description={context.msg.t('no.custom.filter.added')}
                                             link={context.msg.t('custom.filters.help.link')}
                                             linkLabel={context.msg.t('know.more')}
                            />
                        )}
                        customAction={() => (
                            <BngDropdownCheckbox
                                boundariesElement={boundariesElement}
                                customButton={({openDropdown}) => (
                                    <BngIconButton icon="add"
                                                   onClick={openDropdown}/>
                                )}
                                items={availableItems}
                                onApply={addCustomItem}
                            />
                        )}
                        ItemComponent={ActionTagItem}/>
        </Accordion>
    )
};

const FilterAccordion = ({
                             availableFilters = [],
                             projectFilters = [],
                             itemsData = {},
                             fetchFilterConfig = _.noop,
                             applyFilters = _.noop,
                             ...props
                         }) => {
    const {values, initialValues, setFieldValue, isSubmitting} = useFormikContext();
    const [appliedValues, setAppliedValues] = React.useState(initialValues.filterConfig);

    useEffect(() => {
        if (props.isReloaded) {
            setFieldValue('filterConfig', initialValues.filterConfig);
            applyValues(initialValues);
            props.setIsReloaded(false);
        }
    }, []);

    const applyValues = (values) => {
        const valuesCloneFilterConfig = _.cloneDeep(values.filterConfig);
        setAppliedValues(valuesCloneFilterConfig);

        const valuesClone = _.cloneDeep(values);
        props.setAppliedValues(valuesClone);

        applyFilters(values);
    };

    const configureFilter = async (filter = {}) => {
        props.toggleAccordion(
            ConfigureFilterAccordion,
            "Configure_FiltersMenuItem",
            {filterId: filter.id, appliedValues}
        );
    };

    const toggleFilter = (filter = {}) => {
        const partialFilters = values.filterConfig.filters.filter(f => f.id !== filter.id);
        if (partialFilters.length === values.filterConfig.filters.length) {
            filter = _.merge(ConfigureFilterSchema.default(), filter);
            setFieldValue('filterConfig.filters', partialFilters.concat(filter));
        } else {
            setFieldValue('filterConfig.filters', partialFilters);
        }
    };

    const removeItemFilter = ({item = {}, filter = {}}) => {
        const customFilters = values.filterConfig.customFilters.map(cf => {
            if (cf.itemId !== item.id) return cf;
            return {
                ...cf,
                filters: cf.filters.filter(cf => cf.id !== filter.id)
            };
        });
        setFieldValue('filterConfig.customFilters', customFilters)
    };

    const removeCustomFilter = (item = {}) => {
        const customFilters = values.filterConfig.customFilters.filter(cf => cf.itemId !== item.id);
        setFieldValue('filterConfig.customFilters', customFilters)
    };

    return (
        <>
            <AccordionList className="ObjectRightMenuAccordion FilterAccordion HasFixedButton">
                <LayoutAccordion values={values}
                                 {...props}
                />
                <ListAccordion {...props}
                               setFieldValue={setFieldValue}
                               dashFilters={availableFilters}
                               projectFilters={projectFilters}
                               filters={values.filterConfig.filters}
                               toggleFilter={toggleFilter}
                               configureFilter={configureFilter}
                />
                <CustomFiltersAccordion {...props}
                                        setFieldValue={setFieldValue}
                                        itemsData={itemsData}
                                        removeItemFilter={removeItemFilter}
                                        removeCustomFilter={removeCustomFilter}
                                        filters={values.filterConfig.filters}
                                        customFilters={values.filterConfig.customFilters}
                />
            </AccordionList>

            <Accordion className="ApplyFilterConfig AccordionFixedButton" customTitle={() => null}>
                <Button className="bng-button save"
                        style={{margin: 0}}
                        type="button"
                        onClick={() => applyValues(values)}
                        disabled={!filterConfigHasChanged(values.filterConfig, appliedValues)}
                        loading={isSubmitting}>
                    {props.context.msg.t('apply')}
                </Button>
            </Accordion>
        </>
    )
};

const FilterAccordionContainer = (props) => {
    useEffect(() => {
        props.fetchDataSources();
    }, []);

    const fetchFilterConfig = async () => {
        try {
            return await Api.Dash.getFiltersConfig(props.path);
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
        return [];
    };

    const applyFilters = async (values) => {
        await props.handleFilterSubmit(values);
    };

    return (
        <FilterAccordion fetchFilterConfig={fetchFilterConfig}
                         applyFilters={applyFilters}
                         {...props}
        />
    )
};

export const filterConfigHasChanged = (a, b) => {
    const prepare = (x) => {
        x = _.cloneDeep(x);
        x.filters.forEach(f => {
            delete f.type;
            delete f.datasources;
            delete f.displayName;
            delete f.name;
            delete f.timeFilter;
        });
        return x;
    }

    a = prepare(a);
    b = prepare(b);

    return !_.isEqual(a, b);
}

export default ContextEnhancer(FilterAccordionContainer);