import React from 'react';
import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';
import { GROUP_KEYS } from 'components/bng/pages/marketplace/MarketplaceLayout';

const SERVICES_GROUPS = [
  {
    title: 'tabNav.services',
    desc: 'services.desc',
    key: GROUP_KEYS.SERVICES,
  },
];

export default function MktServicesTab({ ...props }) {
  return <MktDefaultTab className={'MktAddonsTab'} groupsToRender={SERVICES_GROUPS} {...props} />;
}
