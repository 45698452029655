import './BngImageUpload.css';

import React from 'react';
import PropTypes from 'prop-types';

import UiMsg from "components/ui/UiMsg";
import Api from "components/Api"
import Icon from "components/ui/common/Icon";
import ContextEnhancer from 'components/ContextEnhancer';

class BngImageUpload extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        height: PropTypes.any,
        width: PropTypes.any,
    };

    static defaultProps = {
        width: 131,
        height: 72,
    };

    handleSelectFile = (e) => {
        const files = e.target.files;
        if (files[0].type.includes('image')) {
            this.props.form.setFieldValue(this.props.field.name, files[0]);
        } else {
            UiMsg.error(this.props.context.msg.t('only.image.support'));
        }

    };

    clearImageSelect = (e) => {
        this.props.form.setFieldValue(this.props.field.name, null);
        e.preventDefault();
    };

    render() {
        const {field, form, title, width, height, ...props} = this.props;
        const imgSrc = Api.Upload.previewUrl(field.value);
        return (
          <div className={`BngImageUpload imageupload ${this.props.className || ''}`}>
              {(!field.value) &&
                <label>
                    <input type="file"
                           accept="image/*"
                           className="image-upload-hidden"
                           onChange={this.handleSelectFile}
                           {...props}
                    />
                    <div className="lbl-image-upload" style={{width: width, height: height}} title={title}>
                        <Icon icon="add"/>
                    </div>
                </label>
              }
              {(!!field.value) &&
                <React.Fragment>
                    <div className="button-cancel-image" onClick={this.clearImageSelect}>
                        <Icon icon="close"/>
                    </div>
                    <label className="label-container-image">
                        <img alt="user" style={{width: width, height: height}} src={imgSrc} align="middle"/>
                    </label>
                </React.Fragment>
              }
          </div>
        );
    }

}

export default ContextEnhancer(BngImageUpload);
