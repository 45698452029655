import styles from './BngColorPicker.module.css';

import React, { useEffect, useState } from 'react';
import BngAnimatedTabsBar from 'components/bng/ui/BngAnimatedTabsBar';
import BngColorPalette from 'components/bng/colorPicker/BngColorPalette';
import BngColorSolid from 'components/bng/colorPicker/BngColorSolid';
import BngColorGradient from 'components/bng/colorPicker/BngColorGradient';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import usePrevious from 'components/hooks/usePrevious';

const BngColorPicker = (
  {
    paletteEnabled = true,
    solidEnabled = true,
    gradientEnabled = false,
    width = 330,
    onApply = _.noop,
    customColorOptions = [],
    color = {
      colors: [{ color: '#005dff', opacity: 1, position: 0, id: 0 }],
      rotation: 0,
    },
    transparencyEnabled = false,
    gradientRotationEnabled = false,
    onTabChange = _.noop,
    fetchProjectColors = true,
    closeDropdown = _.noop,
    footerSlot = _.noop,
    uncontrolled = false,
  },
  forwardedRef
) => {
  const TABS = (paletteEnabled ? 1 : 0) + (solidEnabled ? 1 : 0) + (gradientEnabled ? 1 : 0);
  const initialTab = paletteEnabled ? 0 : solidEnabled ? 1 : gradientEnabled ? 2 : -1;

  const [tabValue, setTabValue] = useState(initialTab);
  const [themeColors, setThemeColors] = useState(customColorOptions);
  //Marker position is a memo of the last calculated marker position, to minimize the inconsistencies when changing tabs
  const [markerPosition, setMarkerPosition] = useState([]);
  const [gradientListMemo, setGradientListMemo] = useState(_.cloneDeep(color.colors));
  const [selectedColor, setSelectedColor] = useState(_.cloneDeep(color));

  const context = useBimContext();
  const prevColor = usePrevious(color.colors);

  const loadThemeColors = async () => {
    try {
      if (_.isEmpty(customColorOptions)) {
        const projectId = context?.project?.id;
        if (projectId && fetchProjectColors) {
          const data = await Api.Project.findTheme(projectId);
          setThemeColors(data.colorPalette.map((c) => c.color));
        }
      } else {
        setThemeColors(customColorOptions);
      }
    } catch (e) {
      console.error('Error on loadThemeColors()', e);
    }
  };

  useEffect(() => {
    loadThemeColors();
  }, [customColorOptions]);

  useEffect(() => {
    insertIdInGradientList();
  }, [color, tabValue]);

  useEffect(() => {
    onTabChange(tabValue);
  }, [tabValue]);

  useEffect(() => {
    if (uncontrolled) {
      setSelectedColor(_.cloneDeep(color));
    }
  }, [color]);

  const insertIdInGradientList = () => {
    let updatedColorList = [];
    let listChanged = false;

    if (tabValue === 2 && color.colors.length === 1) {
      updatedColorList = [...color.colors];
      if (gradientListMemo.length === 1) {
        updatedColorList.push({ color: '#ffffff', opacity: 1, position: 1 });
      } else {
        updatedColorList = [...gradientListMemo];
        updatedColorList[0] = { ...color.colors[0] };
      }
      listChanged = true;
    }

    if (gradientEnabled && color.colors.some((c) => !Object.hasOwn(c, 'id'))) {
      updatedColorList = [...color.colors];
      updatedColorList = updatedColorList.map((c, idx) => {
        return { id: idx, ...c };
      });
      listChanged = true;
    }
    if (listChanged) {
      const colors = { colors: updatedColorList, rotation: color.rotation };
      onApply(colors);
      if (uncontrolled) {
        setSelectedColor(colors);
      }
    }
  };

  //Used to reset marker position when a color is set, so the position is calculated again
  const resetMarkerPosition = (forceReset = false) => {
    if (!_.isEqual(color.colors, prevColor) || forceReset) setMarkerPosition([]);
  };

  const selectColor = (newColor, gradientRotation = null) => {
    if (gradientRotation !== null) {
      let colorArr = [];
      if (gradientListMemo.length === 1) {
        colorArr = color.colors.map((color) => (newColor.id === color.id ? newColor : color));
      } else {
        colorArr = gradientListMemo.map((color) => (newColor.id === color.id ? newColor : color));
      }

      setGradientListMemo([...colorArr]);
      const colors = { colors: [...colorArr], rotation: Number(gradientRotation) };
      onApply(colors);
      if (uncontrolled) {
        setSelectedColor(colors);
      }
    } else {
      const colorArr = gradientListMemo.map((c, idx) => (idx === 0 ? newColor : c));
      setGradientListMemo([...colorArr]);
      const colors = { colors: [newColor], rotation: color.rotation };
      onApply(colors);
      if (uncontrolled) {
        setSelectedColor(colors);
      }
    }
  };

  const TABS_WIDTH = width / TABS;

  const validColor = uncontrolled ? selectedColor : color;

  return (
    <div className={`BngColorPicker ${styles.colorPickerContainerWrapper}`} style={{ width }} ref={forwardedRef}>
      <div className={`ColorPickerContainerHeader ${styles.colorPickerContainerHeader}`}>
        {paletteEnabled && (
          <div
            className={`ColorPickerPalette ${styles.paletteTab} ${tabValue === 0 ? styles.selectedTab : ''}`}
            onClick={() => {
              if (tabValue !== 0) {
                selectColor(color.colors[0]);
                resetMarkerPosition(true);
                setTabValue(0);
              }
            }}
            style={{ width: TABS_WIDTH }}
          >
            <span>{context.msg.t('color.picker.palette')}</span>
          </div>
        )}
        {solidEnabled && (
          <div
            className={`ColorPickerSolid ${styles.solidTab} ${tabValue === 1 ? styles.selectedTab : ''}`}
            onClick={() => {
              if (tabValue !== 1) {
                selectColor(color.colors[0]);
                resetMarkerPosition(true);
                setTabValue(1);
              }
            }}
            style={{ width: TABS_WIDTH }}
          >
            <span>{context.msg.t('color.picker.solid')}</span>
          </div>
        )}
        {gradientEnabled && (
          <div
            className={`ColorPickerGradient ${styles.gradientTab} ${tabValue === 2 ? styles.selectedTab : ''}`}
            onClick={() => {
              if (tabValue !== 2) {
                resetMarkerPosition(true);
                setTabValue(2);
              }
            }}
            style={{ width: TABS_WIDTH }}
          >
            <span>{context.msg.t('color.picker.gradient')}</span>
          </div>
        )}
      </div>
      <BngAnimatedTabsBar barWidth={width} tabs={TABS} currentTab={tabValue} />
      {tabValue === 0 ? (
        <BngColorPalette
          themeColors={themeColors}
          color={validColor.colors[0]}
          selectColor={selectColor}
          transparencyEnabled={transparencyEnabled}
          width={width}
        />
      ) : tabValue === 1 ? (
        <BngColorSolid
          color={validColor.colors[0]}
          selectColor={selectColor}
          width={width}
          setMarkerPosition={setMarkerPosition}
          markerPosition={markerPosition}
          transparencyEnabled={transparencyEnabled}
        />
      ) : (
        <BngColorGradient
          color={validColor}
          selectColor={selectColor}
          width={width}
          setMarkerPosition={setMarkerPosition}
          markerPosition={markerPosition}
          transparencyEnabled={transparencyEnabled}
          resetMarkerPosition={resetMarkerPosition}
          gradientRotationEnabled={gradientRotationEnabled}
        />
      )}
      {footerSlot?.({ color: validColor, closeDropdown })}
    </div>
  );
};

export default React.forwardRef(BngColorPicker);
