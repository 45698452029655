import styles from './NavRoundButton.module.css';

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

import { BngIsNewLabel } from 'components/bng/ui/BngHorizontalCard';
import useBimContext from 'components/hooks/useBimContext';
import useQueryParams from "components/hooks/useQueryParams";
import Utils from 'components/Utils';

const NAV_BUTTON_DROPDOWN_QUERY_PARAM = 'navButtonDropdown';

export default function NavRoundButton({
                                           DropdownComponent = _.noop,
                                           label = '',
                                           isNovelty = false,
                                           noClick = false,
                                           ...props
                                       }) {
    const context = useBimContext();
    const { queryParams } = useQueryParams();

    const destroyView = (container) => {
        if (queryParams.has(NAV_BUTTON_DROPDOWN_QUERY_PARAM)) {
            Utils.History.updateQuery({
                navButtonDropdown: null,
            })
        }
        ReactDOM.unmountComponentAtNode(container);
        j(container).remove();
    };

    const openDropdown = (event) => {
        const boundaryElement = document.body;
        const container = document.createElement('div');
        container.classList.add('NavRoundButtonParent');
        boundaryElement.appendChild(container);
        ReactDOM.render(
            <DropdownComponent container={container} boundaryElement={boundaryElement} anchorEl={event.target} destroyView={() => destroyView(container)} {...props} />,
            container
        );
    };

    useEffect(() => {
        if (queryParams.has(NAV_BUTTON_DROPDOWN_QUERY_PARAM) && !noClick) {
            openDropdown({target: j('.NavRoundButton')})
        }
    },[])

    return (
        <li
            className={`NavRoundButton ${styles.NavRoundButton} ${noClick ? styles.noClick : ''}`}
            onClick={noClick ? _.noop : openDropdown}
        >
            {!isNovelty &&
                <span
                    className={`${styles.activateNowSpan}`}
                    dangerouslySetInnerHTML={{
                        __html: context.msg.t(context.projectActivated ? 'project.activated' : 'activate.now.link'),
                    }}
                />
            }
            <span className={isNovelty ? '' : `${styles.labelSpan}`} dangerouslySetInnerHTML={{ __html: label }} />
            {isNovelty && <BngIsNewLabel className="Blue" {...props} />}
        </li>
    );
}
