import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import { connect } from 'react-redux';

import Api from 'components/Api';
import Utils from 'components/Utils';
import ContextEnhancer from 'components/ContextEnhancer';
import Text from 'components/ui/dashboard/components/Text';
import IconText from 'components/ui/dashboard/components/IconText';
import BiSource from 'components/ui/map/BiSource';
import DeepEqualComponent from 'components/DeepEqualComponent';
import Kpi from 'components/ui/kpi/Kpi';
import BigTableRenderer from 'components/bng/pages/bigTable/BigTableRenderer';
import LoadingPlaceholder from 'components/ui/loading/LoadingPlaceholder';
import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import FilterService from 'components/filter/FilterService';
import HtmlComponent from 'components/ui/dashboard/components/HtmlComponent';
import BngErrorBoundary from 'components/bng/ui/BngErrorBoundary';
import BngAnalysisDrillDownBar, { extractMemberLastPart } from 'components/bng/analysis/BngAnalysisDrillDownBar';
import DashGridContext from 'components/ui/dashboard/components/DashGridContext';
import FilterUtils from 'components/filter/FilterUtils';
import UiMsg from 'components/ui/UiMsg';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import AnalysisECharts from 'components/bng/analysis/AnalysisECharts';
import BimEventBus from 'BimEventBus';
import { PUBLISHER_FULL_INTERACTION_EVENT } from 'components/service/bng/PublisherApi';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';

window.RENDERABLE_PRELOAD_CACHE = {};

const renderLoadingPlaceholder = (params = {}) => {
  return (
    <div className="free-style-first-dashboard-load fill-w fill-h">
      <LoadingPlaceholder {...params} />
    </div>
  );
};

const DYNAMIC_ALREADY_ALERTED = [];

const RENDER_FINISHED_EVENT = 'RenderablePreload:RENDER_FINISHED_EVENT';

class RenderablePreload extends DeepEqualComponent {

    static propTypes = {
        id: PropTypes.string.isRequired,
        path: PropTypes.string,
        viewType: PropTypes.string,
        filters: PropTypes.any,
        dynamic: PropTypes.bool,
        order: PropTypes.number.isRequired,
        additionalProps: PropTypes.object,
        resizeContent: PropTypes.bool,
        align: PropTypes.string,
        objectLastUpdate: PropTypes.any,
        renderQueue: PropTypes.any.isRequired,
        renderCallback: PropTypes.func,
        borderType: PropTypes.string,
        style: PropTypes.object,
        inContainer: PropTypes.bool,
        position: PropTypes.object,
        enableIframe: PropTypes.bool,
    };

    static defaultProps = {
        path: '',
        viewType: '',
        filters: '',
        order: 1,
        dynamic: false,
        additionalProps: {},
        resizeContent: false,
        mobile: false,
        dashboardPath: '',
        renderCallback: _.noop,
        borderType: 'SIMPLE',
        style: {
            allowMargin: false,
            allowTheme: false,
            allowItemTransparency: false,
        },
        inContainer: false
    };

    state = {
        width: 0,
        height: 0,
        reloadRequestTime: 0,
        visible: false,
        result: null,
        loading: true,
        invalidFilter: false,
    };

    componentDidMount() {
        this.__cleanup = BimEventBus.on(RENDER_FINISHED_EVENT, ({ renderItem, result }) => {
          if (renderItem.id !== this.props.id) {
            return;
          }
          this.updatePreloadResult(result);
        });

        this.verifyInvalidFilters(true);
    }

    verifyInvalidFilters(alert = false) {
        if (this.props.isText || Utils.Object.isOrgmap(this.props.path)) {
            return;
        }

        const currentDate = new Date();

        const filtersIsArray = _.isArray(this.props.filters);
        const containSomeInvalidDateFilter = filtersIsArray ? this.props.filters.some(f => f.members.some(m => !FilterUtils.isPeriodicityValidOnDate(m, currentDate))) : false;
        const dynamicTimeFilterConflictFilterId = this.props.additionalProps?.dynamicTimeFilterConflictFilterId;
        let invalidPeriodicityIsOverridden = false;
        if (filtersIsArray && dynamicTimeFilterConflictFilterId) {
            const match = this.props.filters.find(f => f.id === dynamicTimeFilterConflictFilterId);
            invalidPeriodicityIsOverridden = match && match.members.length > 0
        }

        const periodicity = this.props.additionalProps?.dynamicTimeFilter?.periodicity;
        const invalidPeriodicity = !FilterUtils.isPeriodicityValidOnDate(periodicity, currentDate)
            && !invalidPeriodicityIsOverridden;

        if (alert
            && !containSomeInvalidDateFilter
            && invalidPeriodicity
            && !DYNAMIC_ALREADY_ALERTED.includes(this.props.path)) {
            DYNAMIC_ALREADY_ALERTED.push(this.props.path);
            UiMsg.warn(
                `${this.props.context.msg.t('attention')}!`,
                this.props.context.msg.t(
                    'invalid.date.for.periodicity.alert',
                    [this.props.context.msg.t(periodicity), this.props.caption]
                )
            );
            setTimeout(() => {
                const idx = DYNAMIC_ALREADY_ALERTED.indexOf(this.props.path);
                if (idx !== -1) {
                    DYNAMIC_ALREADY_ALERTED.splice(idx, 1);
                }
            }, 1000);
        }

        const invalidFilter = containSomeInvalidDateFilter || invalidPeriodicity;

        if (this.state.invalidFilter !== invalidFilter) {
            this.setState({
                invalidFilter
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps, this.props)) {
            if (!_.isEqual(this.props.filters, prevProps.filters)) {
                this.verifyInvalidFilters(true);
            }

            if (_.isEqual(this.props.position, prevProps.position)) {
                this.renderElement(false);
            } else {
                this.updateSize();
            }
        } else if (this.state.visible
            && (
                this.state.height !== prevState.height
                || this.state.width !== prevState.width
                || !this.state.result
                || (!this.state.invalidFilter && this.state.invalidFilter !== prevState.invalidFilter)
            )) {
            this.renderElement(false);
        }
    }

    componentWillUnmount() {
        this.__cleanup();
    }

    updateSize = _.debounce((size) => {
        if (!size) {
            size = this.calculateSize();
        }
        this.setState(size, () => {
            this.renderElement();
        });
    }, 200);

    renderElement = (reload = false) => {
        const {width, height, visible, reloadRequestTime, invalidFilter} = this.state;
        if (!width || !height || !visible) {
            return;
        }

        this.setState({
            result: null,
            loading: true,
            reloadRequestTime: reload ? Date.now() : reloadRequestTime
        });

        const {
            id,
            path = '',
            viewType,
            isText,
        } = this.props;

        if (!id) {
            const result = {
                html: '',
                preload: {
                    status: 'NOT_FOUND'
                }
            };
            this.updatePreloadResult(result);
            return;
        }

        if (!isText && !Utils.Object.isOrgmap(path) && invalidFilter) {
            const result = {
                html: '',
                preload: {
                    status: 'NO_CONTENT'
                }
            };
            this.updatePreloadResult(result);
            return;
        }

        if (isText
            || Utils.Object.isKpi(path)
            || Utils.Object.isMap(path)
            || Utils.Object.isBigTable(path)
            || (Utils.Object.isAnalysis(path) && viewType !== 'html')) {
            const result = {
                html: '',
                preload: {
                    status: 'OK'
                }
            };
            this.updatePreloadResult(result);
            return;
        }

        this.requestRenderForItem({width, height, reload});
    };

    requestRenderForItem = ({width, height, reload, drillState, filterStateUpdate} = {
        width: 100,
        height: 100,
        reload: false,
        drillState: {drills: []},
        filterStateUpdate: undefined
    }) => {
        return new Promise(resolvePromise => {
            const {
                id,
                filters,
                dynamic,
                viewType,
                resizeContent,
                objectLastUpdate,
                align,
                cancelToken,
                dashboardPath,
                highlight,
                style,
                path,
                originalPath
            } = this.props;

            const cacheInfo = {
                path,
                width,
                height,
                filters,
                dynamic,
                viewType,
                resizeContent,
                objectLastUpdate,
                align,
                highlight,
                restrictFilters: this.props.restrictFilters,
                allowTheme: style.allowTheme,
                allowMargin: style.allowMargin,
                allowContainerMargin: style.allowContainerMargin,
                margin: style.margin,
                containerMargin: style.containerMargin,
                backgroundTheme: style.backgroundTheme,
                highlightColor: style.highlightBoxColor,
                drillState
            };

            const renderItem = {
                id,
                date: new Date(),
                finished: false,
                cacheInfo
            };

            renderItem.render = async () => {
                let result = window.RENDERABLE_PRELOAD_CACHE[id];

                if (!result || !_.isEqual(result.cacheInfo, cacheInfo)) {
                    try {
                        if (this.props.mobile) {
                            result = await Api.Dash.renderItemMobile(id, {
                                width,
                                height,
                                dashboardPath,
                                ...FilterService.createFilterParam(filters || [], true),
                                drillState,
                                sourceType: viewType,
                                itemToRenderPath: path !== originalPath ? path : null
                            });
                        } else {
                            const filterParams = FilterService.createFilterParam(filters || []);
                            result = await Api.Dash.renderItemAxios(id, {
                                id,
                                width,
                                height,
                                reload,
                                cancelToken,
                                plainFilter: filterParams.filter,
                                dashboardPath,
                                drillState,
                                filterStateUpdate
                            });

                        }
                    } catch (e) {
                        console.error('error render dashboard element...', e);
                        result = {preload: {status: 'ERROR', message: e.message}}
                    }
                    renderItem.finished = true;
                    if (result.preload.status === 'OK') {
                        result.cacheInfo = cacheInfo;
                        window.RENDERABLE_PRELOAD_CACHE[id] = result;
                    }
                }

                BimEventBus.emit(RENDER_FINISHED_EVENT, {
                    renderItem,
                    result
                });
                resolvePromise(result);
            };

            this.props.renderQueue.add(renderItem);
        });
    }

    updatePreloadResult = (result) => {
        window.RENDERABLE_PRELOAD_CACHE[this.props.id] = result;
        this.setState({result, loading: false}, () => {
            this.props.renderCallback({ result });
        });
    };

    showError = () => {
        ObjectErrorPopup({message: this.state.result.preload.message});
    };

    toggleVisibility = (visible) => {
        if (this.state.visible) {
            return;
        }

        if (!visible) {
            this.props.renderCallback();
            return;
        }

        const sizes = this.calculateSize();

        this.setState({...sizes, visible: true});
        const isVisible = !isNaN(sizes.width) && !isNaN(sizes.height);
        if (isVisible) {
            this.setState({...sizes, visible: true});
        } else {
            this.setState({visible: false});
        }
    };

    calculateSize() {
        return this.props.position;
    }

    getDashMargin = () => {
        const {style} = this.props;
        if (style.allowTheme && style.allowMargin) {
            return style.margin;
        }
        return 0;
    }

    render() {
        const {
            id,
            path,
            viewType,
            order,
            dynamic,
            isText,
            itemProps,
            additionalProps,
            resizeContent,
            filters,
            style,
            highlight,
            align,
            dashItemId,
            mobile,
            isPresentation,
            dashboardPath,
            inContainer,
            enableIframe,
        } = this.props;

        const {
            result,
            loading,
            visible,
            reloadRequestTime,
            invalidFilter
        } = this.state;

        let {width, height} = this.state;


        const itemMargin = this.getDashMargin() * 2;
        width -= itemMargin;
        height -= itemMargin

        let content = '';
        if (!visible) {
            content = (
                <VisibilitySensor onChange={this.toggleVisibility} delayedCall={true}/>
            )
        } else if ((loading || (result && result.cancelRequest))) {
            content = renderLoadingPlaceholder({path, viewType, width, height});
        } else if (invalidFilter || result?.preload?.status === 'NO_CONTENT') {
            content = (
                <DashboardItemInformation path={path} viewType={viewType} width={width} height={height}/>
            );
        } else if (result) {
            switch (result.preload.status) {
                case 'OK':
                    const Comp = this.findComponent(path, viewType, dynamic, isText);
                    content = (
                        <div style={{height: height, width: width}}>
                            <BngErrorBoundary path={path}
                                              height={height}
                                              message={this.props.context.msg.t('dashboard.item.error.message')}
                                              width={width}
                                              errorComponent={DashboardItemInformation}
                            >
                                <Comp id={id}
                                      path={path}
                                      filters={filters}
                                      order={order}
                                      height={height}
                                      width={width}
                                      html={result.html}
                                      dashboardStyle={style}
                                      highlight={highlight}
                                      align={align}
                                      itemProps={itemProps}
                                      additionalProps={additionalProps}
                                      resizeContent={resizeContent}
                                      dashboardPath={dashboardPath}
                                      showErrorDialog={this.showError}
                                      onUpdateHtml={this.updatePreloadResult}
                                      dashItemId={dashItemId}
                                      mobile={mobile}
                                      isPresentation={isPresentation}
                                      inContainer={inContainer}
                                      backgroundTheme={style.backgroundTheme}
                                      requestRenderForItem={this.requestRenderForItem}
                                      reloadRequestTime={reloadRequestTime}
                                      enableIframe={enableIframe}
                                      result={result}
                                />
                            </BngErrorBoundary>
                        </div>
                    );
                    break;
                case 'BAD_QUERY':
                    content = (
                        <DashboardItemInformation path={path} viewType={viewType} width={width} height={height}
                                                  message={this.props.context.msg.t('xmla_query_error')}/>
                    );
                    break;
                case 'NOT_FOUND':
                    content = (
                        <DashboardItemInformation path={path} viewType={viewType} width={width} height={height}
                                                  showErrorDialog={this.showError}
                                                  reload={() => this.renderElement(true)}
                                                  message={this.props.context.msg.t('dashboard.item.error.message')}/>
                    );
                    break;
                case 'MISS_DASHBOARD':
                    content = renderLoadingPlaceholder({path, viewType, width, height});
                    break;
                case "STRUCTURE_NOT_LOADED":
                    content = (
                        <DashboardItemInformation
                            path={path}
                            message={this.props.context.msg.t("dashboard.item.not.loaded.message")}
                            showErrorDialog={null}
                            width={width}
                            height={height}
                            snackbarType='not-loaded'
                            snackbarIcon='cached'
                        />
                    )
                    break;
            }
        }

        if (!content) {
            content = (
                <div style={{height: '100%'}}>
                    <div className="fill-w fill-h free-style-first-dashboard-load">
                        {(result && result.preload.status !== 'OK' && !result.cancelRequest) &&
                            <DashboardItemInformation path={path} viewType={viewType} width={width} height={height}
                                                      showErrorDialog={this.showError}
                                                      reload={() => this.renderElement(true)}
                                                      message={this.props.context.msg.t(result.preload.status === 'ERROR' ? 'dashboard.item.error.message' : 'dashboard.item.timeout.message')}
                                                      errorTrace={result.preload.message}
                            />
                        }
                    </div>
                </div>
            );
        }

        return (
            <div id={`dashbox-${id}`}
                 className="RenderablePreload"
                 style={{width: width, height: height}}
                 ref={ref => this._ref = ref}>
                {content}
            </div>
        );
    }

    findComponent(path, viewType, dynamic = false, isText = false) {
        if (isText) {
            switch (viewType) {
                case 'label': {
                    return LabelRender;
                }
                case 'icon' : {
                    return IconRender;
                }
                case 'imageContent' : {
                    return ImageRender;
                }
                case 'text':
                case 'textNew': {
                    return HtmlRender;
                }

            }
        }
        const extension = Utils.Object.getObjectType(path);
        switch (extension) {
            case 'analysis': {
                if (viewType === 'html') {
                    if (dynamic) {
                        return AnalysisDynamicTableRender;
                    }
                    return AnalysisTableRender;
                }
                return AnalysisChartRender;
            }
            case 'newmap':
            case 'maps': {
                return MapRender;
            }
            case 'orgmap': {
                return OrgmapRender;
            }
            case 'kpi': {
                return NewKpiRender;
            }
            case 'bigtable': {
                return BigTableRender;
            }
        }
        return ComponentNotFound;
    }
}

const ComponentNotFound = (props) => {
    useEffect(() => {
        console.log('Component not found for props:', props)
    }, []);
    return (<></>);
}

const OrgmapRender = ({order, html, height, resizeContent}) => {
    const style = {
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
        width: '100%',
        height: '100%',
    };
    return (
        <ContentSubWrapper height={height} resizeContent={resizeContent} className="OrgmapRender">
            <div
                style={{width: '100%', height: '100%', display: 'table'}}
                className={`orgmap-${order}`}>
                <div className={`item-orgmap-${order}`}
                     style={style}
                     ref={ref => {
                         if (!ref) return;

                         let style = `
                                          <style type="text/css">
                                            .view-more-painels-fixed {
                                     position: fixed;
                                     background: #ffffff;
                                     width: 300px;
                                     border-left: 1px solid #ccc;
                                     border-left: 1px solid rgba(0, 0, 0, 0.2);
                                     border-right: 1px solid #ccc;
                                     border-right: 1px solid rgba(0, 0, 0, 0.2);
                                     border-bottom: 1px solid #ccc;
                                     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
                                     background-clip: padding-box;
                                 }
                     </style>
                     `;

                         let script = `<script type="text/javascript">
                         j(j('.orgmap-${order}').parent().parent()).on('scroll', function () {
                         j('.orgmap-${order} .view-more-painels-fixed').css('margin-top','-'+j(j('.orgmap-${order}').parent().parent()).scrollTop()+'px');
                         j('.orgmap-${order} .view-more-painels-fixed').css('margin-left','-'+j(j('.orgmap-${order}').parent().parent()).scrollLeft()+'px');
                         j(j('.orgmap-${order} .view-more-painels-fixed').parent().parent()).css('display','none');
                     });
                     </script>`;
                         j(ref).html(html + style + script);
                     }}
                />
            </div>
        </ContentSubWrapper>
    );
};

const AnalysisHeader = ({
                            showTitle = false,
                            title = '',
                            showDescription = false,
                            description = "",
                            showLineBreak = false,
                            titleFontSize = 11,
                            titleTextAlign = 'center',
                            titleColor = '#333333',
                            titleFontStyle = ''
                        }) => {
    const style = {
        margin: '5px 0 2px 0',
        fontSize: `${titleFontSize}px`,
        textAlign: titleTextAlign,
        color: titleColor,
    };

    if (titleFontStyle === 'font-weight: bold;') {
        style.fontWeight = 'bold';

    } else if (titleFontStyle === 'font-style: italic;') {
        style.fontStyle = 'italic';

    } else if (titleFontStyle === 'font-weight: bold; font-style: italic;') {
        style.fontWeight = 'bold';
        style.fontStyle = 'italic';
    }

    const descriptionStyle = {...style, fontSize: `${titleFontSize - 4}px`};
    return (
        <div className="titleItemDash">
            {(showTitle) &&
                <div style={style}>
                    {title}
                </div>
            }

            {(showDescription) &&
                <div style={descriptionStyle}>
                    {description}
                </div>
            }
            {(showLineBreak) &&
                <hr style={{margin: '2px'}}/>
            }
        </div>
    );
};

const AnalysisDynamicTableRender = ({id, order, html, onUpdateHtml, additionalProps, height, resizeContent, path}) => {
    let updating = false;

    return (
        <ContentSubWrapper height={height}
                           resizeContent={resizeContent}
                           className="AnalysisDynamicTableRender"
                           childRenderClassName={additionalProps.fixedHeader ? '' : 'analysisScrollHorizontalFix'}
        >
            <AnalysisHeader {...additionalProps}/>
            <AnalysisWrapper {...{id, order, ...additionalProps}}>
                <div className="mdx-table-container AnalysisDynamicTableRender" ref={ref => {
                    if (!ref) return;

                    const $el = j(ref);
                    $el.html(html);
                    $el.find('input').removeAttr('onclick');

                    const findScrollDiv = () => ref.querySelector('.clusterize-scroll');
                    const restoreScroll = !!findScrollDiv();
                    let scrollYPosition = 0;


                    if(ref.__updateTable) {
                        return;
                    }

                    ref.__updateTable = async (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (updating) return;

                        const $container = $el.parents('.item-content-container');
                        $container.append(`<div class="overlay-container"><div class="overlay"></div> <LoadingPulse/> </div>`);

                        try {
                            updating = true;
                            if (restoreScroll) {
                                scrollYPosition = findScrollDiv().scrollTop;
                            }
                            const result = await Api.Dash.updateTable(id, {[event.target.name]: '', content: path});
                            $el.off('click', 'input', ref.__updateTable);
                            delete ref.__updateTable;
                            onUpdateHtml(result);

                            BimEventBus.emit(PUBLISHER_FULL_INTERACTION_EVENT, {
                                source: 'AnalysisDynamicTable'
                            });
                        } finally {
                            $container.find('.overlay-container').remove();
                            if (restoreScroll) {
                                findScrollDiv().scrollTop += scrollYPosition
                            }
                            updating = false;
                        }
                    };
                    $el.on('click', 'input', ref.__updateTable);
                }}>
                </div>
            </AnalysisWrapper>
        </ContentSubWrapper>
    );
};

const AnalysisTableRender = ({id, order, html, path, viewType, width, height, additionalProps, resizeContent}) => {
    return (
        <ContentSubWrapper height={height}
                           resizeContent={resizeContent}
                           className="AnalysisTableRender"
                           childRenderClassName={additionalProps.fixedHeader ? '' : 'analysisScrollHorizontalFix'}>
            <AnalysisHeader {...additionalProps}/>
            <AnalysisWrapper {...{id, order, ...additionalProps}}>
                <div className="mdx-table-container AnalysisTableRender" ref={ref => {
                    if (!ref) return;
                    const $el = j(ref);
                    $el.html(html);
                }}>
                </div>
            </AnalysisWrapper>
        </ContentSubWrapper>
    )
};

let __AnalysisChartRender__PERSISTED_STATE = {};

const AnalysisChartRender = ({
                                 id,
                                 path,
                                 viewType,
                                 filters,
                                 width,
                                 height,
                                 inContainer,
                                 backgroundTheme,
                                 additionalProps,
                                 requestRenderForItem,
                                 reloadRequestTime,
                                 highlight,
                                 dashboardStyle,
                                 mobile = false,
                                 result,
                                 dashboardPath,
                             }) => {
    const dashGridCtx = useContext(DashGridContext);

    const [loading, setLoading] = useState(false);
    const [chartAreaRef, setChartAreaRef] = useState();
    const [lastSizeUpdate, setLastSizeUpdate] = useState(Date.now());
    const [currentDrillState, setCurrentDrillState] = useState();
    const [currentDrillResponse, setCurrentDrillResponse] = useState();

    const htmlIsEmpty = !result?.html;

    const PERSISTED_STATE = __AnalysisChartRender__PERSISTED_STATE[id];
    const drillState = currentDrillState
      ?? PERSISTED_STATE?.drillState
      ?? result?.additionalProps?.drillState
      ?? additionalProps.drillData?.drillState;

    const drillResponse = currentDrillResponse
      ?? additionalProps.drillData?.drillResponse
      ?? result?.additionalProps?.drillResponse
      ?? PERSISTED_STATE?.drillResponse;

    const chartWidth = Math.min(chartAreaRef?.clientWidth ?? 99999, width);
    const chartHeight = Math.min(chartAreaRef?.clientHeight ?? 99999, height);
  useEffect(() => {
    if (!chartAreaRef || additionalProps.isEchartsModel) {
      return;
    }

    let drillStateCopy = drillState;
    if (drillStateCopy) {
      drillStateCopy = _.cloneDeep(drillStateCopy);
      drillStateCopy.drills?.forEach((drill) => {
        if (filters instanceof Array && filters.length !== 0) {
          const filter = filters.find((filter) => drill.filterId === filter.id);
          if (filter && _.isEmpty(filter.members)) {
            drill.member = '';
          }
        }
      });
    }

      window.requestAnimationFrame(() => {
        requestRenderForItem({
          width: chartWidth,
          height: chartHeight,
          reload: false,
          drillState: drillStateCopy,
        });
      });
  }, [chartAreaRef, inContainer, width, height, reloadRequestTime, htmlIsEmpty, filters, drillState]);

  const marginAdjust =   inContainer
    ? (dashboardStyle.allowContainerMargin ? dashboardStyle.containerMargin : 10)
    : 24; // margin applied on .zoom-target-disable

  const showDrillButtons =
    additionalProps.isDrillableChartModel && (!dashGridCtx.isFromPublisher || dashGridCtx.privateVisibility);
  const dashCtxFiltersCopy = showDrillButtons ? _.cloneDeep(dashGridCtx?.filters ?? []) : null;
  const buttonContainer = showDrillButtons
    ? document.querySelector(`.dash-item-newmenu[data-item-id="${id}"] .DrillButtonsContainer`)
    : additionalProps.isEchartsModel
    ? document.querySelector(`.dash-item-newmenu[data-item-id="${id}"] .EChartsButtonsContainer`)
    : undefined;

  useEffect(() => {
    setLastSizeUpdate(Date.now());
  }, [width, height]);

    const highlightColor = highlight ? dashboardStyle.highlightBoxColor : null;

    return (
        <ContentSubWrapper
            className={`AnalysisChartRender ${showDrillButtons ? 'BngAnalysisDrillDownContainer' : ''} ${
                additionalProps.isEchartsModel ? 'EChartModelAnalysisContainer' : ''
            }`}
        >
        <UiBlocker className={`AnalysisChartRenderWrapper`}
                       style={{height: `${height - marginAdjust}px`}}
                       block={loading}
            >
                <AnalysisHeader {...additionalProps}/>
                <div className={`AnalysisChartRenderChart ${showDrillButtons ? 'mt-5' : ''}`}
                     ref={ref => setChartAreaRef(ref)}
                >
                    {!chartAreaRef ?  null : (
                        additionalProps.isEchartsModel ? (
                            <AnalysisECharts path={path}
                                             filters={filters}
                                             backgroundTheme={backgroundTheme}
                                             toolbarContainer={buttonContainer}
                                             highlightColor={highlightColor}
                                             dashboardPath={dashboardPath}
                                             disableNavigation={dashGridCtx.editMode}
                                             itemId={id}
                                             drillState={drillState}
                                             assistedData={additionalProps.assistedData}
                                             onQueryResult={queryResult => {
                                                 if(_.isEqual(drillResponse, queryResult.drillResponse)) {
                                                     return;
                                                 }

                                                 setCurrentDrillResponse(queryResult.drillResponse);
                                             }}
                            />
                        ) : (
                          htmlIsEmpty ? (
                                renderLoadingPlaceholder({
                                    path: path,
                                    viewType: viewType,
                                    height: chartHeight,
                                    width: chartWidth
                                })
                            ) : (
                                <div dangerouslySetInnerHTML={{__html: result.html}}/>
                            )
                        )
                    )}
                </div>
            </UiBlocker>
            {showDrillButtons &&
                <BngAnalysisDrillDownBar drillButtonsContainer={buttonContainer}
                                         assisted={additionalProps.assistedData}
                                         drillHandler={async (drills, prevDrills, {fromFilterEvent}) => {
                                             setLoading(true);
                                             try {
                                                 const filterStateUpdate = [];
                                                 if (drills.length > prevDrills.length) {
                                                     // Drill down
                                                     for (const drill of drills) {
                                                         if (!drill.filterId || !drill.member) continue;
                                                         let match = dashCtxFiltersCopy.find(f => f.id === drill.filterId);
                                                         if (!match) {
                                                             match = {
                                                                 id: drill.filterId,
                                                                 members: [],
                                                                 restrictionType: 'SHOW_SELECTED'
                                                             }
                                                             dashCtxFiltersCopy.push(match);
                                                         } else {
                                                             // Clear all members if filter already applied
                                                             // so that the only member applied is the selected from the graph
                                                             match.members = [];
                                                         }

                                                         // Drill contain full member name, ex: [Aonde].[activity.where.project]
                                                         // so remove to [activity.where.project]
                                                         const memberName = extractMemberLastPart(drill.member);
                                                         if (!match.members.includes(memberName)) {
                                                             if (dimensionApplied(drills, drill)) {
                                                                 match.members = [];
                                                                 match.members.push(memberName);
                                                                 filterStateUpdate.push(match);
                                                             } else {
                                                                 match.members.push(memberName);
                                                                 filterStateUpdate.push(match);
                                                             }
                                                         }
                                                     }
                                                 } else if (prevDrills.length === drills.length) {
                                                     const newFilterMember = drills[drills.length - 1];
                                                     const match = dashCtxFiltersCopy.find(f => f.id === newFilterMember?.filterId);
                                                     if (match) {
                                                         try {
                                                             const member = extractMemberLastPart(newFilterMember.member);
                                                             if (dimensionApplied(prevDrills, newFilterMember)) {
                                                                 match.members[0] = member;
                                                             } else {
                                                                 match.members = [member];
                                                             }

                                                             filterStateUpdate.push(match);
                                                         } catch (e) {
                                                             console.error('Error on filter apply', {
                                                                 newFilterMember,
                                                                 match,
                                                                 dashCtxFiltersCopy
                                                             }, e);
                                                         }
                                                     }
                                                 } else {
                                                     // Drill up
                                                     const drillsToIterate = prevDrills.slice().reverse();
                                                     const drillsDiff = prevDrills.length - drills.length;
                                                     for (let i = 0; i < drillsDiff; i++) {
                                                         const lastDrill = drillsToIterate[i];
                                                         if (lastDrill && lastDrill.filterId && lastDrill.member) {
                                                             const match = dashCtxFiltersCopy.find(f => f.id === lastDrill.filterId);
                                                             if (match) {
                                                                 const memberName = extractMemberLastPart(lastDrill.member);
                                                                 const idx = match.members.indexOf(memberName);
                                                                 if (idx !== -1) {
                                                                     match.members.splice(idx, 1);
                                                                     if (dimensionApplied(prevDrills, lastDrill) && drills.length !== 0) {
                                                                         const newLastDrill = drills[drills.length - 1];
                                                                         if(match.id === newLastDrill.filterId) {
                                                                             const memberLastPart = extractMemberLastPart(newLastDrill.member);
                                                                             match.members.push(memberLastPart);
                                                                         }
                                                                     }
                                                                     filterStateUpdate.push(match);
                                                                 }
                                                             }
                                                         }
                                                     }
                                                 }

                                                 if (application.ice.isLoaded()) {
                                                     const response = await requestRenderForItem({
                                                         width: chartWidth,
                                                         height: chartHeight,
                                                         reload: false,
                                                         drillState: {
                                                             drills
                                                         },
                                                         filterStateUpdate
                                                     });

                                                     __AnalysisChartRender__PERSISTED_STATE[id] = response?.additionalProps;
                                                 } else {
                                                     setCurrentDrillState({
                                                         drills
                                                     });
                                                 }

                                                 if (mobile) {
                                                     if (!fromFilterEvent && window.__FILTER_CHANGE_LISTENER) {
                                                         const result = window.__FILTER_CHANGE_LISTENER(
                                                           filterStateUpdate,
                                                           false,
                                                           true
                                                         );

                                                         if (window.__CURRENT_FILTER_BAR__) {
                                                             const filterBarInstance =
                                                               window.__CURRENT_FILTER_BAR__.wrappedComponent;
                                                             filterBarInstance.notifyFilterChange(result);
                                                             filterBarInstance.forceUpdate();
                                                         }
                                                     }
                                                 } else {
                                                     Api.updateJsf();
                                                 }
                                             } finally {
                                                 setLoading(false);
                                             }
                                         }}
                                         drillState={drillState}
                                         drillResponse={drillResponse}
                                         chartClickEventFilterRef={chartAreaRef}
                                         reprocessImgMapTime={Date.now()}
                                         itemId={id}
                />
            }
        </ContentSubWrapper>
    );
};

const dimensionApplied = (drillArray, drillToCompare) => {
    return drillArray.some((compDrill, idx) => {
        const indexOfTimeSelector = compDrill.dimension.indexOf('.(');
        if (indexOfTimeSelector === -1) {
            return false;
        }

        const drillIdx = drillArray.findIndex((el) => el === drillToCompare);
        if (idx >= drillIdx && (drillToCompare.member !== compDrill.member && idx === drillIdx)) {
            return false;
        }

        const dimPrefix = compDrill.dimension.substring(0, indexOfTimeSelector);
        return drillToCompare.dimension.startsWith(dimPrefix)
    });
}

const AnalysisWrapper = ({
                             id,
                             order,
                             dynamic,
                             tableThemeClass,
                             rowSeparatorClass,
                             tableRowSizeClass,
                             resizeContent,
                             fixedHeader,
                             fixedColumn,
                             fontSize,
                             children,
                         }) => {
    const style = `
            .div-table-${id} .mdx-table tr:nth-child(odd), 
            .div-table-${id} .mdx-table tr:nth-child(odd) th, 
            .div-table-${id} .mdx-table tr:nth-child(odd) td,
                .div-table-${id} .mdx-table tr:nth-child(even), 
                .div-table-${id} .mdx-table tr:nth-child(even) th, 
                .div-table-${id} .mdx-table tr:nth-child(even) td {
                font-size: ${fontSize}px !important;
            `;
    let innerContent = children;
    if (dynamic) {
        innerContent = (
            <div className={`
                ${!resizeContent && fixedHeader ? 'fixedtableHeader' : ''}
                ${!resizeContent && fixedColumn ? 'fixedtableColumn' : ''}`
            }>
                {children}
            </div>
        );
    }
    return (
        <div className={`div-table-${id} ${tableThemeClass} ${rowSeparatorClass} ${tableRowSizeClass}`}
             data-table-container={`${order}`}>

            <style type="text/css" dangerouslySetInnerHTML={{__html: style}}></style>

            {innerContent}

        </div>
    );
};

const ContentSubWrapper = ({
                               height,
                               overflow = 'auto',
                               textAlign = '',
                               className = '',
                               childRenderClassName = '',
                               children,
                               resizeContent,
                               noZoomTarget = false
                           }) => {
    let childRender;
    if (noZoomTarget) {
        childRender = children;
    } else {
        childRender = (
            <div className={`${resizeContent ? 'zoom-target' : 'zoom-target-disable'} ${childRenderClassName}`}>
                {children}
            </div>
        )
    }

    return (
        <div className={`item-content-container ${resizeContent ? 'resize-content' : ''} ${className || ''}`}
             style={{
                 height: `${height}px`,
                 overflow: overflow,
                 textAlign: textAlign
             }}>
            {childRender}
        </div>
    );
};

const LabelRender = ({html, height, order, additionalProps}) => (
    <ContentSubWrapper height={height}
                       className="LabelRender">
        <div className={`item-label label-${order}`} style={{height: `${height}px`}}>
            <Text {...additionalProps}/>
        </div>
    </ContentSubWrapper>
);

const IconRender = ({html, height, order, additionalProps}) => (
    <ContentSubWrapper height={height}
                       className="IconRender">
        <div className={`item-label-icon label-${order}`} style={{height: `${height}px`}}>
            <IconText {...additionalProps}/>
        </div>
    </ContentSubWrapper>
);

const imageSize = (originalSize, proportionalSize, resizeContent) => {
    let size = '';
    if (originalSize) {
        size = 'imageDashOriginalSize';
    } else if (proportionalSize) {
        size = 'imageDashProportionalSize';
    } else if (resizeContent) {
        size = 'imageDashResizeSize';
    }
    return size;
}

const ImageRender = ({html, height, order, additionalProps}) => {
    const {originalSize = false, proportionalSize = false, resizeContent = false} = additionalProps;
    const size = imageSize(originalSize, proportionalSize, resizeContent);
    const queryParams = jQuery.param({content: additionalProps.image});
    const style = {background: `url('${Api.baseUrl()}/upload?${queryParams}')`};
    return (
        <ContentSubWrapper height={height} className="ImageRender">
            <div className={`image-${order}`} style={{height: `${height}px`}}>
                <div style={style}
                     className={`fill-w fill-h ${size}`}>
                </div>
            </div>
        </ContentSubWrapper>
    );
};

const NewKpiRender = ({
                          path,
                          width,
                          height,
                          filters,
                          align,
                          dashboardStyle,
                          highlight,
                          showErrorDialog,
                          dashboardPath,
                      }) => {
    let filter = filters === '' ? [] : filters;
    return (
        <ContentSubWrapper noZoomTarget={true} height={height} className={'NewKpiRender'}>
            <Kpi path={path}
                 height={height}
                 dashboardStyle={dashboardStyle}
                 highlight={highlight}
                 width={width}
                 showErrorDialog={showErrorDialog}
                 filters={filter}
                 align={align}
                 dashboardPath={dashboardPath}
            />
        </ContentSubWrapper>
    )
};

const BigTableRender = connect(state => {
    return {
        bigtableFilterModel: state.bigTable.data.bigtableFilterModel,
        bigtableSortModel: state.bigTable.data.bigtableSortModel
    }
})(function BigTableRenderInner({path, width, height, filters, dashboardPath, isPresentation, bigtableFilterModel = [], bigtableSortModel = []}) {
    const filterModel = Utils.BigTable.returnFilterModelForPath(path, bigtableFilterModel);
    const sortModel = Utils.BigTable.returnSortModelForPath(path, bigtableSortModel);
    return (
        <ContentSubWrapper noZoomTarget={true} height={height} className={'BigTableRender'}>
            <BigTableRenderer
                path={path}
                height={height}
                width={width}
                filters={filters}
                location={'renderablePreload'}
                onDashboard={true}
                dashboardPath={dashboardPath}
                isPresentation={isPresentation}
                bigtableFilterModel={_.isEmpty(filterModel) ? undefined : filterModel}
                bigtableSortModel={_.isEmpty(sortModel) ? undefined: sortModel}
            />
        </ContentSubWrapper>
    )
});


const HtmlRender = ({height, additionalProps, resizeContent, enableIframe}) => {
    const {editMode} = useContext(DashGridContext);
    return (
        <ContentSubWrapper height={height}
                           resizeContent={resizeContent}
                           className="HtmlRender">
            <div className="dashboard-item-html-component"
                 style={{textAlign: 'left', height: `${height}px`}}>
                {editMode &&
                    <div className="HtmlDragOverlay"></div>
                }
                <HtmlComponent html={additionalProps.processedContent || additionalProps.content}
                               renderOnIframe={enableIframe}
                />
            </div>
        </ContentSubWrapper>
    );
};

const MapRender = ({
                       width,
                       height,
                       itemProps,
                       additionalProps,
                       path,
                       filters,
                       dashItemId,
                       mobile,
                       isPresentation,
                       exportView,
                       dashboardPath,
                   }) => {
    const dashGridCtx = useContext(DashGridContext);

    const mapStyleOverride = useMemo(() => {
        const override = {};
        if (itemProps.mapCenter) override.center = itemProps.mapCenter;
        if (itemProps.mapZoom) override.zoom = itemProps.mapZoom;
        return override;
    }, [itemProps]);
    return (
        <ContentSubWrapper height={height}
                           className="MapRender">
            <BiSource mapPath={path}
                      height={height}
                      width={width}
                      mdxFilter={filters}
                      dashItemId={dashItemId}
                      mobile={mobile}
                      isPresentation={isPresentation}
                      exportView={exportView}
                      dashboardPath={dashboardPath}
                      mapStyleOverride={mapStyleOverride}
                      fromCockpit={dashGridCtx.fromCockpit}
                      {...additionalProps}
            />
        </ContentSubWrapper>
    );
};

RenderablePreload = ContextEnhancer(RenderablePreload);
window.__RENDERABLE_PRELOAD_CLEAR_CACHE = () => {
    window.RENDERABLE_PRELOAD_CACHE = {};
    __AnalysisChartRender__PERSISTED_STATE = {};
}
export default RenderablePreload;
