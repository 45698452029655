import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import * as Actions from "components/ui/redux/Actions";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import DashboardTextForm from "components/ui/dashboard/components/DashboardTextForm";

class DashboardItemMenuEditLabel extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    editLabel = async () => {
        try {
            const labelProps = await Api.Dash.findLabelProps(this.props.item.id);
            window.ReduxStore.dispatch(Actions.MODALS.open(DashboardTextForm, {entity: labelProps}));
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        }
    };

    render() {
        return (
            <li onClick={this.editLabel}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('edit.content')}
                                        icon="fa-pencil-square"
                                        more={false}
                />
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuEditLabel);