import styles from './ReplicationStep.module.css';
import stepsCommonsStyles from './StepsCommonStyles.module.css';

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { ContentContainer } from 'components/bng/ui/ContentContainer';
import useBimContext from 'components/hooks/useBimContext';
import BngCheckbox from 'components/bng/form/BngCheckbox';

const intelligences = [
  { label: 'analysis', value: true },
  { label: 'dashboard', value: true },
  { label: 'maps', value: true },
  { label: 'newmap', value: true },
  { label: 'kpi', value: true },
  { label: 'orgmap', value: true },
  { label: 'bigtable', value: true },
];

const projectResources = [
  { label: 'folders', value: true },
  { label: 'cockpits', value: true },
  { label: 'data.origins', value: true },
  { label: 'data.origins.data', value: true },
  { label: 'number.schedules.from.project', value: true },
  { label: 'filters', value: true },
];

export default function ReplicationStep() {
  const { msg } = useBimContext();
  const { values, setFieldValue, handleChange } = useFormikContext();

  useEffect(() => {
    let temp = [];
    intelligences.map((i) => temp.push({ label: i.label, selected: i.value }));
    setFieldValue('intelligences', temp);
    temp = [];
    projectResources.map((p) => temp.push({ label: p.label, selected: p.value }));
    setFieldValue('projectResources', temp);
    temp = null;
  }, []);

  const updateFieldValue = (item, i) => {
    setFieldValue(
      item,
      values[item].map((t) => {
        if (t.label === i.label) {
          return { label: i.label, selected: !t.selected };
        }
        return t;
      })
    );
  };

  return (
    <ContentContainer className={`${stepsCommonsStyles.ContentContainerStyle} ${styles.BodyWrapper}`}>
      <div className={styles.Intelligences}>
        <legend>{msg.t('intelligences')}</legend>
        {values.intelligences.map((i) => (
          <BngCheckbox
            field={{
              onChange: (event) => {
                updateFieldValue('intelligences', i);
              },
              defaultChecked: i.selected,
            }}
            label={msg.t(i.label)}
          />
        ))}
      </div>
      <div className={styles.ProjectResource}>
        <legend>{msg.t('project.resources')}</legend>
        {values.projectResources.map((p) => (
          <BngCheckbox
            field={{
              onChange: () => {
                updateFieldValue('projectResources', p);
              },
              defaultChecked: p.selected,
            }}
            label={msg.t(p.label)}
          />
        ))}
      </div>
    </ContentContainer>
  );
}
