import React from 'react';
import PropTypes from 'prop-types';
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import {Field, Form, Formik} from "formik";
import {bngYup} from "components/bng/form/yup/BngYup";
import {BngField} from "components/bng/form/BngField";
import Icon from "components/ui/common/Icon";
import {FormikListener} from "components/bng/form/formik/FormikListener";

const MIN_SIZE = 40;
const MAX_SIZE = 9999;

const ResizeItemSchema = bngYup(yup => {
    return yup.object().shape({
        width: yup.number().required().min(MIN_SIZE).max(MAX_SIZE),
        height: yup.number().required().min(MIN_SIZE).max(MAX_SIZE),
    })
});

class DashboardItemMenuResize extends React.Component {

    static propTypes = {
        item: PropTypes.object,
        currentBreakpoint: PropTypes.object,
    };

    static defaultProps = {
        item: {},
        currentBreakpoint: {
            effectiveBreakpoint: 'DESKTOP'
        },
    };

    state = {
        open: false,
    };

    initialFormValues = ResizeItemSchema.default();

    openOptions = (event) => {
        if (!this.state.open) this.props.onOpen(event);
        this.setState({open: !this.state.open});
    };

    forceClose() {
        this.setState({open: false});
    };

    apply = async (values, actions) => {
        if (!this.state.open) {
            actions.setSubmitting(false);
            return;
        }
        try {
            const {item, position, currentBreakpoint} = this.props;
            const $dashContent = currentBreakpoint.effectiveBreakpoint === 'DESKTOP' ? j('.page-content') : j(`.breakpoint-${currentBreakpoint.effectiveBreakpoint}`);

            if (!values.width) {
                values.width = position.width;
            }

            if (!values.height) {
                values.height = position.height;
            }

            await Api.Dash.updateItemSize({
                id: item.id,
                screen: {
                    height: $dashContent.height(),
                    width: $dashContent.width(),
                },
                item: values,
                breakpoint: currentBreakpoint.effectiveBreakpoint,
            });
            actions.resetForm({values: this.initialFormValues});
            this.forceClose();
            await Api.updateJsf();
        } catch (e) {
            UiMsg.ajaxError('error', e);
        }
    };

    setInputFocus = (event) => {
        event.persist();
        setTimeout(function () {
            event.target.blur();
            event.target.focus();
        }, 50);
    };

    render() {
        const {onClick, position} = this.props;
        return (
            <Formik initialValues={{width: position.width, height: position.height}}
                    validationSchema={ResizeItemSchema}
                    onSubmit={this.apply}>
                <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`} onClick={onClick}>

                    <FormikListener onChange={({values, setValues}) => {
                        const update = {};
                        if (!_.isEmpty(update)) {
                            setValues({
                                ...values,
                                ...update
                            });
                        }
                    }}
                    />

                    <DashboardItemMenuTitle title={this.props.context.msg.t('dashboard.item.define.size')}
                                            icon="icon-resize-full" onClick={this.openOptions}
                                            more={true}
                    />

                    {this.state.open &&
                    <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-resize"
                        style={{padding: 0}}>
                        <li style={{padding: '10px 0 0 0'}}>
                            <Form>
                                <div style={{display: 'flex', alignItems: 'top', padding: '0 20px'}}>
                                    <Field component={BngField}
                                           name="width"
                                           type="number"
                                           placeholder={position.width}
                                           className="input-dashboard-item-size input-dashboard-item-size-height"
                                           onClick={this.setInputFocus}/>
                                    <Icon icon="close" className="resize-item-icon"/>
                                    <Field component={BngField}
                                           name="height"
                                           type="number"
                                           placeholder={position.height}
                                           className="input-dashboard-item-size input-dashboard-item-size-height"
                                           onClick={this.setInputFocus}/>
                                </div>
                                <button className="button-div-dashboard-item-size" type="submit">
                                    {this.props.context.msg.t('apply')}
                                </button>
                            </Form>
                        </li>
                    </ul>
                    }

                </li>
            </Formik>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuResize);