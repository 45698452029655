import React, {useState} from "react";
import {Field, Formik} from "formik";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import Dialog from "components/ui/Dialog";
import {BngField} from "components/bng/form/BngField";
import BngSwitch from "components/bng/form/BngSwitch";
import {DefaultDialogActions} from "components/ui/FormUtils";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import {MODALS} from "components/ui/redux/Actions";
import ComponentFactory from "components/ComponentFactory";
import OpConfirmation from "components/ui/OpConfirmation";
import ContainerMenu from "components/ui/dashboard/components/ContainerMenu";
import {BngForm} from "components/bng/form/BngForm";
import BngMarkdownEditor from "components/bng/form/markdown/BngMarkdownEditor";
import {bngYup} from "components/bng/form/yup/BngYup";
import BngInputIconSelector from 'components/bng/form/BngInputIconSelector';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';

const schema = bngYup(yup => {
    return yup.object().shape({
        title: yup.string().when('showTitle', {
                is: true,
                then: yup.string().default(""),
                otherwise: yup.string().default("")
            }
        ),
        description: yup.string().default(""),
        showTitle: yup.boolean().default(true),
        icon: yup.object().shape({
            name: yup.string().default(""),
            type: yup.number().default(0)
        })
    })
});

const TitleDialog = ContextEnhancer(
    class extends React.Component {

        initialValues = schema.default();

        handleFormikValues() {
            this.initialValues.title = this.props.item.additionalProps.title;
            this.initialValues.description = this.props.item.additionalProps.description;
            if (this.props.item.additionalProps.icon !== null) {
                this.initialValues.icon = this.props.item.additionalProps.icon;
            }
            if (!this.props.item.additionalProps.showTitle && this.props.item.additionalProps.title !== "") {
                this.initialValues.showTitle = false
            }
        }

        componentDidMount() {
            this.handleFormikValues();
        };

        static defaultProps = {
            item: {}
        };

        save = async (values, actions) => {
            try {
                await Api.Dash.updateContainerItem({
                    order: this.props.item.order,
                    ...this.props.item.additionalProps,
                    ...values
                });
                await Api.updateJsf();
                this.props.closeModal();
            } catch (e) {
                console.error(e);
                UiMsg.ajaxError('error', e);
                actions.setSubmitting(false);
            }
        };

        render() {
            return (
                <Formik initialValues={this.initialValues}
                        validationSchema={schema}
                        onSubmit={this.save}>
                    {({values, isSubmitting}) => {
                        return (
                            <Dialog title={this.props.context.msg.t('configure.title')}
                                    onClose={this.props.closeModal}
                                    loading={isSubmitting}
                                    className="ContainerMenuTitleDialog"
                                    contentFullWidth>
                                <BngForm>
                                    <div className="container-configure-title-body">
                                        <Field component={BngField}
                                               name="title"
                                               maxLength={128}
                                        />
                                        <label
                                            className="control-label">{}</label>
                                        <Field
                                            label={this.props.context.msg.t('dataFeeds_label_description')}
                                            component={BngField}
                                            inputComponent={BngMarkdownEditor}
                                            name="description"
                                            maxLength={1000}
                                            height={`150px`}
                                        />
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Field component={BngSwitch}
                                                   id="showContainerTitle"
                                                   title={this.props.context.msg.t('show')}
                                                   name="showTitle"
                                            />
                                            <Field component={BngInputIconSelector}
                                                   id="icon"
                                                   name="icon"
                                                   title={this.props.context.msg.t('select.icon')}
                                            />
                                        </div>
                                    </div>
                                    <DefaultDialogActions buttonClass="bng-button borderless" {...this.props}/>
                                </BngForm>
                            </Dialog>
                        );
                    }}
                </Formik>
            )
        }
    }
);

const ContainerMenuEditor = ({
                                 context = {},
                                 style = {},
                                 item = {},
                                 currentBreakpoint = {},
                                 itemsData = [],
                             }) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const popperContainer = j('.page-content')[0];

    const configureTitle = (event) => {
        setOpen(false);
        window.ReduxStore.dispatch(MODALS.open(TitleDialog, {item: item}));
    };

    const itemHighlight = async () => {
        setLoading(true);
        try {
            const gridData = await Api.Dash.toggleHighlightItem(item.id);
            ComponentFactory.Dash.updateCurrentDash(gridData);
            await Api.updateJsf();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const ungroupElements = (event) => {
        return removeContainer(event, false);
    };

    const removeContainer = async (event, removeChildren = true) => {
        if (loading) return;

        const executeRemovalFn = async () => {
            setLoading(true);
            try {
                await Api.Dash.removeItem(item.id, {removeChildren, breakpoint: currentBreakpoint.effectiveBreakpoint});
                setLoading(false);
                await Api.updateJsf();
            } catch (e) {
                console.error(e);
                UiMsg.ajaxError(null, e);
            } finally {
                setLoading(false);
            }
        };

        if (removeChildren) {
            OpConfirmation({
                title: context.msg.t('attention'),
                message: context.msg.t('container.remove.message'),
                msg: context.msg,
                onConfirm: () => {
                    executeRemovalFn();
                },
            });
        } else {
            await executeRemovalFn();
        }

    };

    const editContainer = async (event) => {
        await ungroupElements(event);
        bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU);
        const selectedItems = {};
        const bp = currentBreakpoint.effectiveBreakpoint;
        const gridLayout = window.___LAST_DASHBOARD_OPTS.layouts[bp];
        (item.additionalProps.items || [])
            .filter(layout => itemsData.hasOwnProperty(layout.i))
            .forEach(layout => {
                selectedItems[layout.i] = {
                    layout: gridLayout.find(l => l.i === layout.i),
                    data: itemsData[layout.i],
                    ...currentBreakpoint
                };
            });

        ComponentFactory.Dash.toggleContainerCreator(
            () => window.__CURRENT_DASHGRID,
            {...item.additionalProps, selectedItems, highlight: item.highlight}
        );
    };

    const moveContainer = async () => {
        bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU);
        await ComponentFactory.Dash.toggleMove(() => window.__CURRENT_DASHGRID, item.id)
    };

    const containerOpts = () => {
        let opts = [{label: context.msg.t('container.config'), isSeparator: true}];
        const defaultOpts = [
            {icon: 'title', label: context.msg.t('configure.title'), onClick: configureTitle},
            {icon: 'web', label: context.msg.t('edit.container'), onClick: editContainer},
            {icon: 'icon-bim-move', label: context.msg.t('select.dashboard.container'), onClick: moveContainer},
            {icon: 'border_clear', label: context.msg.t('ungroup.items'), onClick: ungroupElements},
            {icon: 'close', label: context.msg.t('remove'), onClick: (event) => removeContainer(event)},
        ];
        if (style.allowTheme) {
            opts.push({
                icon: item.highlight ? 'star' : 'star_border',
                label: context.msg.t(item.highlight ? 'remove.highlight.dashboard.item' : 'highlight.dashboard.item.container'),
                onClick: itemHighlight
            });
        }
        opts = opts.concat(defaultOpts);
        if (currentBreakpoint.viewBreakpoint !== 'DESKTOP') {
            opts.push({
                icon: 'remove_red_eye',
                label: context.msg.t(item.availableOnMobile ? 'hide.item.on.mobile' : 'show.item.on.mobile'),
                onClick: hideItemOnMobile
            },);
        }
        return opts;
    };

    const hideItemOnMobile = async () => {
        try {
            await Api.Dash.changeMobileItemView({id: item.id, availableOnMobile: !item.availableOnMobile});
            await Api.updateJsf();
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    return (
        <BngDropdown icon="more_horiz"
                     popperOpts={{placement: "bottom-start"}}
                     container={popperContainer}
                     className="icon-dropdown"
                     popperClassName="ContainerMenuPopper"
                     options={containerOpts()}
                     btnIconProps={{iconProps: {className: 'DashboardItemOptsButton'}}}
        />
    )
}

const ContainerMenuWrapper = (props) => {
    return (
        <ContainerMenu containerMenuComponent={ContainerMenuEditor} {...props}/>
    )
}

export default ContainerMenuWrapper;
