import PropType from 'prop-types';
import React from 'react';
import ComponentFactory from "components/ComponentFactory";
import ContextEnhancer from "components/ContextEnhancer";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";

const PermissionMenuItem = (props) => {

    const openPathPermission = () => {
        const {clearAccordion, path} = props;
        clearAccordion();
        ComponentFactory.Permissions.openPathPermission({path, useParentPermission: true});
    };

    return (
        <RightMenuItem {...props}
                       onClick={openPathPermission}
                       className="PermissionMenuItem"
                       title={props.context.msg.t('permission')}
                       icon="lock"
        />
    );
}

PermissionMenuItem.propTypes = {
    path: PropType.string,
    clearAccordion: PropType.func,
}

export default ContextEnhancer(PermissionMenuItem);