// css imported on CommonCssImports.js
import React from "react";

export const BngPanel = ({className = '', children, whiteBg = false, ...props}) => {
    return (
        <div className={`BngPanel ${whiteBg ? 'WhiteBg' : ''} ${className}`} {...props}>
            {children}
        </div>
    )
};

export const BngPanelHeader = ({className = '', title = null, children, ...props}) => {
    return (
        <div className={`Panel Header ${className}`} {...props}>
            {title &&
            <span className="Title">{title}</span>
            }
            {children}
        </div>
    )
};

export const BngPanelFooter = ({className = '', children, ...props}) => {
    return (
        <div className={`Panel Footer ${className}`} {...props}>
            {children}
        </div>
    )
};