import { Axios } from 'commonUtils';

const endpoint = '/spr/bimQuery';

class BimQueryApi {
  validateQuery = async (queryData) => {
    return await Axios.postData(`${endpoint}/validateQuery`, queryData);
    }

  executeQuery = async (queryData) => {
    return await Axios.postData(`${endpoint}/executeQuery`, queryData);
  }
}

export default BimQueryApi;
