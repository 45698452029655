"use strict";

import {Axios} from "commonUtils";
import {transformLayoutToPersist} from "components/ui/dashboard/components/common";
import FilterService from "components/filter/FilterService";

const $ = jQuery;


class DashApi {

    static newDashboardWithObject(newDashboard, isChart) {
        return $.postJSON('/spr/ui/dashboards/create', {
            ...newDashboard,
            isChart
        });
    }

    static updateLabelItem(labelUpdate) {
        return $.postJSON('/spr/ui/dashboards/items/label', labelUpdate);
    }

    static updateIconItem(iconUpdate) {
        return Axios.postData('/spr/ui/dashboards/items/icon', iconUpdate);
    }

    static updateImageItem(update) {
        return $.postJSON('/spr/ui/dashboards/items/image', update);
    }

    static updateOrder(order) {
        return Promise.resolve($.postJSON('/spr/ui/dashboards/items/mobile-order', order));
    }

    static updateLayout(layout, breakpoint) {
        return Promise.resolve($.postJSON('/spr/ui/dashboards/layout', {layout, breakpoint}));
    }

    static preloadItem(id, params = {}) {
        return Promise.resolve($.getJSON('/spr/ui/dashboards/preload/' + id, params));
    }

    static renderItemMobile = (id, params = {}) => {
        let prefix = '/spr';
        if (window.location.pathname.indexOf('/api/') !== -1) {
            prefix = '/api';
        }
        return Promise.resolve($.postJSON(`${prefix}/stateless/ui/dashboards/render/${id}?t=${Date.now()}`, params));
    }

    static renderItemAxios = async (id, params = {}) => {
        const result = await Axios.cancelableRequest(`/spr/ui/dashboards/render/${id}`, params);
        if (result.cancelRequest) {
            result.preload = {status: 'CLIENT_CLOSE_REQUEST'};
            return result;
        }
        return result.data;
    };

    static updateTable(id, params = {}) {
        if (window.location.pathname.indexOf('/spr/') !== -1) {
            return Promise.resolve($.post(`/spr/mobile/analysis/interaction`, params));
        } else {
            return Promise.resolve($.post(`/spr/ui/dashboards/render/${id}/update-table`, params));
        }
    }

    static addItem({dashboardPath, path, isChart}) {
        return Promise.resolve($.postJSON('/spr/ui/dashboards/items/object', arguments[0]));
    }

    static addItems(params) {
        return Axios.postData('/spr/ui/dashboards/items/add', params);
    }

    static getFiltersConfig = async () => {
        const {data} = await Axios.get(`/spr/ui/dashboards/filters/config`);
        return data;
    }

    static getFilterConfig = async ({filterId}) => {
        const {data} = await Axios.get(`/spr/ui/dashboards/filters/${filterId}`);
        return data;
    }

    static applyFilterConfig = async ({filterData}) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/filters`, filterData);
        return data;
    }
    static applyFilterBarOrder = async ({idFilterList}) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/filtersBar`, idFilterList);
        return data;
    }

    /*filter action*/
    static saveFilters() {
        return Promise.resolve($.post('/spr/ui/dashboards/filter/save'));
    }

    static applyFilter(filter) {
        return Promise.resolve($.postJSON('/spr/ui/dashboards/filter', filter));
    }

    static clearFilter(filter) {
        return Promise.resolve($.postJSON('/spr/ui/dashboards/filter/clear', filter));
    }

    static moveFilterTo = async (filterList) => {
        return await Axios.post(`/spr/ui/dashboards/filter/move`, filterList);
    };

    static async applyFilterArray(filters) {
        const {data} = await Axios.post(
            `/spr/ui/dashboards/filters/apply`,
            FilterService.createFilterParam(filters, true)
        );
        return data;
    }

    static async clearFilterArray(filters) {
        const {data} = await Axios.post(
            `/spr/ui/dashboards/filters/clear`,
            FilterService.createFilterParam(filters, true)
        );
        return data;
    }

    /*end filter actions*/

    static updateContainerItem(containerUpdate) {
        if (!_.isString(containerUpdate.items)) {
            containerUpdate = {...containerUpdate};
            if (!containerUpdate.items[0].hasOwnProperty('uuid')) {
                containerUpdate.items = transformLayoutToPersist(containerUpdate.items);
            }
            containerUpdate.items = JSON.stringify(containerUpdate.items);
        }
        return $.postJSON('/spr/ui/dashboards/items/container', containerUpdate);
    }

    static removeItem(id, {removeChildren = false, breakpoint = 'DESKTOP'}) {
        return Promise.resolve(
            $.deleteReq(`/spr/ui/dashboards/items/${id}?${j.param({removeChildren, breakpoint})}`)
        );
    }

    static findItemMdx(id) {
        return Promise.resolve($.getJSON(`/spr/ui/dashboards/items/${id}/current-mdx`))
    }

    static async findItemMdxRef(itemPath) {
        const {data} = await Axios.get(`/spr/ui/dashboards/items/mdx-ref`, {
            params: {
                itemPath
            }
        });
        return data.key;
    }

    static changeImageContentSize(params) {
        const {id, ...props} = params;
        return Promise.resolve(
            $.postJSON(`/spr/ui/dashboards/items/${id}/image-content-size`, props)
        );

    }

    static findIconProps(id) {
        return Promise.resolve(
            $.getJSON(`/spr/ui/dashboards/items/${id}/icon-properties`)
        );
    }

    static findLabelProps(id) {
        return Promise.resolve(
            $.getJSON(`/spr/ui/dashboards/items/${id}/label-properties`)
        );
    }

    static async swapSelectItem(fromId, toId) {
        const {data} = Axios.post(`/spr/ui/dashboards/items/swap`, {
            fromId,
            toId
        });
        return data;
    }

    static toggleHighlightItem(id) {
        return Promise.resolve(
            $.post(`/spr/ui/dashboards/items/${id}/highlight`)
        );
    }

    static updateItemSize({id, ...params}) {
        return Promise.resolve(
            $.postJSON(`/spr/ui/dashboards/items/${id}/size`, params)
        );
    }

    static toggleMobile() {
        return Promise.resolve($.post(`/spr/ui/dashboards/toggle-mobile`));
    }

    static async validateFilterMembers(dashboardPath, filters) {
        const {data} = await Axios.post(
            `/spr/stateless/ui/dashboards/filters/validateMembers`, {
                dashboardPath,
                ...FilterService.createFilterParam(filters, true)
            }
        );
        return data;
    }

    static async getItemLinks(id, path) {
        const {data} = await Axios.get(`/spr/ui/dashboards/items/${id}/links`, {
            params: {
                path
            }
        });
        return data;
    }

    static saveAs = async ({folder, name, description}, printerProperties) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/save-as`, {folder, name, description});
        await Axios.post(`/spr/bng-mig/object/config`, {path: data.path, name, description, printer: printerProperties})
        return data;
    };

    static changeMobileItemView = async ({id, availableOnMobile}) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/items/${id}/mobile-view`, {availableOnMobile});
        return data;
    };

    static changeBreakpointView = async ({breakpoint}) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/breakpoint`, {}, {
            params: {
                breakpoint
            }
        });
        return data;
    };

    /**
     * Returns dashboard style
     *
     * @returns {data}
     */
    static getStyle = async () => {
        const {data} = await Axios.get(`/spr/ui/dashboards/style`);
        return data;
    };

    /**
     * @param values
     * @returns {data}
     */
    static saveStyle = async (values) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/style`, {...values});
        return data;
    };

    static storageMDX = async (dashboardItemId) => {
        const {data} = await Axios.post(`/spr/ui/dashboards/tempMdx`, null, {
            params: {
                id: dashboardItemId
            }
        });
        return data;
    }

    static getDataSources = async (path) => {
        const {data} = await Axios.get(`/spr/ui/dashboards/datasources`, {
            params: {
                path: path
            }
        });
        return data;
    }

    static loadDash = async (path, filter) => {
        const {data} = await Axios.post(`/spr/stateless/ui/dashboards/load`, {
            path,
            filter
        });
        return data;
    }

    static resetMobileLayout = async (breakpoint) => {
        await Axios.post(`/spr/ui/dashboards/resetMobileLayout`, {breakpoint});
    }

    static updateNewMapDefaultPosition = async (itemId, center, zoom) => {
        const {data} = await Axios.post(
            `/spr/ui/dashboards/updateNewMapDefaultPosition`,
            {itemId, center, zoom}
        );
        return data;
    }

    static restoreNewMapDefaultPosition = async (itemId) => {
        const {data} = await Axios.post(
            `/spr/ui/dashboards/restoreNewMapDefaultPosition`,
            {itemId}
        );
        return data;
    }

    static updateItemLinks = async (itemId, links = []) => {
        const {data} = await Axios.post(
            `/spr/ui/dashboards/items/${itemId}/links`,
            {links}
        );
        return data;
    }

    static cloneItem = async (body) => {
        const {data} = await Axios.post(
            `/spr/ui/dashboards/clone`,
            body
        );
        return data;
    }

    static findDashsUsingPath = async (path) => {
        const {data} = await Axios.get(`/spr/ui/dashboards/usingPath`, {
            params: {
                path
            }
        });
        return data;
    }

    static newDashboardPage = async () => {
        await Axios.post(`/spr/ui/dashboards/newDashboard`);
    }

    static updatePath = async (path) => {
        return await Axios.postData(`/spr/ui/dashboards/updatePath`, {path});
    }

}

export default DashApi;