import { Axios, findContextPath } from 'commonUtils';

class AssistedAnalysisApi {
  findTypes = async () => {
    return await Axios.getData(`/spr/assistedAnalysis/types`);
  };

  createNewAnalysis = async (reqBody = {}, redirect = true) => {
    const data = await Axios.postData(`/spr/assistedAnalysis/newAnalysis`, reqBody, {
      params: {
        persist: !redirect,
      },
    });

    if (redirect) {
      let url = `${findContextPath()}/${reqBody.type === 'BIG_TABLE' ? 'bigtable' : 'reports'}.iface`;
      if (data.showNonEmptyAlert) {
        url = `${url}?${new URLSearchParams(data)}`;
      }
      window.location.href = url;
    }

    return data;
  };

  applyChanges = async (params) => {
    await Axios.postData(`/spr/assistedAnalysis/applyChanges`, params);
  };

  update = async (params) => {
    await Axios.postData(`/spr/assistedAnalysis/update`, params);
  };
}

export default AssistedAnalysisApi;
