import styles from './BngNumberCounter.module.css';
import React from 'react';
import Icon from 'components/ui/common/Icon';

export default function BngNumberCounter({
  className = '',
  field,
  onDelete,
  blockRightBtn = false,
  onChange,
  value,
}) {
  const name = field?.name ?? '';
  onChange = onChange ?? field?.onChange;
  value = value ?? field?.value;

  const onSubtract = () => {
    if (value > 1) {
      onChange({ target: { name, value: value - 1 } });
    } else if (onDelete) {
      onDelete();
    }
  };

  const onAdd = () => {
    onChange({ target: { name, value: value + 1 } });
  };

  return (
    <div className={`BngNumberCounter ${styles.quantitySelector} ${className}`}>
      <div
        className={`${styles.quantitySelectButton} ${styles.quantitySelectButtonLeft} ${
          value === 1 && !onDelete ? styles.disabled : ''
        }`}
        onClick={onSubtract}
      >
        <Icon icon={`${onDelete && value === 1 ? 'delete' : 'remove'}`} />
      </div>
      <span className={styles.quantityCounter}>{value}</span>
      <div
        className={`${styles.quantitySelectButton} ${styles.quantitySelectButtonRight} ${
          blockRightBtn ? styles.disabled : ''
        }`}
        onClick={blockRightBtn ? undefined : onAdd}
      >
        <Icon icon={'add'} />
      </div>
    </div>
  );
}
