import create from 'zustand';
import Api from 'components/Api';

const useRightMenuStore = create((set) => {
  return {
    printerConfig: null,
    fetchPrinterConfig: async (path) => {
      try {
        const printerConfig = await Api.Bng.getPrinterConfiguration(path);
        set(() => ({ printerConfig }));
      } catch (e) {
        set(() => ({ printerConfig: null }));
        throw e;
      }
    },
    setPrinterConfig: (printerConfig) => set(() => ({ printerConfig }))
  };
});

export default useRightMenuStore;
