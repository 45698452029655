'use strict';

import { Axios, findContextPath } from 'commonUtils';

const $ = jQuery;


class BngApi {

    favoriteProject = async (projectId = 'null') => {
        return await $.post(`/spr/bng-mig/favorite-project?${$.param({projectId})}`);
    };

    findSupportTickets = async (projectName) => {
        return await $.getJSON(`/spr/bng-mig/support/tickets`, {projectName});
    };

    findSupportTicket = async (idChamado) => {
        return await $.getJSON(`/spr/bng-mig/support/tickets/${idChamado}`);
    };

    addSupportTicketComment = async (idChamado, comment) => {
        return await $.postJSON(`/spr/bng-mig/support/tickets/${idChamado}`, {comment});
    };

    findOccupationAreas = async () => {
        return await $.getJSON(`/api/bng-mig/profile/occupations`);
    };

    getProfileData = async () => {
        return await $.getJSON(`/spr/bng-mig/profile/general-data`);
    };

    getGeneralData = async () => {
        return await Axios.getData(`/api/bng-mig/profile/general-data`)
    }

    isUserOnline = async (userId) => {
        return await $.getJSON(`/spr/bng-mig/profile/${userId}/status`);
    };

    getObjectInformation = async (path) => {
        return await $.getJSON(`/spr/bng-mig/object/information?${$.param({path})}`);
    };

    toggleKpiMobile = () => {
        return Promise.resolve(
            $.post(`/spr/bng-mig/kpi/toggle-mobile`)
        );
    };

    getKpiDynamicPeriodicities = () => {
        return Promise.resolve(
            $.getJSON(`/spr/bng-mig/kpi/dynamic-periodicities`)
        );
    };

    toggleOrgmapMobile = () => {
        return Promise.resolve(
            $.post(`/spr/bng-mig/orgmap/toggle-mobile`)
        );
    };

    getExportExcelLink = (path = '', props = {}) => {
        return Axios.getUri({
            url: `/Print`,
            params: {cube: '01', type: '0', path, ...props},
        });
    };

    getPathCaption = (path) => {
        return Promise.resolve($.getJSON(`/spr/bng-mig/pathname`, {path}))
    }

    getLatestVersions = async (path) => {
        const {data} = await Axios.get(`/spr/bng-mig/object/versions`, {params: {path}});
        return data;
    };

    restoreVersion = async (props = {path: '', versionId: 0}) => {
        const {data} = await Axios.post(`/spr/bng-mig/object/version/restore`, props);
        return data;
    };

    getVersionMdx = async (version) => {
        const {data} = await Axios.post(`/spr/bng-mig/object/version/mdx`, version);
        return data;
    };

    setObjectConfigs = async (props) => {
        if (props.path.endsWith('dashboard')) {
            await Axios.post(`/spr/ui/dashboards/nameAndDescription`, props);
        }
        const {data} = await Axios.post(`/spr/bng-mig/object/config`, props);
        return data;
    };

    getPrinterConfiguration = async (path) => {
        const {data} = await Axios.get(`/spr/bng-mig/object/printer`, {params: {path}});
        return data;
    };

    accessProjectUrl(projectName, postAccessUrl = undefined) {
        return Axios.getUri({
            url: `/spr/bng-mig/accessProject`,
            params: {
                projectName,
                postAccessUrl
            },
        });
    }

    async loadProjectInfo(projectName, disableCockpit = false) {
        await Axios.get('/spr/bng-mig/loadProjectInfo', {
            params: {
                projectName,
                disableCockpit
            }
        });
    }

    async accessObjectSearch(){
        return await Axios.postData('/spr/bng-mig/accessObjectSearch');
    };

}

export default BngApi;