import React from 'react';
import PropTypes from 'prop-types';

import {SimpleValue} from "components/ui/kpi/SimpleValue";
import {Default} from "components/ui/kpi/Default";
import {ProgressBar} from "components/ui/kpi/ProgressBar";
import {Pie} from "components/ui/kpi/Pie";
import {MeterGauge} from "components/ui/kpi/MeterGauge";
import {GaugeKpi} from "components/ui/kpi/GaugeKpi";
import {JustGauge} from "components/ui/kpi/JustGauge";
import {Pizza} from "components/ui/kpi/Pizza";
import {Square} from "components/ui/kpi/Square";
import {OnlyValue} from "components/ui/kpi/OnlyValue";
import {GaugeKpiPercent} from "components/ui/kpi/GaugeKpiPercent";
import {PizzaPercent} from "components/ui/kpi/PizzaPercent";
import {JustGaugePercent} from "components/ui/kpi/JustGaugePercent";
import {PiePercent} from "components/ui/kpi/PiePercent";
import {MeterGaugePercent} from "components/ui/kpi/MeterGaugePercent";
import Api from "components/Api";
import LoadingPlaceholder from "components/ui/loading/LoadingPlaceholder";
import DashboardItemInformation from "components/ui/dashboard/components/DashboardItemInformation";
import ContextEnhancer from "components/ContextEnhancer";
import FilterService from "components/filter/FilterService";
import BaseKpi from "components/ui/kpi/BaseKpi";
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';
import UiMsg from "components/ui/UiMsg";

export const KPI_MODELS = {
    'DEFAULT': Default,
    'SQUARE': Square,
    'SQUAREPERCENT': Square,
    'PIZZA': Pizza,
    'PIZZAPERCENT': PizzaPercent,
    'GAUGE': GaugeKpi,
    'GAUGEPERCENT': GaugeKpiPercent,
    'SIMPLE_VALUE': SimpleValue,
    'SIMPLE_VALUEPERCENT': SimpleValue,
    'PROGRESS_BAR': ProgressBar,
    'PROGRESS_BARPERCENT': ProgressBar,
    'JUST_GAGE': JustGauge,
    'JUST_GAGEPERCENT': JustGaugePercent,
    'PIE': Pie,
    'PIEPERCENT': PiePercent,
    'METER_GAUGE': MeterGauge,
    'METER_GAUGEPERCENT': MeterGaugePercent,
    'ONLY_VALUEPERCENT': OnlyValue,
    'ONLY_VALUE': OnlyValue,
};

export const kpiShouldChangeColor = (dashboardStyle = {}, highlight = false) => {
  if (dashboardStyle.allowTheme) {
    if (highlight) {
      return dashboardStyle.highlightBoxColor !== '#ffffff' && dashboardStyle.highlightBoxColor !== '#fec447';
    }
    return dashboardStyle.backgroundTheme === 'CORPORATIVE' || dashboardStyle.backgroundTheme === 'BLACK';
  }
  return false;
};

class Kpi extends React.Component {
  static propTypes = {
    dashboardStyle: PropTypes.object,
    highlight: PropTypes.bool,
    path: PropTypes.string,
    filters: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    align: PropTypes.string,
    data: PropTypes.object,
    dashboardPath: PropTypes.string,
    mutations: PropTypes.object,
  };

  static defaultProps = {
    showErrorDialog: null,
    dashboardStyle: {},
    highlight: false,
    path: '',
    width: 0,
    height: 0,
    align: '',
    dashboardPath: '',
    mutations: undefined,
  };

  state = {
    width: 0,
    height: 0,
    kpiData: {
      ...BaseKpi.defaultProps,
    },
    loaded: false,
    mutations: null,
  };

  async componentDidMount() {
    document.fonts.addEventListener('loadingdone', this.fontFinishedLoading);
    this.applyFiltersKpi();
    bimEventBus.on(Api.Kpi.KPI_UPDATED_EVENT, this.onKpiChanged);
    bimEventBus.on(Api.Kpi.KPI_EDIT_MENU_APPLY_EVENT, this.onKpiMenuApply);
  }

  componentWillUnmount() {
    bimEventBus.off(Api.Kpi.KPI_UPDATED_EVENT, this.onKpiChanged);
    bimEventBus.off(Api.Kpi.KPI_EDIT_MENU_APPLY_EVENT, this.onKpiMenuApply);
    document.fonts.removeEventListener('loadingdone', this.fontFinishedLoading);
  }

  fontFinishedLoading = () => {
    this.forceUpdate();
  };

  onKpiMenuApply = ({ mutations }) => {
    if(this.props.mutations) {
      return;
    }

    this.setState({ mutations }, () => {
      this.applyFiltersKpi();
    });
  };

  onKpiChanged = (data) => {
    if (this.props.path !== data.path) {
      return;
    }
    this.setState({
      mutations: null,
    }, () => {
        this.applyFiltersKpi();
    });
  };

  applyFiltersKpi = async () => {
    const { filters, data } = this.props;

    this.setState({ loaded: false });

    let kpiData = data;

    if (!kpiData) {
      const filterParam = FilterService.createFilterParam(filters).filter;
      try {
        kpiData = await Api.Kpi.getData({
          path: this.props.path,
          publisherContent: this.props.dashboardPath,
          filter: filterParam ? JSON.parse(filterParam) : null,
          mutations: this.props.mutations ?? this.state.mutations,
        });
      } catch (error) {
        if (error.responseJSON?.statusCode === 412) {
          kpiData = {
            errorInformation: {
              status: 'STRUCTURE_NOT_LOADED',
            },
          };
        } else {
          console.error('Error fetching kpiData: ', error);
          kpiData = {
            errorInformation: {
              status: error.status || 500,
              message: error.message || '',
            },
          };
        }
      }
    }

    if (kpiData.errorInformation?.status === 'INVALID_PERIODICITY') {
      const periodicity = kpiData.errorInformation.message;
      const details = this.props.context.msg.t('invalid.date.filter.alert', [this.props.context.msg.t(periodicity)]);

      kpiData.errorInformation.status = 'NO_CONTENT';
      kpiData.errorInformation.message = details;

      if (!this.props.dashboardPath) {
        UiMsg.warn(`${this.props.context.msg.t('attention')}!`, details);
      }
    }

    if (kpiData.percent) {
      kpiData.model += 'PERCENT';
    }

    if (kpiData.model === 'DEFAULTPERCENT') {
      kpiData.model = 'DEFAULT';
    }

    if (!kpiData.colorTarget) {
      kpiData.colorTarget = kpiData.color;
    }

    this.setState({ kpiData, loaded: true });
  };

  kpiModelType = () => {
    const { kpiData } = this.state;
    const { dashboardStyle, highlight, context, width, height, align } = this.props;

    const changeColor = kpiShouldChangeColor(dashboardStyle, highlight);

    const offsetParent = this.$container.offsetParent;
    const clientWidth = !!offsetParent ? offsetParent.clientWidth : 0;
    const clientHeight = !!offsetParent ? offsetParent.clientHeight : 0;

    const props = {
      ...kpiData,
      context,
      width: !!width ? width : clientWidth,
      height: !!height ? height : clientHeight,
      div: this.$container,
      align: align,
      $container: this.$container,
      shouldChangeColor: changeColor,
    };

    if (props.height > props.width * 0.9) {
      props.height = props.width;
    }

    const KpiModel = KPI_MODELS[kpiData.model];
    return <KpiModel {...props} />;
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_.isEqual(prevProps.data, this.props.data) ||
      !_.isEqual(prevProps.filters, this.props.filters) ||
      !_.isEqual(prevProps.mutations, this.props.mutations)
    ) {
      await this.applyFiltersKpi();
    }
  }

  showErrorDialog = () => {
    ObjectErrorPopup({ message: this.state.kpiData.errorInformation.message });
  };

  hasError() {
    const { kpiData } = this.state;
    return !!kpiData.errorInformation && (kpiData.errorInformation.status || kpiData.errorInformation.message);
  }

  render() {
    const { loaded, kpiData } = this.state;

    let content;
    if (!loaded) {
      content = <LoadingPlaceholder path={this.props.path} height={this.props.height} width={this.props.width} />;
    } else if (loaded && this.hasError()) {
      const { status, message } = kpiData.errorInformation;
      if (status === 'NO_CONTENT') {
        content = (
          <DashboardItemInformation
            path={this.props.path}
            width={this.state.width}
            height={this.props.height}
            showErrorDialog={this.showErrorDialog}
          />
        );
      } else if (status === 'STRUCTURE_NOT_LOADED') {
        if (this.props.dashboardPath) {
          content = (
            <DashboardItemInformation
              path={this.props.path}
              width={this.props.width}
              height={this.props.height}
              showErrorDialog={null}
              message={this.props.context.msg.t('dashboard.item.not.loaded.message')}
              snackbarType="not-loaded"
              snackbarIcon="cached"
            />
          );
        }
      } else {
        content = (
          <DashboardItemInformation
            path={this.props.path}
            width={this.state.width}
            height={this.props.height}
            showErrorDialog={this.showErrorDialog}
            message={this.props.context.msg.t('dashboard.item.error.message')}
            errorTrace={message}
            reload={this.applyFiltersKpi}
          />
        );
      }
    } else {
      content = this.kpiModelType();
    }

    let item = j('.kpi-preview-crud-box').length;
    let id = item > 0 ? 'Kpi-render-itemexamplekpi' : 'Kpi-render-item' + kpiData.kpiId;
    let errorDiv = this.hasError() ? '100%' : '';
    let isSquare = !!kpiData.model && kpiData.model.startsWith('SQUARE');

    return (
      <div
        id={id}
        data-loaded="false"
        style={{ height: errorDiv }}
        className={`KpiType-${kpiData.model} Kpi-render-item ${loaded ? 'loaded' : ''} ${
          this.props.align === 'left' && !isSquare ? 'kpi-left' : ''
        }`}
        ref={(el) => (this.$container = el)}
      >
        {content}
      </div>
    );
  }
}

export default ContextEnhancer(Kpi);