import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import ComponentFactory from 'components/ComponentFactory';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';

class DashboardItemMenuMoveElement extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    closeDropdown: PropTypes.func,
  };

  static defaultProps = {
    item: {},
    closeDropdown: _.noop,
  };

  move = async () => {
    try {
      bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU);
      await ComponentFactory.Dash.toggleMove(() => window.__CURRENT_DASHGRID, this.props.item.id);
      this.props.closeDropdown();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <li onClick={this.move}>
        <DashboardItemMenuTitle
          title={this.props.context.msg.t('select.dashboard.item')}
          icon="icon-bim-move"
          more={false}
        />
      </li>
    );
  }
}

export default ContextEnhancer(DashboardItemMenuMoveElement);
