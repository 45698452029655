import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import FilterAccordion from "components/ui/dashboard/FilterAccordion";

class DashboardItemMenuCustomFilter extends React.Component {

    static propTypes = {
        item: PropTypes.object,
        closeDropdown: PropTypes.func,
    };

    static defaultProps = {
        item: {},
        closeDropdown: _.noop,
    };

    state = {};

    toggleCustomFilter = async () => {
        if (!!window.__TOGGLE_RIGHT_MENU) {
            window.__TOGGLE_RIGHT_MENU(FilterAccordion, 'FiltersMenuItem', {customFilterState: true, customFilterAdd: this.props.item})
            this.props.closeDropdown();
        } else {
            console.error("Function '__TOGGLE_RIGHT_MENU' not found: ", window.__TOGGLE_RIGHT_MENU);
            UiMsg.warn(this.props.context.msg.t('configure.filter.not.available'));
        }
    };

    render() {
        return (
            <li className="bt-item-new-menu-inside">
                <DashboardItemMenuTitle title={this.props.context.msg.t('custom.filters')}
                                        icon="icon-bim-filter"
                                        more={false}
                                        onClick={this.toggleCustomFilter}
                />
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuCustomFilter);