import './BngAssistedAnalysisForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import UiMsg from 'components/ui/UiMsg';
import { BngForm } from 'components/bng/form/BngForm';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import { BngField } from 'components/bng/form/BngField';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import Button from 'components/ui/Button';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import { BngAssistedAnalysisHelp } from 'components/bng/ui/BngAssistedAnalysisHelp';
import { default as ParamType } from 'components/bng/pages/newAnalysis/BngAssistedAnalysisFormParamType';

// Validações 'pré-moldadas' por que a maravilha do IceFaces sobrescreve algum prototype
// das classes do browser que causa problema no Yup (poderiam ser construidas dinamicamente em cima dos params).

export const assistedAnalysisBaseSchema = bngYup((yup) =>
  yup.object({
    datasource: yup.string().required().default(''),
    cube: yup.string().required().default(''),
  })
);

window.__BngAssistedAnalysisFormCache = {};
const CACHE = window.__BngAssistedAnalysisFormCache;

class BngAssistedAnalysisFormInternal extends React.PureComponent {
  static propTypes = {
    type: PropTypes.object,
    service: PropTypes.object,
    initialFormValues: PropTypes.object,
    editing: PropTypes.bool,
    createObjectLabel: PropTypes.string,
    bottomSlot: PropTypes.func,
    embeddedOpts: PropTypes.object,
    path: PropTypes.string,
    onFormikRef: PropTypes.func,
    afterUpdate: PropTypes.func,
  };

  static defaultProps = {
    type: {},
    service: undefined,
    initialFormValues: {},
    editing: false,
    createObjectLabel: undefined,
    bottomSlot: null,
    embeddedOpts: undefined,
    path: undefined,
    onFormikRef: _.noop,
    afterUpdate: _.noop,
  };

  state = {
    loading: true,
    sources: [],
    cubes: [],
    sourceFields: [],
    periodicities: [],
    xmlaSchema: {},
    showParams: false,
    globalFilters: [],
  };

  initialFormValues = _.merge(
    {
      datasource: '',
      cube: '',
      params: {},
    },
    this.props.initialFormValues
  );

  _isSaving = false;

  async componentDidMount() {
    try {
      let stateUpdate = {};
      if (this.props.editing) {
        await this.datasourceChanged(this.initialFormValues.datasource, true);
        await this.loadCubeData(this.initialFormValues, true);
      } else {
        stateUpdate.sources = await this.props.service.findSources({ projectId: this.props.context.project.id });
      }
      stateUpdate.globalFilters = await Api.MdxGlobalFilter.findAll(this.props.context.project.id, true);
      this.setState(stateUpdate);
    } catch (e) {
      console.error('Error on componentDidMount()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    // When type changes
    if (this.props.type.type !== prevProps.type.type) {
      this.setState({ showParams: false }, () => {
        const { values } = this.$formik;
        this.$formik.setValues({
          ...values,
          params: this.initialParamValues(),
        });
        this.setState({ showParams: this.containParams() && !_.isEmpty(this.state.xmlaSchema) });
      });
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('beforeunload', this.beforeUnloadWindow);
  }

  datasourceChanged = async (sourceName, useCache = false) => {
    this.setState({ loading: true });
    let cubes = [];
    if (sourceName) {
      try {
        const cacheKey = `datasourceChanged${sourceName}`;
        if (useCache && CACHE.hasOwnProperty(cacheKey)) {
          cubes = CACHE[cacheKey];
        } else {
          cubes = await this.props.service.sourceCubes({
            projectId: this.props.context.project.id,
            sourceName,
          });
          CACHE[cacheKey] = cubes;
        }
      } catch (e) {
        console.error('Error on datasourceChanged()', e);
        UiMsg.ajaxError(null, e);
      }
    }
    await this.setState({
      cubes,
      loading: false,
      sourceFields: [],
      periodicities: [],
      xmlaSchema: {},
      showParams: false,
    });
    return cubes;
  };

  initialParamValues() {
    const params = {};
    this.props.type.params.forEach((param) => {
      let defaultValue = param.defaultValue || '';
      if (ParamType[param.type].defaultVal) {
        defaultValue = ParamType[param.type].defaultVal(defaultValue);
      }
      _.set(params, param.name, defaultValue);
    });
    return params;
  }

  formChanged = async (next, current) => {
    if (next.values === current.values) return;

    // Load source fields when cube is selected or changed
    const emptySourceFields =
      _.isEmpty(this.state.sourceFields) && this.containParams() && next.values.datasource && next.values.cube;
    const cubeChanged = next.values.cube && current.values.cube && next.values.cube !== current.values.cube;

    if (emptySourceFields || cubeChanged) {
      this.setState({ loading: true });
      try {
        await this.loadCubeData(next.values);
      } catch (e) {
        console.error('Error on formChanged()', e);
        UiMsg.ajaxError(null, e);
      } finally {
        this.setState({ loading: false });
      }
    }

    // When datasource changes
    if (next.values.datasource !== current.values.datasource) {
      const cubes = await this.datasourceChanged(next.values.datasource);

      const newFormValues = {
        ...next.values,
        datasource: _.isEmpty(cubes) ? '' : next.values.datasource,
        cube: _.isEmpty(cubes) ? '' : cubes[0].value,
        params: this.initialParamValues(),
      };

      next.setValues(newFormValues);
    }
  };

  async loadCubeData({ cube }, useCache = false) {
    this.setState({
      loading: true,
      sourceFields: [],
      periodicities: [],
      xmlaSchema: {},
      showParams: false,
    });
    try {
      const cacheKey = `loadCubeData${cube}`;

      let result;

      if (useCache && CACHE.hasOwnProperty(cacheKey)) {
        result = CACHE[cacheKey];
      } else {
        const [sourceFields, periodicities, xmlaSchema] = await Promise.all([
          this.props.service.sourceFields({
            projectId: this.props.context.project.id,
            sourceName: cube,
          }),
          this.props.service.dynamicPeriodicities(),
          this.props.service.queryStructure({
            projectId: this.props.context.project.id,
            sourceName: cube,
          }),
        ]);
        result = { sourceFields, periodicities, xmlaSchema };
        CACHE[cacheKey] = result;
      }

      const { sourceFields, periodicities, xmlaSchema } = result;

      this.setState({
        sourceFields,
        periodicities,
        xmlaSchema,
        showParams: true,
      });

      // Filter dimension check
      {
        const timeDims = sourceFields.filter((sf) => sf.type === 'TimeDimension' && !sf.value.startsWith('BIMF'));
        const withoutFilterDim = timeDims.filter((sf) => {
          return !xmlaSchema.cubes[cube].dimensions.hasOwnProperty(`BIMF${sf.value}`);
        });
        if (!_.isEmpty(withoutFilterDim)) {
          UiMsg.warn(
            this.props.context.msg.t('attention'),
            this.props.context.msg.t(
              'filter.dimension.not.found.warning',
              withoutFilterDim.map((sf) => sf.label).join(', ')
            )
          );
        }
      }
    } catch (e) {
      console.error('Error on loadCubeData()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  }

  formAction = async (values, formikProps) => {
    this._isSaving = true;
    this.setState({ loading: true });
    try {
      for (const param of this.props.type.params) {
        const type = ParamType[param.type];
        if (type.processFormData) {
          values = type.processFormData({
            formData: values,
            param,
            context: this.props.context,
            ...this.state,
          });
        }
      }

      const params = {
        path: this.props.path,
        type: this.props.type.type,
        ...values,
      };

      const additionalOpts = {
        formikProps,
        embeddedOpts: this.props.embeddedOpts,
        editing: this.props.editing,
      };

      // Only analysis contain 'edit' currently
      if (this.props.editing) {
        const result = await this.props.service.updateObject(params, additionalOpts);
        this.props.afterUpdate?.({ result, $formik: this.$formik, values });
      } else {
        await this.props.service.createObject(params, additionalOpts);
      }
    } catch (e) {
      this._isSaving = false;
      if (e.response?.status === 409) {
        UiMsg.warn(this.props.context.msg.t('name.already.in.use.chose.another'));
      } else {
        console.error('Error on formAction()', { e }, e);
        UiMsg.ajaxError(null, e);
      }
      this.setState({ loading: false });
    } finally {
      if (this.props.editing) {
        this.setState({ loading: false });
      }
    }
  };

  containParams() {
    return !_.isEmpty(this.props.type.params);
  }

  render() {
    const selectedType = this.props.type;
    const serviceParams = {
      embeddedOpts: this.props.embeddedOpts,
      editing: this.props.editing,
    };
    const validationSchema =
      this.props.service.findValidationSchema(selectedType, serviceParams) ?? assistedAnalysisBaseSchema;
    const typeParams = this.state.showParams ? this.props.service.findParams(selectedType, serviceParams) : [];
    return (
      <>
        <Formik
          initialValues={this.initialFormValues}
          validationSchema={validationSchema}
          onSubmit={this.formAction}
          innerRef={(ref) => {
            this.$formik = ref;
            this.props.onFormikRef(ref);
          }}
        >
          {(formikProps) => {
            return (
              <UiBlocker block={this.state.loading} className={'BngAssistedAnalysisForm'}>
                <BngForm key={selectedType.type}>
                  <FormikListener onChange={this.formChanged} />

                  {!this.props.editing && (
                    <React.Fragment>
                      <Field
                        name="datasource"
                        label={this.props.context.msg.t('header_menu_in_memory')}
                        component={BngField}
                        inputComponent={BngSelectSearch}
                        groupedOpts={true}
                        options={this.state.sources}
                        defaultPreviewIcon="insert_chart"
                        rootProps={{
                          'data-test': 'datasourceField',
                        }}
                      />

                      {this.state.cubes.length > 1 && (
                        <Field
                          name="cube"
                          label={this.props.context.msg.t('cube')}
                          component={BngField}
                          inputComponent={BngSelectSearch}
                          options={this.state.cubes}
                          rootProps={{
                            'data-test': 'cubeField',
                          }}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {this.state.showParams && (
                    <React.Fragment>
                      {typeParams
                        .filter((param) => !!ParamType[param.type])
                        .map((param, idx) => {
                          let renderParam = true;

                          if (param.props?.visibilityOpts) {
                            const { visibilityOpts } = param.props;
                            if (visibilityOpts.hideOnEdit && this.props.editing) {
                              renderParam = false;
                            } else {
                              if (visibilityOpts.showIfList) {
                                for (const { field, values } of visibilityOpts.showIfList) {
                                  const fieldValue = _.get(formikProps.values, `params.${field}`, '');
                                  renderParam = values.includes(fieldValue);
                                }
                              }
                            }
                          }

                          if (!renderParam) {
                            return null;
                          }

                          const ParamComponent = ParamType[param.type].render;
                          return (
                            <div key={idx} className="mb-2">
                              <ParamComponent
                                {...param}
                                {...this.state}
                                name={`params.${param.name}`}
                                label={this.props.context.msg.t(param.label)}
                                formikProps={formikProps}
                                editing={this.props.editing}
                                context={this.props.context}
                              />
                            </div>
                          );
                        })}
                    </React.Fragment>
                  )}

                  <div className="d-flex">
                    <Button
                      type="submit"
                      className={'bng-button fix save flex-grow-1 Submit Action'}
                      disabled={this.props.editing && !formikProps.dirty}
                      data-test="SubmitFormButton"
                    >
                      {this.props.editing
                        ? this.props.context.msg.t('apply')
                        : this.props.createObjectLabel ?? this.props.context.msg.t('create')}
                    </Button>
                  </div>
                  {this.props.bottomSlot?.({ formikProps })}
                </BngForm>
              </UiBlocker>
            );
          }}
        </Formik>

        {_.isNil(this.props.embeddedOpts) && selectedType.showHelp && (
          <BngAssistedAnalysisHelp
            editing={this.props.editing}
            helpVideoUrl={selectedType.helpVideoUrl}
            documentationUrl={selectedType.documentationUrl}
            title={selectedType.title}
            explanation={selectedType.explanation}
            showIntro={false}
            onFinishIntro={_.noop}
          />
        )}
      </>
    );
  }
}

const BngAssistedAnalysisForm = ContextEnhancer(BngAssistedAnalysisFormInternal);
export default BngAssistedAnalysisForm;
