import { connect } from 'react-redux';
import { MENU, USER } from 'components/ui/redux/Actions';
import Menu from 'components/ui/menu/Menu';

const mapStateToProps = (state) => {
  if (!state.menu.roots) return {};

  return {
    rootItems: state.menu.roots,
    expanded: state.menu.expanded,
    favorites: state.context.favorites,
    breadcrumb: state.context.breadcrumb.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findMenuRoots: () => dispatch(MENU.findRoots()),
    toggleExpandMenu: () => dispatch(MENU.toggleExpandMenu()),
    findFavorites: () => dispatch(USER.findFavorites()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
