import styles from './AddonInfoAd.module.css';
import backgroundImg from './assets/AddonInfoAdDashBg.png';

import Api from 'components/Api';
import { proposalUtils } from 'components/service/bng/AccountApi';
import useTranslation from 'components/hooks/useTranslation';
import useFetchData from 'components/hooks/useFetchData';
import useBimNavigate from 'components/hooks/useBimNavigate';

export default function AddonInfoAd({
  className = '',
  accountId,
  imageClassName,
  addon = null,
  forMarketplace = false,
}) {
  const { t } = useTranslation();
  const navigate = useBimNavigate();

  const { data: selectedAddon } = useFetchData(async () => {
    if (addon) {
      return addon;
    }

    const fetchedAddons = await Api.Account.findAccountAddons(accountId);
    let filteredAddons = fetchedAddons.allAddons.filter((addon) => addon.billingAdditional);

    if (fetchedAddons.accountAddons.length !== filteredAddons.length) {
      filteredAddons = filteredAddons.filter((addon) => !fetchedAddons.accountAddons.includes(addon));
    }

    return filteredAddons[Math.floor(Math.random() * filteredAddons.length)];
  });

  const caption = selectedAddon ? proposalUtils.translationsForSales(selectedAddon.billingAdditional)?.name : null;
  return (
    <div
      className={`AddonInfoAd ${styles.AddonInfoAd} ${className}`}
      onClick={() => {
        if (!selectedAddon) {
          return;
        }
        let url = '#';

        if (forMarketplace) {
          url = Api.buildUrl('/spr/bng/marketplace', {
            currentTab: 'item',
            itemId: selectedAddon.id,
            breadcrumb: true,
          });
          navigate(url);
        } else {
          url = Api.buildUrl('/pages/addon/addon-info.iface', {
            addon: selectedAddon.id,
            breadcrumb: true,
          });
          window.location.replace(url);
        }
      }}
    >
      {selectedAddon && (
        <>
          <div className={styles.imageWrapper}>
            <img
              className={`${styles.addonImage} ${imageClassName}`}
              src={Api.Marketplace.marketplaceImageUrl(
                selectedAddon.billingAdditional.marketplaceInfo?.pageContent.find(
                  (content) => content.type === 'HEADER'
                ).marketplaceBlockImages[0].path
              )}
            />
          </div>
          <div className={styles.titleWrapper}>
            <img className={styles.backgroundImage} src={backgroundImg} />
            <span className={styles.addonPrefix}>{t('addon')}</span>
            <h2 className={styles.addonTitle}>{caption ?? t(selectedAddon.nameKey)}</h2>
          </div>
        </>
      )}
    </div>
  );
}
