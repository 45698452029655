let fontSizeOptionsArray = [];
for (let i = 6; i <= 32; i++) {
  fontSizeOptionsArray.push({
    label: `${i} px`,
    value: '' + i,
  });
}
export const fontSizeOptions = fontSizeOptionsArray;

export const alignOptions = [
  {
    icon: 'format_align_left',
    value: 'left',
  },
  {
    icon: 'format_align_center',
    value: 'center',
  },
  {
    icon: 'format_align_right',
    value: 'right',
  },
];

export const verticalAlignOptions = [
  {
    icon: 'vertical_align_top',
    value: 'flex-start',
  },
  {
    icon: 'vertical_align_center',
    value: 'center',
  },
  {
    icon: 'vertical_align_bottom',
    value: 'flex-end',
  },
];

export const textFormatOptions = [
  {
    icon: 'format_underlined',
    value: 'underline',
  },
  {
    icon: 'format_italic',
    value: 'italic',
  },
  {
    icon: 'format_bold',
    value: 'bold',
  },
];

export const formatOptions = [
  {
    label: 'formatOptions.text',
    value: 'text',
  },
  {
    label: 'formatOptions.number',
    value: 'number',
  },
  {
    label: 'formatOptions.currency',
    value: 'currency',
  },
  {
    label: 'formatOptions.percent',
    value: 'percent',
  },
  {
    label: 'formatOptions.time',
    value: 'time',
  },
  {
    label: 'FormatString.Accounting',
    value: 'Accounting',
  },
  {
    label: 'formatOptions.accountingBrl',
    value: 'AccountingReal',
  },
  {
    label: 'formatOptions.accountingUsd',
    value: 'AccountingDollar',
  },
  {
    label: 'formatOptions.accountingEur',
    value: 'AccountingEuro',
  },
];

export const textColorOptions = [
  {
    label: 'colorOptions.fixed',
    value: 'fixed',
  },
  {
    label: 'colorOptions.gradient',
    value: 'gradient',
  },
  {
    label: 'colorOptions.ranges',
    value: 'ranges',
  },
  {
    label: 'colorOptions.automatic',
    value: 'automatic',
  },
];

export const cellColorOptions = [
  {
    label: 'colorOptions.none',
    value: 'none',
  },
  {
    label: 'colorOptions.gradient',
    value: 'gradient',
  },
  {
    label: 'colorOptions.ranges',
    value: 'ranges',
  },
  {
    label: 'colorOptions.bar',
    value: 'bar',
  },
];

export const colorValueTypeOptions = [
  {
    label: 'colorValueType.absolute',
    value: 'absolute',
  },
  {
    label: 'colorValueType.perc_max',
    value: 'perc_max',
  },
  {
    label: 'colorValueType.perc_sum',
    value: 'perc_sum',
  },
];

export const barOptions = [
  {
    label: 'barOptions.fixed',
    value: 'fixed',
  },
  {
    label: 'barOptions.perc_measure',
    value: 'perc_measure',
  },
  {
    label: 'barOptions.measure',
    value: 'measure',
  },
  {
    label: 'barOptions.perc_max',
    value: 'perc_max',
  },
  {
    label: 'barOptions.perc_sum',
    value: 'perc_sum',
  },
  {
    label: 'barOptions.perc_avg',
    value: 'perc_avg',
  },
];

export const cellBarColorValueTypeOptions = [
  {
    label: 'barColorValue.cell',
    value: 'cell',
  },
  {
    label: 'barColorValue.perc',
    value: 'perc',
  },
];

export const borderIconOptions = [
  {
    icon: 'border_clear',
    value: 'none',
  },
  {
    icon: 'border_inner',
    value: 'all',
  },
  {
    icon: 'border_horizontal',
    value: 'horizontal',
  },
  {
    icon: 'border_vertical',
    value: 'vertical',
  },
  {
    icon: 'border_outer',
    value: 'outer',
  },
];

export const columnPinningOptions = [
  {
    icon: 'vertical_align_bottom',
    value: 'left',
  },
  {
    icon: 'vertical_align_top',
    value: 'right',
  },
];

let rowHeightOptionsArray = [];
for (let i = 15; i <= 75; i += 5) {
  rowHeightOptionsArray.push({
    label: `${i} px`,
    value: '' + i,
  });
}
export const rowHeightOptions = rowHeightOptionsArray;

export const backgroundColors = ['#6D48E7', '#528DE3', '#4E9D5A', '#F8C445', '#FFFFFF', '#E9414C'];

export const fontColors = ['#528DE3', '#000', '#5A5A5A', '#BFBFBF', '#E7E7E7', '#FFF'];

export const fieldTypes = [
  {
    label: 'fieldType.numeric',
    value: 'NUMERIC',
  },
  {
    label: 'fieldType.date',
    value: 'DATE',
  },
  {
    label: 'fieldType.text',
    value: 'TEXT',
  },
];
export const urlFieldTypes = [
  {
    label: 'fieldType.url',
    value: 'URL',
  },
  {
    label: 'fieldType.image.url',
    value: 'IMAGE_URL',
  },
];

export function maxColumnWidthAccumulator() {
  const columns = {};
  return {
    result(columnId = '') {
      if (columnId) {
        return columns[columnId];
      }
      return columns;
    },
    accumulate(value = 0, columnId = '') {
      columns[columnId] = Math.max(value, columns[columnId] || 0);
    },
  };
}

export const totalizerOperators = {
  SUM: {
    label: 'bigtable.totalizer.sum',
    createAccumulator() {
      let acc = 0;
      return {
        result() {
          return acc;
        },
        accumulate(value) {
          acc += value ?? 0;
        },
      };
    },
  },
  AVG: {
    label: 'bigtable.totalizer.avg',
    createAccumulator() {
      let acc = 0;
      let rowCount = 0;
      return {
        result() {
          return acc / (rowCount || 1);
        },
        accumulate(value) {
          acc += value ?? 0;
          rowCount++;
        },
      };
    },
  },
  MIN: {
    label: 'bigtable.totalizer.min',
    createAccumulator() {
      let acc = 0;
      return {
        result() {
          return acc;
        },
        accumulate(value) {
          if (acc === 0) acc = value;
          if (value) {
            acc = Math.min(acc, value);
          }
        },
      };
    },
  },
  MAX: {
    label: 'bigtable.totalizer.max',
    createAccumulator() {
      let acc = 0;
      return {
        result() {
          return acc;
        },
        accumulate(value) {
          if (value) {
            acc = Math.max(acc ?? 0, value);
          }
        },
      };
    },
  },
  COUNT: {
    label: 'bigtable.totalizer.count',
    createAccumulator() {
      let acc = 0;
      return {
        result() {
          return acc;
        },
        accumulate(value) {
          if (value) {
            acc++;
          }
        },
        format({ value }) {
          return value;
        },
      };
    },
  },
  DISTINCT_COUNT: {
    label: 'bigtable.totalizer.distinct.count',
    createAccumulator() {
      let acc = 0;
      const valueMapper = {};
      return {
        result() {
          return acc;
        },
        accumulate(value) {
          if (value && !valueMapper[value]) {
            valueMapper[value] = true;
            acc++;
          }
        },
        format({ value }) {
          return value;
        },
      };
    },
  },
};

export const imageSizes = {
  SMALL: {
    label: 'bigtable.image.size.small',
    value: 35,
  },
  AVG: {
    label: 'bigtable.image.size.avg',
    value: 55,
  },
  BIG: {
    label: 'bigtable.image.size.big',
    value: 75,
  },
  CUSTOM: {
    label: 'bigtable.image.size.custom',
    value: 20,
  },
};

export const imageFrameStyles = {
  SQUARE: {
    label: 'bigtable.image.frame.square',
    value: 0,
  },
  BORDER_RADIUS: {
    label: 'bigtable.image.frame.borderRadius',
    value: 10,
  },
  ROUNDED: {
    label: 'bigtable.image.frame.rounded',
    value: '100%',
  },
};

export const actionClickOptions = [
  {
    value: 'NO_ACTION',
    label: 'no.action',
  },
  {
    value: 'APPLY_FILTER',
    label: 'apply.filter',
  },
];

export const applyFilterActionOptions = [
  {
    value: 'ADD_FILTER',
    label: 'add.filter',
  },
  {
    value: 'CHANGE_FILTER',
    label: 'change.filter',
  },
];
