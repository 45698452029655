import React from "react";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import ObjectRightMenu from "components/ui/right-menu/ObjectRightMenu";


const OrgMapRightMenu = (props) => {

    const saveOrgMap = async ({submitForm = _.noop}) => {
        await Api.executeExp(`#{orgMapMB.save()}`);
        await submitForm();
    };

    const removeOrgMap = async () => {
        await Api.executeExp(`#{orgMapMB.remove()}`);
    };

    return (
        <ObjectRightMenu {...props}
                         saveAs={Api.OrgMap.saveAs}
                         save={saveOrgMap}
                         remove={removeOrgMap}/>
    )
}

export default ContextEnhancer(OrgMapRightMenu);