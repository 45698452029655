import { Axios } from 'commonUtils';

class TemporaryStorageApi {
  async find(key = '', invalidate = true) {
    return await Axios.getData(`/spr/temporaryStorage`, {
      params: {
        key,
        invalidate,
      },
    });
  }
  async put(value) {
    const { data } = await Axios.post(`/spr/temporaryStorage`, {value});
    return data;
  }
}

export default TemporaryStorageApi;
