import React, { useEffect, useRef } from 'react';

export default function HtmlComponent({ html, renderOnIframe = false, className = '', ...props }) {
  const $frameRef = useRef(null);

  useEffect(() => {
    if (!$frameRef.current) return;

    const contentDocument = $frameRef.current.contentDocument || $frameRef.current.contentWindow || $frameRef.current.contentDocument.document;
    contentDocument.open();
    contentDocument.write(html);
    contentDocument.close();
  }, [$frameRef.current]);

  if (renderOnIframe) {
    return (
      <div className={`w-100 h-100 ${className}`} {...props}>
        <iframe height="100%" width="100%" style={{ border: 0 }} ref={$frameRef} />
      </div>
    );
  } else {
    return (
      <div
        style={{ margin: '0', padding: '0', font: '10pt Arial, Verdana' }}
        dangerouslySetInnerHTML={{ __html: html }}
        className={className}
        {...props}
      ></div>
    );
  }
}
