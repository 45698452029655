import styles from './BngTreeDropdown.module.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import BngPopper from 'components/bng/ui/BngPopper';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import BngSearch from 'components/bng/ui/BngSearch';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Utils from 'components/Utils';
import LoadingBox from 'components/ui/loading/LoadingBox';

const getNodeLevel = (path) => {
  return path.split('/').length - 2;
};

function getNodeValue(node) {
  return node.value || node.path || node.href;
}

function getNodeLabel(node) {
  return node.text || node.label;
}

function findNodeOnTree(tree = [], path = '') {
  for (const node of tree) {
    const nodePath = getNodeValue(node);
    if (nodePath === path) return node;
    if (node.children) {
      const match = findNodeOnTree(node.children, path);
      if (match) return match;
    }
  }
  return null;
}

const PreviewBreadCrumb = ({
  openDropdown,
  values = null,
  singleSelect,
  selectFolders,
  setSelectedNodes,
  field,
  form,
  loading = false,
}) => {
  const context = useBimContext();
  const [label, setLabel] = useState();
  const [currentOverflow, setCurrentOverflow] = useState(0);
  const breadCrumbRef = useRef();

  useEffect(() => {
    setCurrentOverflow(0);
  }, [values]);

  useEffect(() => {
    setLabel(buildLabel());
  }, [values, currentOverflow, loading]);

  useEffect(() => {
    const hasOverflown = breadCrumbRef.current?.clientWidth < breadCrumbRef.current?.scrollWidth;
    if (hasOverflown && values?.split('/').length - 3 >= currentOverflow) {
      setCurrentOverflow(currentOverflow + 1);
    }
  }, [label]);

  const calculateMaxTextWidth = (folderDepth, fieldPadding) => {
    const textSpace = breadCrumbRef.current?.clientWidth - fieldPadding;
    return Math.abs(textSpace - (folderDepth + 1) * 32);
  };

  const buildLabel = () => {
    if (loading) {
      return context.msg.t('common_load');
    }

    if (!values || values.length === 0) {
      return context.msg.t('select.one');
    } else if (selectFolders) {
      const path = values.split('/').slice(2);
      return (
        <div className="flex-center-items no-wrap" title={values}>
          {path.map((pathSection, idx) => {
            const label = pathSection === `${context.user.id}` ? context.msg.t('personal.folder') : pathSection;

            const isLastItem = idx === path.length - 1;
            const shouldRenderLabel = !(idx <= currentOverflow) || path.length <= idx || isLastItem;

            const folderDepth = values?.split('/').length - 3;
            const textFieldPadding = 61;
            const filledWidth = folderDepth * 36 + textFieldPadding;
            const subFolderOverflown = filledWidth > breadCrumbRef.current?.clientWidth;
            const labelStyle =
              isLastItem && !subFolderOverflown
                ? {
                    maxWidth: calculateMaxTextWidth(folderDepth, textFieldPadding),
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }
                : {};

            return (
              <React.Fragment key={`${pathSection}${idx}`}>
                {subFolderOverflown && isLastItem && <Icon icon={'chevron_right'} style={{ fontSize: '18px' }} />}
                {(!subFolderOverflown || isLastItem) && (
                  <>
                    <Icon icon={'folder'} className={styles.previewIcon} style={{ fontSize: '18px' }} />
                    {shouldRenderLabel && (
                      <span className="ml-1" style={labelStyle}>
                        {label}
                      </span>
                    )}

                    {!isLastItem && <Icon icon={'chevron_right'} style={{ fontSize: '18px' }} />}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    } else {
      if (singleSelect) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Icon icon={Utils.Object.getObjectIcon(values)} style={{ fontSize: '18px' }} />
            <span>{Utils.Object.fileName(values)}</span>
          </div>
        );
      } else {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon={'chevron_right'} style={{ fontSize: '18px' }} />
            <span>{`${context.msg.t('objects.selected', [values.length])}`}</span>
          </div>
        );
      }
    }
  };

  if (loading) {
    openDropdown = undefined;
  }

  return (
    <div className={`${styles.FieldPreview} FieldPreview`} ref={breadCrumbRef}>
      <div className={`flex-grow-1 ${styles.breadcrumbWrapper}`} onClick={openDropdown}>
        {label}
      </div>
      {loading ? (
        <LoadingBox className="p-0 mr-2" />
      ) : (
        <>
          <Icon icon="expand_more" className={`mr-1 ${styles.expandIcon}`} onClick={openDropdown} />
          <BngIconButton
            icon={'close'}
            onClick={() => {
              setSelectedNodes({});
              if (form.hasOwnProperty('setFieldTouched')) form.setFieldTouched(field.name, true);
              form.setFieldValue(field.name, null);
            }}
            iconProps={{ style: { fontSize: 16, color: '#333' } }}
            disable={loading}
          />
        </>
      )}
    </div>
  );
};

const TreeNode = ({
  msg,
  node,
  isExpanded,
  isSelected,
  expandNode = _.noop,
  onSelect = _.noop,
  selectable = false,
  selectFolders = false,
  width = null,
  singleSelect = false,
}) => {
  const hasChildren = selectFolders ? node.children?.find((child) => !child.leaf) : node.children?.length > 0;
  const label = getNodeLabel(node);
  const nodeLevel = getNodeLevel(node.value);
  const nodeTitle = msg.translateIfHasKey(
    node.disabled ? node.title || 'folder.move.disabled' : !hasChildren ? 'empty_folder' : ''
  );

  return (
    <div
      className={`TreeNode ${styles.TreeNode} ${node.disabled ? styles.disabled : ''}`}
      style={{ width: width ? width : '100%' }}
      onClick={
        node.disabled
          ? undefined
          : () => {
              if (selectable) {
                onSelect(node);
              } else {
                hasChildren && expandNode(node, !isExpanded);
              }
            }
      }
    >
      <div className={styles.borderBottom}>
        <div
          className={`${styles.inlineItems} ${nodeLevel > 1 ? styles.childBorder : ''}`}
          style={{ marginLeft: (nodeLevel - 1) * 20 }}
          title={nodeTitle}
        >
          {selectable && node.leaf && (
            <BngCheckbox
              disabled={node.disabled}
              field={{
                onChange: () => onSelect(node),
                value: isSelected,
              }}
              style={{
                transform: 'scale(1.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingLeft: '12px',
              }}
            />
          )}

          {!node.leaf && (
            <BngIconButton
              icon={isExpanded || !hasChildren ? 'remove' : 'add'}
              className={styles.expandIconButton}
              disabled={!hasChildren || node.disabled}
              style={{ marginRight: '5px' }}
              onClick={(e) => {
                e.stopPropagation();
                expandNode(node, !isExpanded);
              }}
            />
          )}

          <div className={styles.nodeLabelWrapper}>
            {node.icon && (
              <Icon
                icon={node.icon}
                size={'xl'}
                outlined={isExpanded || !hasChildren}
                className={`fileIcon ${isExpanded && styles.expandedNodeIcon} ${
                  node.disabled ? styles.disabledIcon : ''
                }`}
              />
            )}
            <span className={styles.nodeLabel}>{label}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function BngTreeDropdown({
  dataTree,
  field,
  form,
  style,
  popperClassName,
  selectFolders = false,
  initialValue,
  className,
  foldersToIgnore,
  disabled = false,
  objectFilterFunction = () => true,
  singleSelect = false,
  updateJsfOnSelect = true,
}) {
  const context = useBimContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedNodes, setSelectedNodes] = useState({});
  const [popperWidth, setPopperWidth] = useState(null);
  const $rootElement = useRef(null);

  const onScrollChange = useCallback(
    (node) => {
      if (node !== null && node.scrollWidth > $rootElement.current.clientWidth) {
        setPopperWidth(node.scrollWidth);
      }
    },
    [expandedNodes]
  );

  useEffect(() => {
    if (field) {
      const folderPath = field.value;
      if (folderPath) {
        const node = findNodeOnTree(dataTree, folderPath);
        if (node) {
          setSelectedNodes({
            [folderPath]: node,
          });
        }
      } else {
        setSelectedNodes({});
      }
    }
  }, [dataTree, field?.value]);

  useEffect(() => {
    if (!initialValue) return;
    form.setFieldValue(field.name, initialValue);
    const node = findNodeOnTree(dataTree, initialValue);
    if (node) {
      setSelectedNodes({
        [initialValue]: node,
      });

      // expand parents
      const expandedParentFolders = {};
      let folderPath = initialValue;
      for (let i = getNodeLevel(initialValue); i > 1; i--) {
        folderPath = Utils.Object.parentPath(folderPath);
        const folderNode = findNodeOnTree(dataTree, folderPath);
        if (folderNode) {
          expandedParentFolders[folderPath] = folderNode;
        }
      }
      setExpandedNodes(expandedParentFolders);
    }
  }, []);

  const buildExpandedNodes = (nodeArray) => {
    if (!nodeArray) return [];

    nodeArray = nodeArray.slice();
    const showOnSearch = [];

    for (let i = 0; i < nodeArray.length; i++) {
      let children = [];
      let nodeItem = nodeArray[i];

      const baseLevel = getNodeLevel(getNodeValue(nodeArray[0]));
      const nodeValue = getNodeValue(nodeItem);
      const nodeLevel = getNodeLevel(nodeValue);
      const label = getNodeLabel(nodeItem);

      if (nodeValue in expandedNodes && nodeLevel === baseLevel) {
        children = buildExpandedNodes(nodeItem.children);
        nodeArray.splice(i + 1, 0, ...children);
      }

      if ((label?.toLowerCase().includes(searchTerm.toLowerCase()) && nodeLevel === baseLevel) || children.length > 0) {
        showOnSearch.push(nodeItem);
      }
      showOnSearch.push(...children);
    }

    return (searchTerm ? showOnSearch : nodeArray).filter(objectFilterFunction);
  };

  const onSearch = (search) => {
    if (search && search !== '') {
      const foldersToExpand = { ...expandedNodes };
      dataTree.forEach((treeNode) => {
        drillDownAllFolders(treeNode, foldersToExpand);
      });
      setExpandedNodes(foldersToExpand);
    } else {
      setExpandedNodes({});
    }

    setSearchTerm(search);
  };

  const drillDownAllFolders = (treeNode, foldersToExpand) => {
    if (treeNode.leaf) return;
    expandNode(treeNode, true, foldersToExpand);
    treeNode.children?.forEach((treeNode) => drillDownAllFolders(treeNode, foldersToExpand));
  };

  const expandNode = (node, expand, foldersToExpand) => {
    const nodePath = node.value ?? node.path;
    if (expand) {
      foldersToExpand[nodePath] = node;
    } else {
      delete foldersToExpand[nodePath];
    }
  };

  const toggleSelected = (node) => {
    let updatedSelectedNodes = selectFolders || singleSelect ? [] : selectedNodes;

    if (node.value in updatedSelectedNodes) {
      delete updatedSelectedNodes[node.value];
    } else {
      updatedSelectedNodes[node.value] = node;
    }
    setSelectedNodes({ ...updatedSelectedNodes });
  };

  const onSelect = async (nodes) => {
    if (form.hasOwnProperty('setFieldTouched')) {
      form.setFieldTouched(field.name, true);
    }

    if (nodes && nodes.length !== 0) {
      if (selectFolders || singleSelect) {
        form.setFieldValue(field.name, nodes[0].value);
      } else {
        form.setFieldValue(
          field.name,
          nodes.map((node) => {
            return node.value || node.path;
          })
        );
      }
      if (updateJsfOnSelect) {
        await Api.updateJsf();
      }
    }

    setDropdownOpen(false);
  };

  const fieldValue = field.value || selectFolders ? Object.keys(selectedNodes)[0] : Object.keys(selectedNodes);

  const showDropdown = dropdownOpen && !disabled;
  return (
    <div
      ref={$rootElement}
      style={style}
      className={`BngTreeDropdown ${styles.BngTreeDropdown} ${className} ${disabled ? styles.disabled : ''}`}
    >
      <PreviewBreadCrumb
        openDropdown={() => setDropdownOpen(true)}
        selectFolders={selectFolders}
        values={fieldValue}
        setSelectedNodes={setSelectedNodes}
        field={field}
        form={form}
        singleSelect={singleSelect}
        loading={_.isEmpty(dataTree)}
      />

      {showDropdown && (
        <>
          <BngPopper
            className={`TreeDropdownPopper ${styles.TreeDropdownPopper} ${popperClassName}`}
            open={true}
            anchorEl={$rootElement.current}
            modifiers={{ flip: { enabled: false } }}
          >
            <div style={{ marginTop: -$rootElement.current.offsetHeight }}>
              <div className={styles.searchFieldWrapper}>
                <BngSearch
                  name={`${field.name}-search`}
                  onChange={onSearch}
                  simple={true}
                  alwaysOpen={true}
                  inputAutoComplete={false}
                  value={searchTerm}
                  placeholder={context.msg.t('search.field.placeholder')}
                />
              </div>
              <div
                className={styles.BngTreePopper}
                style={{ width: $rootElement.current.clientWidth }}
                ref={onScrollChange}
              >
                {buildExpandedNodes(dataTree).map((node) => {
                  if (node.leaf && selectFolders) {
                    return null;
                  } else {
                    node = { ...node };
                    if (foldersToIgnore && foldersToIgnore.includes(node.path)) {
                      node.disabled = true;
                    }
                    const selectable = (selectFolders && !node.leaf) || node.leaf;
                    if (!node.value) {
                      node.value = getNodeValue(node);
                    }

                    return (
                      <TreeNode
                        msg={context.msg}
                        node={node}
                        key={`${node.value}-node`}
                        isExpanded={node.value in expandedNodes}
                        expandNode={(node, expand) => {
                          const nodesToExpand = { ...expandedNodes };
                          expandNode(node, expand, nodesToExpand);
                          setExpandedNodes(nodesToExpand);
                        }}
                        isSelected={node.value in selectedNodes}
                        selectable={selectable}
                        selectFolders={selectFolders}
                        width={popperWidth}
                        singleSelect={singleSelect}
                        onSelect={async (nodes) => {
                          toggleSelected(nodes);
                          if (selectFolders || singleSelect) {
                            await onSelect([nodes]);
                          }
                        }}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </BngPopper>
          <BngClickOutsideOverlay
            className={styles.backgroundOverlay}
            onClick={async () => {
              if (selectFolders) {
                setDropdownOpen(false);
              } else {
                await onSelect(Object.values(selectedNodes));
              }
            }}
          />
        </>
      )}
    </div>
  );
}
