import React, {Component} from 'react';
import {Mention, MentionsInput} from "react-mentions";
import {getPositionOfCapturingGroup, escapeRegex} from "react-mentions/es/utils";

import './BngMention.css';


const PLACEHOLDERS = {
    id: '__id__',
    display: '__display__',
    type: '__type__'
};

const markupToRegex = function markupToRegex(markup) {
    let markupPattern = escapeRegex(markup);
    markupPattern = markupPattern.replace(PLACEHOLDERS.display, '(.+?)');
    markupPattern = markupPattern.replace(PLACEHOLDERS.id, '(.+?)');
    markupPattern = markupPattern.replace(PLACEHOLDERS.type, '(.+?)');

    return new RegExp(markupPattern, 'g');
};

const generateMarkup = function(value, markup, displayTransform, regex) {
    regex = regex || markupToRegex(markup);
    let idPos = getPositionOfCapturingGroup(markup, 'id', regex);
    let displayPos = getPositionOfCapturingGroup(markup, 'display', regex);
    let typePos = getPositionOfCapturingGroup(markup, 'type', regex);
    value = value.replace(regex, function () {
        // first argument is the whole match, capturing groups are following
        let id = arguments[idPos + 1];
        let display = arguments[displayPos + 1];
        let type = typePos !== null ? arguments[typePos + 1] : null;
        if (displayTransform) display = displayTransform(id, display, type);
        return `<strong>${display}</strong>`;
    });
    return `<div class="BngMentionText">${value}</div>`;
};

export class BngMention extends Component {

    static defaultProps = {
        className: '',
        mentions: {},
        suggestionsPortalHost: null
    };

    onChange = (event, newValue, newPlainTextValue, mentions) => {
        this.props.form.setFieldValue(this.props.field.name, newValue);
        this.props.form.setFieldValue(`${this.props.field.name}Text`, newPlainTextValue);
        this.props.form.setFieldValue(`${this.props.field.name}Mentions`, mentions);
    };

    static displayTransform(id, display, type) {
        return `${type}${display || id}`;
    };

    static parseMentionStringAsMarkup(value = '', trigger='@') {
        if(!value) {
            return '';
        }
        return generateMarkup(
            value,
            trigger+'[__display__](__id__)',
            (id, display, type) => BngMention.displayTransform(id, display, type || trigger),
        );
    }

    render() {
        const {
            className,
            field,
            form,
            mentions = {},
            style = {},
            suggestionsPortalHost,
            ...props
        } = this.props;

        return (
            <MentionsInput
                className={`BngMention ${className}`}
                style={{...defaultStyle, ...style}}
                {...field}
                onChange={this.onChange}
                displayTransform={(id, display, type) => {
                    return (BngMention.displayTransform(id, display, type || Object.keys(mentions)[0]));
                }}
                suggestionsPortalHost={suggestionsPortalHost || document.body}
                {...props}
            >
                {Object.keys(mentions).map(trigger => (
                    <Mention
                        key={trigger}
                        trigger={trigger}
                        type={trigger}
                        data={[...mentions[trigger].data.map(opt => ({id: `${opt.value}`, display: `${opt.label || opt.value}`}))]}
                        style={{...defaultMentionStyle, ...(mentions[trigger].style || {})}}
                        appendSpaceOnAdd={true}
                    />
                ))}

            </MentionsInput>
        )
    }
}

const defaultStyle = {

    highlighter: {
        overflow: 'auto',
        position: 'absolute',
    },

    suggestions: {
        list: {
            boxShadow: 'inset 0 0 0 0 #B5B5B5, 0 2px 10px 0 rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            backgroundColor: 'white',
        },

        item: {
            padding: '5px 15px',
            fontWeight: '500',
            '&focused': {
                backgroundColor: '#eee',
            },
        },
    },
};

const defaultMentionStyle = {
    fontWeight: '500',
    textShadow: '0 0 0 black',
};