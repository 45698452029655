'use strict';

import { Axios, findContextPath } from 'commonUtils';

class FeatureApi {
  FEAT_ACCOUNTS_PAGE = 'ACCOUNTS_PAGE';
  FEAT_COCKPIT_REACT = 'COCKPIT_REACT';

  findUsersWithFeature = async (selectedFeature) => {
    const { data } = await Axios.get(`/spr/features/unlocked-users`, {
      params: { selectedFeature },
    });
    return data;
  };

  unlockForSelectedUser = async (feature, userId, publicLabs = false) => {
    const { data } = await Axios.post(`/spr/features/unlock`, {
      feature,
      userId,
      publicLabs,
    });
    return data;
  };

  unlockForAllUsers = async (feature) => {
    const { data } = await Axios.post(`/spr/features/unlock-all`, { feature });
    return data;
  };

  lockForAllUsers = async (selectedFeature) => {
    const { data } = await Axios.delete(`/spr/features/lock-all`, {
      params: { selectedFeature },
    });
    return data;
  };

  lockForSelectedUser = async (feature, userId, publicLabs = false) => {
    const { data } = await Axios.delete(`/spr/features/lock`, {
      params: { feature, userId, publicLabs },
    });
    return data;
  };

  findUserUnlockedFeatures = async () => {
    const { data } = await Axios.get(`/spr/features/forUser`);
    return data;
  };

  findAvailableFeatures = async () => {
    const { data } = await Axios.get(`/spr/features`);
    return data;
  };

  userAccessedPublicLabs = async () => {
    await Axios.post(`/spr/seenFeatures/userAccessed`);
  };

  sendRequestEmail = async (userId, feature, projectId) => {
    await Axios.post(`/spr/features/sendRequestEmail`, { userId, feature, projectId });
  };

  redirectToLabsPage = () => {
    window.location.replace(`${findContextPath()}/spr/bng/labs`);
  };

  fetchOldestFeedbackFeature = async () => {
    const { data } = await Axios.get(`/spr/features/feedback`);
    return data;
  };

  updateFeedbackFeature = async (feature) => {
    await Axios.post(`/spr/features/feedback`, { feature });
  };

}

export default FeatureApi;
