"use strict";

const $ = jQuery;


class EventApi {
    static getAndProcessEvents() {
        $.getJSON('/spr/ui/events').then((data) => {
            try {
                data.forEach(js => eval(js));
            } catch (e) {
                console.error(e.message, e);
            }
            j('.jsf-react-load-placeholder').remove();
        });
    }
}

export default EventApi;