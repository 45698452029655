import styles from './LogPreview.module.css';
import React from 'react';
import useTranslation from 'components/hooks/useTranslation';

export default function LogPreview({ messageLog = '' }) {
  const { t } = useTranslation();

  return (
    <div className={`LogPreview ${styles.LogPreviewWrapper}`}>
      <div className={styles.title}>{t('error')}:</div>

      <div className={`${styles.errorDescription} mt-3`}>
        {messageLog.split('\n').map((line) => {
          return <div>{line}</div>;
        })}
      </div>
    </div>
  );
}
