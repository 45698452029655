import styles from './BngQuotaViewer.module.css';
import React, { useEffect, useState } from 'react';
import Api from 'components/Api';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngButton from 'components/bng/ui/BngButton';
import { MODALS } from 'components/ui/redux/Actions';
import AddAdditionalDialog, { ADDITIONALS } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngIconButton from 'components/bng/ui/BngIconButton';
import UiMsg from 'components/ui/UiMsg';

const DEFAULT_REQUEST_QUOTA = '5GB';

export default function BngQuotaViewer({ className = '', shouldShowPopover = true, projectId }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    available: 0,
    quota: {
      spent: 0,
    },
  });

  useEffect(() => {
    fetchQuotaMetrics();
  }, []);

  const fetchQuotaMetrics = async () => {
    setLoading(true);
    let status = {
      available: 0,
      quota: {
        spent: 0,
      },
    };
    try {
      status = await Api.Project.findTransferQuota(projectId);

      const noLimit = context.isInbox || status.available === -1;
      const spent = status.quota.spent > status.available ? status.available : status.quota.spent;
      status.percent = Math.ceil(noLimit ? 100 : (spent / status.available) * 100) || 0;
      status.alert = noLimit ? 'success' : 'info';
      status.color = '#00A355';
      if (!noLimit) {
        if (status.percent >= 90) {
          status.alert = 'danger';
          status.color = '#E23D3D';
        } else if (status.percent >= 70) {
          status.alert = 'warning';
          status.color = '#FAA133';
        }
      }

      status.comparison = noLimit
        ? context.msg.t('no.limit')
        : `${Utils.fileSizeSI(spent)} / ${Utils.fileSizeSI(status.available)}`;
    } catch (e) {
      console.error('Error on function fetchQuotaMetrics()', e);
      status.error = true;
      if (e.status === 503) {
        status.comparison = context.msg.t('service.unavailable');
      }
    } finally {
      setLoading(false);
    }
    setStatus(status);
  };

  const requestQuota = async () => {
    setLoading(true);

    try {
      UiMsg.ok(context.msg.t('request.quota.notification.message', [DEFAULT_REQUEST_QUOTA]));
      await Api.ProjectQuotaRequest.requestQuota(projectId);
    } catch (e) {
      console.error('Error on requestQuota()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }

    window.requestAnimationFrame(() => {
      fetchQuotaMetrics();
    });
  };

  const additionalQuotaRequested = status?.quotaRequest?.validForToday;

  return (
    <BngDropdown
      popperOpts={{
        placement: 'bottom-start',
      }}
      popperClassName={`${styles.QuotaViewerDropdown}`}
      customButton={({ openDropdown }) => {
        const width = status.quota.spent > status.available ? 185 : (185 * status.percent) / 100;
        return (
          <div
            className={`BngQuotaViewer ${styles.BngQuotaViewer} ${className}`}
            onClick={shouldShowPopover && !status.error ? openDropdown : _.noop}
          >
            <span>{loading ? `${context.msg.t('loading')}...` : status.comparison}</span>
            <div className={styles.progressBarWrapper}>
              {!loading && (
                <>
                  <svg width="185" height="7" viewBox="0 0 185 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="185" height="7" rx="3.5" fill="#D9D9D9" />
                    <rect width={Number.isFinite(width) ? width : 0} height="7" fill={status.color} rx="3.5" />
                  </svg>
                  <span className={styles.valuePercent}>{`${(status.percent || 0).toFixed()}%`}</span>
                </>
              )}
            </div>
          </div>
        );
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={styles.dropdownContent}>
            <div className={styles.infoRow}>
              <strong>{context.msg.t('average.consumption')}:</strong>
              <span>{Utils.fileSizeSI(status.quota.avgSpent)}</span>
              <BngIconButton
                icon={'refresh'}
                className={`${styles.refreshBtn} ${loading ? 'spin-element' : ''}`}
                onClick={fetchQuotaMetrics}
              />
            </div>
            <div
              className={styles.infoRow}
              title={status.quota.lastUpdate ? Utils.Date.formatDateTime(status.quota.lastUpdate) : '-'}
            >
              <strong>{context.msg.t('last.update')}:</strong>
              <span>{status.quota.lastUpdate ? moment(status.quota.lastUpdate).fromNow() : '-'}</span>
            </div>
            <div
              className={styles.infoRow}
              title={status.quota.lastReset ? Utils.Date.formatDateTime(status.quota.lastReset) : '-'}
            >
              <strong>{context.msg.t('last.reset')}:</strong>
              <span>{status.quota.lastReset ? moment(status.quota.lastReset).fromNow() : '-'}</span>
            </div>

            <BngButton
              className={`requestQuotaBtn ${styles.requestQuotaBtn}`}
              disabled={loading || additionalQuotaRequested}
              onClick={async () => {
                if (context.accountEnabledForBilling) {
                  dispatch(
                    MODALS.open(AddAdditionalDialog, {
                      featureKey: ADDITIONALS.QUOTA.key,
                      isConsumptionAdditional: true,
                      onActivate: () => window.location.reload(),
                    })
                  );
                } else if (!additionalQuotaRequested) {
                  await requestQuota();
                }
              }}
            >
              {context.msg.t('request.quota.button')}
            </BngButton>
          </div>
        );
      }}
    />
  );
}
