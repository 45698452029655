import './MoveObject.css';
import React from 'react';
import PropTypes from 'prop-types';
import Api from 'components/Api';
import ReactDOM from 'react-dom';
import Snackbar from 'components/ui/common/Snackbar';
import ContextEnhancer from 'components/ContextEnhancer';

export const MoveObject = ContextEnhancer(
  class extends React.PureComponent {
    static propTypes = {
      className: PropTypes.string,
      dashGrid: PropTypes.any,
      container: PropTypes.any,
      idFirstSwap: PropTypes.any,
    };

    static defaultProps = {
      className: '',
    };

    state = {
      items: {},
      lastSelected: {},
      targetItemId: '',
      loading: true,
    };

    async componentDidMount() {
      application.dashboards.adjustHeightForFixedFilter(false);
      j('.free-style-marker-class').addClass('OnGridCreation');
      this.getDashGrid();
      this.setState({ loading: false });
    }

    componentWillUnmount() {
      application.dashboards.adjustHeightForFixedFilter(true);
      j('.free-style-marker-class').removeClass('OnGridCreation');
      const dashGrid = this.getDashGrid();
      dashGrid.toggleCustomSelect();
      dashGrid.forceUpdate();
      this.props.container.remove();
    }

    getDashGrid = () => {
      const dashGrid = this.props.dashGrid();
      if (!dashGrid.state.customSelect) {
        const items = this.state.items;
        items[this.props.idFirstSwap] = this.props.idFirstSwap;
        dashGrid.toggleCustomSelect({
          onSelect: this.selectionHandler,
          itemClass: this.getItemClass,
        });
      }
      return dashGrid;
    };

    selectionHandler = (props) => {
      const items = { ...this.state.items };

      props = Array.isArray(props) ? props : [props];

      props.forEach((p) => {
        if (!!p.layout && items.hasOwnProperty(p.layout.i) && p.layout.i !== this.props.idFirstSwap) {
          delete items[p.layout.i];
        } else {
          if (p.layout.i !== this.props.idFirstSwap) {
            delete items[this.state.lastSelected];
          }
          items[p.layout.i] = p;
        }
        if (p.layout.i !== this.props.idFirstSwap) {
          this.state.targetItemId = p.layout.i;
          this.setState({ lastSelected: p.layout.i });
        }
      });

      return this.setState(
        {
          items,
        },
        () => {
          this.getDashGrid().forceUpdate();
        }
      );
    };

    getItemClass = ({ i }) => {
      if (i in this.state.items) {
        return 'selected-by-container select-item-to-move'; //selected items to move
      }
      return '';
    };

    destroyOnClickOut = (event) => {
      this.close();
    };

    close = () => {
      ReactDOM.unmountComponentAtNode(this.props.container);
    };

    haveSelectItems = () => {
      return Object.keys(this.state.items).length > 0;
    };

    save = async () => {
      this.setState({ loading: true });
      try {
        await this.saveMove();
        await Api.updateJsf();
        this.close();
      } catch (e) {
        console.error(e);
        this.setState({ loading: false });
      }
    };

    saveMove = async () => {
      await Api.Dash.swapSelectItem(this.props.idFirstSwap, this.state.targetItemId);
    };

    render() {
      const validSelection = Object.keys(this.state.items).length > 1;

      return (
        <React.Fragment>
          {ReactDOM.createPortal(<div className="MoveObjectOverlay" onClick={this.destroyOnClickOut} />, document.body)}

          <Snackbar
            className={`save-snackbar softComing ${validSelection ? 'Valid' : ''}`}
            icon={validSelection ? 'check' : 'touch_app'}
            onClick={validSelection && !this.state.loading ? this.save : null}
            message={this.props.context.msg.t(validSelection ? 'click.to.move.items' : 'select.items.to.move')}
          >
            <span
              className="material-icons cancel-move-object notranslate"
              onClick={this.close}
              title={this.props.context.msg.t('cancel')}
            >
              close
            </span>
          </Snackbar>
        </React.Fragment>
      );
    }
  }
);
