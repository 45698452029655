import React, { Component } from 'react';
import {
  BreadcrumbToolbar,
  ExportToolbarItem,
  FavoriteToolbarItem,
  InformationToolbarItem,
  MobileToolbarItem,
  NotesToolbarItem,
  PublisherToolbarItem,
} from 'components/ui/breadcrumb-toolbar';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';

class DashBreadcrumbToolbar extends Component {
  static defaultProps = {
    isNotMapPersisted: false,
    path: '',
    caption: '',
    mobile: true,
  };

  state = {
    loading: true,
    onAjax: false,
    mobile: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ mobile: this.props.mobile });
  }

  toggleMobile = async (event) => {
    this.props.context.execute(`#{MapsBean.addCurrentMapToMobile(${!this.state.mobile})}`);
    await Api.updateJsf();
    this.setState({ mobile: !this.state.mobile });
  };

  render() {
    const { onAjax } = this.state;
    const { isNotMapPersisted, path, caption, mobile } = this.props;

    return (
      <BreadcrumbToolbar loading={onAjax}>
        <ExportToolbarItem disabled={isNotMapPersisted} caption={caption} path={path} />

        <PublisherToolbarItem path={path} caption={caption} disabled={isNotMapPersisted} />

        <NotesToolbarItem disabled={isNotMapPersisted} path={path} />

        <FavoriteToolbarItem disabled={isNotMapPersisted} path={path} />

        <MobileToolbarItem disabled={isNotMapPersisted} inactive={!mobile} path={path} onClick={this.toggleMobile} />

        <InformationToolbarItem
          disabled={isNotMapPersisted}
          title={this.props.context.msg.t('object.information.map')}
          dataSource={null}
          path={path}
        />
      </BreadcrumbToolbar>
    );
  }
}

export default ContextEnhancer(DashBreadcrumbToolbar);
