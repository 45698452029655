import styles from './SchedulingPage.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import HelpIcon from 'components/ui/common/HelpIcon';
import SchedulingDropdown from 'components/bng/exportScheduling/SchedulingDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import { ColorDot } from 'components/bng/pages/admin/structures/GroupRender';
import useEventBus from 'components/hooks/useEventBus';
import UiMsg from 'components/ui/UiMsg';
import { MODALS } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngExportDialog from 'components/bng/exportScheduling/BngExportDialog';
import FindTraceabilityDialog from 'components/ui/traceability/FindTraceabilityDialog';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import OpConfirmation from 'components/ui/OpConfirmation';

export default function SchedulingPage({}) {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const [schedulings, setSchedulings] = useState([]);
  const [tableSortMode, setTableSortMode] = useState({ createdAt: 'DESC' });
  const [createdByOpts, setCreatedByOpts] = useState([]);

  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const filteredRows = useMemo(() => {
    return filterRows(schedulings, filters, tableSortMode);
  }, [schedulings, filters, tableSortMode]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await Api.ExportScheduling.findAll(context.project.name);
      setSchedulings(data);

      const userOpts = Object.values(
        data.reduce((acc, scheduling) => {
          const createdBy = scheduling.scheduling.createdBy;
          if (!acc[createdBy.id]) {
            acc[createdBy.id] = {
              id: createdBy.email,
              label: Utils.Users.displayName(createdBy),
              title: createdBy.email,
            };
          }
          return acc;
        }, {})
      );
      setCreatedByOpts(userOpts);
    } catch (e) {
      console.error('Error on SchedulingPage.fetchData()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEventBus(Api.ExportScheduling.CHANGE_EVENT, fetchData);

  const tableColumns = useMemo(
    () =>
      buildTableColumns({
        fetchData,
      }),
    [filteredRows, schedulings]
  );

  useEffect(() => {
    fetchData();
  }, []);

  const updateEnabledInSchedulings = async (enable) => {
    if (!Utils.Project.canExecuteSchedulings(context.project)) {
      UiMsg.warn(
        context.msg.t('orgmap.warn.dialog.title'),
        context.msg.t('SchedulingPage.enable.all.production.warning', [context.msg.t(context.project.projectType)])
      );
    } else {
      try {
        const ids = filteredRows.map((schedule) => {
          return schedule.id;
        });
        OpConfirmation({
          title: context.msg.t('attention'),
          message: enable
            ? context.msg.t('SchedulingPage.enable.all.warning')
            : context.msg.t('SchedulingPage.disable.all.warning'),
          onConfirm: () =>
            Api.ExportScheduling.updateEnabledOnScheds(ids, enable).then(() => {
              fetchData().then(() => {
                UiMsg.ok(
                  enable ? context.msg.t('SchedulingPage.enabled.all') : context.msg.t('SchedulingPage.disabled.all')
                );
              });
            }),
          msg: context.msg,
        });
      } catch (e) {
        console.error('Error while trying to enable all schedulings', e);
      }
    }
  };

  const headerButtons = () => {
    return (
      <div className="flex-center-items">
        <SchedulingFilterDropdown
          onChange={(values) => setFilters({ ...filters, filterButton: values })}
          initialValues={filters.filterButton}
          createdByOpts={createdByOpts}
        />
        {(context.permissions.isAdmin() || context.permissions.isMaster()) && (
          <BngIconButton
            icon="policy"
            onClick={() => {
              checkAddonEnabled(AddonType.TRACEABILITY.key, true)
                ? dispatch(
                    MODALS.open(FindTraceabilityDialog, {
                      projectId: context.project.id,
                    })
                  )
                : undefined;
            }}
            title={context.msg.t('traceability')}
          />
        )}
        <BngIconButton
          icon="play_circle"
          title={context.msg.t('SchedulingPage.enable.all')}
          onClick={() => updateEnabledInSchedulings(true)}
        />
        <BngIconButton
          icon="pause_circle"
          title={context.msg.t('SchedulingPage.disable.all')}
          onClick={() => updateEnabledInSchedulings(false)}
        />
      </div>
    );
  };

  return (
    <div className={`SchedulingPage`}>
      <CrudPageLayout
        fetchData={fetchData}
        filters={filters}
        tableRows={filteredRows}
        loading={loading}
        emptyAlertMessage={context.msg.t('SchedulingPage.no.scheduling.found')}
        pageTitle={context.msg.t('send.scheduling')}
        tableSortMode={tableSortMode}
        setFilters={setFilters}
        tableColumns={tableColumns}
        selectedRows={selectedRows}
        headerButtons={headerButtons}
        setTableSortMode={setTableSortMode}
        pageButton={() => <FloatButton />}
      />
    </div>
  );
}

const FloatButton = () => {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  return (
    <BngIconButton
      icon={`add`}
      className={`info ${styles.addButton}`}
      onClick={() =>
        dispatch(MODALS.open(BngExportDialog, { schedulingType: Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED }))
      }
      title={context.msg.t('SchedulingPage.create.schedule')}
    />
  );
};

const buildTableColumns = () => {
  const context = useBimContext();

  return [
    {
      key: 'scheduling.identifier',
      label: context.msg.t('identifier'),
      sortable: true,
      size: 500,
      render: (row) => {
        return (
          <div className={`${styles.captionContainer}`}>
            <div className={`${styles.captionIcon}`}>
              <Icon icon={'schedule_send'} />
            </div>
            <span className={`caption ${styles.rowSpan}`}>{row.scheduling.identifier}</span>
          </div>
        );
      },
    },
    {
      key: 'scheduling.type',
      label: context.msg.t('SchedulingPage.frequency'),
      sortable: true,
      size: 200,
      render: (row) => {
        return (
          <div className={`${styles.frequencyHelpWrapper}`}>
            <span className={`caption ${styles.rowSpan}`}>{context.msg.t(row.scheduling.type)}</span>
            <HelpIcon title={row.scheduling.representativeString} />
          </div>
        );
      },
    },
    {
      key: 'messageType',
      label: context.msg.t('SchedulingPage.deliveryMethod'),
      sortable: true,
      size: 200,
      render: (row) => {
        return (
          <div className={`${styles.captionContainer}`}>
            <div className={`${styles.deliveryMethodIcon}`}>
              <Icon icon={Utils.Scheduling.iconForMsgType(row.messageType)} />
            </div>
            <span className={`caption ${styles.rowSpan}`}>{context.msg.t(row.messageType)}</span>
          </div>
        );
      },
    },
    {
      key: 'scheduling.createdBy.displayName',
      label: context.msg.t('SchedulingPage.responsible'),
      sortable: true,
      size: 200,
      render: (row) => {
        const displayName = Utils.Users.displayName(row.scheduling.createdBy) ?? '-';
        return (
          <div className={`${styles.labelContainer} ${styles.responsible}`} title={row.scheduling.createdBy.email}>
            <span className={`caption ${styles.rowSpan}`}>{displayName}</span>
          </div>
        );
      },
    },
    {
      key: 'scheduling.enabled',
      label: context.msg.t('status'),
      sortable: true,
      size: 120,
      render: (row) => {
        return (
          <div className={`${styles.labelContainer} ${row.scheduling.enabled ? styles.active : styles.inactive}`}>
            <span className={`caption ${styles.rowSpan}`}>
              {row.scheduling.enabled ? context.msg.t('active') : context.msg.t('inactive')}
            </span>
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: context.msg.t('actions'),
      size: 100,
      render: (row) => {
        return <SchedulingDropdown exportScheduling={row} />;
      },
    },
  ];
};

const filterRows = (selectedRows, filters, tableSortMode) => {
  const {
    searchTerm,
    filterButton: { type, status, periodicity, createdBy },
  } = filters;

  if (!_.isEmpty(searchTerm)) {
    selectedRows = selectedRows.filter((selectedRows) =>
      Utils.Strings.includesIgnoreCase(selectedRows.scheduling.identifier, searchTerm)
    );
  }

  if (!_.isEmpty(type)) {
    selectedRows = selectedRows.filter((selectedRow) => selectedRow.messageType === type);
  }

  if (!_.isEmpty(status)) {
    selectedRows = selectedRows.filter((selectedRow) => {
      if (status === 'active') {
        return selectedRow.scheduling.enabled;
      } else {
        return !selectedRow.scheduling.enabled;
      }
    });
  }

  if (!_.isEmpty(periodicity)) {
    selectedRows = selectedRows.filter((selectedRow) => selectedRow.scheduling.type === periodicity);
  }

  if (!_.isEmpty(createdBy)) {
    selectedRows = selectedRows.filter(
      (selectedRow) => selectedRow.scheduling.createdBy.email.toLowerCase() === createdBy.toLowerCase()
    );
  }

  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    selectedRows = _.orderBy(selectedRows, (row) => `${_.get(row, prop)}`.toLowerCase(), [direction.toLowerCase()]);
  });

  return selectedRows;
};

const SchedulingFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    type: yup.string().nullable().default(''),
    status: yup.string().nullable().default(''),
    periodicity: yup.string().nullable().default(''),
    createdBy: yup.string().nullable().default(''),
  });
});

const SchedulingFilterDropdown = ({ onChange = _.noop, initialValues = {}, createdByOpts = [] }) => {
  const { msg } = useBimContext();

  const typeOpts = useMemo(
    () =>
      FilterOpts({
        options: Object.values(Utils.Scheduling.DELIVERY_TYPE),
        hasIcon: true,
      }),
    []
  );

  const statusOpts = useMemo(
    () =>
      FilterOpts({
        options: [
          { name: 'active', color: '#00a355' },
          { name: 'inactive', color: '#f98900' },
        ],
        customIcon: (value) => <ColorDot color={value.color} />,
      }),
    []
  );

  const periodicityOpts = useMemo(
    () =>
      FilterOpts({
        options: ['Daily', 'Weekly', 'Monthly', 'Interval'],
      }),
    []
  );

  const createdByFilterOpts = useMemo(
    () =>
      FilterOpts({
        options: createdByOpts,
        useIdAsValue: true,
      }),
    [createdByOpts]
  );

  const initialFormValues = useMemo(() => _.merge({}, SchedulingFilterDropdownSchema.default(), initialValues), [
    initialValues,
  ]);

  const filterFields = [
    {
      name: 'type',
      label: msg.t('type'),
      options: typeOpts,
    },
    {
      name: 'status',
      label: msg.t('status'),
      options: statusOpts,
    },
    {
      name: 'periodicity',
      label: msg.t('frequency'),
      options: periodicityOpts,
    },
    {
      name: 'createdBy',
      label: msg.t('SchedulingPage.responsible'),
      options: createdByFilterOpts,
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      initialFormValues={initialFormValues}
      dropdownSchema={SchedulingFilterDropdownSchema}
      onChange={onChange}
    />
  );
};
