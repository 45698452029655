import React, {Component} from "react";
import Api from "../../../Api";

const uri = Api.baseUrl() + '/upload';

class ImageDash extends Component {

    static propTypes = {};

    static defaultProps = {
        dataImg: false
    };

    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        const src = this.props.dataImg ? this.props.image : `${uri}?${jQuery.param({content: this.props.image})}`;
        return (
            <img src={src} ref={ref => this.img = ref} className="fill-w fill-h"/>
        );
    }
}

export default ImageDash;
