import './ProjectMergeDialog.css';

import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';

import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import Button from 'components/ui/Button';
import Dialog from 'components/ui/Dialog';
import ReduxFormCheckbox from 'components/ui/ReduxFormCheckbox';
import { optionsFromRoots } from 'components/ui/common/SaveAsDialog';
import { Field, Validations, wrapForm } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';


const conflictsOrder = {
    connections: {validators: [Validations.maxLength(100)]},
    origins: {validators: [Validations.inMemoryName]},
    cockpits: {validators: [Validations.maxLength(30)]},
};

class ProjectMergeDialog extends React.Component {

    static propTypes = {
        closeModal: PropTypes.func,
        onMaximize: PropTypes.func
    };

    static defaultProps = {
        formValues: {}
    };

    state = {
        projects: [],
        availableFolders: [],
        message: 'none',
        loading: true,
    };

    validateFolderName = (value) => {
        return Validations.folderName(value) === undefined;
    };

    async componentDidMount() {
        const {projectId} = this.props;
        try {
            const allProjects = await Api.Navbar.projectsMenuDetails();
            const roots = await Api.Menu.findRootsFor({projectId});
            const projectData = await Api.ProjectApp.findInstalls(projectId);

            const projects = allProjects.filter(p => p.id !== projectId && (p.canImport || p.isMaster));

            projectData.installs.forEach(projectApp => {
                const match = projects.find(p => p.id === projectApp.app.id);
                if (match) {
                    match.disabled = true;
                    match.title = this.props.context.msg.t('app.already.installed', projectApp.install.path)
                }
            });

            projectData.installedOn.forEach(projectApp => {
                const match = projects.find(p => p.id === projectApp.app.id);
                if (match) {
                    match.disabled = true;
                    match.title = this.props.context.msg.t('project.already.installed.as.app', projectApp.install.path);
                }
            });

            const availableFolders = optionsFromRoots(roots);
            const currentProject = allProjects.find(p => p.id === projectId);

            availableFolders.unshift({value: `/${currentProject.name}`, label: '/', icon: '', depth: 0});

            this.setState({
                currentProject,
                projects,
                availableFolders
            });
        } catch (e) {
            UiMsg.ajaxError('', e);
        } finally {
            this.setState({loading: false});
        }
    }

    checkForConflicts = async (params) => {
        let gotConflict = false;
        if (!this.state.conflicts) {
            const conflictData = await Api.ProjectMerge.checkForConflicts(params);
            Object.entries(conflictData).forEach(([k, val]) => {
                val.conflicts.forEach(name => {
                    let newName = name + 'Import';
                    while (val.inUse.indexOf(newName) !== -1) {
                        newName += 'Import';
                    }
                    this.props.change(`${k}.${name}`, newName);
                    gotConflict = true;
                });
            });
            if (gotConflict) {
                this.setState({conflicts: conflictData});
            }
        }
        return gotConflict;
    };

    validateConflictResolution = (data) => {
        let msg = '';
        Object.keys(conflictsOrder).forEach(key => {
            let innerMsg = '';
            const vals = data[key];
            const repeatCheck = vals ? _.countBy(Object.values(vals)) : {};
            Object.entries(repeatCheck).forEach(([k, v]) => {
                if (v > 1) {
                    if (innerMsg.length === 0) {
                        innerMsg += `<b>${this.props.context.msg.t(key)}</b><ul>`;
                    }
                    innerMsg += `<li>${this.props.context.msg.t('merge.name.in.use', [v, k])}</li>`;
                }
            });
            if (innerMsg.length !== 0) {
                innerMsg += '</ul>';
                msg += innerMsg;
            }
        });
        return msg;
    };

    save = async (data) => {
        data.folder = (data.folder || '').trim();
        const params = {
            importObjects: data.importObjects,
            importData: data.importData,
            projectFrom: data.project,
            projectTo: data.projectId,
            parentFolder: data.parentFolder,
            folder: data.folder,
        };
        if (this.validateFolderName(data.folder) || !data.importObjects) {
            this.setState({message: 'none'});
            try {
                const gotConflict = await this.checkForConflicts(params);
                if (gotConflict) return;

                const msg = this.validateConflictResolution(data);
                if (msg.length > 0) {
                    UiMsg.warn('', msg);
                    return;
                }

                await Api.ProjectMerge.runMerge({
                    ...params,
                    origins: data.origins,
                    cockpits: data.cockpits,
                    connections: data.connections,
                });

                UiMsg.ok(this.props.context.msg.t('project.merge.success'));
                this.props.context.execute('#{projectSelectionMB.reloadCurrent()}');
                this.props.closeModal();
            } catch (ex) {
                const e = ex.response;
                if (e && e.status) {
                    switch (e.status) {
                        case 403:
                            UiMsg.warn(this.props.context.msg.t('project.merge.permission.denied.import'));
                            break;
                        case 409:
                            UiMsg.error(this.props.context.msg.t('project.merge.error'), e.data);
                            break;
                        default:
                            UiMsg.error(this.props.context.msg.t('project.merge.error'), e.data.message);
                            break;
                    }
                    return;
                }
                UiMsg.ajaxError(this.props.context.msg.t('project.merge.error'), e || ex);
            }
        } else {
            this.setState({message: 'inline'});
        }

    };

    canSave() {
        const {formValues} = this.props;
        return formValues.importData || formValues.importObjects;
    }

    render() {
        if (!this.props.initialized) {
            return null;
        }
        const {formValues} = this.props;
        const {msg} = this.props.context;
        const {projects, availableFolders, conflicts} = this.state;

        return (
            <Dialog open={this.props.open} className="large ProjectMergeDialog"
                    title={msg.t('import.project')}
                    onClose={this.props.closeModal}>
                <BlockUi tag="div" blocking={this.props.submitting || this.state.loading}>
                    <form onSubmit={this.props.handleSubmit(this.save)}>

                        <div className="row-fluid">
                            <div className="span6">
                                <Field label={msg.t('project')}
                                       name="project"
                                       parse={val => parseInt(val)}
                                       componentClass="select"
                                       validate={Validations.required}
                                       className="fill-w">
                                    <option value={''}>{msg.t('select.one')}</option>
                                    {projects.map(p => (
                                        <option key={p.id} value={p.id} disabled={p.disabled ?? false} title={p.title}>
                                            {p.displayName}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            {formValues.importObjects &&
                            <div className="span6">
                                <Field label={msg.t('folder')}
                                       name="parentFolder"
                                       componentClass="select"
                                       validate={Validations.required}
                                       className="fill-w">
                                    <option value={''}>{msg.t('select.one')}</option>
                                    {availableFolders.map((folder, idx) => (
                                        <option key={idx} value={folder.value}>
                                            {folder.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            }
                        </div>

                        {formValues.importObjects &&
                            <div className="row-fluid">
                                <div className="span12">
                                    <Field label={msg.t('new.folder.name')}
                                           className="fill-w"
                                           name="folder"
                                           autoComplete="off"
                                           validate={[
                                               Validations.required,
                                               Validations.folderName,
                                               Validations.notIn({
                                                   values: availableFolders.map(f => f.value),
                                                   valueTransformer: val => formValues.parentFolder + '/' + val
                                               })
                                           ]}
                                    />
                                    <span style={{
                                        display: this.state.message,
                                        color: '#700'
                                    }}>{msg.t('folder.name.only.alphanumeric')}</span>
                                </div>
                            </div>
                        }

                        <div className="row-fluid">
                            <div className="span12">
                                <Field label={msg.t('import.analytical.objects')}
                                       name="importObjects"
                                       groupClass="inline-checkbox"
                                       componentClass={ReduxFormCheckbox}
                                />
                            </div>
                        </div>

                        <div className="row-fluid">
                            <div className="span12">
                                <Field label={msg.t('import.data.structures')}
                                       name="importData"
                                       groupClass="inline-checkbox"
                                       componentClass={ReduxFormCheckbox}
                                />
                            </div>
                        </div>

                        {conflicts &&
                        <div>
                            <fieldset>
                                <legend>{msg.t('conflicts')}</legend>
                            </fieldset>
                            <div className="row-fluid">
                                {Object.entries(conflictsOrder).map(([key, additional], idx) => {
                                    const val = conflicts[key];
                                    if (!_.isObject(val)) return null;

                                    const fieldValidations = [
                                        Validations.required,
                                        Validations.notIn({
                                            values: [...val.inUse, ...val.conflicts]
                                        }),
                                        ...additional.validators
                                    ];

                                    return (
                                        <div key={idx} className="span4 conflict-box">
                                            <h5 className="conflict-title">
                                                {this.props.context.msg.t(key)}
                                            </h5>

                                            <div className="conflict-body scrollbar-outer" ref={r => j(r).scrollbar()}>
                                                {val.conflicts.map((e, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <Field
                                                                className="fill-w"
                                                                label={e}
                                                                name={`${key}.${e}`}
                                                                validate={fieldValidations}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        }

                        <hr/>
                        <div className="row-fluid">
                            <div className="span12 text-right btn-fix">
                                <Button onClick={this.props.closeModal}
                                        className="bng-button cancel"
                                        disabled={this.props.submitting}>
                                    {msg.t('cancel')}
                                </Button>
                                {' '}
                                <Button type="submit"
                                        className="bng-button save"
                                        disabled={!this.canSave()}
                                        loading={this.props.submitting}>
                                    {msg.t('import')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </BlockUi>
            </Dialog>
        );
    }

}

export default ContextEnhancer(
    wrapForm({
            component: ProjectMergeDialog,
            formName: 'projectMergeDialog',
            mapToState: (state) => ({
                initialValues: {
                    ...state.modals.current.params,
                    importData: true,
                    importObjects: true,
                }
            })
        }
    )
);