import './ContainerMenu.css';

import React from 'react';
import PropTypes from 'prop-types';

import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import Icon from "components/ui/common/Icon";
import ContainerDescription from "components/ui/dashboard/components/ContainerDescription";
import EmptyComponent from "components/ui/EmptyComponent";
import {processMarkdown} from "components/bng/form/markdown/MarkdownViewer";

class ContainerMenu extends React.Component {

    static propTypes = {
        editMode: PropTypes.bool,
        item: PropTypes.any,
        itemsData: PropTypes.object,
        currentBreakpoint: PropTypes.object,
        style: PropTypes.object,
        containerMenuComponent: PropTypes.any,
    };

    static defaultProps = {
        item: {},
        editMode: false,
        style: {},
        containerMenuComponent: EmptyComponent
    };

    state = {
        openTitleDialog: false,
        resizeContent: true,
        processedDescription: {
            markdown: '',
            result: '',
        },
    };

    componentDidMount() {
        this.setState({resizeContent: this.props.item.additionalProps.resizeContent});
        this.processDescription();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.processDescription();
    }

    updateContainer = async (e) => {
        const resizeContent = !this.state.resizeContent;
        this.setState({resizeContent});
        try {
            await Api.Dash.updateContainerItem({
                order: this.props.item.order,
                ...this.props.item.additionalProps,
                resizeContent,
            });
            await Api.updateJsf();
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError('error', e);
        }
    };

    processDescription = async () => {
        const description = this.props.item?.additionalProps?.description || '';
        if (description === this.state.processedDescription.markdown) return;

        const result = await processMarkdown(description);
        this.setState({processedDescription: {markdown: description, result}});
    }

    render() {
        const {additionalProps} = this.props.item;
        const LazyContainerMenuEditor = this.props.containerMenuComponent;
        const isTitleAndIconBlank = additionalProps.title === '' && additionalProps.icon?.name === '' ? 'blank-icon-and-title' : 'with-title';

        return (
            <div
                className={`container-dropdown-menu ${additionalProps.showTitle ? isTitleAndIconBlank : 'without-title'} ${this.props.editMode ? 'edit-mode' : ''}`}>
                {additionalProps.showTitle &&
                <>
                    <div className="container-title">
                        {additionalProps.icon &&
                        <Icon type={additionalProps.icon.type}
                              icon={additionalProps.icon.name}/>
                        }
                        <span title={additionalProps.title}>{additionalProps.title}</span>
                    </div>
                    {additionalProps.description &&
                    <div title={this.state.processedDescription.result}
                         className="iconDescription">
                        <ContainerDescription icon={additionalProps.icon ? additionalProps.icon.name : ''}
                                              titleContainer={additionalProps.title}
                                              description={this.state.processedDescription.result}
                        />
                    </div>
                    }
                </>
                }
                {this.props.editMode &&
                <LazyContainerMenuEditor {...this.props}/>
                }
            </div>
        )
    }

}

export default ContextEnhancer(ContainerMenu);